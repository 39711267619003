@import "assessment_variable";

.ability-ranking-result {
  border-bottom: 1px solid $color-gray;
  margin-top: 20px;
  padding-bottom: 16px;

  &__ {
    &title {
      color: $color-uiblue;
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 20px;
      text-align: center;
    }

    &detail-link {
      font-size: 12px;
      line-height: 1;
      display: block;
      text-align: center;
      margin-bottom: 16px;
    }

    &caution {
      color: $color-darkgray;
      font-size: 10px;
      line-height: 15px;
      padding-left: 10px;
      text-indent: -10px;
    }
  }
}

.ability-ranking-result-main {
  margin-bottom: 16px;

  &__note {
    color: $color-darkgray;
    font-size: 10px;
    line-height: 1;
    text-align: center;
  }
}

.ability-ranking-result-rank-list {
  display: flex;
  margin-bottom: 8px;

  .ability-ranking-result-rank {
    background-color: $assessment-bg-blue;
    border-radius: 4px;
    padding: 8px;
    text-align: center;
    width: 100%;

    &:not(:last-child) {
      margin-right: 2px;
    }

    &--in-process {
      background-color: $color-bluegray;
    }

    &__ {
      &label {
        color: $fgcolor-darkbg;
        font-size: 12px;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 4px;
      }

      &label-note {
        color: $fgcolor-darkbg;
        font-size: 10px;
        line-height: 1;
        margin-bottom: 10px;
      }

      &text {
        color: $fgcolor-darkbg;
        font-size: 24px;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 10px;
      }

      &text-note {
        color: $fgcolor-darkbg;
        font-size: 10px;
        line-height: 1;
      }
    }
  }
}
