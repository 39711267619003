.card {
  &.event-card {
    width: 100%;
    height: auto;

    & + .event-card {
      margin-top: 20px;
    }

    .card-link {
      color: $fgcolor-normal;
      z-index: 2;

      &:hover {
        text-decoration: none;
      }
    }

    &.event-card-exit {
      .event-list-current-status {
        height: 46px;
        padding: 0 15px;
        font-size: 24px;
        font-weight: 600;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq-sp {
          height: 26px;
          padding: 0 7px;
          font-size: 16px;
          border-radius: 4px;
        }

        &__ {
          &event-end {
            color: $color-white;
            background-color: $color-darkgray;
            border: 1px solid $shadow-24;
          }

          &full-house {
            color: $color-white;
            background-color: $color-red;
            border: 1px solid $color-red; // 他のラベルと高さを合わせるために同じ色のborderを指定している
          }

          &recruitment-end {
            color: $color-darkgray;
            background-color: $color-white;
            border: 1px solid $color-darkgray;
          }

          &stop {
            color: $color-red;
            background-color: $color-white;
            border: 1px solid $color-red;
          }
        }
      }

      .event-list-mv {
        position: relative;

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: $shadow-25;
          z-index: 1;
        }
      }
    }
  }
}
