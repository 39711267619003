// ブラウザデフォルトのリストスタイル(list-style-type: disc)に近い部品
// NOTE: mixin化も検討
.list {
  //NOTE: リストマークの左右の余白
  $spacing: 20px;
  //NOTE: リストマークのサイズ
  $size-circle: 7px;
  $line-height: 1.8;

  line-height: $line-height;
  padding-left: ($spacing * 2) + $size-circle;

  @include mq-sp {
    //NOTE: スマートフォンの時、リスト左右のスペースが半分になる
    padding-left: ($spacing * 2) / 2 + $size-circle;
  }

  li {
    position: relative;

    + li {
      margin-top: 12px;
    }

    &::before {
      display: inline-block;
      position: absolute;
      top: 0.5em * $line-height;
      left: -$spacing;
      margin-top: -$size-circle / 2;
      margin-left: -$size-circle;
      content: "";
      background-color: $basecolor-primary;

      @include circle($size-circle);

      @include mq-sp {
        left: -$spacing / 2;
      }
    }
  }
}

// アクションが無く情報量の少ない一覧で使用する部品
//<ul class="list-group">
//  <li class="list-group__item">
//    <a href="">
//      <figure class="list-group__media">
//        <img src="https://dummyimage.com/40x40/000/fff.png" />
//      </figure>
//      <div class="list-group__content">
//        <h2 class="list-group__heading--multiline-sp">タイトル</h2>
//        <p class="list-group__text--small">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//        <time class="list-group__text--small-right">2016/12/12</time>
//      </div>
//    </a>
//  </li>
//</ul>
.list-group,
.list-group--arrow {
  $thumbnail-width: 75px;
  $text-width: calc(100% - 15px);

  margin-left: -15px;
  margin-right: -15px;
  border-top: 1px solid $bdcolor-normal;
  background-color: $bgcolor-normal;

  @include mq-pc {
    width: 960px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $bdcolor-normal;
  }

  &__item {
    position: relative;
    border-bottom: 1px solid $bdcolor-normal;

    a {
      box-sizing: border-box;
      display: table;
      table-layout: fixed;
      width: 100%;
      padding: 10px 15px;
      color: $fgcolor-normal;
      text-decoration: none;
    }
  }

  &__media {
    display: table-cell;
    width: $thumbnail-width;

    span {
      @include media--square($thumbnail-width);
    }
  }

  &__content {
    display: table-cell;
    vertical-align: top;
    padding-left: 10px;
  }

  &__heading {
    font-size: 18px;
    line-height: 1.5;
    font-weight: normal;
    @include mq-sp {
      font-size: 14px;
    }
  }

  &__heading--multiline-sp {
    @extend .list-group__heading;
    @include mq-sp {
      overflow: hidden;
      $font-size: 14px;
      $line-height: 1.5;
      $line: 2;
      $height: $font-size * $line-height * $line;
      height: $height;
      width: 100%;
    }
  }

  &__text {
    font-size: $font-size-small;
  }

  &__text--small {
    font-size: $font-size-smaller;
    color: $fgcolor-supplemental;
  }

  &__text--small-right {
    @extend .panel__text--small;
    display: block;
    text-align: right;
  }

  &__text--single-line {
    @extend .list-group__text--small;
    @include text-over-flow($text-width);
  }

  &__item--single-line {
    margin-top: 5px;
    font-size: $font-size-smaller;
    color: $basecolor-normal;
    @include text-over-flow($text-width);
  }

  time {
    position: absolute;
    right: 15px;
    bottom: 10px;
  }

  &__question {
    position: relative;
    padding: 15px 15px 15px 45px;
    border-bottom: 1px solid $bdcolor-normal;
    cursor: pointer;

    &::before {
      display: inline-block;
      position: absolute;
      left: 15px;
      content: "Q.";
      font-size: $font-size-large;
      color: $fgcolor-link;
    }
  }

  &__answer {
    display: none;
    position: relative;
    padding: 15px 15px 15px 45px;
    background-color: $bgcolor-primary;

    a {
      display: block;
      padding: 20px 0 10px 0;
    }

    &::before {
      display: inline-block;
      position: absolute;
      left: 15px;
      content: "A.";
      font-size: $font-size-large;
      color: $fgcolor-error;
    }
  }
}

.list-group--arrow {
  &__item {
    a {
      position: relative;
      padding: 10px 35px 10px 15px;

      &::before {
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -17px;
        font-size: 34px;
        color: $basecolor-placeholder;
      }
    }
  }
}

.header--main + .list-group {
  margin-top: 30px;

  @include mq-sp {
    margin-top: 0;
  }
}

.list-group__item.is-unread {
  .list-group__text {
    font-weight: bold;
  }
}

.list-group__item.is-visited {
  background-color: $lightgray;

  .list-group__text {
    font-weight: normal;
  }
}

// 順番が決まっているリスト
.list-group--ordered {
  $thumbnail-width: 50px;

  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  border-top: 1px solid $bdcolor-normal;
  background-color: $bgcolor-input-checkbox;
  color: $fgcolor-supplemental;

  @include mq-pc {
    width: 960px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $bdcolor-normal;
  }

  &__item {
    position: relative;
    border-bottom: 1px solid $bdcolor-normal;

    a {
      box-sizing: border-box;
      display: table;
      width: 100%;
      padding: 10px 15px;
      color: inherit;

      &::after {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -12px;
        font-family: FontAwesome;
        content: "\f105";
        font-size: 24px;
      }

      &:hover {
        text-decoration: none;
      }
    }

    &.is-disabled {
      background-color: $bgcolor-button-hover;
      color: $fgcolor-disabled;

      .list-group--ordered__circle {
        border-color: $bgcolor-button-disabled;
        background-color: $bgcolor-button-disabled;
        color: $fgcolor-darkbg;
      }
    }

    &.is-active {
      background-color: $basecolor-primary-opacity;
      color: $basecolor-primary;

      .list-group--ordered__circle {
        position: relative;
        border-color: $basecolor-primary;
        background-color: $bgcolor-normal;
        color: $fgcolor-darkbg;

        &::before {
          position: absolute;
          top: 2px;
          left: 2px;
          z-index: 2;
          content: "";
          display: inline-block;
          @include circle(26px);
          background-color: $basecolor-primary;
        }
      }
    }
  }

  &__number {
    display: table-cell;
    width: 30px;
    padding-right: 10px;
    vertical-align: middle;

    &::before {
      position: absolute;
      top: 0;
      left: 30px;
      z-index: 1;
      content: "";
      display: block;
      width: 1px;
      height: 100%;
      background-color: $bdcolor-normal-dark;
    }
  }

  &__item:first-of-type {
    .list-group--ordered__number {
      &::before {
        top: 50%;
      }
    }
  }

  &__item:last-of-type {
    .list-group--ordered__number {
      &::before {
        top: auto;
        bottom: 50%;
      }
    }
  }

  &__circle {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $bdcolor-strong;
    @include circle(30px);
    background-color: $bgcolor-normal;
  }

  &__media {
    display: table-cell;
    width: $thumbnail-width + 10;

    span {
      @include media--square($thumbnail-width);
    }
  }

  &__content {
    display: table-cell;
    vertical-align: middle;
    padding-right: 15px;
  }
}

.list-group--ordered__circle {
  span {
    position: relative;
    z-index: 3;
  }
}

.list-group__heading--multiline {
  $line-height: 1.7;
  $line: 2;
  $height: $font-size-small * $line-height * $line;

  overflow: hidden;
  font-size: $font-size-small;
  line-height: 1.7;
  height: $height;
  width: 100%;
}

* + .list-group--ordered {
  margin-top: 15px;
}

//リストが一つしかない時はアイコンの上下に線を出さない
.list-group--ordered__item:only-of-type {
  .list-group--ordered__number::before {
    display: none;
  }
}

.content--empty + .list-group {
  border-top: 0;
}

.segment-control + .list-group--arrow,
.list-group--arrow + .list-group--arrow {
  margin-top: 15px;
}
