// ===================================================================
// Contents-wrapper
// ===================================================================
.candidate-profile-promotion-banner {
  background-color: $bgcolor-primary;
  border-radius: 2px;
  margin-bottom: 38px;
  padding: 10px 20px;

  @include mq-sp {
    padding: 16px;
    font-size: 14px;
    margin-left: -15px;
    margin-right: -15px;
  }

  &__text {
    padding-bottom: 5px;

    @include mq-sp {
      padding-bottom: 16px;
    }
  }

  &__list-item {
    position: relative;

    &__link {
      padding: 5px 0;
      display: block;
      border-top: 1px solid $color-gray;
      line-height: 24px;

      @include mq-sp {
        padding: 8px 0;
      }

      &:hover {
        color: $fgcolor-link-hover;
        text-decoration: none;
      }
    }

    &:last-child {
      a {
        padding-bottom: 0;
      }
    }
  }
}


.candidate-profile-promotion-banner + .profile-candidate-contents-wrapper {
  @include mq-pc {
    margin-top: 40px;
  }
}

.profile-candidate-contents-wrapper {
  display: table;
  width: 100%;
}

.profile-candidate-left-contents-registration-information {
  display: inline-block;

  &__yomi {
    font-size: 12px;
    line-height: 1;
  }

  > li:first-child {
    display: inline-block;
  }

  .profile-candidate-left-contents-ad {
    &::after {
      content: "/";
      margin-left: 4px;
    }
  }
}

.profile-candidate-basic-infomation {
  @include mq-sp {
    display: inline-block;
    margin-left: 5px;
    max-width: 200px;
  }
}

// ===================================================================
// Left-contents
// ===================================================================
.profile-candidate-left-contents-box {
  display: table-cell;

  @include mq-sp {
    display: block;
  }
}

.profile-candidate-left-contents-inner {
  width: 250px;
  text-align: center;

  @include mq-sp {
    width: auto;
    margin-top: 30px;
    text-align: left;
  }
}

.profile-candidate-left-contents-day {
  &::after {
    content: "生";
    margin-left: 5px;
  }
}

.profile-candidate-name {
  font-size: 24px;
  word-break: break-all;

  @include mq-sp {
    font-size: 20px;
  }
}

.profile-candidate-gender {
  i {
    color: $fgcolor-primary-theme;
    font-size: 17px;

    @include mq-sp {
      font-size: 14px;
    }
  }
}

.profile-candidate-left-contents-registration-information-edit {
  margin-top: 20px;

  li {
    @include clearfix();
    position: relative;

    i {
      color: $fgcolor-primary-theme;
      font-size: 20px;
      position: relative;
      top: 3px;

      @include mq-sp {
        font-size: 18px;
      }
    }

    .profile-candidate-left-contens-list {
      display: inline-block;
      word-break: break-all;

      @include mq-sp {
        width: auto;
      }
    }

    .profile-candidate-left-contens-list.profile-candidate-left-contens-list-password {
      @include mq-sp {
        line-height: 38px;
      }
    }

    .profile-candidate-left-contens-change-link {
      margin-left: 20px;

      @include mq-sp {
        @include btn;
        position: absolute;
        right: 0;
        width: 65px;
        height: 30px;
        font-size: 14px;
        padding: 6px 5px;
        line-height: 1.28;
      }
    }
  }
}

.profile-candidate-left-contents-images {
  margin-bottom: 10px;
}

.profile-candidate-profile-image-wrapper {
  @include round-bd-image(120px);
  position: relative;
  display: inline-block;
  background: url(/assets/img/default-profile-img.png) no-repeat center center;
  background-size: cover;

  @include mq-sp {
    border: 1px solid $bdcolor-normal;
    border-radius: 100%;
    width: 60px;
    height: 60px;
  }

  .profile-candidate-profile-image {
    position: absolute;
    width: 120px;
    height: 120px;
  }
}

// ===================================================================
// Right-contents
// ===================================================================
.profile-candidate-right-contents {
  display: table-cell;
  vertical-align: top;
  width: 100%;
  padding-top: 14px; // 編集ボタンが絶対値指定されていたため、上部の余白の調整をしている

  @include mq-sp {
    margin-top: 60px;
    width: 100%;
    display: block;
  }

  h2.heading {
    @include mq-sp {
      font-size: 18px;
    }
  }

  h4 + p,
  h4 + ul,
  h4 .note,
  .profile-candidate-section-category-tbl,
  .profile-candidate-section-category-tbl h5,
  .profile-candidate-section-intern-p,
  .profile-candidate-section-p {
    @include mq-sp {
      font-size: 14px;
    }
  }
}

.profile-candidate-left-contents + .profile-candidate-right-contents {
  margin-top: 40px;
}

.profile-candidate-section + .profile-candidate-section {
  margin-top: 40px;
}

.profile-candidate-section {
  &__ {
    &header {
      line-height: 36px; // Android の古い端末だと表示が崩れるのでここだけ明示的に指定している
    }
  }

  p.note {
    @include mq-sp {
      font-size: 14px;
    }
  }

  .profile-candidate-section-title-wrapper {
    position: relative;

    button {
      position: absolute;
      right: 0;
      top: -65%;

      @include mq-sp {
        top: -25%;
      }
    }
  }

  .profile-candidate-section-contents-wrapper {
    margin-top: 20px;
  }

  .profile-candidate-section-category-contents {
    span:not(:last-child) {
      &:after {
        content: "、";
      }
    }
  }


  .profile-candidate-section-sub-category {
    &-wrapper {
      margin-top: 8px;
    }

    &-title {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5;
    }

    &-contents {
      font-size: 14px;
      line-height: 1.5;

      &:not(:last-child) {
        &:after {
          content: "、";
        }
      }
    }
  }

  .profile-candidate-section-category-title {
    margin-top: 24px;
  }

  .profile-candidate-section-category-sub-title {
    width: 120px;
    text-align: left;
  }

  .profile-candidate-section-category-programming {
    float: left;
  }

  .profile-candidate-section-category-programming-language {
    padding-left: 130px;

    > li + * {
      margin-top: 10px;
    }
  }

  .profile-candidate-section-intern-contents-detail {
    margin-top: 20px;
  }
}

.profile-candidate-section-category-programming-language-title {
  display: inline;

  &:after {
    content: "：";
  }
}

.profile-candidate-section-academic-major-category {
  &:before {
    content: "（";
  }

  &:after {
    content: "）";
  }
}

.profile-candidate-club + .profile-candidate-club {
  border-top: 1px solid $bdcolor-normal;
  margin-top: 30px;
}

.profile-career-values {

  &-items {
    margin-top: 24px;
  }

  &-item + &-item {
    margin-top: 20px;

    @include mq-sp {
      margin-top: 16px;
    }
  }

  &-question {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;

    @include mq-sp {
      font-size: 14px;
      line-height: 21px;
    }
  }

  &-answer-bar {
    margin-top: 8px;
    display: flex;
  }

  &-label {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;

    &-text {
      font-size: 14px;
      line-height: 21px;
      max-width: 320px;
      white-space: break-spaces;

      @include mq-sp {
        font-size: 12px;
        line-height: 18px;
        max-width: 132px;
      }
    }
  }

  &-square {
    width: 100%;
    height: 8px;

    & + .profile-career-values-square {
      margin-left: 4px;

      @include mq-sp {
        margin-left: 2px;
      }
    }

    &-checked {
      background-color: $color-uiblue;
    }

    &-unchecked {
      background-color: $color-lightgray;
    }
  }

  &-show-all-button {
    margin-top: 16px;
    text-align: right;

    &-text {
      font-size: 16px;
      line-height: 24px;
      color: $color-uiblue;
    }
  }
}

.profile-candidate-workplace {
  &-text {
    font-size: 14px;
    line-height: 1.5;
  }
}

// ===================================================================
// Btn
// ===================================================================
.profile-candidate-section-edit-btn {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -20px;
  width: 100px;
}

.profile-candidate-left-contents-edit-btn-wrapper {
  text-align: center;

  @include mq-sp {
    display: inline-block;
    position: absolute;
    right: 0;
  }
}

.profile-candidate-left-contents-edit-btn {
  width: 100px;
  padding: 6px 5px;
  margin-top: 10px;
  line-height: 22px;

  @include mq-sp {
    width: 65px;
    height: 30px;
    font-size: 14px;
    line-height: 1.28;
  }
}

.profile-candidate-contents-inner-btn {
  height: 29px;
  margin-top: 8px;
  padding: 0 20px;
  font-size: 14px;
  line-height: 1.5;
}

.candidate-profile-unanswered {
  display: none;
}

// ===================================================================
// Icon
// ===================================================================

.profile-candidate-left-contents-wrapper {
  @include mq-sp {
    position: relative;
  }
}

.profile-candidate-section-tags {
  list-style-type: none;
  margin-top: 10px;
  > li {
    display: inline-block;
    font-size: $font-size-small;
    margin-right: 10px;

    &::before {
      content: "#";
    }
  }
}

.profile-candidate-section-body {
  font-size: $font-size-small;
  white-space: pre-wrap;
  margin-top: 10px;
}
