.global-candidate-notice-modal {
  background-color: $bgcolor-overlay;
  box-sizing: border-box;
  @include mq-sp {
    padding: 0 16px;
  }

  &__inner {
    color: $color-black;
    font-size: 16px;
    line-height: 24px;
    transition: none;
    max-height: 95vh;
    overflow: auto;

    @include mq-pc {
      padding: 32px 40px;
      width: 592px;
      max-width: 592px;
    }

    @include mq-sp {
      padding: 24px;
      margin: 0 auto;
    }
  }
}

.global-candidate-notice-modal-header {
  text-align: center;

  &__title {
    font-weight: bold;
  }

  &__text {
    line-height: 21px;
    margin-top: 16px;
    text-align: left;
  }
}

%check-item-description-sp {
  @include mq-sp {
    font-size: 14px;
    line-height: 21px;
  }
}

.global-candidate-notice-modal-body {
  @include mq-pc {
    margin: 32px auto 0;
    width: 448px;
  }

  @include mq-sp {
    margin-top: 24px;
  }

  &__check-item {
    font-weight: bold;

    &-description {
      padding-left: 28px;
      @extend %check-item-description-sp;
    }

    &-description-list {
      list-style-type: none;
      padding-left: 28px;
      @extend %check-item-description-sp;

      & > li::before {
        content: "・";
      }
    }

    &-additional-text {
      margin-top: 4px;
      padding-left: 28px;
      color: $color-darkgray;
      font-size: 14px;
      line-height: 21px;
      @include mq-sp {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

.global-candidate-notice-modal-body__check-item-list > li + li {
  margin-top: 16px;
}

.global-candidate-notice-modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 32px;

  @include mq-sp {
    flex-direction: column-reverse;
    height: 110px;
  }

  &__btn {
    width: 248px;

    @include mq-sp {
      width: 100%;
    }
  }
}
