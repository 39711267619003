.staticpage-area {
  background-color: $bgcolor-normal;
  padding: 40px;

  @include mq-sp {
    padding: 20px;
    font-size: $font-size-small;
  }

  .staticpage-area-title {
    padding-bottom: 10px;
    border-bottom: 1px solid $basecolor-primary;
    color: $basecolor-primary;
    font-weight: 500;
    @include mq-sp {
      font-size: $font-size-basic;
    }
  }

  .staticpage-area-subtitle {
    margin-top: 20px;
    @extend .heading;
  }
}

.staticpage-heading {
  @include mq-sp {
    margin-top: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  + .staticpage-area {
    margin-top: 10px;
  }
}

.staticpage-president {
  text-align: right;
  margin-bottom: 5px;
}
