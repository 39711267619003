.staticpage-area {
  margin-top: 30px;

  section + section {
    margin-top: 40px;
  }

  .staticpage-area-title {
    margin-bottom: 10px;
  }
}
