.certification-send {
  @include mq-sp {
    margin-top: 10px;
    padding: 3px 6px;
  }

  &__description {
    width: 929px;
    padding: 16px;
    background-color: $color-lightgray;
    color: $color-black;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;

    @include mq-sp {
      width: auto;
      padding: 16px 24px;
    }
  }
}
