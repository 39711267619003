.visit-guide-footer {
  margin-top: 30px;

  &__btn-back {
    margin-right: 20px;
    @include mq-sp {
      margin-bottom: 15px;
    }
  }

  &__btn-search {
    @include mq-sp {
      margin-bottom: 25px;
    }
  }
}
