.l-no-session {
  .header {
    border-bottom: none;
    background-color: $color-white;
    @include mq-pc {
      height: 70px;
    }
  }

  .l-fixed__header {
    margin-top: 60px;
    @include mq-pc {
      margin-top: 70px;
    }

    &.is-app {
      margin: 0;
    }
  }
}
