.company {
  @include mq-sp {
    background-color: $bgcolor-primary;
  }

  &__section {
    @include mq-sp {
      padding: 16px 15px 0 15px;
      background-color: $bgcolor-normal;
    }

    &--derivation {
      @include mq-sp {
        background-color: $bgcolor-primary;
      }
    }
  }

  &__title {
    font-size: $font-size-3L;
    font-weight: bold;
    line-height: 1;
    @include mq-sp {
      position: relative;
      font-size: $font-size-small;
      color: $fgcolor-supplemental;
    }

    i::before {
      font-size: $font-size-LL;
      display: inline-block;
      vertical-align: -1px;
    }
  }

  .global-footer {
    @include mq-sp {
      display: none;
    }
  }
}

a.company__title__link {
  margin-left: 16px;
  font-size: $font-size-small;
  color: $fgcolor-primary-theme;
  @include mq-sp {
    position: absolute;
    top: -3px;
    right: 0;
    color: $fgcolor-link;
    font-weight: normal;
  }
}

.company-follow {
  .company__title {
    @include mq-sp {
      display: none;
      margin-top: 0;
    }
  }
}
