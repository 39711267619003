// sass-lint:disable nesting-depth
@import "../../vendor/fontawesome";

.scout-message-header {
  h1 {
    font-weight: normal;
  }

  + * {
    margin-top: 30px;
  }
}

.scout-message-wrap {
  position: relative;
  background-color: $bgcolor-normal;
  padding: 70px 70px 0;

  @include mq-sp {
    padding: 15px 15px 0;
  }

  header {
    .scout-message-info {
      position: relative;

      @include mq-sp {
        display: flex;
        align-items: center;
        font-size: 12px;
      }

      > * {
        margin: 0 5px 0 0;

        &:first-child {
          margin-left: 0;
        }
      }

      figure {
        @include round-bd-image();
        display: inline-block;
        margin-left: 0;
        margin-right: 0;
        vertical-align: middle;
        flex-shrink: 0;
      }

      span {
        display: inline-block;
        max-width: 620px;
        vertical-align: middle;
        @include mq-sp {
          flex: auto;
          order: 2;
          align-self: center;
          margin-right: 10px;
          max-width: 145px;
        }
      }
    }

    .scout-message-time {
      position: absolute;
      right: 0;
      top: 20px;

      @include mq-sp {
        order: 3;
        flex-shrink: 0;
        align-self: flex-start;
        position: absolute;
        top: 0;
        font-size: 12px;
      }
    }
  }

  .scout-message-text {
    border-top: 1px solid $bdcolor-normal;
    padding-top: 10px;
    margin-bottom: 16px;
    word-break: break-all;
  }

  footer {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;

    .scout-accept-btn {
      width: 200px;
      display: block;
      margin-right: auto;
      margin-left: auto;

      @include mq-sp {
        width: 100%;
      }

      + * {
        margin-top: 30px;
      }

      &.is-disabled {
        background-color: $basecolor-primary-opacity;
        color: $basecolor-primary;

        &::before {
          content: "";
          display: inline-block;
          position: relative;
          top: 5px;
          width: 22px;
          height: 22px;
          margin-right: 10px;
          background: url("/assets/icon/check-icon-blue.png") no-repeat;
          background-size: 100%;
        }
      }
    }

    .scout-limit-btn.scout-accept-btn {
      background-color: $bgcolor-button-disabled;
      color: $fgcolor-button-disabled;
      display: block;
      margin-left: auto;
      margin-right: auto;

      &:before {
        display: none;
      }
    }

    .scout-accept-text {
      color: $color-darkgray;
      font-size: $font-size-smaller;

      + * {
        margin-top: 30px;
      }

      & + .scout-reaction {
        margin-top: 48px;
      }
    }
  }
}

.scout-reaction {
  &.scout-reaction--center {
    text-align: center;
  }
  & + img {
    margin-top: 32px;
  }
  &-button {
    font-weight: bold;
    border: none;
    background-color: transparent;
    color: $color-uiblue;
    & + .scout-reaction-description {
      margin-top: 8px;
    }
  }
  &-description {
    color: $color-darkgray;
    font-size: $font-size-smaller;
  }
}

.scout-popup-matching {
  box-shadow: 0 1px 3px $shadow-39 inset;

  p {
    font-size: 14px;
  }

  header {
    position: relative;

    .scout-matching-bg {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;

      &.invert {
        left: auto;
        right: 0;
        transform: scale(-1, 1);
      }
    }

    + * {
      margin-top: 20px;

      @include mq-sp {
        margin-top: 10px;
      }
    }
  }

  .scout-popup-title {
    width: 328px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 2px;
    border-bottom: 2px solid $bdcolor-primary-theme;

    &::after {
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 8px;
      border-bottom: 1px solid $bdcolor-primary-theme;
      content: "";
    }

    + .scout-popup-lead {
      font-size: 20px;

      @include mq-sp {
        font-size: 16px;
        margin-left: -10px;
        margin-right: -10px;
      }
    }

    @include mq-sp {
      width: auto;
    }

    img {
      @include mq-sp {
        max-width: calc(100% - 20px);
      }
    }
  }

  .scout-special-title {
    @extend .scout-popup-title;
    width: 210px;
  }

  .scout-popup-lead {
    margin-top: 20px;
    color: $basecolor-tertiary;

    + * {
      margin-top: 20px;
    }
  }

  .scout-popup-images {
    @include mq-sp {
      margin-right: -5px;
      margin-left: -5px;
    }

    figure {
      @include round-bd-image(80px);
      display: inline-block;
      margin: 0 10px;
    }
  }

  .scout-popup-message-important {
    font-weight: bold;
  }
}

.encourage-email-change-modal {
  background-color: $shadow-6;

  &-hide {
    display: none;
  }

  &__ {
    &middle {
      padding: 24px;
      border-radius: 10px;
      width: 480px;

      @include mq-pc {
        max-width: 480px;
      }
      @include mq-sp {
        max-width: calc(100% - 48px);
        margin: 0 auto;
      }
    }
    &title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 24px;
      text-align: center;
    }
    &email {
      &-title {
        margin-bottom: 4px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
      }
      &-content {
        padding: 8px;
        margin-bottom: 16px;
        background-color: $color-lightgray;
        border-radius: 10px;
        font-size: 16px;
        line-height: 1.5;
        word-break: break-all;

        &-caution {
          color: $color-pink;
        }
      }
    }
    &message {
      margin-bottom: 24px;
      &-caution {
        color: $color-pink;
      }
    }

    &action {
      display: flex;
    }

    &do-nothing-btn {
      @extend .button--normal;
      height: 44px;
      margin-right: 10px;
      padding: 10px;
      font-size: 16px;
      line-height: 16px;
    }

    &change-btn {
      @include button;
      @include btn-states;
      color: $color-white;
      background-color: $color-uiblue;
      border: 2px solid $color-uiblue;
      height: 44px;
      padding: 10px;
      font-size: 16px;
      line-height: 16px;
    }
  }
}

.scout-special-contents {
  display: none;
}

.scout-special {
  .scout-contents {
    display: none;
  }

  .scout-special-contents {
    display: block;
  }

  .scout-message-wrap {
    background: $color-white;

    header {
      padding-top: 25px;

      @include mq-sp {
        padding-top: 40px;
      }

      h1 {
        width: 408px;
        margin: 0 auto;
        padding-bottom: 3px;
        border-bottom: 3px solid $bdcolor-primary-theme;
        text-align: center;

        @include mq-sp {
          width: auto;
          margin: 0 20px;
          img {
            max-width: calc(100% - 20px);
          }
        }

        &::after {
          display: block;
          width: 100%;
          height: 1px;
          margin-top: 8px;
          border-bottom: 1px solid $bdcolor-primary-theme;
          content: "";
        }
      }

      .scout-special-contents {
        + * {
          margin-top: 40px;

          @include mq-sp {
            margin-top: 20px;
          }
        }
      }
    }

    footer {
      padding-bottom: 40px;

      .scout-accept-btn {
        + * {
          margin-top: 45px;
        }
      }

      img {
        max-width: 100%;
      }
    }
  }

  .scout-ornament-top {
    position: absolute;
    top: -11px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 55px;
    height: 82px;

    @include mq-sp {
      top: -8px;
      width: 34px;
      height: 50px;
    }
  }

  .scout-ornament-bottom {
    @extend .scout-ornament-top;
    top: auto;
    bottom: -11px;
    height: 26px;
  }

  .scout-popup-matching {
    > section {
      padding-top: 60px;
      padding-bottom: 40px;
      background-color: transparent;
      background-image: url("/assets/img/scout-special-bg02.jpg");
      background-position: center;
      background-size: cover;

      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        background-color: $shadow-31;
        content: "";
      }

      > * {
        position: relative;
      }
    }

    .scout-ornament-top {
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 34px;
      height: 50px;
    }

    .scout-ornament-bottom {
      @extend .scout-ornament-top;
      top: auto;
      bottom: -1px;
      height: 17px;
    }
  }

  .scout-popup-special {
    @extend .scout-popup-matching;

    > section {
      padding-top: 70px;
      padding-bottom: 50px;
      background-image: url("/assets/img/scout-special-bg01.jpg");
    }
  }
}

.scout-limit {
  color: $fgcolor-supplemental;

  dt,
  dd {
    display: inline;
    font-size: 12px;
  }

  dt:after {
    content: "：";
  }
}

.scout-limit + .scout-accept-btn {
  margin-top: 10px;
}

.l-scout-body {
  .popup-content {
    @include mq-sp {
      padding: 18px;
    }

    .popup-btn-area {
      @include mq-sp {
        margin-top: 15px;
        z-index: auto;
      }
    }
  }

  .lead-to-certification {
    @include mq-sp {
      padding: 0;
    }
  }
}

