.recommend-companies {
  margin-top: 29px;
  border-top: 1px solid $bdcolor-normal;
  @include mq-sp {
    margin-top: 19px;
  }

  &__ {
    &item {
      box-sizing: border-box;
      border-bottom: 1px solid $bdcolor-normal;
      @include mq-sp {
        background-color: $bgcolor-normal;
      }
    }

    &right {
      flex: 1;
      text-align: left;
      min-width: 0;
    }

    &avatar {
      width: 40px;
      height: 40px;
      flex-basis: 40px;
      border-radius: 20px;
      display: block;
      margin-right: 10px;
      margin-left: 33px;
      box-sizing: border-box;
      border: 1px solid $bdcolor-normal;
      @include mq-sp {
        margin-left: 48px;
      }
    }

    &label {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      margin: 0;
      padding: 15px 0;
      position: relative;
      box-sizing: border-box;

      &:hover {
        cursor: pointer;
      }

      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        width: 18px;
        height: 18px;
        margin-top: -10px;
        background: $bgcolor-input-checkbox;
        border: 1px solid $color-black-6;
        z-index: 1;
        @include mq-sp {
          left: 15px;
        }
      }

      &:after {
        content: " ";
        display: block;
        width: 5px;
        height: 10px;
        border: solid $bdcolor-focus;
        border-width: 0 2px 2px 0;
        position: absolute;
        left: 1px;
        top: calc(50% - 5px);
        margin-top: -3px;
        transform: scale(0) rotate(45deg);
        transform-origin: right bottom;
        z-index: 2;
        transition: 0.075s linear;
        @include mq-sp {
          left: 16px;
        }
      }
    }

    &checkbox {
      display: none;

      &:checked {
        & + .recommend-companies__label {
          &:after {
            transform: scale(1) rotate(45deg);
          }
        }
      }
    }

    &name {
      font-size: $font-size-basic;
      line-height: $font-size-basic + 5;
      @include text-over-flow(none);
      @include mq-sp {
        font-size: $font-size-small;
      }
    }

    &industry {
      margin-top: 4px;
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;
      font-size: $font-size-small;
      color: $fgcolor-normal;
      font-weight: normal;
      @include text-over-flow(none);
      @include mq-sp {
        $follow-company-industry-width: calc(100% - 100px);
        @include text-over-flow($follow-company-industry-width);
      }

      &:empty {
        padding-right: 0;
      }
    }

    &ob {
      margin-top: 4px;
      display: inline-block;
      vertical-align: middle;
      font-size: $font-size-small;
      color: $fgcolor-primary-theme;

      .item-count {
        padding-left: 10px;
        font-weight: normal;
        color: $fgcolor-normal;
      }
    }
  }

  & + .btn-secondary {
    margin-top: 30px;
    transition: 0.075s linear;
  }
}

.btn-secondary--recommend-company {
  opacity: 0.3;
  pointer-events: none;
}

.resister-form-area {
  &__ {
    &recommend-company-text {
      text-align: center;
      margin-top: 15px;
      @include mq-sp {
        font-size: $font-size-small;
        text-align: left;
        padding: 0 20px;
        margin-top: 10px;
      }

      br {
        @include mq-sp {
          display: none;
        }
      }
    }

    &recommend-company-skip-link {
      @extend .resister-form-area__recommend-company-text;
      @include mq-sp {
        text-align: center;
      }
    }
  }
}
