.event-company {
  @include clearfix;

  .event-company-logo-wrapper {
    @include round-bd-image();
    width: 40px;
    height: 40px;
    border: 1px solid $bdcolor-normal;
    border-radius: $bdradius-circle;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    z-index: 1;
    vertical-align: middle;

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }
  }

  .event-company-name {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
}

@include mq-sp(660px) {
  .event-company {
    .event-company-logo-wrapper {
      display: inline-block;
      vertical-align: middle;
      width: 35px;
      height: 35px;
    }

    .event-company-name {
      margin-left: 5px;
      font-size: $font-size-smaller;
      display: inline-block;
      vertical-align: middle;
      @include text-over-flow(200px);
    }
  }
}
