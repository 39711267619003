// sass-lint:disable no-vendor-prefixes
.company-article {
  &__inner {
    position: relative;

    @include mq-sp {
      margin-top: 20px;
      margin-bottom: 48px;
    }

    & + * {
      margin-top: 30px;
    }
  }

  article + * {
    margin-top: 30px;
  }

  &__heading {
    display: flex;
    align-items: center;
    font-size: $font-size-LL;

    &__logo {
      @include round-bd-image();
      margin-right: 15px;

      @include mq-sp {
        width: 50px;
        height: 50px;
      }
    }

    &__company {
      display: flex;
      align-items: center;
      width: calc(100% - 80px);
      @include mq-sp {
        display: inline-block;
      }

      h1 {
        margin-right: 10px;
        display: inline;
        font-size: $font-size-LL;
        @include mq-sp {
          font-size: $font-size-small;
        }
      }
    }

    &__title {
      margin-top: 10px;
      font-size: 32px;

      @include mq-sp {
        font-size: $font-size-large;
      }
    }

    &__button {
      @extend .btn-primary;
      width: 120px;
      height: 35px;
      line-height: 1;
      font-size: $font-size-smaller;
      margin-top: 0;

      @include mq-sp {
        width: 100px;
        height: 30px;
      }

      span {
        @include mq-sp {
          display: none;
        }
      }
    }

    &__date {
      font-size: $font-size-small;
      color: $fgcolor-supplemental;

      @include mq-sp {
        float: left;
      }
    }

    &__tag {
      box-sizing: border-box;
      display: inline-block;
      margin-left: 15px;
      border: 1px solid $bdcolor-primary-theme;
      border-radius: 4px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: $font-size-small;
      height: 28px;
      line-height: 26px;
      color: $fgcolor-primary-theme;

      @include mq-sp {
        margin-top: -5px;
        float: right;
        height: 22px;
        line-height: 20px;
        font-size: $font-size-smaller;
      }
    }

    &__tag:empty {
      border: none;
    }

    &__under {
      @include clearfix();

      &-left {
        margin-top: 20px;
        float: left;
        @include mq-sp {
          margin-top: 30px;
        }
      }

      &-right {
        float: right;
        margin-top: -15px;
        width: 70px;
        text-align: center;
        @include mq-sp {
          margin-top: 15px;
        }
      }
    }
  }

  &__main-image__cover {
    position: relative;
    overflow: hidden;

    @include mq-sp {
      margin: 15px -15px;
    }
  }

  &__main-image--blur {
    box-sizing: border-box;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 400px;
    padding: 20px;
    overflow: hidden;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(10px);

    @include mq-sp {
      display: none;
    }
  }

  &__main-image {
    position: relative;
    z-index: 2;
    margin-left: auto;
    margin-right: auto;
    width: 960px;
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include mq-sp {
      width: auto;
      height: 0;
      padding-top: percentage(320px/960px);
    }
  }

  &__title-area {
    padding: 20px 15px;
    background-color: $bgcolor-primary;

    @include mq-sp {
      margin: -15px;
      padding: 15px;
    }
  }

  &__discription {
    line-height: 1.8;

    @include mq-sp {
      font-size: $font-size-small;
      line-height: 1.6;
    }
  }

  &__detail {
    margin-top: 50px;

    @include mq-sp {
      margin-top: 40px;
    }

    * + * {
      margin-top: 25px;
    }

    * + h2 {
      margin-top: 50px;
    }

    //マークダウン形式で出力なので
    br + br {
      margin-top: 0;
    }

    h2 {
      font-size: $font-size-larger;
      font-weight: bold;
      color: $fgcolor-primary-theme;
    }

    p {
      line-height: 1.8;
    }

    blockquote {
      margin-left: 20px;
      border-left: 4px solid $bdcolor-normal;
      padding-left: 20px;
      color: $fgcolor-lowpriority;
      @include mq-sp {
        margin-left: 10px;
        padding-left: 10px;
      }
    }

    strong {
      font-weight: bold;
    }

    a {
      color: $fgcolor-link;
      text-decoration: none;
    }

    img {
      max-width: 100%;
    }
  }

  &__under {
    margin-top: 32px;
    text-align: center;
  }

  &__under + .body--default {
    margin-top: 50px;
  }

  &__list {
    margin-top: 50px;
    padding-top: 40px;
    padding-bottom: 80px;
    background-color: $bgcolor-primary;

    @include mq-sp {
      margin: 40px -15px;
      padding: 20px 15px 60px;
    }
  }

  &__list__heading {
    font-size: $font-size-LL;
    color: $fgcolor-supplemental;
    line-height: 1;

    & + * {
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }

  &__facebook-button {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    box-sizing: border-box;
    display: block;
    width: 300px;
    height: 45px;
    line-height: 45px;
    border-radius: 100px;
    text-align: center;
    cursor: pointer;
    font-size: $font-size-small;
    color: $fgcolor-darkbg;
    background-color: $bgcolor-button-facebook;
    border: 1px solid $bgcolor-button-facebook;

    &:before {
      content: "";
      display: inline-block;
      position: relative;
      top: 5px;
      width: 24px;
      height: 24px;
      margin-right: 10px;
      background: url(/assets/img/btn-bg-facebook.png) no-repeat;
      background-size: 100%;
    }

    @include mq-pc {
      &:hover {
        color: $fgcolor-button-facebook;
        background-color: $bgcolor-button-facebook-hover;
      }
    }
  }

  &__action {
    text-align: center;

    .btn-primary {
      $company-article-register-font: 15px;
      font-size: $company-article-register-font;
      max-width: 300px;
    }
  }

  &__action__notice {
    margin-top: 10px;
    line-height: 1.6;
    font-size: $font-size-smaller;
    color: $fgcolor-supplemental;
  }

  &__univ-list {
    margin: 20px auto 0 auto;
    text-align: center;
    width: 450px;

    @include mq-sp {
      text-align: left;
      width: auto;
    }

    > li {
      display: inline-block;
      margin-left: 20px;
    }
  }

  &-like {
    text-align: center;
    height: 128px;

    &__text {
      margin-bottom: 24px;
      font-weight: bold;
    }

    &__reaction {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 8px;
      width: 176px;
      height: 54px;
      line-height: 54px;
      border: 1px solid $bdcolor-normal;
      border-radius: 100px;
      background-color: $bgcolor-primary;
      color: $fgcolor-normal;
      cursor: pointer;
      font-size: $font-size-large;
      font-weight: bold;
      outline: 0;
      -webkit-tap-highlight-color: $shadow-9;

      &:hover,
      &:active {
        color: $fgcolor-normal;
        text-decoration: none;
      }

      &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      .icon--like {
        margin-right: 5px;
      }
    }

    @include mq-pc {
      &__reaction:hover,
      &__reaction:active {
        .icon--like {
          animation: animeScale 0.5s ease-out;
          transform-origin: 50% 50%;
          animation-play-state: running;
        }
      }
    }

    &__reaction-count {
      display: block;
      text-align: center;
      font-size: $font-size-smaller;
      font-weight: bold;
    }

    @keyframes animeScale {
      0% {
        transform: scale(0.8, 0.8);
      }
      50% {
        transform: scale(1.1, 1.1);
      }
      100% {
        transform: scale(1, 1);
      }
    }

    &__reaction.is-active {
      .icon--like {
        width: 29px;
        height: 24px;
        background: url("/assets/icon/icn-like.svg") no-repeat;
        background-position: 0 0;
      }
    }
  }

  //スマホ用のアニメーション
  .is-animation {
    .icon--like {
      animation: animeScale 0.5s ease-out;
      transform-origin: 50% 50%;
      animation-play-state: running;
    }
  }

  &__external-link {
    margin-top: 50px;
    text-align: center;

    i {
      font-size: 16px;
      margin-left: 5px;
      vertical-align: text-bottom;
    }

    & + * {
      margin-top: 30px;
    }
  }

  &-footer {
    position: fixed;
    left: auto;
    right: 16px;
    bottom: 16px;
    width: 320px;
    z-index: 10;
    box-sizing: border-box;
    padding: 10px 16px;
    border: 1px solid $bdcolor-normal;
    border-radius: 6px;
    background-color: $bgcolor-normal;
    @include mq-sp {
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      border: solid $bdcolor-normal;
      border-width: 1px 0 0 0;
      border-radius: 0;
    }

    &__inner {
      margin-bottom: 4px;
      display: flex;
      align-items: flex-start;
      font-size: $font-size-LL;
    }

    &__logo {
      @include round-bd-image(50px);
      margin-right: 10px;
    }

    &__company {
      width: calc(100% - 60px);
    }

    &__company-name {
      @include text-overflow();
      margin-bottom: 3px;
      font-size: $font-size-small;
      font-weight: bold;
    }

    &__button {
      @extend .btn-secondary;
      width: 100%;
      height: 25px;
      line-height: 20px;
      font-size: $font-size-smaller;
      border-radius: 4px;
      @include mq-pc {
        margin-top: 0;
      }

      &.is-active {
        background-color: $bgcolor-hover;
        border: none;
        font-weight: bold;
      }
    }

    &__text {
      text-align: center;
      font-size: $font-size-XS;
      @include mq-pc {
        text-align: left;
        font-size: $font-size-smaller;
      }
    }
  }

  .modal__inner {
    @include mq-pc {
      max-width: 320px;
    }
    @include mq-middle {
      max-width: 320px;
    }
  }
}
