.l-register-body {
  background-color: $bgcolor-primary;
}

.l-register {
  max-width: 960px;
  margin: 0 auto;
  @include mq-pc {
    padding-top: 80px;
  }

  // アプリheaderがネイティブのときにheader以下をつめるために使用
  &.is-app {
    padding-top: 50px;
  }

  @include mq-sp {
    background-color: $bgcolor-sp-normal;
  }

  &--medium {
    max-width: 600px;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 80px;

    // アプリheaderがネイティブのときにheader以下をつめるために使用
    &.is-app {
      padding-top: 50px;
    }

    @include mq-sp {
      width: 100%;
      max-width: none;
      padding-top: 0;
    }
  }
}
