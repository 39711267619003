// Card
//
// カード
//
// .card - カード
//
// Markup:
// <section class="{$modifiers}">
//   <a class="card-link" href="#"></a>
// </section>
//
// Styleguide 3.4
.card {
  border-radius: 2px;
  position: relative;
  width: 310px;
  padding: 20px 15px;
  box-shadow: 0 3px 3px 0 $basecolor-shadow-color;
  border: $basecolor-shadow;
  box-sizing: border-box;
  display: inline-block;
  background-color: $bgcolor-normal;
  transition: all 0.5s ease;
  word-break: break-all;

  &__heading {
    @extend .text--medium;
    @include text-over-flow-multiline($font-size: 14px, $line-height: 1.7, $line: 2);
  }

  &__content--right {
    @extend .l-grid__column--62;
    @include text-over-flow(600px);
    @include mq-sp {
      margin-left: 15px;
      width: calc(100% - 60px);
      display: inline-block;

      p {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .round-bd-image--30 + .card__content--right {
    line-height: 30px;
  }

  &__content--full {
    @include text-over-flow(100%);
  }

  &__content--full-bordered {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid $bdcolor-normal;
  }

  &:hover {
    box-shadow: 0 10px 10px 0 $shadow-2;
    transition: all 0.5s ease;
  }

  .l-grid__row + .l-grid__row {
    margin-top: 10px;
  }
}

.card-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

//iOS8対応
html[data-os="iOS 8"] {
  .card-buttons {
    &__inner {
      display: table;
      table-layout: fixed;
      width: 100%;
    }

    &__items {
      display: table-cell;
    }
  }

  .card__content--right {
    width: 145px;
  }
}

.card-buttons {
  position: relative;
  margin-top: 20px;
  width: 100%;

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__items {
    flex: 1;
    padding-right: 5px;
    padding-left: 5px;

    [class^="button--"] {
      font-size: $font-size-small;
      @include mq-pc {
        width: 100%;
      }
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}

//text-over-flow
.text-over-flow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// smart phone
@media only screen and (max-width: 660px) {
  .card {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 15px;
  }
}

// カード内で使用するボタン
.card-buttons__items {
  [class^="button--"] {
    width: 100%;
  }
}

.panel__text + .card {
  margin-top: -1px;
}

.card--full {
  @extend .card;
  width: 100%;
  padding: 0;

  .card--full__item {
    display: block;
    padding: 15px;

    &:hover {
      text-decoration: none;
    }
  }

  time {
    font-size: $font-size-smaller;

    span {
      @include mq-sp {
        display: block;
      }
    }
  }

  + .card--full {
    margin-top: 15px;
  }

  &.is-disable {
    background-color: $bgcolor-primary;

    .text--small-emphasis {
      color: $fgcolor-warning;
    }
  }

  a:hover {
    @include mq-pc {
      text-decoration: none;
    }
  }

  h2 {
    @include text-over-flow(100%);
    margin-top: 10px;
  }

  .is-visited {
    background-color: $color-black-9;

    h2,
    p,
    time {
      font-weight: normal;
    }

    time {
      color: $basecolor-appendix;
    }
  }

  .is-unread {
    h2,
    p,
    time {
      font-weight: bold;
    }

    time {
      color: $basecolor-primary;
    }
  }
}
