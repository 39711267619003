$mini-view-height: 81px;
$mini-view-width: $mini-view-height * 16 / 9;
$header-padding: 8px;
$header-height: $mini-view-height + $header-padding * 2;

$control-dimension: 44px;
$footer-padding: 16px;
$footer-height: $control-dimension + $footer-padding * 2;

.online-room-visit {
  &--container {
    background: linear-gradient(180deg, #141313 0%, #333333 50.52%, #161414 100%);
    height: 100%;
    width: 100%;
    position: fixed;

    h1 + * {
      margin-top: 0;
    }
  }

  &__network-warning {
    color: $color-white;
    text-align: center;
    font-size: 16px;
    position: absolute;
    top: $header-height;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 20;
    padding: 4px 0;
  }

  &__self {
    &--container {
      position: absolute;
      right: $header-padding;
      top: $header-padding;
      z-index: 10;
    }

    width: $mini-view-width;
    height: $mini-view-height;
    border-radius: 4px;

    &--off {
      background: $color-black;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 36px;
      }
    }
  }

  &__partner-title {
    &--container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      transition: 0.28s ease-out;
      opacity: 0;
      position: relative;
      z-index: 10;

      .online-room-visit__partner-title {
        cursor: default;
      }

      &-show {
        opacity: 1;

        .online-room-visit__partner-title {
          cursor: text;
        }
      }
    }

    color: $color-white;
    font-weight: 700;

    &--name {
      font-size: 20px;
      line-height: 30px;
    }

    &--status {
      font-size: 16px;
      line-height: 24px;
    }

    &--end-notification {
      font-size: 14px;
      font-weight: 500;
      line-height: 24.5px;
      margin-top: 32px;

      & + & {
        margin-top: 0;
      }
    }
  }

  &__partner-video {
    &--container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      top: $header-height;
      bottom: $footer-height;
      height: auto;
      width: 100%;
    }

    &--icon {
      height: 100%;
      aspect-ratio: 1;
      max-width: 256px;
      max-height: 256px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        display: inline-block;
        object-fit: cover;
      }
    }

    &--video {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      canvas {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    &--share-mode {
      left: $header-padding;
      top: $header-padding;
      width: $mini-view-width;
      height: $mini-view-height;
      border-radius: 4px;
      overflow: hidden;
      background: $color-black;

      .online-room-visit__partner-video--icon {
        width: 64px;
        height: 64px;
      }
      .online-room-visit__partner-video--video {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__share-view {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: $header-height;
    bottom: $footer-height;
    height: auto;
    width: 100%;

    canvas {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    video {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    &-video-container {
      width: 100%;
      height: 100%;
    }

    &-loading {
      max-height: 200px;
      max-width: 200px;
      animation: loading-spin 2s linear infinite;
    }
  }

  &__loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &__active-device {
    position: fixed;
    bottom: $footer-height + 16px;
    right: 16px;
    color: $color-white;
    font-size: 12px;
    font-weight: normal;
    transition: 0.28s ease-out;
    opacity: 0;

    &-show {
      opacity: 1;
    }

    &--title {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  @function control-coords($col, $row) {
    @return (-($control-dimension * $col)) (-($control-dimension * $row));
  }

  &__control {
    position: relative;

    &--button {
      height: $control-dimension;
      width: $control-dimension;
      background-size: $control-dimension * 5, $control-dimension * 4;
      border: none;
      cursor: pointer;
      background-image: url("/assets/img/online-room/controls.png");
      background-repeat: no-repeat;
      background-color: transparent;
    }

    &--button:hover + &--tooltip {
      display: block;
    }

    &--tooltip {
      display: none;
      position: absolute;
      background-color: #7B7070;
      padding: 0 8px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      line-height: 24.5px;
      color: $color-white;
      white-space: pre-wrap;
      text-align: center;
      width: max-content;
      max-width: 320px;
      z-index: 20;
      left: 50%;
      transform: translateX(-50%);
      bottom: calc(100% + 5px);
    }

    & + & {
      margin-left: 24px;
    }
  }

  &__controls {
    $camera-on-coords: control-coords(0, 0);
    $camera-on-hover-coords: control-coords(1, 0);
    $camera-off-coords: control-coords(2, 0);
    $camera-off-hover-coords: control-coords(3, 0);
    $microphone-on-coords: control-coords(0, 1);
    $microphone-on-hover-coords: control-coords(1, 1);
    $microphone-off-coords: control-coords(2, 1);
    $microphone-off-hover-coords: control-coords(3, 1);
    $screen-share-on-coords: control-coords(0, 2);
    $screen-share-on-hover-coords: control-coords(1, 2);
    $screen-share-off-coords: control-coords(2, 2);
    $screen-share-off-hover-coords: control-coords(3, 2);
    $blur-on-coords: control-coords(0, 3);
    $blur-on-hover-coords: control-coords(1, 3);
    $blur-off-coords: control-coords(2, 3);
    $blur-off-hover-coords: control-coords(3, 3);
    $loading-camera-mic-coords: control-coords(4, 0);
    $loading-share-blur-coords: control-coords(4, 2);
    $setting-coords: control-coords(0, 4);
    $setting-hover-coords: control-coords(1, 4);

    &--container {
      position: fixed;
      bottom: 0;
      padding: $footer-padding 0;
      width: 100%;
      background-color: $color-black;
      display: flex;
      justify-content: center;
      transition: 0.28s ease-out;
      opacity: 0;
      z-index: 10;

      .online-room-visit__control--button {
        cursor: default;
      }

      &-show {
        opacity: 1;

        .online-room-visit__control--button {
          cursor: pointer;
        }
      }
    }

    &--camera {
      &-on {
        background-position: $camera-on-coords;

        &:hover {
          background-position: $camera-on-hover-coords;
        }
      }
      &-off {
        background-position: $camera-off-coords;

        &:hover {
          background-position: $camera-off-hover-coords;
        }
      }
    }

    &--microphone {
      &-on {
        background-position: $microphone-on-coords;

        &:hover {
          background-position: $microphone-on-hover-coords;
        }
      }

      &-off {
        background-position: $microphone-off-coords;

        &:hover {
          background-position: $microphone-off-hover-coords;
        }
      }
    }

    &--screen-share {
      &-on {
        background-position: $screen-share-on-coords;

        &:hover {
          background-position: $screen-share-on-hover-coords;
        }
      }

      &-off {
        background-position: $screen-share-off-coords;

        &:hover {
          background-position: $screen-share-off-hover-coords;
        }
      }
    }

    &--blur {
      &-on {
        background-position: $blur-on-coords;

        &:hover {
          background-position: $blur-on-hover-coords;
        }
      }

      &-off {
        background-position: $blur-off-coords;

        &:hover {
          background-position: $blur-off-hover-coords;
        }

        &:disabled {
          opacity: 0.4;
          background-position: $blur-off-coords;
          cursor: not-allowed;
        }
      }
    }

    &--loading {
      &-gray {
        background-position: $loading-camera-mic-coords;
        -webkit-animation: loading-spin 2s linear infinite;
        animation: loading-spin 2s linear infinite;
      }

      &-green {
        background-position: $loading-share-blur-coords;
        -webkit-animation: loading-spin 2s linear infinite;
        animation: loading-spin 2s linear infinite;
      }

      img {
        width: 100%;
        height: auto;
      }

      @keyframes loading-spin {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }
    }

    &--denied {
      &:after {
        content: "";
        background-image: url("/assets/img/online-room/warning-mark.png");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: -8px;
        right: -12px;
        width: 24px;
        height: 24px;
      }
    }

    &--setting {
      background-position: $setting-coords;

      &:hover {
        background-position: $setting-hover-coords;
      }

      &--small {
        background-position: $setting-coords;
        zoom: 50%;
      }
    }
  }

  &__end-call {
    &--container {
      position: fixed;
      bottom: 20px;
      right: $footer-padding;
      transition: 0.28s ease-out;
      opacity: 0;
      cursor: default;
      z-index: 10;

      .online-room-visit__end-call--control {
        cursor: default;
      }

      &-show {
        opacity: 1;

        .online-room-visit__end-call--control {
          cursor: pointer;
        }
      }
    }

    &--control {
      width: 64px;
      height: 36px;
      padding: 0;
      background-color: $color-pink;
      border-radius: 8px;
      border-color: $color-pink;
      color: $color-white;
      font-size: 14px;
      font-weight: 700;
    }
  }

  &__join-prompt {
    &--container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 32px;
      height: 100%;
    }

    &--title {
      color: $color-white;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
    }

    &--button {
      background-color: rgba(0, 166, 113);
      color: white;
      border: none;
      border-radius: 8px;
      font-weight: 700;
      font-size: 20px;
      padding: 8px 50px;

      &:hover {
        background-color: rgba(0, 166, 113, 0.5);
      }
    }
  }

  &-setting-modal {
    .modal__inner {
      max-width: 400px;
    }

    header {
      display: block; // 共通の指定で消えてしまう場合があるので打ち消しておく
    }

    &-section-list {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      margin-bottom: 24px;
    }

    &-section {
      padding: 0 24px;
      display: flex;
      flex-direction: column;

      &__title {
        font-size: 16px;
        font-weight: bold;
        margin: 0 0 8px 0;
      }
      &__select {
        margin: 0;
        padding: 6px 5px;
        border: 1px solid $color-black-7;
      }
      &__empty {
        margin: 0;
        font-size: 16px;
      }
    }
  }

  &__ended {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 16px;
    width: 100%;
    box-sizing: border-box;

    &-countdown {
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
      color: $color-white;
      margin-top: 52px;
    }
  }

  &-feedback {
    width: 100%;
    max-width: 640px;

    &-container {
      box-sizing: border-box;
      background: $bgcolor-normal;
      border-radius: 6px;
      min-height: 385px;
      line-height: normal;
    }

    &-header {
      border-bottom: 1px solid $bdcolor-normal;
      text-align: center;
      height: 44px;
      box-sizing: border-box;
      position: relative;
      display: grid;
      grid-template-columns: 60px 1fr 60px;
      align-items: center;
      padding: 0 16px;

      &__title {
        line-height: 44px;
        height: 44px;
        font-size: 16px;
      }

      &__left {
        text-align: left;
      }

      &__back {
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        appearance: none;
        color: $color-uiblue;
      }

      &__right {
        text-align: right;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      padding: 16px 24px 24px;
    }


    &-question {
      &__title {
        font-size: 16px;
        margin-bottom: 24px;
      }

      &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
      }

      &__button {
        border: 1px solid $color-uiblue;
        border-radius: 8px;
        background-color: $color-white;
        color: $color-uiblue;
        font-size: 14px;
        font-weight: bold;
        padding: 8px;
        width: 100%;

        &:hover {
          opacity: 0.6;
        }
      }

      &__checkboxes {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
      }

      &__checkbox-item {
        // .form-checkbox + label で外部に余白がついていてレイアウトしづらいので打ち消している
        margin: -5px -18px -5px 0;
      }

      &__checkbox-label {
        cursor: pointer;
      }

      &__textarea {
        height: 144px;
        width: 100%;
        resize: vertical;
        box-sizing: border-box;
      }

      &-divider {
        border-top: 1px solid $color-gray;
        margin: 24px 0;
      }
    }

    &-action {
      display: flex;
      justify-content: center;
      margin-top: 16px;

      &__button {
        width: 120px;
        height: 40px;
        line-height: 0;
        font-weight: normal;
        cursor: pointer;
        user-select: none;
        border-radius: 100px;
        box-sizing: border-box;
        outline: none;
        text-decoration: none;
        color: $color-white;
        background-color: $color-pink;
        border: 2px solid $color-pink;
        font-size: 16px;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    &-thanks {
      font-size: 16px;
    }
  }
}
