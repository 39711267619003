.profile-edit-form-btn {
  margin-top: 40px;
  text-align: center;

  .btn,
  .btn-secondary,
  .btn-big {
    @include mq-sp {
      width: 100%;
    }
  }
}
