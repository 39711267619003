.staticpage-companies-body {
  background-color: $color-gray-2;
}

.staticpage-companies {
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 960px;
  padding: 148px 0 80px;

  * {
    box-sizing: border-box;
  }

  @include mq-sp {
    padding: 93px 0 48px;
  }
}

.staticpage-companies-title {
  font-size: 32px;
  text-align: center;
  font-weight: 700;

  & + .staticpage-companies-sub-title {
    margin-top: 30px;
  }

  @include mq-sp {
    font-size: 24px;
  }
}

.staticpage-companies-sub-title,
.staticpage-companies-description {
  width: 453px;
  margin-left: auto;
  margin-right: auto;

  @include mq-sp {
    width: 343px;
  }
}

.staticpage-companies-sub-title {
  font-size: 18px;
  line-height: 1.6;
  font-weight: 500;
  text-align: center;

  & + .staticpage-companies-description {
    margin-top: 16px;
  }

  @include mq-sp {
    margin: 0 12px;
    font-size: 16px;
  }
}

.staticpage-companies-description {
  font-size: 10px;
  padding: 13px 11px;
  color: $color-darkgray;
  border: 1px solid $color-darkgray;
  border-radius: 3px;

  & + div {
    margin-top: 18px;
  }

  @include mq-sp {
    margin: 0 12px;
  }
}

.staticpage-companies-back-link {
  display: flex;
  justify-content: center;

  > a {
    font-size: 14px;
    font-weight: 700;
  }

  & + div {
    margin-top: 48px;
  }
}

.staticpage-companies-logo-area {
  background-color: $color-white;
  padding: 60px 70px 61px 62px;
  position: relative;

  img {
    width: 100%;
  }

  & + .staticpage-companies-cta {
    margin-top: 72px;
  }

  @include mq-sp {
    margin: 0 12px;
    padding: 35px 24px 38px;

    & + .staticpage-companies-cta {
      margin-top: 30px;
    }
  }
}

.staticpage-companies-logo-area-description {
  position: absolute;
  bottom: 26px;
  right: 69px;
  color: $color-darkgray;

  @include mq-sp {
    right: 13px;
    bottom: 11px;
  }
}

.staticpage-companies-cta {
  display: flex;
  justify-content: center;

  .app-cta-web {
    bottom: -47px;
  }

  & + .staticpage-companies-back-link {
    margin-top: 88px;

    @include mq-sp {
      margin-top: 73px;
    }
  }

  .app-cta-app-banners {
    @include mq-sp {
      > a + a {
        margin-left: 10px;
      }
    }
  }
}
