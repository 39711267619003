// Row
//
// 要素を行にまとめるラッパ
//
// .row.row-left   - 左寄せしたい場合はこちら。
// .row.row-center - 中央寄せしたい場合はこちら。
// .row.row-right  - 右寄せしたい場合はこちら。
//
// Markup:
// <div class="{$modifiers}">
//   <button class="btn btn-primary">送信する</button>
// </div>
//
// Styleguide 3.24

.row {
  width: 100%;

  &.row-left {
    text-align: left;
  }

  &.row-center {
    text-align: center;
  }

  &.row-right {
    text-align: right;
  }
}
