// Note
//
// 補足
//
// .note - 補足文
// .note.note-light - 補足2
// .note.note-normal - 補足2
// .note.note-warning - 警告文
// .note.note-error - エラー文
//
// Markup:
// <div>
//  <p class="{$modifiers}">補足文</p>
// </div>
//
// Styleguide 3.15

.note {
  font-size: 14px;
  line-height: 1.8;
  font-weight: normal;
  color: $color-darkgray;

  &.note-light {
    font-size: 12px;
    line-height: 2;
    font-weight: normal;
    color: $color-darkgray;
  }

  &.note-normal {
    font-size: 12px;
    line-height: 2;
    font-weight: normal;
    color: $color-black;
  }

  &.note-warning {
    font-size: 16px;
    line-height: 1.8;
    font-weight: normal;
    color: $fgcolor-primary;
  }

  &.note-error {
    font-size: 16px;
    line-height: 1.8;
    font-weight: normal;
    color: $color-red;
  }
}
