// sass-lint:disable no-important no-vendor-prefixes
@import "top-variables";

.top .header {
  transform: translateY(-70px);
  animation: showHeader 0.5s ease-out 1s forwards;
}

@keyframes fadeup {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes showHeader {
  0% {
    transform: translateY(-70px);
  }

  100% {
    transform: translateY(0);
  }
}

.inner {
  position: relative;
  max-width: 1280px;
  width: 100vw;
  margin: 0 auto;
}

.top-service {
  width: $content-size;
  margin: 95px auto 112px auto;

  @include mq-sp($lp-breakpoint-sp) {
    display: block;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    max-width: 414px;
    margin-bottom: 88px;
  }

  ul {
    display: flex;
    justify-content: center;
    gap: 52px;

    @include mq-sp($lp-breakpoint-sp) {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
  }

  &__ {
    &title {
      font-weight: bold;
      font-size: 32px;
      line-height: 46px;
      text-align: center;
      margin-bottom: 64px;

      @include mq-sp($lp-breakpoint-sp) {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 48px;
      }
    }

    &list-item {
      width: 286px;
      transition: opacity 0.8s, transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translateY(8px);
      opacity: 0;


      &:nth-of-type(2) {
        transition-delay: 0.3s;
        @include mq-sp($lp-breakpoint-sp) {
          transition-delay: 0;
        }
      }
    }

    &block {
      box-sizing: border-box;
      margin-bottom: 8px;
      border: 1px solid #F0F0F0;
      border-radius: 4px;
      padding-top: 40px;
      height: 308px;

      @include mq-sp($lp-breakpoint-sp) {
        padding-top: 32px;
        width: 100%;
        height: 277px;
      }
    }

    &img1 {
      display: block;
      margin: 0 auto;
      height: 160px;

      @include mq-sp($lp-breakpoint-sp) {
        height: 153px;
      }
    }

    &img2 {
      display: block;
      margin: 0 auto;
      height: 152px;

      @include mq-sp($lp-breakpoint-sp) {
        height: 147px;
      }
    }

    &text {
      margin-top: 24px;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.4;
      text-align: center;

      @include mq-sp($lp-breakpoint-sp) {
        margin-top: 16px;
      }
    }

    &text-information {
      padding: 0 24px;
      font-size: 10px;
      line-height: 16px;
      color: $color-darkgray;
      margin-top: 8px;

      > span {
        color: $color-uiblue;
        cursor: pointer;
      }
    }
  }
}

// ここに書く
.top-feature {
  display: flex;
  align-items: center;
  flex-direction: column;

  &-title {
    font-weight: bold;
    font-size: 32px;
    line-height: 46px;
    text-align: center;

    @include mq-sp($lp-breakpoint-sp) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &-list {
    margin: 64px auto 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 32px;

    @include mq-sp($lp-breakpoint-sp) {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }

  &-card {
    width: 338px;
    height: 475px;
    padding: 32px 16px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    box-sizing: border-box;

    transition: opacity 0.8s, transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateY(8px);
    opacity: 0;

    @include mq-sp($lp-breakpoint-sp) {
      width: 322px;
      height: 426px;
    }

    &:nth-of-type(even) {
      transition-delay: 0.3s;
      @include mq-sp($lp-breakpoint-sp) {
        transition-delay: unset;
      }
    }

    &-figure {
      position: relative;
    }

    &-img1 {
      height: 261px;
      @include mq-sp($lp-breakpoint-sp) {
        height: 234px;
      }
    }

    &-img2 {
      height: 243px;
      @include mq-sp($lp-breakpoint-sp) {
        height: 218px;
      }
    }

    &-img3 {
      height: 254px;
      @include mq-sp($lp-breakpoint-sp) {
        height: 228px;
      }
    }

    &-img4 {
      height: 242px;
      @include mq-sp($lp-breakpoint-sp) {
        height: 217px;
      }
    }

    &-img5 {
      border: 7px solid #123262;
      border-radius: 30px;
      background-color: #123262;
      height: 202px;

      @include mq-sp($lp-breakpoint-sp) {
        height: 181px;
      }

      &-sub {
        position: absolute;
        right: -3px;
        bottom: -40px;
        height: 129px;

        @include mq-sp($lp-breakpoint-sp) {
          height: 116px;
        }
      }
    }

    &-img6 {
      height: 263px;
      @include mq-sp($lp-breakpoint-sp) {
        height: 236px;
      }
    }

    &-img7 {
      height: 226px;
      @include mq-sp($lp-breakpoint-sp) {
        height: 203px;
      }
    }

    &-img8 {
      height: 238px;
      @include mq-sp($lp-breakpoint-sp) {
        height: 214px;
      }
    }

    &-content {
      height: 142px;
    }

    &-title {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }

    &-description {
      font-size: 14px;
      margin-top: 24px;
    }
  }
}

.top-fade-in {
  transform: translateY(0) !important;
  opacity: 1 !important;
}

.top-answer {
  padding: 0 40px;
  margin-bottom: 32px;

  @include mq-sp($lp-breakpoint-sp) {
    width: 264px;
    margin: 0 auto;
    padding: 0;
    text-align: left;
  }

  &__ {
    &title {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
    }

    &tag {
      margin-bottom: 6px;
      width: 85px;
      border-radius: 30px;
      background-color: $shadow-38;
      color: $color-pink;
      font-weight: bold;
      font-size: 12px;
      line-height: 24px;
      text-align: center;
    }

    &text {
      font-size: 14px;
    }
  }
}

.top-register-area {
  display: flex;
  justify-content: center;
  margin: 112px auto;

  @include mq-sp($lp-breakpoint-sp) {
    margin: 88px auto;
  }
}

.register-buttons {
  &__ {
    &wrap {
      display: flex;
      margin: 0 auto 32px;
      width: 250px;
      height: 92px;

      @include mq-sp($lp-breakpoint-sp) {
        display: block;
        height: auto;
        width: auto;
      }
    }

    &list {
      display: flex;
      align-items: center;
      width: 125px;
      height: 88px;

      @include mq-sp($lp-breakpoint-sp) {
        display: block;
        width: auto;
        height: auto;
      }
    }

    &list li {
      height: 40px;

      @include mq-sp($lp-breakpoint-sp) {
        text-align: center;
      }
    }

    &list li:first-child {
      margin-bottom: 8px;

      @include mq-sp($lp-breakpoint-sp) {
        margin-bottom: 8px;
      }
    }

    &separater {
      margin: 20px 16px;
      width: 1px;
      height: 44px;
      background: $color-gray;

      @include mq-sp($lp-breakpoint-sp) {
        display: none;
      }
    }

    &img {
      width: 125px;
      height: 40px;

      @include mq-sp($lp-breakpoint-sp) {
        width: 137px;
        height: auto;
      }
    }

    &qp-code {
      width: 92px;
      height: 92px;

      @include mq-sp($lp-breakpoint-sp) {
        display: none;
      }
    }

    &web-registration {
      display: block;
      margin: 0 auto;
      width: 72px;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
    }
  }
}

.top-article-section {
  max-width: 960px;
  margin: 0 auto 88px;
  display: flex;
  flex-direction: column;
  gap: 88px;

  @include mq-sp {
    gap: 16px;
  }
}

.top-statement {
  text-align: center;
  margin-bottom: $unit-index * 9;

  @include mq-pc {
    margin-bottom: $unit-index * 11;
  }

  &-lp {
    margin-bottom: 0;
  }

  &__ {
    &logo {
      width: 187px;
      display: block;
      margin-bottom: 24px;
      margin-left: auto;
      margin-right: auto;

      @include mq-sp($lp-breakpoint-sp) {
        width: 145px;
      }
    }

    &text {
      font-size: $font-size-small;
      line-height: $unit-index * 4;
      margin: 0 $horizontal-margin-index $unit-index * 2 $horizontal-margin-index;

      @include mq-pc {
        font-size: $font-size-LL;
        line-height: $unit-index * 5;
        margin: 0 0 $unit-index * 4 0;
      }

      &--service-name {
        margin: 0 $unit-index * 3;
        line-height: $unit-index * 3;

        @include mq-pc {
          line-height: $unit-index * 4;
          margin: 0;
        }
      }
    }

    &copy {
      font-size: 20px;
      line-height: $unit-index * 4;
      margin-bottom: $unit-index * 3;

      @include mq-pc {
        font-size: 28px;
        line-height: $unit-index * 4;
        margin-bottom: $unit-index * 4;
      }
    }
  }
}

.top-br {
  &-- {
    &pc {
      @include mq-sp($lp-breakpoint-sp) {
        display: none;
      }
    }

    &sp {
      @include mq-pc {
        display: none;
      }
    }
  }
}

.top-popup {
  width: 360px;
  padding: 0;
  border-radius: 10px;

  @include mq-sp {
    top: 50%;
    margin: 0 auto;
    max-width: calc(100% - 32px);
    transform: translateY(-50%);
  }
}

.university-popup {
  padding: 0;
  width: 512px;
  border-radius: 10px;

  @include mq-sp {
    top: 50%;
    margin: 0 auto;
    max-width: calc(100% - 32px);
    transform: translateY(-50%);
  }
}

.top-lecture-college {
  &__ {
    &header-title {
      border-bottom: 1px solid $color-gray;
      height: 44px;
      font-weight: bold;
      line-height: 44px;
      font-size: 16px;
    }

    &infomation {
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $color-darkgray;
      text-align: center;
    }

    &contents {
      padding: 24px;

      @include mq-sp($lp-breakpoint-sp) {
        padding: 16px;
      }
    }

    &text-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 16px;
      text-align: left;
      font-size: 14px;
      line-height: 24px;
    }

    &name {
      flex-shrink: 0;
      margin-right: 8px;
    }
  }
}
