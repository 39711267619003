.event-list-status-tag {
  padding: 5px 25px;
  font-size: 12px;

  &.event-list-status-tag-exit {
    background-color: $shadow-27;
    color: $color-black-5;
  }
}

.event-list-status-tag-entry {
  background-color: $color-uiblue;
  color: $bgcolor-normal;
  padding: 5px 10px;
  border-radius: 4px;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;

  &::before {
    content: "";
    display: inline-block;
    position: relative;
    top: 3px;
    width: 14px;
    height: 14px;
    margin-right: 5px;
    background: url(/assets/img/event-status-tag-check.png) no-repeat;
    background-size: 100%;
  }
}

@include mq-sp(660px) {
  .event-inner .card {
    .event-list-status-tag {
      display: block;
      position: static;
      margin: 0;
      padding: 2px 5px;
      font-size: 10px;
      text-align: center;

      + * {
        margin-top: 5px;
      }
    }

    .event-list-status-tag-entry {
      padding: 2px 5px;
      font-size: 10px;
      top: 10px;
      left: 10px;

      &::before {
        width: 10px;
        height: 10px;
      }
    }
  }
}
