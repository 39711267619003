.ob-visit-survey-balloon {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $color-gray;
  gap: 16px;
  padding: 8px 16px;

  &-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    > i {
      line-height: 1;
    }
  }

  &-description {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
  }

  &-button {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
    color: $color-uiblue;
    background-color: transparent;
    border: none;
    white-space: pre;
  }
}
