// Color Pallette
$assessment-color-white: #fff;
$assessment-color-light: #f3f5f7;
$assessment-color-dark: #383333;
$assessment-color-black: #000000;
$assessment-color-alert: #ed546e;
$assessment-color-blue: #00478f;
$assessment-color-choice: #3b90e6;

// Specific Type
$assessment-bg-blue: #0960b8;
$assessment-shadow-box-assessment: #d9dbdd;
$assessment-status-process-bar: $assessment-color-alert;
$assessment-process-bar: #e9ecef;

// Fonts
$assessment-font-large: 24px;
$assessment-font-medium: 22px;
$assessment-font-18: 18px;
$assessment-font-regular: 16px;
$assessment-font-small: 14px;
$assessment-font-micro: 12px;

// Radio
$assessment-radio-circle-size: 16px;
$assessment-border-circle-radio: $assessment-color-light;

// Shadow
$assessment-box-shadow-button: 0 4px 20px 0 rgba(0, 0, 0, 0.13);

// Spacing
$assessment-space-none: 0;
$assessment-space-ss: 4px;
$assessment-space-s: 8px;
$assessment-space-m: 16px;
$assessment-space-24: 24px;
$assessment-space-l: 32px;
$assessment-space-xl: 64px;
$assessment-space-xxl: 128px;

// Specific Type
$assessment-bg-blue: #0960b8;
$assessment-shadow-box-assessment: #d9dbdd;
$assessment-status-process-bar: $assessment-color-alert;
$assessment-status-process-bar-background: #e3e5e7;
// Fonts
$assessment-font-large: 24px;
$assessment-font-medium: 22px;
$assessment-font-regular: 16px;
$assessment-font-small: 14px;
$assessment-font-micro: 12px;
$assessment-font-13: 13px;

// Radio
$assessment-radio-circle-size: 16px;
$assessment-border-circle-radio: $assessment-color-light;

// Shadow
$assessment-box-shadow-button: 0 4px 20px 0 rgba(0, 0, 0, 0.13);

// Font style
$asessment-font-style-yugothic: "Yu Gothic", YuGothic, "メイリオ", Meiryo, sans-serif;
$assessment-font-style-hiragino: "Hiragino Kaku Gothic Pro";
