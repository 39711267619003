.pc-hide,
.sp-show,
.sp-show-inline {
  display: none;
}

.pc-show {
  display: block;
}

@media only screen and (max-width: 660px) {
  .sp-hide,
  .pc-show {
    display: none !important;
  }
  .sp-show,
  .pc-hide {
    display: block !important;
  }
  .sp-show-inline {
    display: inline-block !important;
  }
}

// NOTE 上記の 660px 時の pc-hide の importantに勝つため後勝ちで上書きする
.tablet-hide {
  @media (641px <= width <= 660px) {
    display: none !important;
  }
}

