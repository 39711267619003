.article-ranking {
  border-top: 1px solid $bdcolor-normal;
  box-sizing: border-box;
  margin: 0 -16px;

  &__ {
    &content {
      display: flex;
      box-sizing: border-box;
      align-items: flex-start;
      @include mq-pc {
        height: 88px;
        align-items: center;
      }
    }

    &item {
      border-bottom: 1px solid $bdcolor-normal;
    }

    &link {
      display: block;
      color: $fgcolor-normal;
      transition: 0.05s linear;
      text-decoration: none;
      @include mq-pc {
        height: 100%;
      }
      @include mq-sp {
        padding: 16px;
      }

      &:hover {
        color: $fgcolor-normal;
        text-decoration: none;
        opacity: 0.8;
      }
    }

    &rank {
      border: 1px solid $bdcolor-primary-theme;
      color: $fgcolor-primary-theme;
      box-sizing: border-box;
      font-weight: bold;
      text-align: center;
      margin-right: 16px;
      flex-basis: 40px;
      height: 40px;
      line-height: 38px;
    }

    &text {
      flex: 1;
    }

    &title {
      font-size: $font-size-small;
      overflow: hidden;
      line-height: 24px;
      height: 48px;
      font-weight: bold;
      @include mq-pc {
        font-size: $font-size-LL;
        line-height: 32px;
        height: 32px;
      }
    }

    &date {
      @include mq-pc {
        font-size: $font-size-smaller;
      }
    }
  }
}
