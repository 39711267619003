@import "top-variables";

.top-companies {
  display: flex;
  justify-content: flex-start;
  max-width: 1176px;
  height: 326px;
  margin: -56px auto 0;
  padding: 40px 27px 40px 27px;
  box-sizing: border-box;
  border: 1px solid $color-gray;
  border-radius: 8px;
  position: relative;
  z-index: 5;
  background: $color-white;
  animation: fadeup 0.5s ease-out forwards;

  * {
    box-sizing: border-box;
  }

  @include mq-sp($company-logo-section-breakpoint) {
    margin-top: -16px;
    padding: 24px;
    flex-direction: column;
    height: auto;
    width: 342px;
  }

  @include mq-sp($lp-breakpoint-sp) {
    margin-top: -32px;
  }
}

.top-companies-logos-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top-companies-logos-heading {
  > h3 {
    font-size: 20px;
    text-align: center;

    @include mq-sp($company-logo-section-breakpoint) {
      font-size: 16px;
    }
  }

  > p {
    margin-top: 4px;
    font-size: 10px;
    color: $color-darkgray;
    text-align: center;
  }
}

.top-companies-logos {
  width: 738px;
  height: 177px;
  margin-top: 4px;

  @include mq-sp($company-logo-section-breakpoint) {
    margin-top: 16px;
    width: 292px;
    height: 171px;
  }

  img {
    width: 100%;
  }
}

.top-companies-logos-description {
  position: relative;
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  > a {
    font-size: 14px;
    font-weight: 700;
  }

  > p {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    font-size: 10px;
    color: $color-darkgray;
  }
}

.top-companies-divider {
  border: 2px solid $color-gray;
  height: 245px;
  margin-left: 42px;

  & + .app-cta {
    margin-left: 50px;
  }

  @include mq-sp($company-logo-section-breakpoint) {
    height: unset;
    margin-left: unset;
    margin-top: 4px;
    margin-bottom: 8px;

    & + .app-cta {
      margin: 0 auto;
    }
  }
}

.break {
  @include mq-sp($company-logo-section-breakpoint) {
    display: none;
  }
}
