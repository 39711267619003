.profile-edit-profile-img {
  @include round-bd-image(200px);
  position: relative;

  img {
    display: block;
    position: relative;
    width: 200px;
    height: 200px;
    margin: auto;
    background: url(/assets/img/default-profile-img.png);

    &.profile-img-portrait {
      width: 200px;
      height: auto;
      transform: translateY(-50%);
      top: 50%;
    }

    &.profile-img-landscape {
      width: auto;
      height: 200px;
      transform: translateX(-50%);
      left: 50%;
    }
  }
}

.profile-edit-profile-img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  background-color: $bgcolor-dark;
  border-radius: $bdradius-circle;
  cursor: pointer;
  opacity: 0;
  transition: opacity 300ms linear;

  .profile-edit-profile-img-overlay-text {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 200px;
    font-size: 20px;
    color: $fgcolor-darkbg;

    &:before {
      content: "";
      display: inline-block;
      position: relative;
      top: 5px;
      left: -2px;
      width: 34px;
      height: 26px;
      margin: 0 3px 0 0;
      background: url(/assets/img/profile-upload-icon.png) no-repeat;
      background-size: 100%;
    }
  }

  &:hover {
    opacity: 0.6;
    transition: opacity 300ms linear;
  }
}
