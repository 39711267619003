// copied from company/ob
// ===================================================================
// NOTE: Checkbox をコンポーネント化する際は、「FIXME: [Checkbox]」が記載でスタイルの上書きがされていることを考慮すること
// ===================================================================

// Hide default checkbox
input[type="checkbox"].checkbox-component {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}

.checkbox-component-label {
  display: inline-block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

// UnChecked style
.checkbox-component-label:before {
  position: absolute;
  content: "";
  top: 10px;
  left: 0;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  background: $bgcolor-input-checkbox;
  border: 1px solid $color-gray;
  border-radius: 2px;
}

// Indeterminate style
.checkbox-component:indeterminate ~ .checkbox-component-label {
  &:before {
    background-color: $color-uiblue;
  }

  &:after {
    content: "";
    display: block;
    width: 10px;
    border: solid $color-white;
    border-width: 0 0 2px 0;
    position: absolute;
    left: 3px;
    top: 17px;
  }
}

// Checked style
.checkbox-component:checked ~ .checkbox-component-label {
  &:before {
    background-color: $color-uiblue;
  }

  &:after {
    content: "";
    display: block;
    width: 4px;
    height: 8px;
    border: solid $color-white;
    border-width: 0 2px 2px 0;
    position: absolute;
    left: 5px;
    top: 11px;
    transform: rotate(45deg);
  }
}

// Disabled style
.checkbox-component:checked:disabled ~ .checkbox-component-label {
  &:before {
    background-color: $color-gray;
  }
}
