.confirm-email-over-limit-main {
  padding: 40px;
  background: $color-white;
  text-align: center;

  &__ {
    &title {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      height: 40px;
      @include mq-sp {
        font-size: 15px;
      }
    }

    &text {
      max-width: 560px;
      margin: 0 auto 24px;
      font-size: 16px;
      line-height: 28px;
      text-align: left;
      @include mq-sp {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.confirm-email-over-limit-email {
  display: flex;
  max-width: 560px;
  margin: 40px auto 32px;
  justify-content: center;

  @include mq-sp {
    margin-left: 0;
    margin-bottom: 24px;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    min-width: 112px;
    font-weight: 700;
  }

  &__text {
    margin-left: 10px;
    padding-right: 40px;
    text-align: left;

    @include mq-sp {
      margin-left: 0;
      padding-right: 0;
    }

    &--plain {
      margin-right: 10px;
      font-size: 16px;
      line-height: 24px;
      word-break: break-all;
    }

    &--link {
      font-size: 16px;
      line-height: 24px;
      color: $color-uiblue;
      word-break: normal;

      @include mq-sp {
        margin-left: 0;
      }
    }
  }
}

.confirm-email-over-limit-buttons {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  text-align: center;
  @include mq-sp {
    flex-direction: column-reverse;
  }

  & a + a {
    margin-left: 8px;

    @include mq-sp {
      margin-left: 0;
      margin-bottom: 8px;
    }
  }

  &__item {
    &--inquiry {
      @extend %btn-item;
      color: $color-uiblue;
      background: $color-white;
      border: 1px solid $color-uiblue;

      &:hover {
        color: $color-white;
        background-color: $color-uiblue;
      }
    }

    &--resend {
      @extend %btn-item;
      border: 1px solid $color-uiblue;
      color: $color-white;
      background: $color-uiblue;

      &:hover {
        color: $color-uiblue;
        background-color: $color-white;
      }
    }
  }
}

%btn-item {
  font-size: 16px;
  display: inline-block;
  height: 44px;
  width: 160px;
  box-sizing: border-box;
  line-height: 43px;
  border-radius: 22px;
  transition-duration: 0.2s;

  &:hover {
    text-decoration: none;
    transition-duration: 0.2s;
  }

  @include mq-sp {
    font-size: 14px;
    width: 152px;
  }
}

.confirm-email-over-limit-btn-back-to-top {
  @include mq-sp {
    font-size: 14px;
  }
}
