// sass-lint:disable nesting-depth
.visit-type-notice-modal {
  background-color: $shadow-29;

  @include mq-sp {
    box-sizing: border-box;
    overflow: auto;
  }

  $modal-horizontal-margin: 16px;
  $modal-horizontal-padding: 24px;
  $modal-width-pc: 418px;
  $modal-width-sp: calc(100vw - #{$modal-horizontal-margin * 2});

  &__ {
    &middle {
      border-radius: 10px;
      transition: none;
      margin: 24px $modal-horizontal-margin;

      @include mq-pc {
        max-width: $modal-width-pc;
      }
      @include mq-sp {
        width: $modal-width-sp;
      }
    }

    &content {
      padding-bottom: 32px;
      overflow: hidden;
    }

    &container {
      display: flex;
      position: relative;
      transition: left 0.3s ease-in-out;
      left: 0;

      &-- {
        @for $pageNumber from 1 through 2 {
          &current-page-#{$pageNumber} {
            @include mq-pc {
              left: calc(#{$modal-width-pc} * #{($pageNumber - 1) * -1});
            }
            @include mq-sp {
              left: calc(#{$modal-width-sp} * #{($pageNumber - 1) * -1});
            }
          }
        }
      }
    }

    &action {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &btn {
      @extend .button--primary;
      width: 160px;
      color: $color-white;
      background-color: $color-uiblue;
      border: 1px solid $color-uiblue;
      @include mq-pc {
        &:hover {
          color: $color-uiblue;
          background-color: $color-white;
          transition: 0.1s linear;
        }
        &.is-suspend {
          background-color: $color-bluegray;
          border-color: $color-bluegray;
          color: $color-gray;
        }
      }
    }

    &btn-area--article {
      width: 160px;
      @include mq-pc {
        margin-right: 8px;
      }
    }

    &btn--article {
      @extend .button--primary;
      width: 100%;
      color: $basecolor-primary;
      background-color: $bgcolor-button-secondary;
      border: 1px solid $basecolor-primary;
      @include mq-pc {
        &:hover {
          color: $basecolor-primary;
          background-color: $bgcolor-button-secondary-hover;
        }
      }
    }
  }

  &- {
    &page {
      flex-shrink: 0;
      align-items: center;
      text-align: center;
      padding-top: 32px;
      box-sizing: border-box;

      @include mq-pc {
        width: $modal-width-pc;
        padding-left: $modal-horizontal-padding;
        padding-right: $modal-horizontal-padding;
      }
      @include mq-sp {
        width: $modal-width-sp;
      }

      &--page1 {
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &__ {
        &title {
          font-size: 18px;
          font-weight: bold;
          line-height: 27px;
          margin-bottom: 19px;
          @include mq-sp {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
          }
        }

        &text {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 4px;
          @include mq-sp {
            margin-bottom: 8px;
          }
        }

        &page1 {
          &- {
            &logo {
              width: 212px;
              height: auto;
              margin-bottom: 20px;
            }

            &cover-title {
              font-size: 20px;
              font-weight: bold;
              line-height: 28px;
              margin-bottom: 24px;
            }
          }
        }

        &page2 {
          &- {
            &img {
              width: 305px;
              height: auto;
            }

            &description-area {
              margin-top: 20px;
              text-align: left;
              @include mq-sp {
                padding: 0 24px;
              }
            }
          }
        }
      }
    }
  }
}
