.needs-update-preferred-modal {
  max-width: 544px;
  text-align: left;
  border-radius: 10px;

  @include mq-sp {
    top: 50vh;
    transform: translateY(-50%);
  }

  &__header {
    text-align: center;
    border-bottom: 1px solid $color-gray;

    h3 {
      padding: 10.5px 44px;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.5;
    }
  }

  &__content {
    padding: 24px;
  }

  &__description {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
  }

  &__footer {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;

    @include mq-sp {
      justify-content: center;
    }
  }

  &__button {
    display: block;
    width: 120px;
    height: 40px;
    font-weight: normal;
    line-height: 0;
    @include mq-sp {
      flex: 1;
    }
  }
}
