.staticpage-li {
  margin-top: 15px;
  margin-left: 2em;
  @include mq-sp {
    margin-left: 0;
  }

  li + li {
    margin-top: 10px;
  }

  & p {
    text-indent: 0;
  }
}

.staticpage-li-dec {
  list-style-type: decimal;
  @include mq-sp {
    margin-left: 1em;
  }

  > li {
    text-indent: 0;
  }
}

.staticpage-li-brackets-num {
  margin-left: 3em;
  @include mq-sp {
    margin-left: 2em;
  }

  > li {
    counter-increment: count;
    list-style: none;
    text-indent: -3em;
    @include mq-sp {
      text-indent: -2.5em;
    }

    &:before {
      content: "（" counter(count) "）";
    }
  }
}

.staticpage-li-kana {
  > li {
    text-indent: -1.8em;
    margin-left: 1em;
  }
}

.staticpage-li-round-num {
  > li {
    text-indent: -1.3em;
    margin-left: 1em;
  }
}

.staticpage-li-small-dot {
  margin-left: 1em;
  list-style-type: none;

  li {
    position: relative;
    text-indent: 0;

    &:before {
      position: absolute;
      top: 12px;
      left: -10px;
      display: inline-block;
      width: 2px;
      height: 2px;
      content: "";
      border-radius: 100%;
      background: $color-black-2;
    }
  }
}

.staticpage-li-supplemental {
  margin-left: 2em;
  list-style-type: none;

  li {
    counter-increment: count;
    text-indent: -1.4em;

    &:before {
      content: "※" counter(count) " ";
    }
  }
}
