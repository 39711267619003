// Label
//
// 汎用的に使えるラベルです
//
// .label - 普通のラベルです
// .label.label-term - インターンの長期と短期を示すラベルです
//
// Markup:
// <label class="label">ラベル</label>
// <label class="label label-term">長期</label>
//
// Styleguide 3.49

$font-size-label: 10px;

.label {
  font-size: 11px;
  padding: 6px 10px;
  color: $fgcolor-lowpriority;
  background-color: $bgcolor-normal;
  border: 1px solid $fgcolor-lowpriority;
  border-radius: 2px;
  text-align: center;

  &.label-destination {
    padding: 3px 10px;
    color: $fgcolor-darkbg;
    border: 1px solid $basecolor-secondary;
    background-color: $basecolor-secondary;

    &:after {
      content: "TO";
    }
  }

  &.label-term {
    font-size: 12px;
    padding: 4px;
  }

  &.label-unanswered {
    position: absolute;
    right: 0;
    font-size: 12px;
    line-height: 14px;
    padding: 4px;
    background-color: transparent;
  }
}

.setting-label {
  display: inline-block;
  position: relative;
  margin-left: 10px;
  padding: 3px 18px;
  border-radius: 2px;
  box-sizing: border-box;

  &::before {
    position: absolute;
    content: "";
    top: 50%;
    left: -10px;
    width: 18px;
    height: 18px;
    margin-top: -10px;
    background: $bgcolor-input-checkbox;
    border: 1px solid $bdcolor-normal;
  }
}

.label--grey {
  display: inline-block;
  padding: 2px 3px;
  border-radius: 2px;
  line-height: 1;
  font-size: $font-size-label;
  background-color: $bgcolor-button-disabled;
  color: $fgcolor-label-gray;
}

.text--small-variable-ellipsis__sub {
  .label--grey {
    margin-right: 5px;
  }
}

.label--blue {
  display: inline-block;
  padding: 5px 10px;
  background-color: $bgcolor-normal;
  border: 1px solid $color-uiblue;
  border-radius: 4px;
  color: $color-uiblue;
  @include mq-sp {
    padding: 2px 5px;
    font-size: $font-size-XS;
  }
}

.label--midnight-blue {
  @include label($basecolor-primary, $basecolor-reverse, $basecolor-primary);

  &-separated {
    @extend .label--midnight-blue;
    margin-right: 10px;
  }
}

.label--attention {
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  padding: 2px 4px;
  background-color: $color-pink;
  border-radius: 4px;
  color: $color-white;
  display: inline-block;
}

.scout-tag-matching {
  @include label($color-icelandblue, $basecolor-secondary, $color-icelandblue);
  position: relative;
  padding: 2px 10px 2px 25px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -4px;
    bottom: 0;
    left: 11px;
    width: 4px;
    height: 8px;
    border: solid $basecolor-secondary;
    border-width: 0 2px 2px 0;
    margin: auto;
    transform: rotate(45deg);
  }
}

.scout-tag-caution {
  @include label($color-black-5, #eeeeee, $color-black-5);
  position: relative;
}
