.company-info {
  &__header {
    &-logo {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
      @include round-bd-image(35px);
    }

    &-name {
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
      width: calc(100% - 60px); //60pxは画像35px＋左右のmargin
      margin-left: 10px;

      @include mq-sp {
        font-size: $font-size-small;
      }
    }

    + * {
      margin-top: 10px;
    }
  }

  &__detail {
    display: table;
    word-break: break-all;

    @include mq-sp {
      display: block;
    }

    li {
      display: table-row;

      @include mq-sp {
        display: block;
        width: auto;
      }

      & + li {
        @include mq-sp {
          margin-top: 10px;
        }

        & > * {
          @include mq-pc {
            padding-top: 15px;
          }
        }
      }
    }

    &-title {
      display: table-cell;
      width: 110px;

      line-height: 1.8;
      font-weight: normal;
      color: $fgcolor-lowpriority;

      @include mq-sp {
        display: inline-block;
        font-size: $font-size-smaller;
        width: auto;
      }
    }

    @mixin detail-data($displayDefault, $displaySp) {
      display: $displayDefault;

      @include mq-sp {
        display: $displaySp;
        margin-left: 5px;
        font-size: $font-size-smaller;
      }
    }

    &-data {
      @include detail-data(table-cell, inline-block);
    }

    &-data--multiline {
      @include detail-data(block, block);
      line-height: 1.8;
      white-space: pre-wrap;

      li + li {
        margin-top: 0;
      }
    }
  }
}
