.password-body {
  background-color: $color-gray-2;
}

.password-form-input-text {
  width: 300px;

  @include mq-sp {
    width: 100%;
    box-sizing: border-box;
  }
}

.password-form-btn {
  margin-top: 40px;
  text-align: center;
}

.password-msg {
  margin-left: auto;
  margin-right: auto;
  width: 515px;

  @include mq-sp {
    width: 100%;
  }

  + form {
    margin-top: 20px;
  }
}

//popup
.password-popup-ttl {
  font-weight: normal;
}

.password-popup-msg > p {
  margin-top: 10px;
  line-height: 2;
  font-size: 14px;
}

.password-popup-btn {
  margin-top: 16px;
}

.password-popup-close-btn {
  width: 150px;
  padding: 10px;
  line-height: 25px;
}
