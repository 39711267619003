.setting-term {
  width: 120px;
  margin-right: 20px;
  display: inline-block;

  @include mq-sp {
    display: block;
  }
}

.setting-data {
  max-width: 750px;
  margin-right: 15px;
  display: inline-block;
  word-break: break-all;

  &.is-disabled {
    color: $fgcolor-lowpriority;
  }

  @include mq-sp {
    display: block;
  }
}

.setting-data-is-disabled {
  color: $fgcolor-lowpriority;
  margin-right: 15px;
}

.setting-link {
  @include mq-sp {
    display: block;
  }
}

.setting-input[type="checkbox"] {
  display: none;

  &:checked + label {
    &:after {
      content: " ";
      display: block;
      width: 5px;
      height: 10px;
      border: solid $bdcolor-primary-theme;
      border-width: 0 2px 2px 0;
      position: absolute;
      left: -4px;
      top: 7px;
      transform: rotate(45deg);
    }
  }
}

.tips-wrap {
  position: relative;
  display: inline;

  @include mq-sp {
    display: none;
  }
}

.question-icon {
  position: relative;
  bottom: 4px;
  color: $basecolor-primary;
  font-size: 13px;
  font-weight: bold;
}

.question-icon:before {
  position: absolute;
  top: -3px;
  left: -4.5px;
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid $basecolor-primary;
  cursor: pointer;
}

.question-icon:hover {
  color: $basecolor-primary;
  z-index: 0;
}

.question-icon:hover:before {
  background-color: $bgcolor-button-secondary-hover;
  z-index: -1;
}

.question-icon:hover + .tool-chip-type-note {
  display: inline-block;
}

.question-icon + .tool-chip-type-note {
  margin-left: 20px;
}

.tool-chip-type-note {
  display: none;
  position: absolute;
  font-size: 12px;
  width: 322px;
  border: 1px solid $basecolor-primary;
  padding: 10px;
  box-sizing: border-box;
  background-color: $bgcolor-normal;
  vertical-align: top;
}

.tool-chip-type-note:after,
.tool-chip-type-note:before {
  right: 100%;
  top: 22px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.tool-chip-type-note:after {
  border-right-color: $bdcolor-reverse;
  border-width: 10px;
  margin-top: -10px;
}

.tool-chip-type-note:before {
  border-right-color: $bdcolor-primary-theme;
  border-width: 11px;
  margin-top: -11px;
}

.l-setting {
  .notification-item {
    margin-top: 30px;
    position: relative;
  }

  .setting-list {
    margin-top: 10px;
  }

  .setting-list-item {
    @include mq-sp {
      margin-bottom: 24px;
    }
  }
}
