//記事配信用のマークダウンファイル出力で生成される特殊なページ
// sass-lint:disable no-vendor-prefixes
$article-title-lineheight: 1.5;
$article-title-fontsize: 18px;
$article-content-lineheight: 1.8;

.articles {
  img {
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: auto;
    max-width: 100%;
    height: auto;
  }

  figure,
  figcaption,
  blockquote {
    margin: 0;
  }

  article,
  aside {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 15px;

    @include mq-sp {
      width: 100%;
    }
  }

  aside {
    text-align: center;

    h2 {
      margin-top: 30px;
      font-size: 20px;
    }

    .top-university-center {
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      max-width: 480px;
      text-align: center;
      font-size: $font-size-LL;
      line-height: 34px;

      li {
        display: inline-block;
        margin: 0 10px;
      }

      @include mq-sp {
        font-size: $font-size-small;
        margin-top: 15px;
      }
    }
  }

  .list-group__heading--multiline-sp + .list-group__text--small {
    @include text-over-flow(850px);
    @include mq-sp {
      display: none;
    }
  }

  .l-grid__container {
    @include mq-sp {
      padding-bottom: 0;
    }
  }

  .header--main {
    .heading {
      @include mq-sp {
        display: none;
      }
    }
  }

  .header--main + .text--small {
    margin-top: 10px;
  }

  h1.article__title {
    font-size: $article-title-fontsize;
    font-weight: bold;
    color: $basecolor-normal;
    line-height: $article-title-lineheight;
    @include mq-sp {
      display: block;
    }
  }

  .article__title-area {
    padding: 20px 15px;
    background-color: $bgcolor-sp-normal;

    @include mq-sp {
      margin: 0 -15px;
    }
  }

  .article__title + .article__discription {
    margin-top: 10px;
  }

  .article__discription {
    font-size: $font-size-basic;
    line-height: $article-content-lineheight;
  }

  .article__header {
    margin-top: 10px;
  }

  * + .articles-detail {
    margin-top: 50px;
    @include mq-sp {
      margin-top: 30px;
    }
  }

  //記事配信ページのみで使うパネル　汎用性はない
  .article__panel {
    @include mq-sp {
      background-color: $bgcolor-primary;
      margin: 0 -15px;
    }

    figure {
      @include image--responsive(320px, 768px);
    }
  }

  .articles-detail {
    //aricle-detail以下の各要素にmargin-top:20pxをつける
    * + * {
      margin-top: 25px;
    }

    * + h2 {
      margin-top: 50px;
    }

    h3 + * {
      margin-top: 5px;
    }

    //マークダウン形式で出力なので
    br + br {
      margin-top: 0;
    }

    h2 {
      @extend .heading--bordered;
    }

    h3 {
      @extend .heading--primary;
    }

    p {
      line-height: $article-content-lineheight;
    }

    ul {
      @extend .list;
    }

    blockquote {
      margin-left: 20px;
      border-left: 4px solid $bdcolor-normal;
      padding-left: 20px;
      color: $fgcolor-lowpriority;
      @include mq-sp {
        margin-left: 10px;
        padding-left: 10px;
      }
    }

    strong {
      font-weight: bold;
    }

    a {
      color: $fgcolor-link;
      text-decoration: none;
    }
  }

  .content--lower {
    margin-top: 80px;
    margin-bottom: 30px;

    @include mq-sp {
      margin-top: 40px;
    }
  }

  .btn-facebook + p {
    margin-top: 10px;
  }

  //広告用
  .text--catch {
    color: $basecolor-primary;
    font-weight: bold;
    font-size: 32px;

    @include mq-sp {
      font-size: 20px;
      width: 295px;
      margin: 0 auto;
    }
  }

  .text--catch + .text--medium {
    margin-top: 35px;
  }
}

.articles-detail + .panel__text--small-right {
  margin-top: 100px;
  @include mq-sp {
    margin-top: 50px;
  }
}

//comicなど幅いっぱいに画像を広げたい時
.articles-detail--full {
  @include mq-sp {
    img {
      width: calc(100% + 30px);
      max-width: initial;
      height: auto;
      margin: 0 -15px;
    }
  }

  @include mq-pc {
    img {
      display: block;
      width: 640px;
      margin: 0 auto;
    }
  }
}

.article-slider {
  margin: -1px -15px 40px -15px;
  width: 100vw;
  height: 160px;
  overflow: hidden;
  box-sizing: border-box;
  @include mq-pc {
    margin: -30px 0 64px 0;
    height: 400px;
  }

  &.slick-dotted {
    &.slick-slider {
      margin: 0 -15px 40px -15px;
    }
  }

  .slick-center {
    opacity: 1;
  }
}

.article-slider-card {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 160px;
  @include mq-pc {
    height: 400px;
    width: 762px;
    opacity: 0.5;
    transition: 0.3s linear;
  }

  &__ {
    &link {
      display: flex;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      background: $shadow-11;
      color: $fgcolor-darkbg;
      transition: none;
      @include mq-pc {
        background: none;
      }

      &:hover {
        text-decoration: none;
        color: $fgcolor-darkbg;
      }
    }

    &body {
      color: $fgcolor-darkbg;
      display: flex;
      flex: 1;
      align-items: flex-end;
      box-sizing: border-box;
      padding: 16px;
      @include mq-pc {
        height: 50%;
        padding: 32px;
        background: linear-gradient(to bottom, $shadow-9, $shadow-10);
      }
    }

    &category {
      display: none;
      @include mq-pc {
        border-bottom: 2px solid $bdcolor-reverse;
        box-sizing: border-box;
        display: inline-block;
        height: 30px;
        font-size: $font-size-LL;
        margin-bottom: 8px;
      }
    }

    &title {
      overflow: hidden;
      width: 100%;
      font-size: $font-size-LL;
      line-height: 24px;
      font-weight: bold;
      height: 48px;
      @include mq-pc {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 8px;
        height: 64px;
        font-weight: normal;
      }
    }

    &date {
      font-size: $font-size-smaller;
      line-height: 16px;
      @include mq-pc {
        font-size: $font-size-basic;
        line-height: 16px;
      }
    }
  }
}

.article-section {
  margin-bottom: 56px;
  @include mq-pc {
    max-width: 960px;
    margin: 0 auto 96px auto;
  }

  &__ {
    &aside {
      box-sizing: border-box;
      text-align: right;
      @include mq-pc {
        border-top: 1px solid $bdcolor-normal;
        height: 32px;
      }
      @include mq-middle {
        margin: 0 16px;
      }
    }

    &more {
      color: $fgcolor-normal;
      font-weight: bold;
      display: inline-block;
      font-size: $font-size-small;
      transition: 0.05s linear;
      padding: 16px;
      margin: 0 -16px;

      &:hover {
        color: $fgcolor-supplemental;
        text-decoration: none;
      }

      @include mq-pc {
        border: 1px solid $bdcolor-normal;
        border-top-color: $bdcolor-reverse;
        position: relative;
        top: -1px;
        height: 32px;
        box-sizing: border-box;
        line-height: 30px;
        padding: 0 16px;
        margin: 0;
      }
    }
  }
}

.article-section-header {
  text-align: center;
  margin-bottom: 24px;
  @include mq-pc {
    margin-bottom: 40px;
  }
  @include mq-middle {
    margin: 0 16px 24px 16px;
  }

  &--top {
    position: relative;
    @include mq-pc {
      height: 40px;
      margin-bottom: 24px;
      text-align: left;
      display: flex;
    }

    @include mq-middle {
      display: block;
      text-align: center;
      height: 112px;
    }
  }

  &--under {
    @include mq-sp {
      margin-top: 24px;
    }
  }

  &__ {
    &hgroup {
      flex: 1;
      font-size: 0; //ソース上の改行でスペースが空いてしまうのを削除
      @include mq-middle {
        margin-bottom: 24px;
      }
    }

    &title {
      font-size: $font-size-LL;
      line-height: 24px;
      margin-bottom: 8px;
      font-weight: bold;
      @include mq-pc {
        font-size: 22px;
        margin-bottom: 0;
      }

      &--horizontal {
        @include mq-pc {
          line-height: 1;
          display: inline-block;
          margin-right: 8px;
        }
        @include mq-middle {
          display: block;
          margin-right: 0;
        }
      }
    }

    &subtitle {
      margin-top: 0;
      color: $fgcolor-supplemental;
      font-weight: normal;
      font-size: $font-size-smaller;
      line-height: 16px;
      @include mq-pc {
        line-height: 24px;
        font-size: $font-size-small;
      }

      &--horizontal {
        line-height: 1;
        display: inline-block;
      }
    }

    &btn {
      width: 44px;
      height: 34px;
      padding: 0;
      background: url(/assets/img/article/btn-category-drawer.svg) no-repeat 0 0;
      border: none;
      -webkit-tap-highlight-color: $shadow-9;
      position: absolute;
      right: 0;
      top: 4px;
      border-radius: 0;
      font-size: 0;
      @include mq-pc {
        display: none;
      }
    }
  }
}

.article-category {
  display: none;
  align-items: flex-end;
  @include mq-pc {
    display: flex;
  }
  @include mq-middle {
    justify-content: center;
  }

  &__ {
    &item {
      @include mq-sp {
        height: 44px;
        box-sizing: border-box;
        border-bottom: 1px solid $bdcolor-normal;
        &:last-child {
          border: none;
        }
      }
      @include mq-pc {
        height: 40px;
      }
    }

    &link {
      padding: 0 16px;
      font-weight: bold;
      font-size: $font-size-basic;
      color: $fgcolor-primary-theme;
      @include mq-sp {
        font-weight: bold;
        display: block;
        height: 44px;
        line-height: 43px;
        &:hover {
          text-decoration: none;
        }
      }
      @include mq-pc {
        padding: 0;
        margin-left: 24px;
        color: $fgcolor-supplemental;
        font-size: $font-size-small;
        background: none;
        border: none;
        outline: none;
      }

      &--active {
        color: $fgcolor-primary-theme;
      }
    }
  }

  &-- {
    &modal {
      display: block;
      margin-top: -16px;
    }
  }
}

.article-section-footer {
  margin: 0 -15px -60px -15px;
  position: relative;
  z-index: 2;
  @include mq-pc {
    display: block;
    text-align: center;
    margin: 0;
  }
  @include mq-sp {
    padding-bottom: 180px;
  }

  &__btn-back {
    height: 44px;
    border-top: 1px solid $bdcolor-primary-theme;
    border-bottom: 1px solid $bdcolor-primary-theme;
    color: $fgcolor-primary-theme;
    line-height: 44px;
    display: block;
    font-weight: bold;
    text-align: center;
    font-size: $font-size-small;
    @include mq-pc {
      border: none;
      height: auto;
      line-height: 1;
      display: inline;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &--without-global-footer {
    margin-top: 48px;
    margin-bottom: 0;
  }
}

.article-register-modal-header {
  margin-bottom: 0;
}

.article-register-modal-content {
  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px;
  gap: 16px;

  > img {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

.article-register-modal-text {
  font-size: 12px;
  padding: 0;
}
