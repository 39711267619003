// sass-lint:disable no-vendor-prefixes
@import "normalize";

html {
  height: calc(100% - #{$header-height--pc});
  font-family: Helvetica Neue, Helvetica, Arial, "游ゴシック", "Yu Gothic", YuGothic, "メイリオ", Meiryo, sans-serif;

  @include mq-sp {
    // for hamburger menu
    position: relative;
    overflow-x: hidden;
    height: calc(100% - #{$header-height--sp});
  }
}

// ----------------------------------------------
//  FONTS
// ----------------------------------------------

html[data-os^="Windows 8.1"] {
  font-family: Arial, "メイリオ", Meiryo, sans-serif;
}

html[data-os^="Windows 10"] {
  font-family: Arial, "Yu Gothic Medium", sans-serif;
}

body {
  color: $fgcolor-normal;
  background-color: $bgcolor-normal;
  font-size: $font-size-basic;
  min-height: 100%;

  &.is-fixed {
    height: 100%;
  }
}

ruby rt {
  color: $color-black-18;
}

hr {
  border: 0;
  height: 0;
  border-bottom: 1px solid $color-black-5;
  margin: 0 5px;
}

// ----------------------------------------------
//  HEADING
// ----------------------------------------------
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

h1 {
  font-size: $font-size-basic * 2;
  font-weight: normal;

  @include mq-sp {
    font-size: 0;
  }

  + * {
    margin-top: 30px;
    @include mq-sp {
      margin-top: 0;
    }
  }
}

h2 {
  font-size: $font-size-basic * 1.5;
  font-weight: normal;

  &.heading-border {
    color: $fgcolor-primary-theme;
    border-bottom: 1px solid $fgcolor-primary-theme;
  }

  + * {
    margin-top: 15px;
  }
}

h5 {
  font-size: $font-size-basic;
}

// ----------------------------------------------
//  LIST
// ----------------------------------------------
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

dl,
dd {
  margin: 0;
  padding: 0;
}

// ----------------------------------------------
//  LINK
// ----------------------------------------------

%text-action-style {
  color: $fgcolor-link;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: all 300ms linear;

  &:hover {
    color: $fgcolor-link-hover;
    text-decoration: underline;
    transition: all 300ms linear;
  }
}

a {
  margin: 0;
  @extend %text-action-style;
}

// ----------------------------------------------
//  TEXT
// ----------------------------------------------
p {
  margin: 0;
}

.error-message {
  color: $basecolor-error;
  white-space: pre-line;
}

// ----------------------------------------------
//  FORM
// ----------------------------------------------

input[type="text"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="email"],
input[type="datetime"],
input[type="date"],
textarea {
  border: 1px solid $bdcolor-normal-dark;
  border-radius: 2px;
  padding: 10px;
  vertical-align: middle;
  box-sizing: border-box;
  -webkit-appearance: none;

  &:focus {
    border: 1px solid $basecolor-primary;
    outline: none;
  }
}

select {
  margin: 5px;
  vertical-align: middle;
  border: 1px solid $color-black-7;
  padding: 6px 5px;
  outline: none;

  &:hover {
    background-color: $bgcolor-normal;
  }
}

:placeholder-shown {
  color: $color-black-8;
}

label {
  padding: 5px;
  color: $fgcolor-normal;
}

input[type="radio"],
input[type="checkbox"] {
  vertical-align: baseline;
  margin: 5px;
}

input[type="submit"],
button {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: $font-size-basic;
  user-select: none;
  border: 1px solid $color-black-7;
  border-radius: 2px;
  color: $color-black-3;
  background-color: $color-black-11;
}

time,
address {
  line-height: 1;
}

// ----------------------------------------------
//  Styleguide Base
// ----------------------------------------------

// Heading
//
// <h1>Heading Level 01</h1>
// <h2>Heading Level 02</h2>
// <h3>Heading Level 03</h3>
// <h4>Heading Level 04</h4>
// <h5>Heading Level 05</h5>
// <h6>Heading Level 06</h6>
//
// Styleguide 1.1

// Paragraph
//
// <p>ここに<ruby><rb>文章</rb><rt>ぶんしょう</rt></ruby>がはいります</p>
// <p>ここに文章がはいります</p>
// <p>ここに文章がはいります</p>
//
// Styleguide 1.2

// Listing(Unorderd/Ordered)
//
// <ul>
//   <li>順不同アイテム</li>
//   <li>順不同アイテム</li>
//   <li>順不同アイテム</li>
// </ul>
// <ol>
//   <li>アイテム</li>
//   <li>アイテム</li>
//   <li>アイテム</li>
// </ol>
//
// Styleguide 1.3

// HyperLinks
//
// <a href="#">リンクです</a><a href="#">リンクです</a><a href="#">リンクです</a>
//
// Styleguide 1.4

// Images
//
// <img src="http://dummyimage.com/300x200/0f7b52/fff.png" alt="ダミー画像" />
//
// Styleguide 1.5

// HyperLinks
//
// <a href="#">リンクです</a><a href="#">リンクです</a><a href="#">リンクです</a>
//
// Styleguide 1.6

// Forms
//
// <div>
//   <label>
//     <input type="checkbox"><span>チェックボックス</span>
//   </label>
// </div>
// <div>
//   <label>
//     <input type="radio" name="test"><span>ラジオ1</span>
//   </label>
//   <label>
//     <input type="radio" name="test"><span>ラジオ2</span>
//   </label>
// </div>
// <div>
//   <label><span>インプット</span>
//     <input type="text" placeholder="placeholder">
//   </label>
// </div>
// <div>
//   <label><span>パスワード</span>
//     <input type="password" placeholder="password">
//   </label>
// </div>
// </div>
// <div>
//   <label><span>テキストエリア</span>
//     <textarea>ここに文章が入ります。ここに文章が入ります。ここに文章が入ります。ここに文章が入ります。ここに文章が入ります。ここに文章が入ります。</textarea>
//   </label>
// </div>
// <div>
//   <label><span>セレクト</span>
//     <select>
//       <option>デフォルト</option>
//       <option value="1">選択肢1</option>
//       <option value="2">選択肢2</option>
//     </select>
//   </label>
// </div>
// <input type="submit" value="送信">
//
// Styleguide 1.7
