// Login Form
//
// Styleguide 3.7.0

// input-login
//
// ログイン時のインプット要素
//
// .input.input-login - 通常時のテキスト入力要素
// .input.input-login.is-invalid - エラー時の要素
//
// Markup:
// <input class="{$modifiers}" type="text" value="メールアドレス">
// <input class="{$modifiers}" type="password" value="パスワード">
//
// Styleguide 3.7.1

input.input.input-login {
  border: none;
  border-bottom: 1px solid $bdcolor-normal;
  box-sizing: border-box;
  border-radius: 0;
  padding-left: 0;

  &:focus {
    background-color: $bgcolor-normal;
    border: none;
    border-bottom: 1px solid;
    border-color: $bdcolor-focus;
    outline: none;
  }

  &.is-invalid {
    border-color: $basecolor-error;

    &:focus {
      background-color: $bgcolor-normal;
      border-color: $basecolor-error;
      outline: none;
    }
  }
}

// ログインフォーム
//
// .login-form - ログインフォーム<br/>
// チェックマーク出したり微妙に幅調整してるためrowモジュールは未使用。
//
// Markup:
// <div class="login-form">
//   <div class="control-group">
//     <label class="control-group-label">メールアドレス</label>
//     <div class="control-group-item">
//       <input class="login-form-input" type="text">
//     </div>
//   </div>
//   <div class="control-group">
//     <label class="control-group-label">パスワード</label>
//     <div class="control-group-item">
//       <input class="login-form-input" type="password">
//     </div>
//   </div>
//   <div class="login-form-actions-row">
//     <button class="login-form-submit-button" type="submit">ログイン</button>
//   </div>
// </div>
//
// Styleguide 3.7.2

.login-form {
  width: 380px;
  margin: 0 auto;

  @include mq-sp {
    width: 100%;
    box-sizing: border-box;
  }

  .control-group {
    .control-group-label {
      padding: 0;
      color: $color-black-5;
      position: relative;
      top: 34px;
      transition-duration: 0.5s;
      cursor: auto;
      user-select: none;

      &.is-active {
        top: 0;
      }
    }

    .control-group-item {
      input.login-form-input {
        @extend .input;
        @extend .input-login;
        width: 100%;
      }
    }

    & + .control-group {
      margin-top: 20px;

      @include mq-sp {
        margin-top: 15px;
      }
    }
  }

  .login-form-actions-row {
    @extend .row;
    margin-top: 40px;
    margin-right: 25px;
    text-align: center;

    @include mq-sp {
      margin-top: 20px;
    }

    .login-form-submit-button {
      @extend .btn;
      @extend .btn-primary;
      @extend .btn-big;

      @include mq-sp {
        width: 100%;
      }
    }
  }

  .login-form-password-reset-link {
    text-align: center;
    margin-top: 15px;
  }
}

// ログインフォーム（エラー時）
//
// Markup:
// <div class="login-form">
//   <div class="control-group is-invalid">
//     <label class="control-group-label is-active">メールアドレス</label>
//     <div class="control-group-item">
//       <input class="login-form-input" type="text">
//     </div>
//   </div>
//   <div class="control-group is-invalid">
//     <label class="control-group-label is-active">パスワード</label>
//     <span class="error-message">必須項目です</span>
//     <div class="control-group-item">
//       <input class="login-form-input" type="password">
//     </div>
//   </div>
//   <div class="login-form-actions-row">
//     <button class="login-form-submit-button" type="submit" disabled>ログイン</button>
//   </div>
// </div>
//
// Styleguide 3.7.3
