.icon--less-than {
  @include icon--left(15px, -10px);

  &::before {
    content: "<";
  }
}

a.icon-back-signup {
  @extend .icon-arrow-left;
  position: relative;
  top: 15px;
  left: 15px;
  font-size: 24px;
  color: $basecolor-primary;
  text-decoration: none;

  span {
    font-size: 0;
  }
}

.is-active {
  .fa-star-o::before {
    content: "\f005";
  }
}

.icon-new {
  display: inline-block;
  @include circle(12px);
  background-color: $color-pink;
  color: $basecolor-reverse;
}

.icon--like {
  width: 29px;
  height: 24px;
  background: url("/assets/icon/icn-like.svg") no-repeat;
  background-position: -29px 0;
}
