// jquery.ui datepicker override

.ui-widget.ui-widget-content {
  padding: 15px;
  // font-size: $font-size-small;

  .ui-datepicker-calendar {
    .ui-state-default {
      margin: 5px;
      padding: 6px;
      border: none;
      border-radius: $bdradius-circle;
      background: none;
      text-align: center;
      transition: none;
      color: $fgcolor-normal;

      &.ui-state-active {
        color: $fgcolor-darkbg;
        background-color: $bgcolor-theme;
      }

      &.ui-state-highlight {
        color: $bgcolor-theme;
        font-weight: bold;

        &.ui-state-active {
          color: $fgcolor-darkbg;
          background-color: $bgcolor-theme;
        }
      }

      &:hover {
        background-color: $bgcolor-button-secondary-hover;
      }
    }

    th {
      font-weight: normal;
    }
  }

  .ui-widget-header {
    border: none;
    background: none;
    font-weight: normal;

    .ui-icon {
      background: none;
    }

    .ui-datepicker-prev {
      &::after {
        display: block;
        position: absolute;
        content: "";
        top: 8px;
        left: 15px;
        width: 7px;
        height: 7px;
        border-bottom: 2px solid $basecolor-appendix;
        border-left: 2px solid $basecolor-appendix;
        transform: rotate(45deg);
      }
    }

    .ui-datepicker-next {
      &::after {
        display: block;
        position: absolute;
        content: "";
        top: 8px;
        width: 7px;
        height: 7px;
        border-top: 2px solid $basecolor-appendix;
        border-right: 2px solid $basecolor-appendix;
        transform: rotate(45deg);
      }
    }

    .ui-datepicker-prev-hover,
    .ui-datepicker-next-hover {
      border: none;
      background: none;
      text-decoration: none;
    }
  }
}

.hasDatepicker {
  position: relative;
  z-index: 3;

  @include mq-sp {
    z-index: 0;
  }
}

div.ui-datepicker {
  @include mq-sp {
    width: auto;
  }
}

div.ui-widget-content {
  @include mq-sp {
    border: none;
  }
}

//iPhone5 の幅だとうまいこといかないのでライブラリの css に追加指定行う。
.ui-datepicker table {
  table-layout: fixed;
}
