// sass-lint:disable no-important no-vendor-prefixes no-misspelled-properties
.select2-selection {
  @extend select;
}

.form-control .select2-selection {
  padding: 5px 6px;
  background-image: none;
}

.select2-results__options {
  @include mq-sp {
    max-height: 250px !important;
    -webkit-overflow-scrolling: touch;
  }
}

.select2-dropdown {
  -webkit-tap-highlight-color: $select2-highlight-color;
}
