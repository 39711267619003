.profile-edit-intern-delete-btn {
  margin-top: 20px;
  text-align: right;

  .btn {
    width: 120px;
    height: 36px;
    line-height: 34px;
  }
}

.profile-edit-intern-add-btn {
  text-align: center;
}
