// sass-lint:disable no-vendor-prefixes

body {
  @include mq-sp {
    // for hamburger menu
    overflow-x: hidden;
  }
}

.body {
  &--default {
    background-color: $bgcolor-primary;
  }
}

.body--index {
  font-feature-settings: "palt";
  background: $bgcolor-normal;
}

//Safariのもじ詰めバグ解消のため、Safariにのみ'pkna'を適応
_::-webkit-full-page-media,
_:future,
:root .body--index {
  font-feature-settings: "pkna";
}
