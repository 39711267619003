.event-detail-summary {
  width: 640px;
  padding-right: 30px;
  box-sizing: border-box;

  @include mq-sp {
    border: 0;
    padding: 0;
    width: auto;
  }

  .event-detail-summary-box {
    word-break: break-all;
    margin-bottom: 16px;
  }

  .event-detail-summary-title {
    color: $color-darkgray;
    margin-bottom: 8px;
  }
}

.event-detail-summary-content {
  font-size: $font-size-small;
}
