.l-grid__container {
  @include l-grid-container(30px, 60px);
}

// 高さが高い場合
.l-grid__container--higher {
  padding-bottom: 140px;
}

// デフォルトでつくpadding-bottomがいらない時
.l-grid__container--init-bottom {
  .l-grid__container {
    @include mq-sp {
      padding-bottom: 0;
    }
  }
}

.l-grid__row {
  @include clearfix;

  &--both-adge {
    @extend .l-grid__row;
    @include mq-pc {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}

$grid-margin: 10px;
[class*="l-grid__column-"] {
  float: left;
  //スマホ表示でカラムは全て100%になる
  width: 100%;
  min-height: 1px;
}

@include mq-pc {
  .l-grid__container {
    width: 960px;
  }
  //grid systemの.l-grid__column-1から.l-grid__column-96までを作成
  $column-number: 96;
  @for $i from 1 through $column-number {
    .l-grid__column--#{$i} {
      $col: 960px / 96 * $i;
      width: $col - $grid-margin * 2;
      margin-left: $grid-margin;
      margin-right: $grid-margin;
    }
  }
}

.l-grid-auto--left {
  float: left;
}

.l-grid-auto--right {
  float: right;
}
