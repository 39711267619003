.receive-notification-modal {
  max-width: 528px;
  text-align: left;
  border-radius: 10px;

  @include mq-sp {
    top: 50vh;
    transform: translateY(-50%);
  }

  &__header {
    padding: 12px 0 8px 0;
    margin: 0;
    height: auto;
    text-align: center;
    border-bottom: 1px solid $color-gray;

    h3 {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
    }
  }

  &__content {
    padding: 16px;
  }

  &__option {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
    margin-bottom: 12px;
    border: 2px solid $color-gray;
    border-radius: 4px;
    cursor: pointer;

    &--checked {
      border-color: $color-uiblue;
    }

    input[type="radio"] {
      margin: 0 4px 0 0;
      position: relative;
      top: 4px;
    }
  }

  &__option-title {
    display: inline-block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    color: $color-uiblue;
  }

  &__option-description {
    font-size: 12px;
    line-height: 1.5;
  }

  &__note {
    margin-bottom: 12px;
    color: $color-darkgray;
    font-size: 12px;
    line-height: 1.5;
  }

  &__button {
    display: block;
    margin: 0 auto;
    width: 270px;
    font-weight: normal;
  }
}
