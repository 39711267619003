// sass-lint:disable nesting-depth
.convert-lp-wrapper {
  @include mq-pc {
    max-width: 960px;
    margin: 0 auto;
    position: relative;
  }
}

$convert-ob-lp-blue: #2aa9f0;
$convert-ob-lp-pink: #ff7595;
$convert-ob-lp-green: #36d189;

.convert-lp-header {
  background: url(/assets/img/convert/bg.svg) no-repeat center;
  height: 264px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -15px 24px -15px;
  @include mq-pc {
    height: 584px;
    margin: -30px 0 48px 0;
  }

  &__ {
    &title {
      &__text {
        padding: 4px;
        background: $convert-ob-lp-blue;
        color: $color-white;
        line-height: 48px;
        @include mq-pc {
          font-size: 72px;
          line-height: 104px;
        }
        @include mq-sp {
          font-size: 32px;
        }
        @media screen and (max-width: 340px) {
          font-size: 28px;
        }
      }
    }
  }
}

.convert-lp-poem {
  color: $convert-ob-lp-green;
  font-weight: 800;
  margin-bottom: 40px;

  &:after {
    content: "";
    display: block;
    width: 80px;
    height: 10px;
    background: $convert-ob-lp-green;
    margin-top: 40px;
  }

  @include mq-pc {
    margin-bottom: 56px;
    &:after {
      height: 12px;
      margin-bottom: 56px;
    }
  }

  &__ {
    &text {
      margin-bottom: 16px;
      line-height: 32px;
      font-size: 19px;
      @include mq-pc {
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 24px;
      }
    }
  }
}

.convert-lp-section {
  margin-bottom: 48px;

  &__ {
    &title {
      font-size: 24px;
      color: $color-white;
      font-weight: 800;
      margin-bottom: 16px;
      @include mq-pc {
        font-size: 32px;
        margin-bottom: 24px;
      }

      &__ {
        &blue {
          padding: 4px;
          background: $convert-ob-lp-blue;
        }

        &pink {
          padding: 4px;
          background: $convert-ob-lp-pink;
        }

        &green {
          padding: 4px;
          background: $convert-ob-lp-green;
        }
      }
    }

    &text {
      font-size: 19px;
      font-weight: 800;
      line-height: 32px;
      margin-bottom: 16px;
      @include mq-pc {
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 24px;
      }
    }
  }
}

.convert-lp-bottom {
  @include mq-pc {
    display: flex;
  }
}

.convert-lp-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 48px;
  @include mq-pc {
    width: auto;
    position: static;
    border-radius: 24px;
    padding: 24px;
    height: auto;
    box-shadow: 0 11px 20px 0 $lp-footer-shadow;
  }

  &__btn {
    display: block;
    width: 100%;
    height: 100%;
    background: $color-pink;
    box-sizing: border-box;
    line-height: 48px;
    color: $color-white;
    text-align: center;
    font-weight: bold;

    &:hover {
      text-decoration: none;
      color: $color-white;
      @include mq-pc {
        background: $color-white;
        color: $color-pink;
        transition: 0.1s linear;
      }
    }

    @include mq-pc {
      border: 4px solid $color-pink;
      height: 56px;
      line-height: 48px;
      border-radius: 28px;
      font-size: 20px;
      padding: 0 28px;
      width: auto;
      transition: 0.1s linear;
    }
  }
}

.convert-lp-caution {
  color: $color-darkgray;
  margin-bottom: 24px;
  @include mq-pc {
    flex: 1;
  }
}

.convert-lp-app-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 32px;
  padding: 16px;
  background: $bgcolor-lp-app-header;
  transition: 0.2s linear;

  &.is-background {
    background: $bgcolor-lp-app-header-background;
    border-bottom: 1px solid $color-gray;
  }
}
