//記事配信用のマークダウンファイル出力で生成される特殊なページ
// sass-lint:disable no-important no-vendor-prefixes nesting-depth
$article-title-lineheight: 1.5;
$article-title-fontsize: 18px;
$article-content-lineheight: 1.8;

.report-detail {
  font-feature-settings: "palt";

  .fb_iframe_widget > span {
    vertical-align: baseline !important;
  }

  .l-grid__container {
    width: 100%;
    padding-top: 20px;
  }

  &__ {
    &width-wrapper {
      margin: 0 auto 64px auto;

      @include mq-pc {
        position: relative;
        max-width: 720px;
        padding: 0 20px;
        box-sizing: border-box;
        margin: 0 auto 88px auto;
      }

      &.no-session {
        @include mq-pc {
          max-width: 960px;
          display: flex;
          gap: 24px;
          align-items: flex-start;
        }
        @media screen and (min-width: 641px) and (max-width: 660px) {
          max-width: inherit;
          display: inherit;
        }
      }
    }

    &title {
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      min-height: 32px;
      margin-bottom: 16px;
      @include mq-pc {
        min-height: 48px;
        margin-bottom: 20px;
        font-size: 32px;
        line-height: 48px;
      }
    }

    &description {
      font-size: $font-size-basic;
      line-height: 24px;
      margin-bottom: 24px;
      color: $fgcolor-text-supplementation;
      @include mq-pc {
        line-height: 24px;
        font-size: $font-size-basic;
      }
    }

    &date {
      font-size: $font-size-XS;
      color: $fgcolor-text-supplementation;
      margin-bottom: 20px;
      line-height: 20px;
      display: block;
      @include mq-pc {
        font-size: $font-size-small;
      }
    }

    &share-buttons {
      height: 20px;

      &--appeal {
        background: $bgcolor-primary;
        text-align: center;
        padding: 10px;
        margin-bottom: 50px;
        @include mq-pc {
          padding: 20px;
        }
      }
    }

    &label {
      height: 24px;
      font-size: $font-size-basic;
      color: $fgcolor-primary-theme;
      border-bottom: 2px solid $bdcolor-primary-theme;
      display: inline-block;
      margin-bottom: 24px;
      font-weight: bold;
      transition: 0.05s linear;

      &:hover {
        text-decoration: none;
        opacity: 0.5;
        transition: 0.05s linear;
      }
    }

    &cover-image {
      max-width: calc(100% + 30px);
      height: auto;
      margin: 40px -15px 0 -15px;
      @include mq-pc {
        width: 100%;
        margin: 40px auto 40px 0;
        display: block;
      }
    }

    &writer {
      font-size: $font-size-smaller;
      text-align: right;
      line-height: 20px;
      margin-bottom: 0;
      color: $fgcolor-lowpriority;
      @include mq-sp {
        margin-bottom: 40px;
      }
    }
  }
}

.article-register-banner {
  position: sticky;
  contain: paint;
  right: 0;
  top: 80px;

  @include mq-sp {
    position: fixed;
    right: 0;
    left: 0;
    bottom: -3px;
    top: initial;
    z-index: 3; //.article-section-footerが2なのでちょっと上にしておく
    contain: initial;

    img {
      width: 100%;
      height: auto;
    }
  }

  &-button {
    position: absolute;
    right: 4px;
    top: 4px;
    appearance: none;
    border: none;
    width: 22px;
    height: 22px;
    border: #7D7D7D;
    background-color: #7D7D7D;
    cursor: pointer;
    outline: none;
    padding: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq-sp {
      top: -21px;
    }
  }
}

//Safariのもじ詰めバグ解消のため、Safariにのみ'pkna'を適応
_::-webkit-full-page-media,
_:future,
:root .report-detail {
  font-feature-settings: "pkna";
}

// Style of Markdown
.report-article-text {
  margin-top: 24px;
  font-size: 16px;
  @include mq-pc {
    font-size: $font-size-L;
  }

  img {
    width: auto;
    max-width: 100vw;
    height: auto;
    margin: 0 -15px;
    @include mq-pc {
      max-width: 100%;
      margin: 0;
    }
  }

  p {
    line-height: 28px;
    margin-bottom: 24px;
    @include mq-pc {
      line-height: 30px;
      margin-bottom: 40px;
    }
  }

  //マークダウン形式で出力なので
  br + br {
    margin-top: 0;
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    min-height: 32px;
    margin-bottom: 16px;
    @include mq-pc {
      min-height: 48px;
      margin-bottom: 20px;
      font-size: 32px;
      line-height: 48px;
    }
  }

  h3 {
    font-size: 17px;
    font-weight: bold;
    line-height: 24px;
    min-height: 24px;
    margin-bottom: 15px;
    @include mq-pc {
      font-size: $font-size-large;
      line-height: 25px;
      margin-bottom: 20px;
    }
  }

  ul {
    @extend .list;
    margin-bottom: 25px;
    line-height: 20px;
    padding-left: 1em;
    margin-left: -5px;
    @include mq-pc {
      line-height: 30px;
      margin-bottom: 40px;
    }

    li {
      margin-bottom: 15px;

      + li {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        top: 10px;
        left: -7px;
        width: 5px;
        height: 5px;
        background: $fgcolor-normal;
      }
    }
  }

  blockquote {
    border-left: 4px solid $bdcolor-normal;
    padding-left: calc(1em - 4px);
    color: $fgcolor-lowpriority;
  }

  strong {
    font-weight: bold;
  }

  a {
    color: $fgcolor-primary-theme;
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }

  aside {
    text-align: center;

    h2 {
      margin-top: 30px;
      font-size: 20px;
    }
  }

  figure,
  figcaption,
  blockquote {
    margin: 0;
  }

  iframe {
    margin-bottom: 24px;
    @include mq-pc {
      margin-bottom: 40px;
    }
  }

  //markdownの兼ね合いで、止むを得ず入れ子
  .report-ogp-card {
    border: 1px solid $bdcolor-normal;
    border-radius: 15px;
    overflow: hidden;
    margin: 0 auto 25px auto;
    width: 100%;
    @include mq-pc {
      width: 100%;
      margin-bottom: 40px;
    }

    &:hover {
      opacity: 0.8;
    }

    &__ {
      &link {
        display: block;
        text-decoration: none;
        color: $fgcolor-normal;

        &:hover {
          text-decoration: none;
          color: $fgcolor-normal;
        }
      }

      &thumbnail {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        height: 0;
        // 400 / 960 * 100 を四捨五入
        padding-top: 42%;
        @include mq-pc {
          height: 0;
          padding-top: 42%;
        }
      }

      &content-box {
        padding: 15px;
        text-align: left;
        @include mq-pc {
          padding: 20px;
        }
      }

      &title {
        font-size: $font-size-small;
        font-weight: bold;
        line-height: 20px;
        margin: 0 0 10px 0;
        @include mq-pc {
          font-size: $font-size-LL;
          line-height: 25px;
        }
      }

      &description {
        font-size: $font-size-smaller;
        line-height: 15px;
        max-height: 30px;
        overflow: hidden;
        color: $fgcolor-text-supplementation;
        margin-bottom: 0;
        @include mq-pc {
          font-size: $font-size-basic;
          line-height: 25px;
          max-height: 50px;
        }
      }
    }
  }
}

.report-detail-toc {
  padding: 16px 24px;
  background-color: $color-lightgray;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__title {
    font-weight: bold;
  }

  .toc-list {
    color: $color-uiblue;

    > li::before {
      content: "■";
      display: inline-block;
      font-size: 25px;
      height: 24px;
      line-height: 24px;
      margin-right: 2px;
    }
  }

  .toc-link {
    display: inline-block;
    line-height: 1.5;
  }

  .toc-list.is-collapsible {
    list-style: disc;
    padding-left: 48px;

    > li::before {
      display: none;
    }
  }
}

.report-detail-aside-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 960px;
  margin: 0 auto 40px;

  @include mq-sp {
    margin: 0 -15px 40px; // l-grid__container が画面全体に適用している padding を消すためにネガティブ margin を使用
  }


  &__title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
  }
}

.sub-links {
  @include mq-pc {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 960px;
    margin: 0 auto;
  }
  @include mq-middle {
    justify-content: space-around;
  }

  &__ {
    &item {
      border-bottom: 1px solid $bdcolor-normal;
      box-sizing: border-box;

      &:last-child {
        border: none;
      }

      @include mq-pc {
        flex-basis: 270px;
        margin: 0 0 80px 0;
        border: none;
      }
    }

    &link {
      display: block;
      padding: 20px 15px;
      text-decoration: none;
      color: $fgcolor-normal;
      transition: 0.05s linear;

      &:hover {
        text-decoration: none;
        color: $fgcolor-normal;
        opacity: 0.8;
      }

      @include mq-pc {
        padding: 0;
      }

      @include mq-sp {
        display: flex;
        gap: 8px;
      }
    }

    &thumbnail {
      background-image: url("/assets/img/company-article-no-main.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      height: 0;
      padding-top: 42%;
      margin: 0 0 15px 0;
      @include mq-pc {
        height: 0;
        padding-top: 42%;
        margin: 0 0 25px 0;
      }

      @include mq-sp {
        height: 64px;
        width: 64px;
        padding: 0;
        flex-shrink: 0;
      }
    }

    &title {
      font-size: $font-size-small;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 10px;
      @include mq-pc {
        font-size: $font-size-basic;
        min-height: 40px;
        line-height: 25px;
        max-height: 50px;
        overflow: hidden;
        margin-bottom: 15px;
      }

      @include mq-sp {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 42px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &description {
      font-size: $font-size-small;
      line-height: 20px;
      margin-bottom: 10px;
      max-height: 60px;
      overflow: hidden;
      color: $fgcolor-text-supplementation;
      @include mq-pc {
        font-size: $font-size-basic;
        line-height: 25px;
        max-height: 75px;
        margin-bottom: 15px;
      }

      @include mq-sp {
        display: none;
      }
    }

    &date {
      font-size: $font-size-smaller;
      color: $fgcolor-text-supplementation;
      display: block;
      line-height: 15px;
    }
  }
}

.article-attention {
  margin-bottom: 40px;
  box-sizing: border-box;
  padding: 20px 0;
  border-top: 4px solid $bdcolor-primary-theme;
  border-bottom: 4px solid $bdcolor-primary-theme;

  @include mq-sp {
    padding: 15px 0;
    margin-bottom: 20px;
    border-width: 6px;
  }

  p {
    margin-bottom: 0;

    + p {
      margin-top: 10px;
    }
  }

  &__ {
    &link {
      transition: none;

      &:hover {
        transition: none;
      }

      &::after {
        font-family: Material Icons;
        content: "launch";
        display: inline-block;
        vertical-align: -2px;
        margin-left: 5px;
      }
    }
  }
}

@keyframes article-sub-link {
  0%,
  10%,
  20%,
  30%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  5% {
    transform: translate3d(0, -12px, 0);
  }
  15% {
    transform: translate3d(0, -6px, 0);
  }
  25% {
    transform: translate3d(0, -2px, 0);
  }
}

//ここからトーク
$article-talk-icon-ruka-url: "/assets/img/article/talk/icon/ruka/";
$article-talk-icon-takenaka-url: "/assets/img/article/talk/icon/takenaka/";

.article-talk-section {
  margin-bottom: 72px;

  &__heading {
    display: flex;
    align-items: center;

    &:before,
    &:after {
      content: "";
      height: 1px;
      display: block;
      background: $bdcolor-normal;
      flex: 1;
    }

    &:before {
      margin-right: 16px;
    }

    &:after {
      margin-left: 16px;
    }
  }

  &__small {
    @include mq-pc {
      display: none;
    }
  }

  &__large {
    @include mq-sp {
      display: none;
    }
  }

  .article-talk {
    display: flex;
    margin-bottom: 48px;

    &--self {
      flex-direction: row-reverse;

      .article-talk__balloon {
        margin: 0 16px 0 0;
      }

      .article-talk__name {
        margin-left: 16px;
      }
    }

    &__ {
      &icon {
        width: 56px;
        flex-basis: 56px;
        height: 56px;
        background-position: center;
        background-size: cover;
        margin-top: 24px;

        &-- {
          &ruka {
            &-- {
              &normal {
                background-image: url($article-talk-icon-ruka-url + "normal.png");
              }

              &happy {
                background-image: url($article-talk-icon-ruka-url + "happy.png");
              }

              &shock {
                background-image: url($article-talk-icon-ruka-url + "shock.png");
              }

              &question {
                background-image: url($article-talk-icon-ruka-url + "question.png");
              }

              &hokkori {
                background-image: url($article-talk-icon-ruka-url + "hokkori.png");
              }

              &panic {
                background-image: url($article-talk-icon-ruka-url + "panic.png");
              }
            }
          }

          &takenaka {
            &-- {
              &normal {
                background-image: url($article-talk-icon-takenaka-url + "normal.png");
              }

              &tsukkomi {
                background-image: url($article-talk-icon-takenaka-url + "tsukkomi.png");
              }

              &shock {
                background-image: url($article-talk-icon-takenaka-url + "shock.png");
              }

              &doya {
                background-image: url($article-talk-icon-takenaka-url + "doya.png");
              }
            }
          }
        }
      }

      &content {
        flex: 1;
        @include mq-pc {
          max-width: 50%;
          flex: none;
        }
      }

      &name {
        font-weight: bold;
        font-size: $font-size-small;
        margin-left: 32px;
        margin-bottom: 0;
        line-height: 24px;
        height: 24px;
      }

      &balloon {
        background: $bdcolor-normal;
        padding: 14px;
        border-radius: 12px;
        box-sizing: border-box;
        margin: 0 0 0 16px;

        &-- {
          &takenaka {
            background: $color-blue-10;
            color: $fgcolor-darkbg;
          }

          &ruka {
            background: $color-pink-4;
            color: $fgcolor-darkbg;
          }
        }

        a {
          color: $fgcolor-darkbg;
        }
      }

      &text {
        margin-bottom: 0;
        line-height: 24px;
      }

      &caption {
        font-size: $font-size-smaller;
        line-height: 16px;
        display: inline-block;
        margin-top: 8px;
      }
    }
  }
}
