// sass-lint:disable nesting-depth
@mixin lp-font-size {
  font-size: 18px;
  line-height: 34px;

  @include mq-sp {
    font-size: 14px;
    line-height: 28px;
  }
}

.top-sections {
  overflow: hidden;

  section {
    min-width: 960px;
    box-sizing: border-box;

    @include mq-sp {
      min-width: inherit;
      padding-left: 10px;
      padding-right: 10px;
    }

    h1 {
      font-size: 24px;
      font-weight: normal;
      text-align: center;

      @include mq-sp {
        font-size: 20px;
        line-height: 33px;
      }

      + * {
        margin-top: 30px;
      }
    }

    &:nth-child(2n) {
      background-color: $bgcolor-primary;
    }
  }
}

.top-lead {
  padding: 20px 15px;
  margin: 0 auto;
  max-width: 960px;
  border-bottom: 1px solid $bdcolor-normal;

  h1 {
    font-size: $font-size-large;

    + p {
      margin-top: 10px;
    }
  }
}

.top-lp {
  margin-top: 30px;
  width: 960px;
  margin-left: auto;
  margin-right: auto;

  @include mq-sp {
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
  }

  * + * {
    margin-top: 15px;
  }

  h1 {
    $font-size-lp-XL: 28px;
    font-size: $font-size-lp-XL;
    font-weight: bold;
  }

  h2 {
    font-size: $font-size-larger;
    font-weight: bold;
    color: $fgcolor-primary-theme;
  }

  p {
    font-size: $font-size-small;
    line-height: 1.8;
  }

  strong {
    font-size: $font-size-small;
  }

  blockquote {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 4px solid $bdcolor-normal;
    color: $fgcolor-supplemental;
    font-size: $font-size-small;
  }

  hr {
    margin-top: 25px;
    margin-bottom: 30px;
    border-top: 1px solid $bdcolor-normal;
    border-bottom: 1px solid $bdcolor-reverse;
  }

  hr + * {
    margin-top: 0;
  }

  ul li {
    padding-left: 47px;
    text-indent: -47px;

    &::before {
      position: relative;
      top: -3px;
      margin-left: 20px;
      margin-right: 20px;
      content: "";
      display: inline-block;
      background-color: $color-black-8;
      width: 7px;
      height: 7px;
      border-radius: 7px;
    }
  }

  img {
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: auto;
    max-width: 100%;
    height: auto;
  }
}

// ビズリーチ・キャンパスの特徴
.top-features {
  text-align: center;

  h2 {
    color: $basecolor-primary;
    font-size: 24px;
    font-weight: normal;

    @include mq-sp {
      font-size: 20px;
    }

    + * {
      margin-top: 10px;
    }
  }

  ul {
    position: relative;
    left: 15px;
    font-size: 0;

    @include mq-sp {
      position: static;
    }

    li {
      display: inline-block;
      min-width: 151px;
      min-height: 154px;
      background-image: url("/assets/img/logo-bg.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      @include lp-font-size;

      @include mq-sp {
        display: block;
      }

      + li {
        margin-left: 35px;

        @include mq-sp {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }
  }
}

// 登録OB/OG在籍企業一覧
.top-obs-company {
  text-align: center;

  ul {
    margin: 0 auto;
    max-width: 600px;
    font-size: 0;

    li {
      display: inline-block;
      @include lp-font-size;

      &::after {
        display: inline;
        content: "、";
      }

      &:last-child {
        &::after {
          content: "など多数";
          font-size: 14px;
          @include mq-sp {
            font-size: 10px;
          }
        }
      }
    }
  }
}

//実際に使っている学生の声

.top-voices {
  ul {
    width: 980px;
    margin-left: auto;
    margin-right: auto;

    @include mq-sp {
      width: auto;
    }

    > * {
      @include clearfix;
      @include lp-font-size;
      margin-top: 20px;

      > img {
        float: left;
        width: 80px;

        + * {
          position: relative;
          float: right;
          width: 860px;
          min-height: 80px;
          text-align: left;
          border: 1px solid $bdcolor-primary-theme;
          border-radius: 4px;
          box-sizing: border-box;
          padding: 0 0 0 15px;

          @include mq-sp {
            top: 0;
            display: flex;
            align-items: center;
            width: calc(100% - 90px);
            font-size: 14px;
            line-height: 28px;
            padding: 10px 15px;
          }

          &::before {
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
            left: -11px;
            margin: auto 0;
            width: 20px;
            height: 20px;
            content: "";
            background-color: $bgcolor-normal;
            border: 1px solid $bdcolor-primary-theme;
            border-width: 1px 0 0 1px;
            transform: rotate(-45deg);

            @include mq-sp {
              top: 25px;
              bottom: auto;
              left: -6px;
              height: 10px;
              width: 10px;
            }
          }

          > * {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding-right: 15px;

            @include mq-sp {
              position: static;
              top: auto;
              transform: none;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}

//ビズリーチ・キャンパスの使い方
.top-flow {
  position: relative;
  min-width: 980px;
  min-height: 390px;

  @include mq-sp {
    background: none;
    min-width: 0;
    min-height: 0;
    margin-bottom: 0;
  }

  .top-flow-bg {
    position: absolute;
    width: 534px;
    left: 0;
    right: -680px;
    margin: auto;
    bottom: 0;

    @include mq-sp {
      display: none;
    }
  }

  .top-flow-wrap {
    width: 980px;
    margin: 50px auto 0;

    @include mq-sp {
      width: auto;
      margin-top: 30px;
    }
  }

  .top-flow-step {
    width: 515px;

    @include mq-sp {
      width: auto;
    }

    > * {
      padding: 5px 10px;
      background-color: $bgcolor-normal;
      border: 1px solid $bdcolor-primary-theme;
      border-radius: 4px;
      text-align: center;

      @include mq-sp {
        padding: 10px;
        font-size: 16px;
        line-height: 18px;
      }

      + * {
        position: relative;
        margin-top: 30px;

        &::before {
          position: absolute;
          top: -20px;
          content: "";
          display: block;
          width: 0;
          height: 0;
          left: 0;
          right: 0;
          margin: auto;
          border-color: $fgcolor-arrow transparent transparent;
          border-width: 10px;
          border-style: solid;
        }
      }
    }
  }
}

// NOTE フッター分 コンテンツの高さを足す。
.lp-container {
  padding-bottom: calc(86px + 70px);

  &.has-sub-contents {
    padding-bottom: 86px;
  }
}
