.profile-edit-preferred-title {
  @include mq-sp {
    font-size: 24px;
    line-height: 1.5;
    margin-top: 16px;
    font-weight: 700;
  }
}

.profile-edit-preferred {
  background-color: $bgcolor-normal;
  margin-top: 40px;

  @include mq-sp {
    margin-top: 16px;
    margin-left: -15px;
    width: calc(100% + 30px);
  }

  &__description {
    padding: 40px;
    font-size: 16px;
    line-height: 1.5;

    @include mq-sp {
      padding: 16px;
      font-size: 14px;
    }
  }

  &__content {
    padding: 0 40px 40px 40px;

    @include mq-sp {
      padding: 0 0 16px 0;
    }
  }

  &__submit {
    margin-top: 40px;
    text-align: center;

    @include mq-sp {
      margin-top: 32px;
      .btn-secondary {
        width: 280px;
      }
    }
  }
}