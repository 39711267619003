.event-tag {
  padding: 5px 10px;
  background-color: $bgcolor-normal;
  border: 1px solid $bdcolor-tag-accent;
  border-radius: $bdradius-event-tag;
  color: $fgcolor-tag-accent;
}

@include mq-sp(660px) {
  .event-tag {
    padding: 2px 5px;
    font-size: 10px;
  }
}
