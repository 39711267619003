//----------------------------------------------------------------
//REGISTER-HEADER
//----------------------------------------------------------------
.register-header {
  height: $header-height--pc;
  background-color: $bgcolor-normal;
  border-bottom: 1px solid $color-gray;

  .register-header__contents {
    position: relative;
    width: 960px;
    height: 100%;
    margin: 0 auto;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      width: auto;
      height: 40px;
      margin: auto 0;
    }

    @include mq-sp {
      width: auto;
      margin: 0 20px;
    }
  }
}

//----------------------------------------------------------------
//BUTTON
//----------------------------------------------------------------
.register-form-btn {
  text-align: center;

  .btn-secondary {
    @include mq-sp {
      width: 100%;
    }
  }

  & + .register-skip-link {
    margin-top: 16px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
}

.register-email-login-link {
  margin-bottom: 4px;
  text-decoration: underline;
}

//----------------------------------------------------------------
//SELECT-BUTTON
//----------------------------------------------------------------
.register-select-btn {
  display: block;
  font-size: 0;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;

  label {
    display: inline-block;
    padding: 0;

    & + label {
      margin-left: 20px;
    }
  }

  input {
    display: none;

    & + span {
      display: inline-block;
      width: 160px;
      height: 60px;
      line-height: 60px;
      border: 1px solid $bgcolor-button-primary;
      border-radius: 10px;
      color: $bgcolor-button-primary;
      cursor: pointer;
      font-size: 24px;
      text-align: center;
      transition: background-color 300ms linear;
      user-select: none;

      &:hover {
        background-color: $bgcolor-button-secondary-hover;
        transition: background-color 300ms linear;
      }

      @include mq-sp {
        width: 100px;
      }
    }

    &:checked + span {
      background-color: $bgcolor-button-secondary-hover;
    }
  }

  button {
    display: inline-block;
    width: 160px;
    height: 60px;
    line-height: 60px;
    background: none;
    border: 1px solid $bgcolor-button-primary;
    border-radius: 10px;
    color: $bgcolor-button-primary;
    cursor: pointer;
    font-size: 24px;
    outline: none;
    text-align: center;
    transition: background-color 300ms linear;
    user-select: none;

    & + button {
      margin-left: 20px;
    }

    &:hover {
      background-color: $bgcolor-button-secondary-hover;
      transition: background-color 300ms linear;
    }

    @include mq-sp {
      width: 100px;
    }
  }
}

//----------------------------------------------------------------
//SNSBUTTON
//----------------------------------------------------------------
.register-profile-facebook-link {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 40px;
}

//----------------------------------------------------------------
//INPUT
//----------------------------------------------------------------
input.register-form-input-text {
  width: 300px;

  @include mq-sp {
    width: 100%;
    box-sizing: border-box;
  }

  &.register-form-input-name {
    width: 230px;

    @include mq-sp {
      width: 49%;
    }
  }

  &.register-form-input-toeic {
    width: 100px;
    @include mq-sp {
      width: 100px;
    }
  }

  &.register-form-input-code {
    width: 120px;
    height: 40px;
    border: 1px solid $color-darkgray;
    box-sizing: border-box;
    border-radius: 2px;
    padding-left: 10px;
  }

  &--error {
    background: $color-pink-2;
    border-color: $color-pink;
  }
}

.register-form-selectbox {
  width: 300px;

  @include mq-sp {
    width: 100%;
  }

  &.register-form-selectbox-year {
    width: 98px;
  }

  &.register-form-selectbox-date {
    width: 80px;
  }
}

.form-control {
  .form-select-box--error {
    &:after {
      background-color: $color-red;
    }
  }

  .register-form-selectbox {
    &-- {
      &error {
        border-color: $color-red;
        background-color: $color-pink-2;
      }
    }
  }
}

.select2 {
  // sass-lint:disable-block no-important
  &-container {
    width: 300px !important;

    @include mq-sp {
      width: 100% !important;
    }
  }

  &-selection {
    padding-left: 0 !important;

    &__placeholder {
      color: $color-black !important;
    }

    &__rendered {
      color: $color-black !important;
      height: 28px;
      line-height: 28px;
      padding-left: 5px !important;
      padding-right: 40px !important;
    }

    &__arrow {
      width: 40px !important;
    }
  }
}

//----------------------------------------------------------------
//IMG
//----------------------------------------------------------------
.register-img {
  @include round-bd-image(200px, block);
  margin: auto;
}

.register-profile-img-btn button {
  margin-top: 15px;
}

.register-profile-img-input {
  display: none;
}

//----------------------------------------------------------------
//TEXTAREA
//----------------------------------------------------------------
.register-form-textarea {
  width: 400px;
  height: 150px;
}

//----------------------------------------------------------------
//FORM
//----------------------------------------------------------------
.resister-form-area {
  padding: 40px;
  background-color: $bgcolor-normal;

  @include mq-sp {
    background-color: $bgcolor-sp-normal;
    margin: 0 auto;
    padding: 40px 16px;
  }

  &--small-padding {
    @include mq-sp {
      padding: 25px 0 0 0;
    }
  }

  .register-form-area-title-area {
    margin-bottom: 15px;
  }

  .resister-form-area-title {
    text-align: center;
    line-height: 1.2;

    &--left {
      @include mq-sp {
        text-align: left;
        padding: 0 20px;
        font-size: $font-size-basic;
      }
    }

    &__br {
      @include mq-pc {
        display: none;
      }
    }
  }

  .register-form-area-description {
    max-width: 480px;
    margin: 15px auto 48px;
    color: $color-black;

    @include mq-sp {
      margin-bottom: 20px;
    }
  }

  .privacy-mark-area {
    text-align: right;
  }

  .privacy-mark-icon {
    width: 75px;
    height: 75px;

    @include mq-sp {
      width: 45px;
      height: 45px;
    }
  }

  .form .form-table .form-control {
    text-align: left;

    @include mq-pc {
      &.form-table-row {
        th,
        td {
          padding-top: 30px;
        }
      }
    }

    .register-form-table-title-long {
      @include mq-pc {
        padding-top: 8px;
        vertical-align: top;
      }
    }
  }

  .is-require {
    .form-table-title {
      &::after {
        display: inline-block;
        content: "*";
        color: $basecolor-warning;
        margin-left: 2px;
        vertical-align: top;
      }

      &--hidden-required {
        &::after {
          content: "";
        }
      }

      &--remove-required {
        &::after {
          content: none;
        }
      }

      &--label {
        position: relative;
        top: -2px;
      }
    }
  }

  .register-form-appendix-required {
    font-size: $font-size-smaller;
    color: $basecolor-warning;
  }
}

.register-form-table-title {
  font-weight: bold;
  @include mq-pc {
    padding-bottom: 20px;
  }
  @include mq-sp {
    color: $basecolor-normal;
    text-align: left;
    margin-bottom: 5px;
  }

  > .register-form-table-subtitle {
    color: $color-darkgray;
    font-size: 12px;
  }

  > br {
    @include mq-sp {
      display: none;
    }
  }
}

.register-form-table-title-major {
  padding-bottom: 0;
  position: relative;

  &::after {
    display: inline-block;
    content: "*";
    color: $basecolor-warning;
    margin-left: 2px;
    vertical-align: top;
    top: 8px;
    right: 3px;
  }
}

.register-name-form-table-content {
  @include mq-sp {
    margin-top: 5px;
  }
}

.show-email-content {
  max-width: 350px;

  &__text {
    font-size: 16px;
    line-height: 24px;
    color: $color-black;
    word-break: break-all;
  }

  &__btn {
    margin-left: 10px;
    font-size: 16px;
    line-height: 24px;
    color: $color-uiblue;

    @include mq-sp {
      margin-left: 0;
    }
  }
}

.form-table-content-toeic {
  display: inline-block;

  & + .form-table-content-toeic {
    margin-left: 20px;
    @include mq-sp {
      margin: 5px 0 0 0;
      display: block;
    }
  }
}

.form-table-content-toeic-title {
  display: block;
  @include mq-sp {
    font-size: 12px;
    color: $fgcolor-text-supplementation;
  }
}

.form-table-title.form-table-title-toeic {
  position: relative;
  top: 22px;
  vertical-align: top;
  @include mq-sp {
    position: static;
  }
}

.form-table-content-other-language + .form-table-content-other-language {
  margin-top: 5px;
}

.form-textarea {
  width: 100%;
  height: 150px;
}

.club-hr {
  display: none;
  max-width: 435px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;

  .club-block + & {
    display: block;

    + input + .club-block {
      margin-top: 30px;
    }
  }
}

.register-mark-form-wrapper {
  margin-bottom: 20px;
  @include mq-sp {
    margin-bottom: 32px;
  }
}

//----------------------------------------------------------------
//SUPPLEMENTAL
//----------------------------------------------------------------
.register-form-supplemental {
  line-height: 2;
  margin-top: 10px;
  font-size: 14px;
  color: $fgcolor-text-supplementation;
  font-weight: normal;

  &.is-warning {
    color: $fgcolor-warning;
  }
}

.register-form-password-note {
  color: $color-black;
  font-size: 14px;
  margin-top: 10px;
}

.register-form-inquiry-link {
  color: $color-black;
  font-size: 12px;
  line-height: 2;
  margin-top: 10px;

  & + .register-form-supplemental {
    margin-top: 0;
  }
}

.register-form-area-birthdate-contents {
  display: inline-block;

  @include mq-sp {
    display: block;
    margin-top: 5px;
  }
}

.need-phone-number-modal {
  background-color: $bgcolor-overlay;
}

.need-phone-number-modal-inner {
  max-width: 345px;
  padding: 40px 24px;
  transition: none;

  &__title {
    margin-bottom: 24px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }

  &__description {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 21px;

    &--bold {
      font-weight: bold;
    }
  }
}

.need-phone-number-modal-inner-action {
  text-align: center;
  &__btn {
    @extend .button--secondary;
    height: 44px;
    width: auto;
    min-width: 100px;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 16px;
  }
}

//----------------------------------------------------------------
//BOTTOM AREA
//----------------------------------------------------------------
.register-bottom-area {
  text-align: center;
  margin-top: 24px;

  @include mq-sp {
    margin-top: 0;
  }

  &-text {
    font-size: 16px;
    line-height: 24px;
    color: $color-darkgray;
  }
}
