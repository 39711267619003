@import "top-variables";

//トップビュー
.top-kv {
  position: relative;
  margin: 0 auto;
  width: 100vw;
  max-width: 1280px;
  min-width: $content-size;
  height: 532px;
  overflow: hidden;

  @include mq-sp($lp-breakpoint-sp) {
    margin-top: 60px;
    height: 175px;
    margin-bottom: 16px;
    min-width: auto;
    overflow: hidden;
  }

  &-slide-show-item {
    position: absolute;
    top: 0;
    overflow: hidden;
  }

  &-copy-block {
    margin: 0 auto;
    position: absolute;
    top: 137px;
    left: 150px;

    @include mq-sp($lp-breakpoint-sp) {
      top: 22px;
      left: 34px;
    }

    &-sub {
      margin-top: 16px;

      @include mq-sp($lp-breakpoint-sp) {
        margin-top: 12px;
      }

      & > p {
        color: #00478f;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.5;
        background-color: #fff;
        letter-spacing: 1px;
        font-family: sans-serif;
        display: inline-flex;
        padding: 2px 10px;
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;

        @include mq-sp($lp-breakpoint-sp) {
          font-size: 14px;
          padding: 2px 4px;
        }

        & + p {
          margin-top: 4px;
        }
      }
    }
  }
}

.copy-ue {
  width: 0;
  height: 56px;
  overflow: hidden;
  animation: t1 1s forwards;

  @include mq-sp($lp-breakpoint-sp) {
    height: 32px;
    left: 16px;
    bottom: 19px;
    animation: t1-sp 1s forwards;
  }

  & img {
    height: 56px;

    @include mq-sp($lp-breakpoint-sp) {
      height: 32px;
      width: auto;
    }
  }
}

.copy-shita {
  width: 0;
  height: 55px;
  margin-top: -2px; // NOTE 書き出し画像の調整
  overflow: hidden;
  animation: t2 1s 0.5s forwards;

  @include mq-sp($lp-breakpoint-sp) {
    height: 32px;
    left: 45px;
    bottom: -24px;
    animation: t2-sp 1s 0.5s forwards;
  }

  & img {
    height: 55px;

    @include mq-sp($lp-breakpoint-sp) {
      height: 32px;
      width: auto;
    }
  }
}

.copy-animation3 {
  width: 0;
  overflow: hidden;
  animation: t3 1.5s 0.5s forwards;

  @include mq-sp($lp-breakpoint-sp) {
    height: 54px;
    animation: t3-sp 1.5s 0.5s forwards;
  }
}

.story-animation1 {
  z-index: 1;
  animation: story-animation1 10s infinite;
}

.story-animation2 {
  z-index: 2;
  animation: story-animation2 10s infinite;
}

.story-animation3 {
  z-index: 3;
  animation: story-animation3 10s infinite;
}

.story-animation4 {
  z-index: 0;
  animation: story-animation3 10s;
}

.top-img {
  width: 100vw;
  object-fit: cover;

  @include mq-pc($lp-breakpoint-pc) {
    height: 532px;
    max-width: 1280px;
    min-width: 960px;
  }
}

.story-animation1 img {
  animation: jiwa 10s linear infinite;
}

.story-animation2 img {
  animation: jiwa 10s linear infinite;
}

.story-animation3 img {
  animation: jiwa 10s linear infinite;
}

.story-animation4 img {
  animation: jiwalast 10s linear infinite;
}

.top-kv-slide-show {
  opacity: 0;
  animation: showHeroimg 1s 1.5s forwards;
}

@keyframes showHeroimg {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes t1 {
  0% {
    width: 0;
  }

  100% {
    width: 335px;
  }
}

@keyframes t1-sp {
  0% {
    width: 0;
  }

  100% {
    width: 196px;
  }
}

@keyframes t2 {
  0% {
    width: 0;
  }

  100% {
    width: 363px;
  }
}

@keyframes t2-sp {
  0% {
    width: 0;
  }

  100% {
    width: 212px;
  }
}

@keyframes t3 {
  0% {
    width: 0;
  }

  100% {
    width: 410px;
  }
}

@keyframes t3-sp {
  0% {
    width: 0;
  }

  100% {
    width: 300px;
  }
}

@keyframes jiwa {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes jiwalast {
  0% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes story-animation1 {
  0% {
    width: 0;
  }

  6.66% {
    width: 100%;
  }

  100% {
    width: 100%;
  }
}

@keyframes story-animation2 {
  0% {
    width: 0;
  }

  33.33% {
    width: 0;
  }

  40% {
    width: 100%;
  }

  100% {
    width: 100%;
  }
}

@keyframes story-animation3 {
  0% {
    width: 0;
  }

  66.7% {
    width: 0;
  }

  73.36% {
    width: 100%;
  }

  100% {
    width: 100%;
  }
}



.top-section-inner {
  .btn-facebook {
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

.lp-key-visual {
  display: block;
  width: 100%;
  max-width: 960px;
  margin: 2px auto 0 auto;
}
