.l-pc-body {
  min-width: 1040px;

  @include mq-sp {
    width: 100%;
    min-width: 0;
    box-sizing: border-box;
  }
}

.l-terms-body {
  background-color: $color-gray-2;
}

.l-main__header + * {
  margin-top: 30px;
}

.l-header {
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 5px 1px $header-shadow;

  .l-header-inner {
    display: flex;
    margin: 0 auto;
    width: 1070px;
    box-sizing: border-box;
    align-items: center;
  }

  .l-header-logo {
    width: 200px;
    box-sizing: border-box;
  }

  .l-header-search {
    margin-left: auto;
    box-sizing: border-box;
  }
}

.l-container {
  position: relative;
  padding-top: 1px;
  min-height: 100%;
  box-sizing: border-box;
}

.l-no-footer {
  .l-container {
    @extend .l-container;
    padding-bottom: 0;
  }
}

.l-main {
  width: 100%;
  box-sizing: border-box;
  margin-top: $header-height--sp;

  .l-main-inner {
    margin: 0 auto;
    width: 960px;
    box-sizing: border-box;

    @include mq-sp {
      width: 100%;
      box-sizing: border-box;
    }
  }
}

.l-side-menu {
  float: left;
  width: 100%;
  margin-right: -900px;
  padding: 10px;
  padding-right: 900px;
  box-sizing: border-box;
}

.l-content {
  float: right;
  width: 900px;
  box-sizing: border-box;
  padding: 10px;
}

.footer--no-header {
  .l-footer {
    @include mq-sp {
      display: block;
      position: static;
    }
  }
}

.l-secondary-header {
  padding-top: 1px;
  margin-left: -15px;
  position: relative;
  height: 41px;
  width: calc(100% + 30px);

  @include mq-pc {
    margin-left: 0;
    width: 100%;
  }
}
