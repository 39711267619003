.l-withdrawal-apple {

  margin: 15px 0;
  box-sizing: border-box;
  text-align: center;

  .panel {
    margin: 15px 0;
    padding: 40px 15px;

    @include mq-sp {
      padding: 15px 15px;
    }
  }

  .btn-wrapper {
    margin-bottom: 0;
  }

  p {
    margin: 0 auto;
    text-align: left;
  }

  .l-withdrawal-apple-inner {
    display: inline-block;
    text-align: left;
  }

  .text--normal {
    font-size: $font-size-basic;
    line-height: 1.5;
    color: $color-black;

    &.top {
      margin-top: 0;
    }
    margin-top: 15px;
  }

  .text--small {
    margin-top: 16px;
    margin-bottom: 40px;
  }
}
