.event-detail-popup {
  .popup-btn-area {
    @include mq-sp {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      box-sizing: border-box;

      > * {
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
  }
}

.event-detail-popup-btn-cancel,
.event-detail-popup-btn-back {
  margin-right: 50px;

  @include mq-sp {
    margin-right: 0;

    + .event-detail-popup-btn-cancel,
    + .event-detail-popup-btn-back {
      margin-left: 10px;
    }
  }
}

.event-detail-popup-btn-back {
  appearance: none;
  background-color: transparent;
  border: 0;
  color: $fgcolor-supplemental;

  &:hover {
    color: $fgcolor-normal;
  }
}

.event-detail-popup-btn-cancel {
  appearance: none;
  background-color: transparent;
  border: 0;
}

.event-detail-popup-btn-apply,
.event-detail-popup-btn-cancel,
.event-detail-popup-btn-back {
  &.btn {
    width: 200px;
    @include mq-sp {
      width: auto;
    }
  }
}
