.panel {
  box-sizing: border-box;
  box-shadow: $boxshadow-panel;
  border: $bd-panel;
  background-color: $bgcolor-normal;
  padding: 20px 40px;

  @include mq-sp {
    padding: 30px 15px;
  }

  &__text {
    font-size: $font-size-basic;
    line-height: 1.8;
  }

  &__text--small {
    font-size: $font-size-smaller;
    color: $fgcolor-supplemental;
  }

  &__text--small-right {
    @extend .panel__text--small;
    display: block;
    text-align: right;
  }
}

.panel-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 10px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 10px);
  box-sizing: border-box;
  border-top: 1px solid $bdcolor-normal;
  background-color: $bgcolor-normal;
  z-index: 10;

  @include mq-pc {
    padding: 20px 0;
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    //iOS8
    @include mq-sp {
      display: table;
      table-layout: fixed;
      width: 100%;
    }
  }

  &__items {
    padding-right: 5px;
    padding-left: 5px;

    @include mq-sp {
      display: table-cell;
    }

    // FIXME: flexboxの調整
    @include mq-sp {
      flex: 1;
    }
  }

  &__text {
    text-align: center;
  }

  &__caution {
    margin-top: 5px;
    text-align: center;
    font-size: $font-size-small;
    line-height: 1.7;
    color: $fgcolor-lowpriority;

    @include mq-sp {
      font-size: $font-size-smaller;
    }
  }
}
