%modal-bottom-gradient {
  content: "";
  position: absolute;
  left: 0;
  top: -45px;
  display: block;
  width: 100%;
  height: 44px;
  background: $modal-shadow;
  cursor: initial;
}

.view-visit-start-permission-modal {
  box-sizing: border-box;

  @include mq-sp {
    padding-top: 24px;
  }

  &__middle {
    box-sizing: border-box;
    background: $bgcolor-normal;
    border-radius: 16px;
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    transform-origin: right top;
    transition: 0.24s ease-in-out;
    max-width: 440px;
    margin: 0 auto;

    &--show {
      transform: none;
      opacity: 1;
      transition-timing-function: ease-out;
    }
  }

  &__contents {
    &--group {
      padding: 0 24px 24px 24px;
    }

    &--content {
      border-bottom: 1px solid;
      border-color: $color-gray;
      height: 83px;
      margin-top: 17px;
      width: calc(100% - 10%);

      &-wrapper {
        height: 100%;
        width: 100%;
        border-bottom: 1px solid;
        border-color: $color-white;
        line-height: 28px;
      }

      &-subtext {
        font-size: $font-size-smaller;
        margin-top: 16px;
        line-height: 21px;
      }
    }
  }

  &--separate-button {
    cursor: pointer;

    &--long {
      position: relative;

      &::before {
        @extend %modal-bottom-gradient;
      }
    }
  }

  .modal__separate-button__link {
    &:hover {
      transition: none;
    }

    &--important:hover {
      color: lighten($color-uiblue, 30%);
    }

    &--cancel {
      display: block;
      height: 44px;
      line-height: 44px;
      text-align: center;
      font-weight: 700;
      transition: none;
      color: #9299a0;
      text-decoration: none;
      outline: none;

      &:hover {
        color: #bbbfc4;
      }
    }
  }

  &__background {
    background-color: $shadow-6;
  }
}
