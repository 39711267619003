.confirm-email-description {
  max-width: 480px;
  margin: 0 auto 48px;
  color: $color-black;

  @include mq-sp {
    margin-bottom: 20px;
  }
}

.confirm-email-form-table-title {
  font-weight: bold;
  @include mq-pc {
    padding-bottom: 20px;
  }
  @include mq-sp {
    color: $basecolor-normal;
    text-align: left;
    margin-bottom: 5px;
  }
}

.confirmation-code-resend-link {
  text-align: center;
  margin-top: 20px;

  &__disable-text {
    color: $color-darkgray;
  }
}
