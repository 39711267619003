.locked {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: $color-lightgray;

  &__content {
    max-width: 606px;
    box-sizing: border-box;
    margin: 16px;

    &__title {
      margin-bottom: 24px;
    }
  }
}

.locked-main {
  background: $color-white;
  padding: 24px;
  margin-bottom: 24px;

  &__ {
    &title {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      height: 40px;
      @include mq-sp {
        font-size: 15px;
      }
    }

    &text {
      font-size: 16px;
      line-height: 28px;
      text-align: left;
      margin-bottom: 24px;
      @include mq-sp {
        font-size: 14px;
        line-height: 24px;
      }

      &__strong {
        font-weight: 600;
        color: $color-pink;
      }
    }
  }
}

.locked-logo {
  width: 193px;
  height: 54px;
}

.locked-buttons {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
  @include mq-sp {
    flex-direction: column;
  }

  &__item {
    &--inquiry {
      @extend %btn-item;
      color: $color-uiblue;
      background: $color-white;
      margin-right: 8px;
      border: 1px solid $color-uiblue;

      &:hover {
        color: $color-white;
        background-color: $color-uiblue;
      }

      @include mq-sp {
        margin-right: 0;
      }
    }

    &--password-reset {
      @extend %btn-item;
      color: $color-white;
      background: $color-uiblue;
      margin-bottom: 0;
      border: 1px solid $color-uiblue;

      &:hover {
        color: $color-uiblue;
        background-color: $color-white;
      }

      @include mq-sp {
        margin-bottom: 8px;
      }
    }
  }
}

%btn-item {
  font-size: 16px;
  display: inline-block;
  height: 44px;
  width: 160px;
  box-sizing: border-box;
  line-height: 43px;
  border-radius: 22px;

  &:hover {
    text-decoration: none;
    transition-duration: 0.1s;
  }

  @include mq-sp {
    font-size: 14px;
    width: 152px;
  }
}

.locked-btn-back-to-top {
  @include mq-sp {
    font-size: 14px;
  }
}
