.l-company-article {
  .l-grid__container {
    width: 100%;
    padding-bottom: 150px;
  }

  .l-grid__container__inner {
    width: 960px;
    margin-left: auto;
    margin-right: auto;

    @include mq-sp {
      width: 100%;
    }
  }
}
