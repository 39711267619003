.register-preferred-wrapper {
  padding-bottom: 128px;
  @include mq-sp {
    padding-bottom: 0;
  }
}

.register-preferred {
  background-color: $bgcolor-normal;

  @include mq-sp {
    background-color: transparent;
  }

  &-header {
    padding: 40px 40px 0 40px;

    @include mq-sp {
      background-color: $bgcolor-sp-normal;
      margin: 0 auto;
      padding: 40px 16px 0 16px;
    }
  }

  .register-mark-form-wrapper {
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 40px;
    @include mq-sp {
      margin: 0 0 32px 0;
    }
  }

  &-title {
    text-align: center;
    font-size: 18px;
    line-height: 1.5;

    @include mq-sp {
      text-align: left;
    }
  }

  &-title-supplemental {
    text-align: center;
    font-size: 16px;
    line-height: 1.5;

    @include mq-sp {
      text-align: left;
    }
  }

  &-description {
    text-align: center;
    margin-top: 16px;
    font-size: 16px;
    line-height: 1.5;

    @include mq-sp {
      text-align: left;
    }
  }

  .privacy-mark-area {
    text-align: right;
    margin-bottom: 8px;
  }

  .privacy-mark-icon {
    width: 75px;
    height: 75px;

    @include mq-sp {
      width: 45px;
      height: 45px;
    }
  }

  &-form {
    padding-bottom: 40px;
    @include mq-sp {
      padding: 0 0 32px 0;
    }
  }

  &__submit {
    text-align: center;

    .btn-secondary {
      @include mq-sp {
        width: 280px;
      }
    }
  }

  &__skip {
    width: 100%;
    text-align: center;
    margin-top: 16px;
  }
}
