.profile-part-club-delete-btn {
  margin-top: 20px;
  text-align: right;

  .btn {
    width: 120px;
    height: 36px;
    line-height: 32px;
  }
}

.profile-part-club-add-btn {
  text-align: center;
}
