// sass-lint:disable no-important
.card-company,
.card-company--slider {
  font-size: 0;

  &__item {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    width: 270px;
    background-color: $bgcolor-normal;
    word-break: break-all;
  }

  &__item__inner {
    margin-bottom: 16px;
    @include mq-sp {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 270px;

    &:hover {
      @include mq-pc {
        background-color: $bgcolor-normal;
        opacity: 0.6;
      }
    }

    @include mq-sp {
      height: 100%;
    }
  }

  &__thumbnail {
    margin: 0;
    height: 142px;
  }

  &__thumbnail-image {
    @include image--responsive(142px, 270px);
  }

  &__caption {
    position: relative;
    top: -70px;
    left: 90px;
    background-color: $bgcolor-normal;
    @include round-bd-image(90px);
    vertical-align: middle;
    font-size: 0;
  }

  &__name {
    font-size: $font-size-basic;
    font-weight: bold;
    @include text-over-flow(100%);
  }

  &__industry {
    font-size: $font-size-small;
    color: $fgcolor-supplemental;
    line-height: 1;
    @extend .text-empty;
    @include mq-sp {
      font-size: $font-size-smaller;
    }
  }

  &__under {
    @include mq-sp {
      margin-left: 58px;
    }

    .card-company__person + .card-company__person {
      margin-left: 10px;
    }
  }

  &__person {
    display: inline-block;
    line-height: 1;

    &--obog {
      font-size: $font-size-small;
      font-weight: bold;
      color: $fgcolor-primary-theme;
      @include mq-sp {
        font-size: $font-size-smaller;
      }
    }

    &--obog-count {
      margin-left: 5px;
      font-size: $font-size-small;
      font-weight: bold;
      color: $fgcolor-primary-theme;
      @extend .text-empty;
      @include mq-sp {
        font-size: $font-size-smaller;
      }
    }

    &--follower {
      font-size: $font-size-small;
      color: $fgcolor-supplemental;
      @include mq-sp {
        font-size: $font-size-smaller;
      }
    }

    &--follower-count {
      font-size: $font-size-small;
      color: $fgcolor-supplemental;
      margin-left: 5px;
      @extend .text-empty;
      @include mq-sp {
        font-size: $font-size-smaller;
      }
    }
  }

  &__button {
    @extend .button--secondary;
    display: block;
    width: 100%;
    position: relative;
    z-index: 1;
    border-radius: 4px;
    font-weight: bold;
    line-height: 40px;
  }

  .button--secondary.is-done::before {
    display: none;
  }
}

.card-company--slider__person {
  @include mq-sp {
    display: block;
  }
}

.card-company {
  @include mq-sp {
    margin-left: -15px;
    margin-right: -15px;
  }

  &__item {
    @include mq-sp {
      width: 100%;
      padding: 12px;
      border-top: 1px solid $bdcolor-normal;
    }
  }

  &__item:last-of-type {
    @include mq-sp {
      border-bottom: 1px solid $bdcolor-normal;
    }
  }

  &__thumbnail {
    @include mq-sp {
      flex-basis: 50px;
      height: auto;
    }
  }

  &__thumbnail-image {
    @include mq-sp {
      display: none;
    }
  }

  &__caption {
    @include mq-sp {
      top: 0;
      left: 0;
      @include round-bd-image(50px);
    }
  }

  &__name {
    @include mq-sp {
      font-size: $font-size-small;
      overflow: hidden;
      white-space: normal;
      text-overflow: clip;
      line-height: 1.3;
      $height: $font-size-small * 1.3 * 2;
      width: calc(100% - 50px);
      min-width: 135px;
      max-height: $height;
    }
  }

  &__info {
    @include mq-sp {
      flex-basis: calc(100% - 90px);
    }
  }

  &__button {
    @include mq-sp {
      position: absolute;
      top: 15px;
      right: 12px;
      z-index: 2;
      padding-left: 0;
      padding-right: 0;
      width: 70px;
      height: 25px;
      line-height: 20px;
      font-size: $font-size-smaller;
    }
  }
}

.card-company--slider {
  overflow-x: scroll;
  overflow-y: hidden;
  // sass-lint:disable no-misspelled-properties no-vendor-prefixes
  -webkit-overflow-scrolling: touch;
  height: 328px;
  width: 100%;
  @include mq-sp {
    height: 225px;
  }

  &__list {
    display: flex;
    height: 100%;
    @include mq-sp {
      padding-bottom: 16px;
    }
  }

  &__item {
    flex: 0 0 auto;
    @include mq-sp {
      width: 156px;
    }
  }

  &__thumbnail {
    @include mq-sp {
      margin: 0;
      height: 79px;
    }
  }

  &__caption {
    @include mq-sp {
      top: -40px;
      left: 50px;
      @include round-bd-image(50px);
      vertical-align: middle;
      font-size: 0;
    }
  }

  &__info {
    @include mq-sp {
      width: 100%;
    }
  }

  &__name {
    @include mq-sp {
      font-size: $font-size-small;
    }
  }

  &__button {
    @include mq-sp {
      height: 24px;
      line-height: 18px;
      font-size: $font-size-smaller;
    }
  }
}

.company-follow {
  .card-company__item:first-of-type {
    @include mq-sp {
      border-top: none;
    }
  }
}

/* ここから先はOB画面検索結果のcompany-card */
.card-company--result {
  font-size: 0;

  &__item {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    border-radius: 2px;
    width: 310px;
    padding: 20px 15px;
    box-shadow: 0 3px 3px 0 $event-card-simple-shadow;
    border: 1px solid $event-card-simple-shadow;
    background-color: $bgcolor-normal;
    transition: box-shadow 0.3s linear;
    word-break: break-all;

    &:hover {
      box-shadow: 0 10px 10px 0 $shadow-21;
      transition: box-shadow 0.3s linear;
    }

    @include mq-sp {
      width: 100%;
      padding: 12px;
    }

    & + & {
      @include mq-sp {
        margin-top: 15px;
      }
    }
  }

  &__item:not(:nth-child(3n-2)) {
    margin-left: 15px;
    @include mq-sp {
      margin-left: 0;
    }
  }

  &__item:nth-of-type(-n + 3) {
    @include mq-pc {
      margin-top: 0;
    }
  }

  &__header {
    position: relative;
  }

  &__image {
    @include round-bd-image(50px);
    vertical-align: middle;
    @include mq-sp {
      vertical-align: top;
    }
  }

  &__heading {
    display: inline-block;
    margin-left: 10px;
    font-size: $font-size-basic;
    font-weight: 700;
    @include text-over-flow-multiline($font-size-basic, 1.4, 2, false);
    vertical-align: middle;
    width: calc(100% - 60px);
    @include mq-sp {
      vertical-align: top;
      width: calc(100% - 160px);
    }
  }

  &__header__item:first-of-type {
    margin-left: 60px;
  }

  &__header__item {
    display: inline-block;
    margin-top: 5px;
    margin-right: 10px;
  }

  &__label {
    margin-right: 5px;
    font-size: $font-size-smaller;
    font-weight: bold;
    color: $fgcolor-primary-theme;
    vertical-align: middle;
    @include mq-sp {
      display: none;
    }
  }

  &__icons--ob,
  &__icons--event,
  &__icons--industry {
    margin-right: 5px;
    font-size: $font-size-basic;
    color: $fgcolor-primary-theme;
    vertical-align: middle;
  }

  &__icons--event {
    @include mq-sp {
      display: none;
    }
  }

  &__icons--industry {
    display: none;
    @include mq-sp {
      display: inline-block;
      font-size: 0;
    }

    &::before {
      font-size: $font-size-small;
    }
  }

  &__count--ob,
  &__count--event {
    font-size: $font-size-small;
    line-height: 1;
    vertical-align: middle;
    @extend .text-empty;
  }

  &__count--event {
    @include mq-sp {
      display: none;
    }
  }

  &__industry {
    display: none;
    vertical-align: middle;
    @include mq-sp {
      margin-right: 10px;
      font-size: $font-size-small;
      display: inline-block;
      width: 50%;
      @include text-over-flow(50%);
    }
  }

  &__body {
    margin-top: 10px;
    border-top: 1px solid $bdcolor-normal;
    padding-top: 10px;

    i {
      color: $fgcolor-primary-theme;
      margin-right: 10px;
    }

    @include mq-sp {
      display: none;
    }
  }

  &__description {
    margin-top: 5px;
    font-size: $font-size-smaller;
    color: $fgcolor-supplemental;
    @include text-over-flow-multiline($font-size-smaller, 1.4);
    @extend .text-empty;
  }

  &__body__button {
    margin-top: 10px;
    @extend .button--secondary;
    display: block;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    @include mq-sp {
      position: absolute;
      top: 0;
      right: 12px;
      width: 90px;
      height: 35px;
      line-height: 30px;
      font-size: $font-size-smaller;
    }
  }

  &__body__button.is-done::before {
    display: none !important;
  }
}
