.text--medium {
  font-size: $font-size-small;
  line-height: 1.7;
  color: $fgcolor-normal;
}

.text--small {
  font-size: $font-size-smaller;
  line-height: 1.7;
  color: $fgcolor-supplemental;

  &-variable-ellipsis {
    @extend .text--small;
    @include variable-ellipsis();
  }

  &-emphasis {
    color: $fgcolor-normal;
  }
}

.text--small-variable-ellipsis__main + .text--small-variable-ellipsis__sub {
  padding-left: 10px;
}

.text--count {
  text-align: right;
  color: $basecolor-appendix;
}

.textbox + .text--count {
  margin-top: 5px;
}

.text-smaller {
  font-size: $font-size-smaller;
  color: $color-darkgray;
  line-break: 1.6;
}

.l-secondary-header + .text-smaller {
  margin-top: 20px;
}

.text-empty:empty::after {
  content: "\30FC";
  color: $fgcolor-empty;
}

.br-pc {
  @include mq-sp {
    display: none;
  }
}

.br-sp {
  @include mq-pc {
    display: none;
  }
}
