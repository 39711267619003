.certification-complete {
  @include mq-sp {
    margin-top: 40px;
    padding: 3px 6px;
  }

  &__description {
    width: 929px;
    padding: 16px;
    background-color: $color-lightgray;
    color: $color-black;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;

    @include mq-sp {
      width: auto;
      padding: 24px;
    }
  }

  &__btn-next-wrapper {
    margin-top: 40px;
    text-align: center;
    @include mq-sp {
      margin-top: 16px;
    }
  }

  &__btn-next {
    @extend .button--secondary;
    border: 2px solid $color-uiblue;
    width: 94px;
  }
}
