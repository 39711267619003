// notify.js style override

.notifyjs-warning-base {
  width: 280px;

  .notifyjs-bootstrap-base {
    white-space: normal;
    background-position: 4px 10px;
  }

  .title {
    margin-bottom: 8px;
    font-weight: normal;
  }

  .buttons {
    text-align: center;
  }

  button {
    height: 42px;
    line-height: 42px;
    font-size: 16px;
    border-radius: 22px;
    width: 120px;
    background-color: transparent;
    border-color: $color-brown;
    color: $color-brown;
    box-sizing: content-box;
    font-weight: normal;
    padding: 0;

    &:focus {
      outline: 0;
    }
  }
}
