.profile-edit-form-area {
  .form-control {
    .profile-edit-error {
      margin-top: 5px;
      font-size: 12px;
      display: block;

      &:empty {
        display: none;
      }

      &__basic {
        font-size: 16px;
        margin-top: 0;
      }
    }
  }
}
