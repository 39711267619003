.app-banner-sp {
  display: none;
}

html[data-os^="iOS"] {
  .app-banner-sp {
    display: flex;
    position: relative;
    align-items: center;
    box-sizing: border-box;
    z-index: 99; // ヘッダが100で、それより下にしたい
    width: 100%;
    background-color: $bdcolor-tag-accent;
    color: $fgcolor-darkbg;
    padding: 10px 15px;

    &__logo {
      margin-right: 10px;
      width: 26px;
      height: 25px;
      vertical-align: middle;
    }

    &__explain {
      flex: 2;
    }

    &__button {
      a {
        display: inline-block;
        width: 60px;
        padding: 5px;
        border: 1px solid $bdcolor-reverse;
        border-radius: 2px;
        font-size: $font-size-XS;
        color: inherit;
        line-height: 1;
      }
    }

    &__title {
      font-size: $font-size-smaller;
    }

    &__text {
      font-size: $font-size-XS;
    }

    &__close {
      padding-left: 15px;

      i {
        font-size: $font-size-small;
      }
    }
  }

  .l-body-top-boss,
  .l-body-top,
  .l-login-body {
    .app-banner-sp {
      margin-top: 0;
    }
  }

  .top {
    .app-banner-sp {
      display: none;
    }
  }
}

html[data-browser="Safari iOS"] .app-banner-sp {
  display: none;
}
