.company-detail {
  $bgcolor-company-detail-cover-filter: #000000;

  &-header {
    &__cover {
      position: relative;
      width: 100%;
      overflow: hidden;
      background-color: $bgcolor-company-detail-cover-filter;

      &--main {
        position: relative;
        width: 960px;
        height: 320px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin: 0 auto;

        @include mq-sp {
          width: auto;
          height: auto;
          padding-top: percentage(320/960);
        }

        // sass-lint:disable nesting-depth
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 90px;
          background-image: $company-detail-gradient;

          @include mq-sp {
            display: none;
          }
        }
      }

      &--background {
        position: absolute;
        // blurで縁がぼやけないように範囲を広げています
        top: -20px;
        left: -20px;
        right: -20px;
        height: 320px;
        padding: 20px;

        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.6;
        filter: blur(10px);
      }
    }

    &__content {
      @include mq-pc {
        width: 960px;
        margin: 0 auto;
      }
    }

    &__basic {
      margin-top: -65px;
      position: relative;

      @include mq-sp {
        margin: 0;
        padding: 10px 10px 0;
      }

      & + * {
        margin-top: 20px;

        @include mq-sp {
          margin-top: 15px;
        }
      }
    }

    &__logo {
      display: inline-block;
      margin: 0;

      //ロゴの枠線分引いてます
      width: 126px;
      height: 126px;
      background-size: 100% auto;
      background-position: center;
      background-repeat: no-repeat;
      background-color: $bgcolor-normal;
      overflow: hidden;
      text-align: center;
      font-size: 0;
      border: 1px solid $bdcolor-normal;
      box-sizing: border-box;
      vertical-align: bottom;

      @include mq-sp {
        width: 80px;
        height: 80px;
      }
    }

    &__logo-wrapper {
      display: inline-block;

      @include mq-pc {
        background-color: $bgcolor-normal;
        padding: 4px;
        box-shadow: 0 1px 5px 0 $shadow-22;
      }
    }

    &__body {
      display: inline-block;
      vertical-align: top;
      padding-top: 10px;
      padding-left: 10px;
      width: calc(100% - 150px);

      @include mq-sp {
        padding-top: 0;
        padding-left: 10px;
        width: calc(100% - 100px);
      }
    }

    &__name {
      @include mq-pc {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: $font-size-larger;
        color: $fgcolor-darkbg;
        text-shadow: 0 1px 4px $shadow-23;
        line-height: 48px;
      }
      @include mq-sp {
        font-size: $font-size-large;
        line-height: 28px;
        min-height: 40px;
      }
    }

    &__button {
      @extend .btn-primary;
      width: 150px;
      height: 42px;
      line-height: 38px;
      margin-top: 20px;
      font-weight: bold;
      background: $color-white;
      color: $color-uiblue;
      border: 1px solid $color-uiblue;
      padding: 0;
      box-sizing: border-box;
      font-size: $font-size-basic;
      transition: 0.1s linear;
      @include mq-sp {
        width: 90px;
        height: 30px;
        font-size: $font-size-smaller;
        line-height: 26px;
        margin-top: 10px;
      }

      &:hover {
        transition: 0.1s linear;
        background: $color-white;
        color: #0C7AE9FF; // hoverColors.primary
        border-color: #0C7AE9FF; // hoverColors.primary
      }

      &.is-active {
        background: $color-uiblue;
        color: $color-white;
      }
    }
  }

  &-summary {
    & > section {
      & > h2 {
        font-size: $font-size-larger;
        color: $fgcolor-supplemental;

        @include mq-sp {
          font-size: $font-size-large;
        }

        & + * {
          margin-top: 10px;
          margin-bottom: 30px;

          @include mq-sp {
            margin-top: 10px;
            margin-bottom: 20px;
          }
        }
      }

      & + section {
        margin-top: 40px;

        @include mq-sp {
          margin-top: 30px;
        }
      }
    }

    &__ob {
      .ob-card-list {
        @include mq-pc {
          margin-bottom: 15px;
        }
      }
    }

    &__event {
      .event-tag {
        font-size: $font-size-small;

        @include mq-sp {
          padding: 2px 5px;
          font-size: $font-size-XS;
        }
      }
    }
  }

  &-ob {
    .ob-card-list {
      margin-top: 0;
    }
  }

  &-event {
    @extend .event-inner;

    .event-list {
      float: none;
      margin: 0 auto;
    }
  }

  &-info {
    background-color: $bgcolor-normal;
    box-shadow: $boxshadow-soft;
    padding: 15px;
  }
}

.company-event-card {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 470px;
  padding: 10px;
  background-color: $bgcolor-normal;
  box-shadow: $boxshadow-soft;
  @include mq-sp {
    width: 100%;
    height: auto;
  }

  &:nth-of-type(2n) {
    @include mq-pc {
      margin-left: 15px;
    }
  }

  &__image {
    @include image--responsive(190px, 450px);
    @include mq-sp {
      margin-top: -10px;
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  &__body {
    padding: 15px 5px 0 5px;
    @include mq-sp {
      padding: 10px 5px;
    }
  }

  &__title {
    margin-top: 10px;
    @include text-over-flow-multiline($font-size-LL, 1.5);
    font-size: $font-size-LL;
    font-weight: bold;
    line-height: 1.5;

    @include mq-sp {
      margin-top: 5px;
      font-size: $font-size-small;
      line-height: 1.6;
      height: 40px;
      overflow: hidden;
    }
  }

  &__under {
    position: relative;
    margin-top: 5px;
    border-top: 1px solid $bdcolor-normal;

    @include mq-sp {
      margin-top: 0;
      padding-top: 0;
      border: none;
    }
  }

  &__time {
    font-size: $font-size-small;

    @include mq-sp {
      display: none;
    }
  }

  &__address {
    margin-top: 3px;
    font-size: $font-size-smaller;
    color: $fgcolor-supplemental;
    font-style: normal;
    @include text-over-flow(100%);

    @include mq-sp {
      display: none;
    }
  }

  &__count {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: $font-size-small;
    color: $fgcolor-supplemental;

    @include mq-sp {
      display: none;
    }

    > span {
      margin-left: 5px;
      margin-right: 5px;
      font-size: $font-size-larger;
      color: $fgcolor-normal;
    }
  }

  &__company-name {
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    @include text-over-flow(70%);
    vertical-align: middle;

    @include mq-sp {
      margin-left: 5px;
      font-size: $font-size-small;
    }
  }

  &__logo {
    margin-top: 10px;
    @include round-bd-image(35px);
    vertical-align: middle;
  }
}

.company-event-card + .row-right {
  @include mq-sp {
    margin-top: 15px;
  }
}

.company-detail-no-contract {
  position: relative;
  height: 435px;
  @include mq-sp {
    height: auto;
  }

  p {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 215px;
    text-align: center;

    &::before {
      content: "";
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
      width: 100px;
      height: 117px;
      background: url("/assets/img/bg-company-empty.png") center center no-repeat;
      background-size: contain;
      @include mq-sp {
        width: 73px;
        height: 84px;
      }
    }

    @include mq-sp {
      position: static;
      margin-top: 60px;
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }

    br {
      @include mq-sp {
        display: none;
      }
    }
  }
}

.company-detail--no-follow {
  text-align: center;

  &__title {
    margin-top: 70px;
    margin-bottom: 16px;
    font-size: $font-size-basic;
  }

  &__text {
    margin-bottom: 16px;
    font-size: $font-size-smaller;
    color: $fgcolor-supplemental;
  }

  &__button {
    margin-bottom: 16px;
    width: 230px;
    @extend .btn-secondary;
  }
}

.company-ob-group-tab {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
  // sass-lint:disable no-misspelled-properties
  gap: 10px;

  &__item {
    display: flex;

    &__link {
      padding: 9px 13px;
      font-size: 15px;
      color: $fgcolor-primary-theme;
      font-weight: bold;
      line-height: 1;

      &:hover {
        text-decoration: none;
      }
    }

    &__link.is-active {
      position: relative;
      background-color: $bgcolor-theme;
      color: $fgcolor-darkbg;
      border-radius: 4px;
    }
  }
}
