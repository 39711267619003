// sass-lint:disable no-important
@import "assessment_variable";
@import "assessment_popup";
@import "assessment_variable";

.assessments {
  overflow-x: hidden;
  max-width: 100%;
  background-color: $assessment-color-light;
  font-family: "Yu Gothic", YuGothic, "メイリオ", Meiryo, sans-serif;

  &--page {
    width: 446px;
    padding: 0 32px 32px 32px;
    @include mq-sp {
      width: 100%;
      padding: 28px 16px 16px 16px;
    }
    margin: 0 auto;
    margin-top: -30px;
    margin-bottom: -60px;
    background-color: $assessment-color-white;
    min-height: 100vh;
    box-sizing: border-box;

    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }
  }

  &--button-area {
    position: fixed;
    bottom: 0;
    width: 960px;
    margin: 0 auto;
    padding-bottom: 16px;
    @include mq-sp {
      width: 100%;
    }
  }

  &--result-graph {
    &--multiple {
      height: 440px !important;

      .slick-dots {
        top: -150px !important;
      }
    }

    &--page-title-area {
      padding-top: 24px;
      font-size: 18px;
      line-height: 18px;
      font-weight: bold;
      color: $fgcolor-darkbg;
    }

    &--page-deviation-title {
      margin-left: 8px;
      font-weight: normal;
    }

    &--page-deviation-value {
      margin-left: 4px;
      font-size: 32px;
      line-height: 32px;
      font-weight: normal;
    }

    &--personality-chart-area {
      width: 320px;
      height: 270px;
      margin: 24px auto 0;

      canvas {
        top: -55px;
      }
    }
  }

  &--top--page {
    width: 446px;
    padding: 0 32px 128px 32px;
    @include mq-sp {
      width: 100%;
      padding: 32px 0 128px 0;
    }
    margin: 0 auto;
    margin-top: -30px;
    margin-bottom: -60px;
    background-color: $assessment-color-white;
    min-height: 100vh;
    box-sizing: border-box;

    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }

    &--graph {
      height: 454px;
      margin: 0 -32px 40px;

      &.multi-graph {
        height: 492px;
      }

      &.slick-dotted {
        margin: 0 -32px 40px; // 苦しいが slick で複数枚表示したときにレイアウトがずれないように `.slick-dotted.slick-slider` のマージンを打ち消している
      }

      &.slick-dots-top {
        .slick-dots {
          position: relative;
          top: -274px;
        }

        .slick-dots li {
          margin: 0 11px;

          & button:before {
            color: $fgcolor-darkbg;
            font-size: 17px;
            content: " ";
            background-color: $bgcolor-normal;
            height: 12px;
            width: 12px;
            border-radius: 6px;
            box-sizing: border-box;
            opacity: 0.2;
          }

          &.slick-active {
            & button:before {
              opacity: 1;
            }
          }
        }
      }
    }

    &-title-area {
      padding-top: 32px;
      padding-bottom: 4px;
      font-size: 18px;
      line-height: 18px;
      font-weight: bold;
      color: $fgcolor-darkbg;
    }

    &--carousel--page-title {
      padding-top: 40px;
      color: $fgcolor-darkbg;
      font-size: 18px;
      font-weight: bold;
    }
  }

  &--question--page {
    width: 446px;
    padding: 16px 32px 100px 32px;
    @include mq-sp {
      width: 100%;
      padding: 16px;
    }
    margin: 0 auto;
    background-color: $assessment-color-white;
    min-height: 100vh;
    box-sizing: border-box;

    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }
  }

  &--result-button-area {
    text-align: center;
    padding-top: 70px;
    position: relative;
    top: -267px;
  }

  &--result-button {
    display: inline-block;
    min-width: 98px;
    font-size: 17px;
    font-weight: bold;
    line-height: 18px;
    padding: 12px 20px;
    border-radius: 28px;
    text-decoration: none;
    color: $assessment-color-blue;
    background-color: $assessment-color-white;
    border: 1px solid transparent;
    margin: $assessment-space-24 auto;
    transition: none;

    &:hover {
      color: $assessment-color-blue;
      text-decoration: none;
    }
  }

  &--result--page {
    width: 446px;
    padding: 0 0 32px 0;
    margin: -30px auto -60px;
    background-color: $assessment-color-white;
    min-height: 100vh;
    box-sizing: border-box;

    @include mq-sp {
      width: 100%;
      padding: 0 0 16px 0;
      margin-top: 0;
    }

    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }
  }

  & .l-grid__container {
    padding-left: unset;
    padding-right: unset;
  }

  .result-ranking {
    margin-top: 24px;
  }

  .paragraph {
    &__top-title {
      padding: 24px 0 16px 0;
      font-size: 27px;
      line-height: 32px;
    }

    &__list {
      list-style-type: disc;
      padding-left: 20px;
      margin-top: 8px;
    }

    &__list-item {
      font-size: $assessment-font-small;
      color: $assessment-color-dark;
      font-weight: 500;
      line-height: 21px;
      padding-top: 8px;
    }

    &__description {
      font-size: $assessment-font-small;
      font-family: $asessment-font-style-yugothic;
      line-height: 24px;
      color: $assessment-color-dark;
      padding: 0 0 32px 0;
    }

    &__hiragino__title {
      font-family: $asessment-font-style-yugothic;
      font-size: $assessment-font-large;
      font-weight: 200;
      color: $assessment-color-dark;
    }

    &__yugothic__description {
      font-size: $assessment-font-small;
      color: $assessment-color-dark;
      font-weight: 500;
      line-height: 21px;
      padding: 16px 0 0 0;
    }

    &__title_normal_weight {
      font-size: $assessment-font-large;
      color: $assessment-color-dark;
      font-weight: 500;
      line-height: 32px;
      margin-top: 32px;
      padding: 0 0 8px 0;
    }

    &__result_description {
      font-size: $assessment-font-regular;
      color: $assessment-color-dark;
      line-height: 28px;
      font-weight: 500;
      white-space: pre-wrap;
    }

    &__padding__top__30 {
      padding-top: 30px;
    }

    &__padding__top__bottom {
      padding: 16px 0 32px 0;
    }

    &__padding__top__16 {
      padding-top: 16px;
    }

    &__padding__top__48 {
      padding: 48px 0 0 0;
    }

    &__padding__top__8 {
      padding: 8px 0 0 0;
    }
  }

  .assessments-top-ranking {
    margin-bottom: 30px;
  }

  .cover {
    margin: 0 -32px;
    margin-bottom: -6px;

    &--ability {
      margin: 0 -32px;
      margin-bottom: -6px;

      @include mq-sp {
        display: block;
        margin: auto;
        left: 0;
      }

      .cover__img {
        width: 446px;
        @include mq-sp {
          width: 100%;
        }
      }
    }

    &--personality {
      margin: 0 -32px;
      margin-bottom: -6px;
      height: 253px;
      background: url("/assets/img/assessment/personality-page-cover.png") center;
      background-size: cover;
    }
  }

  .cover__second {
    position: relative;
    text-align: center;
    width: 100%;
    padding: 24px 0 24px 0;
    @include mq-sp {
      display: block;
      margin-left: -16px;
      left: 0;
    }

    .cover__second__image {
      width: 350px;
      @include mq-sp {
        width: 80%;
      }
    }
  }

  .button-wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }

  .padding-16 {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
  }

  .padding-left-right-mobile {
    @include mq-sp {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .padding-top-24 {
    padding-top: 24px;
    @include mq-sp {
      padding-top: 16px;
    }
  }

  .border-top-E3E5E7 {
    top: 16px;
    border-top: 1px solid $color-gray;
    position: relative;
    width: 100%;
  }

  .blurb {
    display: block;
    text-decoration: none;
    height: 302px;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 4px 20px 0 $assessment-shadow-box-assessment;
    background-color: $assessment-color-white;
    margin-bottom: 32px;
    position: relative;

    &--personality {
      background-image: url("/assets/img/assessment/personality-cover.png");
      background-size: cover;
    }

    &--skill {
      background-image: url("/assets/img/assessment/skill-cover.png");
      background-size: cover;
    }

    &:hover {
      text-decoration: none;
    }

    &__title {
      font-size: $assessment-font-large;
      font-weight: bold;
      color: $assessment-color-dark;
    }

    &__description {
      font-size: $assessment-font-small;
      font-family: $asessment-font-style-yugothic;
      line-height: 1.71;
      //      line-height: 24px; 重複したスタイルなので上書かれていたほうをコメントアウトしておく
      color: $assessment-color-dark;
    }

    &__disable {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: $shadow-12;
      border-radius: 10px;
      color: $fgcolor-darkbg;
      font-size: 24px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .button {
    display: block;
    width: 188px;
    font-size: 17px;
    padding: 12px 16px;
    border-radius: 28px;
    text-decoration: none;
    color: $assessment-color-blue;
    background-color: $assessment-color-white;
    box-shadow: $assessment-box-shadow-button;
    border: 1px solid transparent;
    margin: $assessment-space-24 auto;

    &:hover {
      text-decoration: none;
    }

    .button__text {
      text-align: center;

      &.button__text--twoline {
        font-size: $assessment-font-regular + 2;
        line-height: 16px;
      }

      &.button__text--disabled {
        color: $color-black-17;
      }
    }

    &.button--disabled {
      color: $color-bluegray;
      background-color: $color-darkgray;
      cursor: default;
    }

    .button__subtext {
      font-size: $assessment-font-micro - 2;
      line-height: $assessment-font-micro - 2;
      margin-top: 4px;
    }

    &.button--alert {
      color: $assessment-color-white;
      background-color: $assessment-color-alert;
      box-shadow: 0 7px 20px 0 $shadow-13;
      box-sizing: border-box;
    }

    &.button--normal {
      color: $assessment-color-blue;
      background-color: $assessment-color-white;
      box-shadow: $assessment-box-shadow-button;
    }

    &.button--blue--border {
      color: $assessment-color-blue;
      background-color: $assessment-color-white;
      border: 1px solid $assessment-color-blue;
      box-shadow: none;
      width: 206px;
    }
  }

  .padding-top-64 {
    padding-top: 64px;
    @include mq-sp {
      padding-top: 32px;
    }
  }

  .txt-blue {
    color: $assessment-color-blue;
  }

  .question-intro-section {
    font-size: $assessment-font-regular;
    line-height: 24px;
    padding: 24px 0 8px 0;
    font-weight: bold;
  }

  .question {
    width: 100%;
    border-radius: 10px;
    box-shadow: $assessment-box-shadow-button;
    background-color: $assessment-color-white;
    margin-top: $assessment-space-m;
    display: flex;
    flex-wrap: wrap;

    &__next-button-area {
      text-align: center;
      padding-top: 64px;
      @include mq-sp {
        padding-top: 16px;
      }
    }

    &__next-button {
      display: inline-block;
      min-width: 98px;
      font-size: 17px;
      font-weight: bold;
      line-height: 18px;
      padding: 12px 20px;
      border-radius: 28px;
      text-decoration: none;
      color: $assessment-color-blue;
      background-color: $assessment-color-white;
      box-shadow: $assessment-box-shadow-button;
      border: 1px solid transparent;
      margin: $assessment-space-24 auto;

      &.is-sending {
        color: $bdcolor-button;
        background-color: $bgcolor-button-hover;
        pointer-events: none;

        &::before {
          content: "\f110";
          width: auto;
          height: auto;
          background-image: none;
          margin-right: 4px;
          @extend .fa;
          @extend .fa-pulse;
          @extend .fa-fw;
        }
      }
    }

    .wrapper {
      width: 100%;
    }

    .question__text {
      padding: 16px;
      font-size: $assessment-font-regular;
      color: $assessment-color-dark;
      box-shadow: inset 0 -1px 0 0 $color-gray;
      font-weight: bold;
      width: 100%;
      white-space: pre-line;
    }

    .question__options {
      font-weight: bold;
      color: $assessment-color-dark;

      // 共通スタイルのリセット用
      label {
        padding: 0;
      }

      input[type="radio"] {
        margin: 0;
      }

      .question__option {
        display: block;
        position: relative;
        cursor: pointer;
        // sass-lint:disable no-vendor-prefixes
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:hover input ~ .checkmark {
          background-color: darken($assessment-border-circle-radio, 2%);
        }
      }

      &__horizontal {
        display: flex;
        padding: 16px;
        font-size: $assessment-font-regular;
        width: 100%;

        .question__option {
          width: 80px;
          height: 110px;
          margin: auto;
          font-size: 10px;
          text-align: center;

          .checkmark__medium {
            top: 0;
            left: 50%;
            margin-top: 8px;
          }

          .checkmark__large {
            top: 0;
            left: 50%;
          }

          &__area {
            margin-top: 60px;
          }

          &__text {
            font-size: 10px;
            line-height: 12px;
            color: $assessment-color-dark;
            font-weight: bold;
            white-space: inherit;
          }
        }

        // よくわからない MediaQuery なのでコメントアウトしておく
        //@media only screen and (max-width: 645px) {
        //  padding: 8px 11px;
        //}
      }

      &__vertical {
        padding: 24px 16px;

        .question__option {
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }

          &__area {
            margin-left: 36px;
          }

          &__text {
            font-size: $assessment-font-regular;
            font-weight: normal;
            line-height: 24px;
          }
        }
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      // チェックのデザインに関するもののみはここ
      .checkmark {
        position: absolute;
        background-color: $color-gray;
        border-radius: 50%;

        &:before {
          content: "";
          height: 1px;
          width: 1px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 0 solid $assessment-color-choice;
          border-radius: 50%;
          background: $color-gray;
          transition-timing-function: ease-in-out;
          transition-duration: 200ms;
          opacity: 0;
          z-index: 20;
        }

        &:after {
          content: "";
          position: absolute;
          display: block;
          top: 50%;
          left: 50%;
          border-radius: 50%;
          background: $assessment-color-white;
          z-index: 20;
        }

        &__small {
          $radio-circle-size: 28px;
          width: $radio-circle-size;
          height: $radio-circle-size;

          &:after {
            $radio-hole-size: 10px;
            margin-left: -($radio-hole-size / 2);
            margin-top: -($radio-hole-size / 2);
            width: $radio-hole-size;
            height: $radio-hole-size;
          }
        }

        &__medium {
          $radio-circle-size: 36px;
          margin-left: -($radio-circle-size / 2);
          height: $radio-circle-size;
          width: $radio-circle-size;

          &:after {
            $radio-hole-size: 14px;
            margin-left: -($radio-hole-size / 2);
            margin-top: -($radio-hole-size / 2);
            width: $radio-hole-size;
            height: $radio-hole-size;
          }
        }

        &__large {
          $radio-circle-size: 52px;
          margin-left: -($radio-circle-size / 2);
          height: $radio-circle-size;
          width: $radio-circle-size;

          &:after {
            $radio-hole-size: 19px;
            margin-left: -($radio-hole-size / 2);
            margin-top: -($radio-hole-size / 2);
            width: $radio-hole-size;
            height: $radio-hole-size;
          }
        }
      }

      input:checked ~ .checkmark {
        border-color: transparent;

        &:hover {
          background-color: $color-gray;
        }

        &:before {
          transition-timing-function: ease-in-out;
          transition-duration: 200ms;
          opacity: 1;
        }

        &:after {
          background-color: $assessment-color-white;
        }

        &__small {
          $radio-circle-size: 28px;

          &:before {
            border: ($radio-circle-size - 1px) / 2 solid $assessment-color-choice;
          }
        }

        &__medium {
          $radio-circle-size: 36px;

          &:before {
            border: ($radio-circle-size - 1px) / 2 solid $assessment-color-choice;
          }
        }

        &__large {
          $radio-circle-size: 52px;

          &:before {
            border: ($radio-circle-size - 1px) / 2 solid $assessment-color-choice;
          }
        }
      }
    }

    // Header Question multiple text
    .question__multiple__text {
      max-width: 50%;
      font-size: $assessment-font-small;

      &:nth-child(2) {
        position: relative;

        &::before {
          width: 1px;
          height: calc(100% - 32px);
          content: "";
          background-color: $color-gray;
          position: absolute;
          left: 0;
        }
      }
    }
  }

  .cp-progress {
    width: 100%;
    background-color: $assessment-color-light;
    padding: 15px 16px;

    @include mq-sp {
      width: 100vw;
      margin: -16px -16px 0;
    }

    .cp-progress__text {
      font-size: $assessment-font-small;
      font-weight: bold;
      color: $assessment-color-dark;
    }

    .cp-progress__text_description {
      font-size: $assessment-font-13;
      font-weight: bold;
      color: $assessment-color-dark;
      padding-left: 8px;
    }

    .cp-progress__bar {
      margin-top: 6px;
      background-color: $assessment-status-process-bar-background;
      border-radius: 7px;

      .progress-bar {
        transition: width 2s;
        background-color: $assessment-status-process-bar;
        height: 14px;
        max-width: 100%;
        border-radius: 7px;
      }
    }
  }

  .questionnaire {
    text-align: left;
    margin-top: 0;
    z-index: 1000;

    .paragraph__title {
      font-size: $assessment-font-medium;
      color: $assessment-color-dark;
      font-weight: 400;
      padding: 0;
    }

    .paragraph__title--bold {
      font-size: $assessment-font-medium;
      font-weight: bold;
      @media only screen and (max-width: 645px) {
        font-size: $assessment-font-regular;
      }
    }

    .box-rating {
      background-color: $color-blue-3;
      border-radius: 5px;
      margin: 15px 0 0 0;
      padding: 10px;
      color: $fgcolor-darkbg;
      @media only screen and (max-width: 645px) {
        padding: 0;
        margin: 16px 0 0 0;
      }

      .title {
        font-size: $assessment-font-regular;
        margin-bottom: 0;
        padding: 6px 16px 0 16px;
        color: $fgcolor-darkbg;
        font-weight: bold;
      }

      .bottom__content {
        clear: both;
        padding: 0 8px 20px 22px;
        text-align: center;
        font-size: 10px;
        margin-bottom: 0;
        color: $fgcolor-darkbg;

        .text__left {
          float: left;

          &.q1 {
            margin-left: 2px;
            @media only screen and (max-width: 645px) {
              margin-left: -15px;
            }
          }

          &.q2 {
            margin-left: 0;
            @media only screen and (max-width: 645px) {
              margin-left: -14px;
            }
          }

          &.q3 {
            margin-left: 10px;
            @media only screen and (max-width: 645px) {
              margin-left: -6px;
            }
          }
        }

        .text__right {
          float: right;

          &.q1 {
            position: relative;
            right: 29px;
            @media only screen and (max-width: 645px) {
              right: 12px;
            }
          }

          &.q2 {
            position: relative;
            right: 8px;
            @media only screen and (max-width: 645px) {
              right: -6px;
            }
          }

          &.q3 {
            position: relative;
            right: 18px;
            @media only screen and (max-width: 645px) {
              right: 0;
            }
          }
        }
      }
    }

    .rating {
      height: 60px;
      padding-right: 20px;
      @media only screen and (max-width: 322px) {
        padding-right: 0;
      }
    }

    .rating label {
      text-indent: -100px;
      width: 50px;
      height: 50px;
      overflow: hidden;
      cursor: pointer;
    }

    .rating label.stars {
      background: transparent url("/assets/img/assessment/rating-star.png") no-repeat center center;
      background-size: 35px 35px;
    }

    .rating input[type="radio"] {
      position: absolute;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px, 1px, 1px, 1px);
    }

    .rating input[type="radio"],
    .rating label.stars {
      float: right;
      margin: 7px;
      @media only screen and (max-width: 645px) {
        margin-left: -6px;
        margin-right: 6px;
      }
    }

    .rating label.stars:hover ~ label.stars,
    .rating label.stars:hover,
    .rating input[type="radio"][name="stars-1"]:checked ~ label.stars {
      background-image: url("/assets/img/assessment/rating-star-hover.png");
    }

    .rating label.stars:hover ~ label.stars,
    .rating label.stars:hover,
    .rating input[type="radio"][name="stars-2"]:checked ~ label.stars {
      background-image: url("/assets/img/assessment/rating-star-hover.png");
    }

    .rating label.stars:hover ~ label.stars,
    .rating label.stars:hover,
    .rating input[type="radio"][name="stars-3"]:checked ~ label.stars {
      background-image: url("/assets/img/assessment/rating-star-hover.png");
    }
  }

  .chart-container {
    text-align: center;
    background-color: $color-blue-4;

    canvas {
      width: 100%;
    }
  }

  .top-chart-container {
    border-radius: 10px;
    text-align: center;
    background-color: $color-blue-4;
    cursor: pointer;
    border: 3px solid $bdcolor-reverse;
  }

  .result-container {
    padding: 0 32px 32px 32px;
    @include mq-sp {
      padding: 0 16px 48px 16px;
    }

    .result-bar-chart {
      &-title {
        font-size: $assessment-font-large;
        color: $assessment-color-dark;
        font-weight: 500;
        line-height: 32px;
        padding: 40px 0 8px 0;
      }

      &-category-group {
        padding-top: 24px;

        &:first-child {
          padding-top: 0;
        }
      }

      &-category-deviation-title {
        margin-left: 8px;
      }

      .title {
        font-size: $assessment-font-medium;
        color: $assessment-color-dark;
        font-weight: 400;
      }

      .subtitle {
        font-size: $assessment-font-18;
        color: $assessment-color-dark;
        font-weight: bold;
        padding-top: 8px;
      }

      .sub-score {
        font-size: $assessment-font-small;
        color: $assessment-color-dark;
        font-weight: bold;
      }

      .description {
        font-size: $assessment-font-regular;
        color: $assessment-color-dark;
        font-weight: 500;
        padding-top: 4px;
      }

      .section-group-top-padding {
        padding-top: 42px;
      }

      .category-container {
        padding-top: 16px;

        .category {
          display: flex;
          align-items: center;

          .category_title {
            width: 50%;
            font-weight: bold;
            font-size: 14px;
            line-height: 14px;
          }

          .category_readmore {
            width: 50%;
            font-weight: bold;
            font-size: $assessment-font-small;
            text-align: end;
            color: $color-uiblue;
          }

          .rigth-arrow {
            background: transparent url("/assets/img/assessment/btn-enter.png") no-repeat center center;
            background-size: 6px;
            padding: 4px;
          }
        }

        .ranking-label {
          display: flex;
          margin-top: 4px;

          &__item {
            border: 1px solid $color-pink;
            border-radius: 4px;
            color: $color-pink;
            font-size: 10px;
            font-weight: bold;
            line-height: 10px;
            margin-right: 4px;
            padding: 4px;
          }
        }

        .deviation {
          display: flex;
          vertical-align: middle;
          height: 20px;
          margin-top: 8px;

          .deviation-bar-title {
            width: 10%;
            font-size: $assessment-font-small;
            font-weight: bold;
          }

          .deviation-bar {
            flex: 1;
            // sass-lint:disable no-vendor-prefixes
            .progress {
              height: 20px;
              border-radius: 0;
              background: alpha-color($color-red-2, 0.1);
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              -webkit-box-shadow: inset 0 $shadow-9;
              box-shadow: inset 0 0 0 $shadow-9;

              .progress-bar {
                height: 20px;
                max-width: 100%;
                background: $color-red-2;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                -webkit-box-shadow: inset 0 $shadow-9;
                box-shadow: inset 0 0 0 $shadow-9;
              }
            }
          }

          .personality-deviation-bar {
            width: 100%;

            .progress {
              height: 20px;
              border-radius: 0;
              background: $color-red-2;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              -webkit-box-shadow: inset 0 $shadow-9;
              box-shadow: inset 0 0 0 $shadow-9;

              .progress-bar {
                height: 20px;
                max-width: 100%;
                background: $color-red-2;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                -webkit-box-shadow: inset 0 $shadow-9;
                box-shadow: inset 0 0 0 $shadow-9;
              }
            }
          }

          .deviation-bar-last-time {
            flex: 1;

            .progress {
              height: 20px;
              border-radius: 0;
              background: $shadow-14;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              box-shadow: inset 0 0 0 $shadow-9;

              .progress-bar {
                height: 20px;
                max-width: 100%;
                background: $color-bluegray;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                box-shadow: inset 0 0 0 $shadow-9;
              }
            }
          }

          .score-label {
            margin-left: 16px;
            text-align: right;
            color: $color-red-2;
            font-size: 20px;
            line-height: 20px;
          }

          .score-label-last-time {
            margin-left: 16px;
            text-align: right;
            color: $color-darkgray;
            font-size: 20px;
            line-height: 20px;
          }
        }

        .category-note {
          font-size: $assessment-font-small;
          color: $assessment-color-dark;
          font-weight: 500;
          padding-top: 4px;
        }

        .progress-bar-section-padding {
          padding-top: 24px;
        }
      }
    }

    .next-action-title {
      font-size: $assessment-font-large;
      color: $assessment-color-dark;
      font-weight: 500;
      line-height: 32px;
      padding: 40px 0 8px 0;
    }
  }

  .result-previous-container {
    .deviation {
      display: flex;
      align-items: center;

      .deviation-bar-title {
        width: 16%;
        margin: 0 8px 0 0;
        @include mq-sp {
          width: 18%;
          margin: 0;
        }
        font-size: $assessment-font-small;
      }

      .deviation-bar {
        flex: 1;
        @include mq-sp {
          padding-left: 8px;
          width: 68%;
        }

        .progress {
          background: $color-red-2;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;

          .progress-bar {
            height: 14px;
            max-width: 100%;
            background: $color-red-2;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }

      .score-label {
        width: 15%;
        @include mq-sp {
          width: 14%;
        }
        text-align: right;
        color: $color-red-2;
        font-size: 10px;

        span {
          font-size: 14px;
          font-weight: bold;
        }
      }

      .deviation-bar-title-bold {
        width: auto;
        margin: 0 8px 0 0;
        font-weight: bold;
        @include mq-sp {
          width: auto;
          margin: 0;
        }
        font-size: $assessment-font-small;
      }
    }
  }

  .progressBar {
    position: relative;
    width: 95%;
    height: 50px;
    margin: 20px auto;
    border: 3px solid $color-yellow-2;
    border-radius: 5px;
    overflow: hidden;
  }

  .progressBar div {
    position: relative;
    height: 100%;
    background-color: $color-yellow-2;
  }

  .progressBar .progressText {
    position: absolute;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
  }

  .carousel-control.left,
  .carousel-control.right {
    background-image: none !important;
    filter: none !important;
  }

  .carousel .carousel-indicators {
    bottom: 24px;
    @include mq-sp {
      bottom: 16px;
    }
  }

  .carousel .carousel-indicators li {
    background-color: $shadow-15;
    border: 0;
    width: 8px;
    height: 8px;
    margin: 0 6px;
    border-radius: 50%;
  }

  .carousel .carousel-indicators .active {
    background-color: $bgcolor-normal;
    border: 0;
    width: 8px;
    height: 8px;
    margin: 0 6px;
    border-radius: 50%;
  }

  .toppage__carousel {
    padding: 0 0 48px 0;
    margin-bottom: 10px;

    .carousel .carousel-indicators {
      position: absolute;
      bottom: -40px;
    }

    .carousel .carousel-indicators li {
      width: 7px;
      height: 7px;
      margin: 0;
      background-color: $color-gray;
      border-radius: 50%;
    }

    .carousel .carousel-indicators .active {
      background-color: $color-uiblue;
    }
  }

  .assessment-result-chart {
    width: 90%;
    top: -87px;
    transform: scale(0.9);
  }

  .assessment-result-chart-summary-top-page {
    width: 90%;
    top: -47px;
    transform: scale(0.9);
  }

  // slick
  // refactor
  .top-chart-container-slick {
    text-align: center;
    background-color: $color-blue-4;
    box-shadow: 7px 2px 22px -5px $shadow-16;
    border-radius: 10px;
    width: 100%;
  }

  .top-chart-container-slick-result {
    border-radius: 0;
    text-align: center;
    background-color: $color-blue-4;
    border-left: 3px solid $bdcolor-reverse;
    border-right: 3px solid $bdcolor-reverse;
    width: 100%;
  }

  .slick-frame {
    visibility: hidden;
  }

  .slick-frame.slick-initialized {
    visibility: visible;
  }

  .slick__carousel {
    overflow: auto;
    white-space: nowrap;
  }

  .inner {
    width: 380px;
  }

  .inner__result_page {
    width: 450px;
    display: inline-block;
  }

  .slick-slider {
    overflow: hidden;
  }

  .slick-slide {
    &:focus,
    a {
      outline: none;
    }

    margin: 0 5px;
  }

  .slick-dots li {
    margin: 0 7px 0 7px;
  }

  .slick-dots-top li {
    margin: 0 4px 0 4px;
  }
  // sass-lint:disable no-ids
  #questionnaire-area > div > .slick-dotted > ul {
    position: relative;
    top: -186px;
  }

  #questionnaire-area {
    .slick-dots li button:before {
      color: $fgcolor-darkbg;
      font-size: 17px;
      content: " ";
      background-color: $bgcolor-normal;
      height: 8px;
      width: 8px;
      border-radius: 4px;
      box-sizing: border-box;
    }

    .slick-dotted.slick-slider {
      margin: 0;
      height: 400px;
    }
  }

  .nodot__carousel {
    margin-bottom: 24px;

    .slick-dots li.slick-active button:before {
      color: $fgcolor-darkbg;
    }

    .slick-dots li.slick-active button:after {
      color: $fgcolor-darkbg;
    }
  }
}
