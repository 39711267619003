.content--empty {
  border: none;
  box-shadow: none;
  background-color: transparent;
  width: 100%;
  height: 400px;
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content__text {
    font-size: $font-size-basic;
    line-height: 1.7;
  }

  * + .content__text--small {
    margin-top: 15px;
  }

  .content__text--small {
    font-size: $font-size-smaller;
    line-height: 1.8;
    color: $fgcolor-lowpriority;
  }

  * + .btn-secondary {
    margin-top: 15px;
  }

  .btn-secondary {
    display: block;
    text-decoration: none;
  }
}

//iOS8対応
html[data-os="iOS 8"] {
  .content--empty {
    margin-top: 60px;
  }
}

.search-panel {
  margin-top: 10px;
  width: 100%;
  font-size: $font-size-small;
  background-color: $bgcolor-normal;

  input[type="checkbox"] {
    display: none;

    &:checked + label {
      &:after {
        content: " ";
        display: block;
        width: 5px;
        height: 10px;
        border: solid $fgcolor-primary-theme;
        border-width: 0 2px 2px 0;
        position: absolute;
        left: -4px;
        top: 11px;
        margin-top: -3px;
        transform: rotate(45deg);
      }
    }
  }

  input[type="text"] {
    border: 1px solid lighten($bdcolor-normal-dark, 10%);
    width: 100%;
    box-sizing: border-box;
    padding: 6px;
  }

  li {
    display: inline-block;

    &,
    * {
      cursor: pointer;
      margin: 0;
    }
  }

  label {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    padding: 6px 18px;
    border-radius: 2px;
    box-sizing: border-box;

    &:before {
      position: absolute;
      content: "";
      top: 50%;
      left: -10px;
      width: 18px;
      height: 18px;
      margin-top: -10px;
      background: $bgcolor-input-checkbox;
      border: 1px solid $color-black-6;
    }
  }
}

.search-btn-area {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 15px;
  background-color: $bgcolor-normal;
  text-align: right;
  vertical-align: middle;
  border-top: 1px solid $color-black-5;
  border-bottom: 1px solid $color-black-5;
  box-sizing: border-box;

  .search-count {
    float: left;
    margin-top: 7px;
    margin-left: 16px;
    font-size: 20px;

    &.disabled {
      color: $fgcolor-disabled;
    }
  }
}

.btn-search {
  @extend .btn-secondary;
  position: relative;
  height: 45px;
  width: 61%;
  border: 1px solid $basecolor-primary;
  color: $basecolor-primary;

  &:hover {
    color: $basecolor-primary;
  }
}

.search-btn-block {
  margin-top: 15px;
  border-top: 1px solid $bdcolor-normal;
  padding-top: 15px;

  .fa {
    font-size: 20px;
    vertical-align: baseline;
  }

  @include mq-sp {
    margin-top: 0;
    border-top: 0;
    padding-top: 0;
  }
}

.search {
  .search-condition {
    margin-top: 12px;
    font-size: 14px;
    color: $fgcolor-normal;
    line-height: 1.8;

    p {
      @extend .text-over-flow;

      span:nth-child(1) {
        font-weight: bold;
      }

      span:nth-child(2) {
        margin-left: 8px;
      }
    }
  }

  @include mq-sp {
    display: block;
    width: 100%;
    box-sizing: border-box;

    margin-left: 0;
    margin-top: 15px;
    padding-top: 0;

    form {
      margin-top: 24px;
    }

    h1 {
      font-size: 20px;
      font-weight: normal;
    }

    h2 {
      color: $fgcolor-primary-theme;
      font-size: 20px;
      font-weight: normal;
      margin: 0 0 15px 0;
      padding: 0 0 2px 0;
      border-bottom: solid 1px $fgcolor-primary-theme;
    }

    h1 + h2 {
      margin-top: 10px;
    }

    input[type="text"] + h2 {
      margin-top: 30px;
    }

    input[type="text"] {
      font-size: $font-size-basic;
      width: 100%;
      box-sizing: border-box;
      padding: 12px;
    }

    input[type="checkbox"] {
      display: none;

      &:checked + label {
        &:after {
          content: " ";
          display: block;
          width: 5px;
          height: 10px;
          border: solid $fgcolor-primary-theme;
          border-width: 0 2px 2px 0;
          position: absolute;
          left: -4px;
          top: 10px;
          margin-top: -3px;
          transform: rotate(45deg);
        }
      }
    }

    label {
      display: inline-block;
      position: relative;
      margin-left: 10px;
      padding: 3px 18px;
      border-radius: 2px;
      box-sizing: border-box;

      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: -10px;
        width: 18px;
        height: 18px;
        margin-top: -10px;
        background: $bgcolor-input-checkbox;
        border: 1px solid $color-black-6;
      }
    }
  }
}

.search-condition-header {
  margin-top: 50px;
}
