// image
//
// 画像関連
//
// .image - 標準の画像装飾
// .image.image-shadowed - 影付き
// .image.image-circle - 丸画像
//
// Markup:
// <image src="http://placehold.it/150x150" class="{$modifiers}"></image>
//
// Styleguide 3.52

$image-title--large: 26px;

.image {
  &.image-shadowed {
    box-shadow: $boxshadow-normal;
  }

  &.image-circle {
    border-radius: $bdradius-circle;
  }
}

.bd-img-circle {
  border: 1px solid $bdcolor-normal;
  border-radius: 100%;
}

.round-bd-image--30 {
  @include round-bd-image(30px);
}

.round-bd-image--40 {
  @include round-bd-image(40px);
}

//画像のアスペクト比が5:2の部品
.image--two-fifth-fixed {
  width: auto;
  height: auto;
  padding-top: percentage(400/960);
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;

  @include mq-sp {
    + * {
      margin-top: 10px;
    }
  }
}

//画像のアスペクト比が5:2の部品
.image--two-fifth-dark {
  height: 400px / 960px * 668px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  background-size: 100% auto;
  background-repeat: no-repeat;

  @include mq-sp {
    height: auto;
    margin-bottom: 0;
    padding-top: percentage(400/960);
    margin: 0 -15px;
  }
  @include mq-pc {
    margin: 0;
  }

  &__heading {
    position: relative;
    z-index: 3;
    margin-top: 15px;
    text-align: center;
    font-size: $image-title--large;
    color: $fgcolor-darkbg;

    @include mq-pc {
      margin-top: 80px;
    }

    > span {
      display: block;
      font-size: $font-size-basic;
    }
  }

  &__heading + time {
    margin-top: 10px;
  }

  time {
    position: relative;
    z-index: 3;
    display: block;
    text-align: center;
    font-size: $font-size-smaller;
    color: $fgcolor-darkbg;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 0;
    padding-top: percentage(400/960);
    background-color: $basecolor-primary;
    opacity: 0.7;
  }

  figcaption {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
}
