// sass-lint:disable no-vendor-prefixes
// Popup
//
// @see PopupUtil.js
//
//Styleguide 3.9

$font-size-popup: 18px;

.popup {
  display: block;
  position: fixed;
  z-index: 1001;
  width: 100%;
  max-width: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $bgcolor-normal;
  border-radius: 2px;
  box-sizing: border-box;
  text-align: center;

  //iOS8対応
  @include mq-sp {
    top: 50px;
    left: 0;
    right: 0;
    margin: auto;
    transform: none;
    width: calc(100% - 30px);
  }

  &__heading {
    padding: 15px;
    background-color: $color-darkgray;

    h1 {
      color: $basecolor-reverse;
      font-size: $font-size-popup;
    }
  }

  &__content {
    position: relative;
    padding: 15px;
    background-color: $basecolor-reverse;

    h1 {
      @include mq-sp {
        display: block;
        font-size: $font-size-large;
      }
    }

    h1 + * {
      margin-top: 15px;
    }

    > .close {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      color: $fgcolor-supplemental;
    }
  }
}

.popup-content {
  display: block;
  position: fixed;
  z-index: 1001;
  width: 100%;
  max-width: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  background-color: $bgcolor-normal;
  border-radius: 2px;
  box-sizing: border-box;
  text-align: center;

  //iOS8対応
  @include mq-sp {
    top: 50px;
    left: 0;
    right: 0;
    margin: auto;
    transform: none;
  }

  .popup-title {
    text-align: left;

    & + p {
      margin-top: 15px;
      font-size: 14px;
    }
  }

  .popup-btn-area {
    margin-top: 25px;
  }

  &.popup-shadow {
    box-shadow: 0 3px 3px 0 $shadow-39;
  }

  &.popup-border {
    border: solid 1px $shadow-39;
  }

  &.popup-overflow {
    overflow-y: auto;
  }

  @include mq-sp {
    width: calc(100% - 30px);
    padding: 25px;

    h1.heading {
      font-size: 18px;
    }
  }

  &.popup__message {
    @extend .text--medium;
  }

  .popup__message + .popup__message {
    margin-top: 10px;
  }
}

//通常のポップアップの背景
.popup-overlay {
  @include popup-background;
  background-color: $shadow-6;

  //吹き出し元のアイコンなどを見せたい時のポップアップの背景
  &--light {
    $basecolor-light-opacity: $shadow-7;
    @include popup-background;
    background-color: $basecolor-light-opacity;
  }
}

//popupの初期状態
.is-hide {
  display: none;
}

.popup-buttons {
  position: relative;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 300px;

  @include mq-sp {
    width: 100%;
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__items {
    flex: 1;
    padding-right: 5px;
    padding-left: 5px;

    @include mq-sp {
      flex: 1;
    }

    [class^="button--"] {
      width: 145px;
      @include mq-sp {
        width: 100%;
        min-width: 100px;
      }
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}

//iOS8対応
html[data-os="iOS 8"] {
  .popup-buttons {
    &__inner {
      display: table;
      table-layout: fixed;
      width: 100%;
    }

    &__items {
      display: table-cell;

      [class^="button--"] {
        width: 100%;
      }
    }
  }
}

//========ここからログイン用モーダル

.bg-login-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: $shadow-8;
  z-index: 999;
  transition: 0.1s ease-in-out;
  opacity: 0;
  pointer-events: none;

  &--show {
    opacity: 1;
    pointer-events: auto;
  }
}

.login-modal {
  &__ {
    &title {
      border-bottom: 1px solid $color-gray;
      font-weight: bold;
      line-height: 44px;
      height: 44px;
      font-size: 16px;
    }

    &button {
      display: inline-block;
      position: relative;
      box-sizing: border-box;
      width: 280px;
      height: 44px;
      border-radius: 22px;
      text-decoration: none;
      line-height: 44px;
      font-weight: bold;
      font-size: 16px;
      transition: 0.1s linear;

      @include mq-sp {
        width: 256px;
      }

      &-- {
        &student {
          margin-bottom: 8px;
          background-color: $color-uiblue;
          border: 1px solid $color-uiblue;
          color: $color-white;
          @include mq-pc {
            &:hover {
              color: $color-uiblue;
              background-color: $color-white;
              text-decoration: none;
            }
          }
        }

        &obog {
          margin-bottom: 24px;
          background-color: $color-white;
          border: 1px solid $color-uiblue;
          color: $color-uiblue;
          @include mq-pc {
            &:hover {
              background-color: $color-uiblue;
              color: $color-white;
              text-decoration: none;
            }
          }
        }
      }
    }

    &text {
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      color: $color-darkgray;
    }

    &banner {
      margin: 24px 48px;
    }

    &login-app {
      margin-bottom: 8px;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
    }

    &btn-cancel {
      position: absolute;
      left: 0;
      top: 0;
      height: 44px;
      width: 44px;
      background: url("/assets/img/article/btn-modal-close.svg") no-repeat center;
      box-sizing: border-box;
      border: none;
      outline: none;
      font-size: 0;
      -webkit-tap-highlight-color: $shadow-9;

      &:hover {
        @include mq-pc {
          text-decoration: underline;
        }
      }
    }
  }
}

//========ここまでログイン用モーダル

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: left;
  font-size: $font-size-basic;
  visibility: hidden;

  &--show {
    visibility: visible;
    transform: none;
  }

  &__ {
    &inner {
      margin: 0 24px;
      width: 100%;
      box-sizing: border-box;
      background: $bgcolor-normal;
      border-radius: 6px;
      opacity: 0;
      transform: translate3d(0, 50px, 0);
      transform-origin: right top;
      transition: 0.2s ease-in;
      @include mq-pc {
        max-width: 320px;
      }
      @include mq-middle {
        max-width: 320px;
      }

      &--show {
        transform: none;
        opacity: 1;
        transition-timing-function: ease-out;
      }
    }

    &header {
      border-bottom: 1px solid $bdcolor-normal;
      text-align: center;
      height: 44px;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 16px;
    }

    &title {
      line-height: 44px;
      height: 44px;
      font-size: $font-size-basic;
    }

    &btn-cancel {
      position: absolute;
      left: 0;
      top: 0;
      height: 44px;
      width: 44px;
      background: url(/assets/img/article/btn-modal-close.svg) no-repeat center;
      box-sizing: border-box;
      border: none;
      outline: none;
      font-size: 0;
      -webkit-tap-highlight-color: $shadow-9;

      &:hover {
        @include mq-pc {
          text-decoration: underline;
        }
      }
    }

    &animation-item {
      &--execute {
        transform: none;
        opacity: 1;
      }
    }

    &text {
      padding: 0 16px 16px 16px;

      &--center {
        text-align: center;
      }
    }

    &separate-button {
      display: flex;
      justify-content: center;
      border-top: 1px solid $bdcolor-normal;
    }

    &separate-button__item {
      flex: 1;

      & + & {
        border-left: 1px solid $bdcolor-normal;
      }
    }

    &separate-button__link {
      display: block;
      height: 44px;
      line-height: 44px;
      text-align: center;
      color: $fgcolor-primary-theme;

      &:hover {
        text-decoration: none;
      }
    }

    &separate-button__link--important {
      @extend .modal__separate-button__link;
      font-weight: bold;
    }
  }
}

.bg-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: $shadow-8;
  z-index: 999;
  transition: 0.3s ease-in;
  opacity: 0;
  pointer-events: none;

  &--show {
    transition: 0.1s ease-out;
    opacity: 1;
    pointer-events: auto;
  }
}

.app-android-modal {
  &__ {
    &inner {
      max-width: 780px;
    }

    &logo {
      width: 300px;
      height: 54px;
      margin-bottom: 8px;
      @include mq-sp {
        width: 240px;
        height: 43px;
      }
    }

    &qrcode {
      width: 160px;
      height: 160px;
      border: 4px solid $color-gray;
      border-radius: 8px;
      margin-bottom: 8px;
    }

    &content {
      text-align: center;
    }

    &store-badge {
      display: block;
      height: 44px;
      width: 148px;
      margin: 0 auto;

      img {
        height: 44px;
        width: 148px;
      }
    }

    &flex {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 32px 32px 32px;
      @include mq-sp {
        display: block;
        padding: 0 16px 16px 16px;
      }
    }

    &titles {
      margin-right: 52px;
      @include mq-sp {
        margin-right: 0;
      }
    }

    &title {
      margin-bottom: 24px;
      @include mq-sp {
        font-size: 14px;
        text-align: left;
      }
    }

    &mockup {
      width: 264px;
    }

    &text {
      font-size: 14px;
    }
  }
}

.lead-to-certification {
  border-radius: 10px;
  width: 360px;
  padding: 0;
  @include mq-sp {
    top: 50%;
    transform: translateY(-50%);
    max-width: calc(100% - 32px);
  }

  &__header {
    box-sizing: border-box;
    border-bottom: 1px solid $color-gray;
    height: 44px;

    &__title {
      font-weight: bold;
      line-height: 44px;
      height: 44px;
      font-size: $font-size-basic;
    }

    &__btn-cancel {
      position: absolute;
      left: 12px;
      top: 0;
      height: 44px;
      width: 44px;
      background: url(/assets/img/article/btn-modal-close.svg) no-repeat center;
      border: none;
      font-size: 0;
      @include mq-sp {
        left: 4px;
      }
    }
  }

  &__contents {
    padding: 24px;
    @include mq-sp {
      padding: 16px;
    }

    &__text {
      margin-bottom: 16px;
      text-align: left;
      font-size: $font-size-small;
      line-height: 24px;
    }
  }

  .popup-buttons {
    width: 100%;
    margin: 0;

    .button--primary {
      width: 144px;
    }
  }
}

.event-apply-failed-recruiting-end {
  border-radius: 10px;
  width: 360px;
  padding: 0;
  @include mq-sp {
    max-width: calc(100% - 32px);
  }

  &-header {
    box-sizing: border-box;
    border-bottom: 1px solid $color-gray;
    height: 44px;

    &__title {
      font-weight: bold;
      line-height: 44px;
      height: 44px;
      font-size: $font-size-basic;
    }
  }

  &-contents {
    padding: 24px;
    @include mq-sp {
      padding: 16px;
    }

    &__text {
      margin-bottom: 16px;
      text-align: left;
      font-size: $font-size-small;
      line-height: 24px;
    }
  }

  .popup-buttons {
    width: 100%;
    margin: 0;

    .button--normal {
      width: 144px;
    }
  }
}
