// sass-lint:disable nesting-depth
.profile-edit-profile {
  margin-bottom: 40px;
  text-align: center;
}

.profile-content {
  position: relative;
  width: 200px;
  margin: 0 auto;
}

.form {
  .form-table {
    &.profile-edit-form-table {
      .form-control {
        & + .form-control .form-table-content {
          padding-top: 40px;

          @include mq-sp {
            padding-top: 0;
          }
        }
      }
    }
  }
}

.form-table-content__basic {
  padding-top: 10px;
  padding-bottom: 10px;
}

h2.heading.heading-border.sp-heading {
  @include mq-sp {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

h2.heading.heading-border.sp-heading:first-of-type {
  @include mq-sp {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.profile-edit-form-area {
  padding: 40px;
  background-color: $bgcolor-normal;

  @include mq-sp {
    padding: 15px;
    margin-top: 10px;
  }

  .profile-edit-form-area-title {
    text-align: center;
    line-height: 1.2;
    margin-bottom: 15px;

    @include mq-sp {
      display: none;
    }
  }

  .profile-edit-form-area-title-supplemental {
    margin-bottom: 30px;
    margin-top: -30px;
    color: $fgcolor-lowpriority;
    text-align: center;
  }
}

.form-control-skill {
  .form-table-title {
    width: 80px;
  }

  .form-table-content {
    font-size: 0;
  }
}

.form-control-location {
  .form-table-title {
    width: 96px;
  }

  .form-table-content {
    font-size: 0;
  }
}

.form-table-content-toeic {
  display: inline-block;

  & + .form-table-content-toeic {
    margin-left: 20px;
  }
}

.form-table-content-toeic-title {
  display: block;
}

.form-table-title.form-table-title-toeic {
  position: relative;
  top: -32px;
}

.form-table-content-other-language + .form-table-content-other-language {
  margin-top: 5px;
}

.profile-edit-birthdate-error-box {
  display: table-cell;
}

.profile-edit-birthdate-error {
  position: relative;
  top: 30px;
  left: 145px;
}

.is-require {
  .form-table-title {
    &::after {
      display: inline-block;
      content: "*";
      color: $basecolor-warning;
      margin-left: 2px;
      vertical-align: top;
    }
  }
}
