.message-guideline-modal {
  @include mq-sp {
    box-sizing: border-box;
    display: block;
    overflow: auto;
    padding: 24px 16px;
  }

  &__ {
    &bg {
      transition: none;
    }

    &middle {
      padding: 20px 24px 24px;
      border-radius: 10px;
      transition: none;

      @include mq-pc {
        max-width: 430px;
      }
      @include mq-sp {
        margin: 0 auto;
      }
    }

    &description {
      padding-bottom: 8px;
    }

    &text {
      font-size: 14px;
      line-height: 22.4px;
    }

    &ng {
      padding-bottom: 4px;
    }

    &ng-item {
      padding-bottom: 4px;
    }

    &ng-title {
      font-size: 14px;
      font-weight: bold;
      line-height: 22.4px;
    }

    &ng-description {
      font-size: 14px;
      line-height: 22.4px;
    }

    &supplemental {
      padding-bottom: 20px;
    }

    &supplemental-text {
      font-size: 12px;
      line-height: 18px;
    }

    &action {
      text-align: center;
    }

    &btn {
      @extend .button--secondary;
      height: 44px;
      width: auto;
      min-width: 100px;
      padding: 10px 20px;
      font-size: 16px;
      line-height: 16px;
    }
  }
}
