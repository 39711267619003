.privacy-contact {
  dd {
    margin-left: 1em;
  }

  dd + dt {
    margin-top: 8px;
  }
}

.privacy-addinfo {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $bdcolor-normal;
}

.privacy-policy-page {
  h2 + * {
    margin-top: 10px;
  }
}

.privacy-policy-telecommunications-business-act {
  * + * {
    margin-top: 8px;
  }
}

.privacy-policy-telecommunications-business-act--table-wrapper {
  overflow: auto;
  @include mq-pc() {
    display: flex;
    justify-content: center;
  }
}

.privacy-policy-telecommunications-business-act--table {
  border-collapse: collapse;

  td,
  th {
    font-size: 14px;
    white-space: nowrap;
    padding: 8px;
    border: 1px solid $color-darkgray;
  }
}