// sass-lint:disable nesting-depth
.form {
  .form-table {
    &.inquiry-form-table {
      .form-control {
        & + .form-control .form-table-title {
          padding-top: 48px;
          vertical-align: top;

          @include mq-sp {
            padding-top: 0;
          }
        }

        & + .form-control .form-table-content {
          padding-top: 40px;

          @include mq-sp {
            padding-top: 0;
          }
        }
      }
    }
  }
}

.inquiry-form-area {
  padding: 40px;
  background-color: $bgcolor-normal;

  .inquiry-form-error {
    margin-top: 5px;
    font-size: 12px;
    display: block;
  }

  @include mq-sp {
    padding: 15px;
    margin-top: 10px;
  }
}

.inquiry-form-input-text {
  width: 100%;

  &--error {
    background: $color-pink-2;
    border-color: $color-pink;
  }
}

.inquiry-form-selectbox {
  width: 400px;

  @include mq-sp {
    width: 100%;
  }
}

.inquiry-form-form-textarea {
  width: 100%;
  height: 128px;
}

.inquiry-form-message {
  width: 520px;

  @include mq-sp {
    width: 100%;
  }

  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;

  p {
    span {
      font-size: 12px;
    }
  }
}

.inquiry-form-recaptcha-checkbox {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.inquiry-form-btn {
  margin-top: 20px;
  text-align: center;

  .btn,
  .btn-secondary,
  .btn-big {
    @include mq-sp {
      width: 100%;
    }
  }
}

.inquiry-form-privacy-policy-note {
  margin-top: 16px;
  text-align: center;
  font-size: 12px;
  line-height: 18px;

  @include mq-sp {
    text-align: left;
  }
}
