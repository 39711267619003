.top-signup {
  width: 100%;
  min-width: 960px;
  background-color: $bgcolor-primary;
  text-align: center;

  @include mq-sp {
    min-width: inherit;
    margin: auto;
  }
}

.top-btn-inner-mail {
  font-size: 18px;
}

.top-to-ob {
  @extend .top-btn-inner-mail;
}
