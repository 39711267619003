// variable
$lp-global-breakpoint-sp: 959px;
$lp-global-main-color: #470a7c;
$lp-global-color-gray: #f4f4f4;
$lp-global-font-size-heading: 30px;

.lp-global {
  font-family: "ヒラギノ角ゴ Std W5", Hiragino Kaku Gothic Std W5, sans-serif, Helvetica Neue, Helvetica, Arial,
    "游ゴシック", "Yu Gothic", YuGothic, "メイリオ", Meiryo;
  background-color: $bgcolor-normal;

  // overwrite
  figure {
    margin: 0;
  }

  // utility
  .u-pc-view {
    @include mq-sp($lp-global-breakpoint-sp) {
      display: none;
    }
  }

  .u-sp-view {
    display: none;
    @include mq-sp($lp-global-breakpoint-sp) {
      display: block;
    }
  }

  // lauout
  .l-contents {
    padding: 90px 0 80px;
    @include mq-sp($lp-global-breakpoint-sp) {
      padding: 70px 24px;
    }

    &__inner {
      width: 970px;
      margin: auto;
      @include mq-sp($lp-global-breakpoint-sp) {
        width: 100%;
      }
    }

    &--company {
      padding-bottom: 96px;
      @include mq-sp($lp-global-breakpoint-sp) {
        padding-bottom: 80px;
      }

      .c-heading {
        padding-bottom: 54px;
      }

      .p-company-voice {
        display: flex;
        justify-content: space-between;
        @include mq-sp($lp-global-breakpoint-sp) {
          flex-direction: column;
        }
      }
    }

    &--feature {
      @include mq-sp($lp-global-breakpoint-sp) {
        padding: 70px 0;
      }

      .c-heading {
        padding-bottom: 90px;
        @include mq-sp($lp-global-breakpoint-sp) {
          padding-bottom: 65px;
        }
      }
    }
  }

  // thema
  .t-target-student {
    background: url("/assets/img/staticpage/global/bg-requirement.jpg") no-repeat center top;
    background-size: cover;
    @include mq-sp($lp-global-breakpoint-sp) {
      padding: 57% 0 0;
      background-size: contain;
    }
  }

  .t-feature {
    background-color: $lp-global-color-gray;
  }

  // project
  .p-mainvisual {
    position: relative;
    display: flex;
    min-height: 720px;
    @include mq-sp($lp-global-breakpoint-sp) {
      flex-direction: column;
      padding-bottom: 36px;
    }

    &__contents-wrap,
    &__thumb-wrap {
      position: relative;
    }

    &__contents-wrap {
      width: 45%;
      padding-top: 74px;
      @include mq-sp($lp-global-breakpoint-sp) {
        width: 100%;
        padding-top: 20px;
      }
    }

    &__thumb-wrap {
      width: 55%;
    }

    &__logo {
      width: 90%;
      max-width: 470px;
      height: auto;
      margin: auto;
      padding-bottom: 54px;
      @include mq-sp($lp-global-breakpoint-sp) {
        width: 288px;
        padding-bottom: 24px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &__contents {
      width: 362px;
      margin: auto;
      @include mq-sp($lp-global-breakpoint-sp) {
        position: relative;
        right: 0;
        width: 100%;
      }
    }

    &__thumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 722px;
      background: url("/assets/img/staticpage/global/mainvisual.jpg") no-repeat left top;
      background-size: cover;

      &-sp {
        padding-bottom: 20px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    &__heading {
      position: relative;
      font-size: $lp-global-font-size-heading;
      font-weight: bold;
      padding-bottom: 40px;
      @include mq-sp($lp-global-breakpoint-sp) {
        padding-bottom: 24px;
        font-size: $font-size-large;
        text-align: center;
      }
    }

    &__description {
      padding-bottom: 50px;
      font-size: $font-size-LL;
      line-height: 1.8;
      @include mq-sp($lp-global-breakpoint-sp) {
        padding: 0 16px 20px;
        font-size: $font-size-basic;
      }
    }

    &__button {
      padding-bottom: 8px;
      @include mq-sp($lp-global-breakpoint-sp) {
        padding-bottom: 16px;
        text-align: center;
      }
    }

    &__note {
      position: relative;
      display: block;
      padding-left: 16px;
      font-size: $font-size-small;
      color: $color-black-15;
      @include mq-sp($lp-global-breakpoint-sp) {
        margin: 0 auto;
        width: 287px;
        padding: 0 20px 0 36px;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: "※";
        @include mq-sp($lp-global-breakpoint-sp) {
          left: 20px;
        }
      }

      a {
        color: inherit;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__scroll-down {
      position: absolute;
      right: 130px;
      bottom: 40px;
      display: inline-block;
      padding-bottom: 110px;
      line-height: 1;
      writing-mode: vertical-rl;
      color: $lp-global-main-color;
      font-size: $font-size-small;
      font-weight: bold;
      @include mq-sp($lp-global-breakpoint-sp) {
        display: none;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        display: block;
        width: 2px;
        height: 100px;
        background: $color-white;
        animation: sdl 1.98s cubic-bezier(0.73, 0.07, 0.83, 0.6) infinite;
      }
    }

    @keyframes sdl {
      0% {
        transform: scale(1, 0);
        transform-origin: 0 0;
      }
      50% {
        transform: scale(1, 1);
        transform-origin: 0 0;
      }
      50.1% {
        transform: scale(1, 1);
        transform-origin: 0 100%;
      }
      100% {
        transform: scale(1, 0);
        transform-origin: 0 100%;
      }
    }
  }

  // p-mainvisual

  .p-target-student {
    width: 558px;
    margin: auto;
    padding: 80px 0;
    background-color: $lp-global-main-color;
    color: $lp-global-color-gray;
    box-sizing: border-box;
    @include mq-sp($lp-global-breakpoint-sp) {
      width: 100%;
      padding: 50px 20px;
    }

    &__heading {
      padding-bottom: 42px;
      font-size: 33px;
      font-weight: bold;
      text-align: center;
      @include mq-sp($lp-global-breakpoint-sp) {
        padding-bottom: 50px;
        font-size: $font-size-larger;
      }
    }

    &__list-item {
      position: relative;
      display: flex;
      justify-content: center;
      padding-bottom: 38px;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      @include mq-sp($lp-global-breakpoint-sp) {
        justify-content: flex-start;
        padding-left: 26px;
        text-align: left;
      }
    }

    &__icon {
      position: relative;

      &::before {
        position: absolute;
        top: 7px;
        left: -20px;
        display: block;
        content: "";
        width: 15px;
        height: 15px;
        background-color: $color-blue-5;
        @include mq-sp($lp-global-breakpoint-sp) {
          top: 7px;
        }
      }
    }

    &__memo {
      position: absolute;
      top: 30px;
      font-size: $font-size-small;
    }

    &__note {
      display: flex;
      justify-content: center;
      font-size: 13px;
      text-align: center;
      @include mq-sp($lp-global-breakpoint-sp) {
        padding-left: 20px;
        text-align: left;
      }

      &-icon {
        position: relative;

        &::before {
          position: absolute;
          top: 0;
          left: 24px;
          display: block;
          content: "※";
          @include mq-sp($lp-global-breakpoint-sp) {
            left: -14px;
          }
        }
      }

      a {
        color: $lp-global-color-gray;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .p-company-voice {
    &__item {
      width: 30%;
      padding: 30px;
      background-color: $lp-global-color-gray;
      box-sizing: border-box;

      & + .p-company-voice__item {
        @include mq-sp($lp-global-breakpoint-sp) {
          margin-top: 33px;
        }
      }

      @include mq-sp($lp-global-breakpoint-sp) {
        width: 100%;
        height: auto;
        padding: 26px 32px;
      }
    }

    &__logo {
      padding-bottom: 24px;
      text-align: center;

      img {
        height: 160px;
        border-radius: 50%;
        @include mq-sp($lp-global-breakpoint-sp) {
          height: 135px;
        }
      }
    }

    &__heading {
      padding-bottom: 20px;
      font-size: $font-size-LL;
      font-weight: bold;
      text-align: center;
      @include mq-sp($lp-global-breakpoint-sp) {
        padding-bottom: 16px;
        font-size: $font-size-LL;
      }
    }

    &__description {
      font-size: $font-size-smaller;
      line-height: 2;
      @include mq-sp($lp-global-breakpoint-sp) {
        font-size: 16px;
        line-height: 1.8;
      }
    }
  }

  .p-bottom-register {
    padding-bottom: 136px;
    margin: auto;
    @include mq-sp($lp-global-breakpoint-sp) {
      padding-bottom: 70px;
    }

    .c-app-buttons {
      text-align: center;
    }

    .c-button {
      text-align: center;

      a {
        margin: auto;
      }
    }
  }

  .p-footer {
    background-color: $lp-global-color-gray;
  }

  .p-footer-link {
    display: flex;
    justify-content: space-between;
    width: 405px;
    margin: auto;
    padding: 40px 0;
    @include mq-sp($lp-global-breakpoint-sp) {
      width: 100%;
      padding: 28px 30px;
      box-sizing: border-box;
    }

    &__item {
      a {
        font-size: $font-size-small;
        color: $lp-global-main-color;
        @include mq-sp($lp-global-breakpoint-sp) {
          font-size: $font-size-smaller;
        }
      }
    }
  }

  // component
  .c-heading {
    font-size: $lp-global-font-size-heading;
    font-weight: bold;
    text-align: center;
    @include mq-sp($lp-global-breakpoint-sp) {
      font-size: $font-size-3L;
      &__space {
        display: inline-block;
        padding-bottom: 25px;
      }
      &--intro {
        font-size: $font-size-3L;
      }
    }
  }

  .app-store-image {
    vertical-align: bottom;
    width: 165px;
    @include mq-sp($lp-global-breakpoint-sp) {
      width: 136px;
    }
  }

  .c-app-buttons {
    margin-bottom: 20px;
    @include mq-sp($lp-global-breakpoint-sp) {
      margin-bottom: 15px;
      text-align: center;
    }

    a + a {
      margin-left: 20px;
      @include mq-sp($lp-global-breakpoint-sp) {
        margin-left: 15px;
      }
    }
  }

  .c-button {
      display: inline-block;
      box-sizing: border-box;
      width: 165px;
      height: 52px;
      border: 1px solid $lp-global-main-color;
      border-radius: 6px;
      font-size: $font-size-basic;
      font-weight: bold;
      color: $lp-global-main-color;
      text-align: center;
      line-height: 52px;
      text-decoration: none;
      @include mq-sp($lp-global-breakpoint-sp) {
        width: 136px;
        height: 43px;
        font-size: $font-size-small;
        line-height: 43px;
      }

      &:hover {
        color: $color-white;
        background-color: $lp-global-main-color;
      }
  }

  .c-feature {
    display: flex;
    justify-content: space-between;
    @include mq-sp($lp-global-breakpoint-sp) {
      flex-direction: column;
    }

    &__item {
      position: relative;
      @include mq-sp($lp-global-breakpoint-sp) {
        height: auto;
        &:nth-child(even) {
          margin: 65px 0;
        }
      }
    }

    &__thumb {
      width: 293px;
      height: 176px;
      margin: 0; //overwrite
      &::before {
        position: absolute;
        display: block;
        content: "";
        width: 240px;
        height: 74px;
      }

      @include mq-sp($lp-global-breakpoint-sp) {
        width: 100%;
        height: auto;
      }

      img {
        width: 100%;
        height: auto;
        @include mq-sp($lp-global-breakpoint-sp) {
          width: 95%;
        }
      }

      &--visit {
        &::before {
          top: -38px;
          left: -26px;
          background: url("/assets/img/staticpage/global/letter-alumnivisit.png") no-repeat left top;
          background-size: cover;
          @include mq-sp($lp-global-breakpoint-sp) {
            top: -28px;
            left: 6px;
          }
        }
      }

      &--event {
        &::before {
          top: -37px;
          left: -14px;
          background: url("/assets/img/staticpage/global/letter-event.png") no-repeat left top;
          background-size: cover;
          @include mq-sp($lp-global-breakpoint-sp) {
            top: -32px;
            left: 12px;
          }
        }
      }

      &--seminar {
        &::before {
          top: -38px;
          left: -22px;
          background: url("/assets/img/staticpage/global/letter-seminar.png") no-repeat left top;
          background-size: cover;
          @include mq-sp($lp-global-breakpoint-sp) {
            top: -20px;
            left: 4px;
          }
        }
      }
    }

    &__contents-wrap {
      position: relative;
      width: 293px;
      height: 267px;
      margin: -14px 0 0 22px;
      padding: 34px 22px 44px;
      background-color: $bgcolor-normal;
      box-sizing: border-box;
      @include mq-sp($lp-global-breakpoint-sp) {
        width: 90%;
        height: auto;
        margin: -20px 0 0 10%;
        padding: 30px;
      }
    }

    &__sub-heading {
      position: relative;
      padding-bottom: 18px;
      font-size: $font-size-small;
      font-weight: bold;
      text-align: center;
      z-index: 1;

      &::after {
        position: absolute;
        top: 10px;
        left: 0;
        content: "";
        width: 100%;
        height: 1px;
        background-color: $color-blue-5;
        z-index: -1;
      }

      span {
        padding: 0 10px;
        color: $color-blue-5;
        background-color: $color-white;
        line-height: 1;
      }
    }

    &__heading {
      padding-bottom: 24px;
      font-size: $font-size-L;
      font-weight: bold;
      @include mq-sp($lp-global-breakpoint-sp) {
        font-size: $font-size-LL;
      }
    }

    &__description {
      font-size: $font-size-small;
      @include mq-sp($lp-global-breakpoint-sp) {
        font-size: 16px;
      }
    }
  }

  // c-feature
}

// lp-global
