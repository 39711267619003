// sass-lint:disable no-vendor-prefixes
// Forms
//
// かっこいいフォームです。
//
// .form-control - フォームのスタイルが適用されます。
//
// Markup:
// <div class="{$modifiers}">
//   <input type="text" placeholder="text">
// </div>
// <div class="{$modifiers}">
//   <input class="is-invalid" type="text" placeholder="text">
// </div>
// <div class="{$modifiers}">
//   <label>
//     <input type="radio" name="form.radio"><span>ラジオ1</span>
//   </label>
//   <label>
//     <input type="radio" name="form.radio"><span>ラジオ2</span>
//   </label>
//   <label>
//     <input type="radio" name="form.radio"><span>ラジオ3</span>
//   </label>
// </div>
// <div class="{$modifiers} form-line">
//   <label>
//     <input type="radio" name="form.radio"><span>縦並びラジオ1</span>
//   </label>
//   <label>
//     <input type="radio" name="form.radio"><span>縦並びラジオ1</span>
//   </label>
//   <label>
//     <input type="radio" name="form.radio"><span>縦並びラジオ1</span>
//   </label>
// </div>
// <div class="{$modifiers} form-checkbox">
//   <label>
//     <input type="checkbox" name="form.checkbox"><span>チェックボックス1</span>
//   </label>
//   <label>
//     <input type="checkbox" name="form.checkbox"><span>チェックボックス2</span>
//   </label>
//   <label>
//     <input type="checkbox" name="form.checkbox"><span>チェックボックス3</span>
//   </label>
// </div>
// <div class="{$modifiers} form-line">
//   <label>
//     <input type="checkbox" name="form.checkbox"><span>縦並びチェックボックス1</span>
//   </label>
//   <label>
//     <input type="checkbox" name="form.checkbox"><span>縦並びチェックボックス2</span>
//   </label>
//   <label>
//     <input type="checkbox" name="form.checkbox"><span>縦並びチェックボックス3</span>
//   </label>
// </div>
// <div class="{$modifiers}">
//   <div class="form-select-box">
//     <select>
//       <option value="">セレクト1</option>
//       <option value="">セレクト2</option>
//       <option value="">セレクト3</option>
//     </select>
//   </div>
// </div>
// <div class="form">
//   <div class="form-table">
//     <div class="{$modifiers}">
//       <span class="form-table-title">タイトル</span>
//       <div class="form-table-content">
//         <input type="text" placeholder="text">
//       </div>
//     </div>
//     <div class="{$modifiers}">
//       <span class="form-table-title">タイトル2</span>
//       <div class="form-table-content">
//         <div class="form-select-box">
//           <select>
//             <option value="">セレクト1</option>
//             <option value="">セレクト2</option>
//             <option value="">セレクト3</option>
//           </select>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
//
// Styleguide 3.30

.form {
  .form-table {
    display: table;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;

    &--register {
      margin-bottom: 20px;
      @include mq-sp {
        margin-bottom: 40px;
      }
    }

    @include mq-sp {
      width: 100%;
    }

    .form-control {
      display: table-row;
      @include mq-sp {
        display: block;
        & + .form-control {
          margin-top: 20px;
        }
      }

      .error-message {
        @include mq-sp {
          display: block;
        }
      }
    }

    .form-table-title {
      display: table-cell;
      padding-right: 10px;

      @include mq-sp {
        display: block;
      }

      &__basic {
        min-width: 100px;
        font-weight: 700;
        vertical-align: middle;
      }
    }

    .form-table__title {
      display: table-cell;
      padding-right: 20px;
      min-width: 40px;
      text-align: left;

      @include mq-sp {
        display: block;
        padding-top: 20px;
      }
    }

    .form-table-content {
      display: table-cell;
      position: relative;

      @include mq-sp {
        display: block;
        width: 100%;
      }

      .form-table-content-list li + li {
        margin-top: 20px;
      }

      & + .form-table-content {
        padding-left: 10px;

        @include mq-sp {
          padding: 0;
        }
      }

      &.form-table-content-keyword {
        font-size: 0;
      }

      &-major {
        > input {
          display: block;
        }

        > input + input {
          margin-top: 8px;
        }
      }

      &--register {
        padding: 0 0 20px;
        @include mq-sp {
          padding: 0;
        }

        &-major {
          > input {
            display: block;
          }

          > input + input {
            margin-top: 8px;
          }
        }
      }

      &--convert {
        margin-bottom: 24px;
      }

      &--password {
        padding-top: 20px;
        @include mq-sp {
          padding-top: 0;
        }
      }
    }
  }
}

.form-control {
  label {
    padding: 0;
  }

  input[type="text"],
  input[type="password"],
  textarea {
    border-radius: $bdradius-form;
    box-sizing: border-box;

    @include mq-sp {
      width: 100%;
    }
  }

  select {
    height: 40px;
    margin: 0;
    padding: 0 50px 0 5px;
    appearance: none;
    background: url(/assets/img/selectbox-arrow.png) no-repeat;
    background-position: center right;
    background-size: 40px 40px;
    background-color: $bgcolor-normal;
    border: 1px solid $bdcolor-normal-dark;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
    text-indent: 0.01px;
    text-overflow: "";
  }

  select::-ms-expand {
    display: none; //IE11 support -ms-のprefixは外さないでください。
  }

  .form-select-box {
    display: inline-block;
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: 40px;
      width: 1px;
      height: 40px;
      background-color: $bdcolor-normal-dark;
    }
  }

  .form-select-box-long {
    @include mq-sp {
      width: 100%;
    }
  }

  &.form-line label {
    display: block;
  }

  .is-invalid {
    border: 1px solid $basecolor-error;
  }

  .is-invalid--common {
    border-color: $basecolor-error;
    background: $color-pink-2;

    &:focus,
    &:hover {
      border-color: $basecolor-error;
    }
  }

  .form-table__title.is-required {
    &::after {
      display: inline-block;
      content: "*";
      color: $basecolor-warning;
      margin-left: 2px;
      vertical-align: top;
    }
  }
}

// .form-control {
//
//   table {
//     margin: auto;
//     border-collapse: collapse;
//     border-spacing: 0;
//
//     tr {
//       th {
//         padding-top: 7px;
//       }
//
//       & + tr {
//         th {
//           padding-top: 27px;
//         }
//
//         td {
//           padding-top: 17px;
//         }
//       }
//
//       td  + td {
//         padding-left: 10px;
//       }
//     }
//
//
//     th {
//       padding-right: 20px;
//       font-size: 16px;
//       font-weight: 500; // FIXME webフォント設定後に変更
//       text-align: left;
//       vertical-align: top;
//     }
//   }
// }
.is-require-mark {
  color: $fgcolor-error;
  font-size: 16px;
}

.radio--vertical {
  display: block;

  @include mq-sp {
    margin-top: 10px;
  }
}

.radio--vertical + .radio--vertical {
  @include mq-pc {
    margin-top: 10px;
  }
}

.form-checkbox {
  display: none;

  & + label {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    padding: 5px 18px;
    border-radius: 2px;
    box-sizing: border-box;
    line-height: 24px;

    &::before {
      position: absolute;
      content: "";
      top: 50%;
      left: -10px;
      width: 18px;
      height: 18px;
      margin-top: -10px;
      background: $bgcolor-input-checkbox;
      border: 1px solid $bdcolor-normal;
    }
  }

  &:checked + label {
    &::after {
      content: " ";
      display: block;
      width: 5px;
      height: 10px;
      border: solid $fgcolor-primary-theme;
      border-width: 0 2px 2px 0;
      position: absolute;
      left: -4px;
      top: 13px;
      margin-top: -3px;
      transform: rotate(45deg);
    }
  }
}

.textbox {
  @include mq-sp {
    width: 100%;
  }
}

.popup__message + .textbox {
  margin-top: 10px;
}

.form-error-above {
  width: 100%;
  padding: 16px;
  margin-bottom: 24px;
  box-sizing: border-box;
  font-size: $font-size-basic;
  line-height: 24px;
  background: $color-pink-2;
  padding-left: 14px;
  border-left: 2px solid $color-red;
  color: $color-red;

  &__ {
    &text:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
