.aside-article-list {
  padding: 16px 0;

  &__title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
  }

  &-fade-in {
    transition: opacity 0.8s, transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateY(8px);
    opacity: 0;
  }

  &__list {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    gap: 16px;

    @include mq-pc {
      padding: 16px 0;
    }

    @include mq-sp($lp-breakpoint-sp) {
      flex-direction: column;
      gap: 0;
      border-bottom: 1px solid $color-gray;
    }

    &-item {
      @include mq-sp {
        border-top: 1px solid $color-gray;
      }
    }
  }

  &__link {
    color: $fgcolor-normal;
    transition: 0.05s linear;

    &:hover {
      text-decoration: none;
      color: $fgcolor-normal;
      opacity: 0.8;
    }

    @include mq-sp {
      display: block;
      padding: 24px 16px 16px;
    }
  }

  &__article {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 172px;

    @include mq-sp($lp-breakpoint-sp) {
      flex-direction: row;
      max-width: 100%;
    }

    &-contents {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @include mq-sp($lp-breakpoint-sp) {
        gap: 4px;
      }
    }

    &-thumbnail {
      width: 172px;
      height: 89px;
      object-fit: cover;

      @include mq-sp($lp-breakpoint-sp) {
        width: 64px;
        height: 64px;
      }
    }

    &-title {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5;
      height: 64px;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;

      @include mq-sp($lp-breakpoint-sp) {
        -webkit-line-clamp: 2;
        height: 42px;
      }
    }

    &-description {
      font-size: 14px;
      line-height: 1.5;
      height: 62px;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;

      @include mq-sp($lp-breakpoint-sp) {
        display: none;
      }
    }

    &-published-at {
      font-size: 12px;
      line-height: 1.5;
    }
  }

  &-more {
    display: flex;
    margin-top: 16px;

    &__divider {
      height: 22px;
      box-sizing: border-box;
      border-top: 1px solid $bdcolor-normal;
      flex: 1;

      @include mq-sp {
        border: none;
      }
    }

    &__link {
      font-size: 12px;
      font-weight: bold;
      height: 22px;
      box-sizing: border-box;
      border: 1px solid $bdcolor-normal;
      border-top: none;
      padding: 0 16px;
      color: $fgcolor-normal;
      transition: 0.05s linear;

      &:hover {
        text-decoration: none;
        color: $fgcolor-normal;
        opacity: 0.8;
      }

      @include mq-sp {
        border: none;
      }
    }
  }
}
