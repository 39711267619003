.l-company-list {
  margin-top: 32px;
  @include mq-sp {
    margin-top: 8px;
    margin-left: -15px;
    margin-right: -15px;
  }

  .company-list__item + .company-list__item {
    margin-top: 40px;
    @include mq-sp {
      margin-top: 0;
    }
  }

  .company-list__item:not(:nth-child(3n-2)) {
    margin-left: 30px;
    @include mq-sp {
      margin-left: 0;
    }
  }

  .company-list__item:nth-of-type(-n + 3) {
    @include mq-pc {
      margin-top: 0;
    }
  }

  &__info {
    dd {
      margin-top: 8px;
      @include mq-sp {
        margin-top: 3px;
      }
    }
  }

  &__button {
    margin-top: 8px;
    @include mq-sp {
      margin-top: 0;
    }
  }
}
