// ===================================================================
// Colors 色のHEXはここにのみ書く 他はここを参照すること 他のファイルにも書かない
// ===================================================================
//命名に困ったらここ参照
// http://www.w3schools.com/colors/colors_groups.asp

// ===================================================================
// 2020 リファクタ分の新規カラー
// ===================================================================
$color-brown: #b99348;
$color-brown-2: #f7b52c;
$color-brown-3: #f7a700;

$color-red-2: #f34f6b;
$color-red-3: #ef4752;
$color-red-4: #ee3833;

$color-pink-2: #fbd8d8;
$color-pink-3: #fd6d85;
$color-pink-4: #e16caa;
$color-pink-5: #d9aee8;

$color-yellow-2: #fab617;
$color-lightyellow: #fff3db;
$color-orange: #f7952d;

$color-green-2: #11d496;
$color-green-3: #3cb169;
$color-green-4: #64ca01;
$color-green-5: #5ee7b9;
$color-green-6: rgba(17, 212, 150, 0.1);

$color-blue-2: #0061c2;
$color-blue-3: #0860b8;
$color-blue-4: #0960b8;
$color-blue-5: #46ceda;
$color-blue-6: #4f95bd;
$color-blue-7: #7f99b2;
$color-blue-8: #96b1bf;
$color-blue-9: #87c1e8;
$color-blue-10: #73b6d8;
$color-blue-11: #6892bc;

$color-black-2: #0a0a0a;
$color-black-3: #333333;
$color-black-4: #383333;
$color-black-5: #cccccc;
$color-black-6: #e6e6e6;
$color-black-7: #d8d8d8;
$color-black-8: #999999;
$color-black-9: #f7f7f7;
$color-black-10: #f4f4f4;
$color-black-11: #efefef;
$color-black-12: #e5e5e5;
$color-black-13: #d8e0e7;
$color-black-14: #c6ccd9;
$color-black-15: #737373;
$color-black-16: #555555;
$color-black-17: #808080;
$color-black-18: #666666;

$shadow-2: rgba(0, 0, 0, 0.2);
$shadow-3: rgba(255, 255, 255, 0);
$shadow-5: rgba(0, 0, 0, 0.05);
$shadow-6: rgba(32, 38, 50, 0.8);
$shadow-7: rgba(51, 51, 51, 0.1);
$shadow-8: rgba(0, 0, 0, 0.85);
$shadow-9: rgba(0, 0, 0, 0);
$shadow-10: rgba(0, 0, 0, 0.7);
$shadow-11: rgba(0, 0, 0, 0.25);
$shadow-12: rgba(56, 51, 51, 0.6);
$shadow-13: rgba(237, 84, 110, 0.39);
$shadow-14: rgba(174, 183, 191, 0.1);
$shadow-15: rgba(255, 255, 255, 0.3);
$shadow-16: rgba(0, 0, 0, 0.18);
$shadow-17: rgba(0, 0, 0, 0.4);
$shadow-18: rgba(32, 38, 50, 0.8);
$shadow-19: rgba(9, 96, 184, 0.5);
$shadow-20: rgba(56, 51, 51, 0.8);
$shadow-21: rgba(51, 51, 51, 0.2);
$shadow-22: rgba(1, 1, 1, 0.3);
$shadow-23: rgba(0, 0, 0, 0.6);
$shadow-24: rgba(255, 255, 255, 0.7);
$shadow-25: rgba(127, 127, 127, 0.7);
$shadow-26: rgba(5, 20, 36, 0.4);
$shadow-27: rgba(204, 204, 204, 0.2);
$shadow-28: rgba(0, 0, 0, 0.3);
$shadow-29: rgba(32, 38, 50, 0.8);
$shadow-30: rgba(255, 255, 255, 0.2);
$shadow-31: rgba(255, 255, 255, 0.9);
$shadow-33: rgba(11, 111, 203, 0.7);
$shadow-34: rgba(193, 236, 248, 0.25);
$shadow-36: rgba(255, 255, 255, 0.5);
$shadow-37: rgba(255, 255, 255, 0.35);
$shadow-38: rgba(193, 33, 43, 0.1);
$shadow-39: rgba(0, 0, 0, 0.5);

$bgcolor-module-disabled: rgba(0, 0, 0, 0.03);
$event-card-simple-shadow: rgba(0, 0, 0, 0.05);
$header-shadow: rgba(100, 100, 100, 0.3);
$lp-footer-shadow: rgba(0, 0, 0, 0.15);
$bgcolor-lp-app-header: rgba(255, 255, 255, 0);
$bgcolor-lp-app-header-background: rgba(255, 255, 255, 0.9);
$card-shadow: rgba(51, 51, 51, 0.4);
$event-card-shadow: rgba(0, 0, 0, 0.15);
$event-select-filter-shadow: rgba(5, 20, 36, 0.25);
$c-button-shadow: rgba(0, 0, 0, 0.06);
$select2-highlight-color: rgba(0, 0, 0, 0);
$career-values-card-shadow: rgba(0, 0, 0, 0.13);

// sass-lint:disable no-color-literals
$company-detail-gradient: linear-gradient(
  to bottom,
  rgba(0, 0, 0, 0) 0%,
  rgba(0, 0, 0, 0.1) 20%,
  rgba(0, 0, 0, 0.2) 40%,
  rgba(0, 0, 0, 0.3) 70%,
  rgba(0, 0, 0, 0.6) 100%
);
$gradientcolor-2: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.65));
$gradientcolor-3: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.65));
$gradientcolor-4: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.45));

@function alpha-color($color, $alpha) {
  @return rgba($color, $alpha);
}

// ===================================================================
// 2017 New Colors fgcolorとか気にせず自由に使ってください！
// ===================================================================

$color-uiblue: #00478f;
$color-pink: #ed546e;
$color-darkgray: #9299a0;
$color-bluegray: #aeb7bf;
$color-black: #383333;
$color-darkBlack: #000000;
$color-gray: #e3e5e7;
$color-gray-2: #f8f8f9;
$color-lightgray: #f3f5f7;
$color-white: #ffffff;
$color-red: #eb3a3a;

//旧colors もう使ってない
$color-gainsboro: $color-gray;
$color-midnightblue: #003366;
$color-royalblue: #4c7093;
$color-lightsteelblue: #99adc2;
$color-icelandblue: #ccd6e0;
$color-lightblue: #e5eaef;
$color-aliceblue: #f3f5f7;
$color-darkblue: #001a33;
$color-darkblue-2: #284563;

// ===================================================================
// Opacities
// ===================================================================
$opacity-normal: 0.6;
$opacity-strong: 0.5;
$opacity-week: 0.9;

$opacity-placeholder: 0.25;
$opacity-invisible: 0;
$opacity-reset: 1;

// ===================================================================
// common colors(Color palette. Don't use these color directly for styling)
// ===================================================================

//ロゴ調整までの繋ぎプライマリー
$color-temp-primary: $color-uiblue;

// ===================================================================
// Base colors(Color palette. Don't use these color directly for styling)
// ===================================================================

$basecolor-primary: $color-uiblue;
$basecolor-primary-opacity: rgba($color-uiblue, 0.2);
$basecolor-sp-primary: $color-uiblue;
$basecolor-primary-hover: $color-darkblue;
$basecolor-secondary: #4c7093;
$basecolor-secondary-hover: #264c73;
$basecolor-tertiary: #2e5d8c;
$basecolor-fourth: #5abcdc;
$basecolor-appendix: $color-bluegray;
$basecolor-normal: $color-black;
$basecolor-placeholder: $color-darkgray;
$basecolor-reverse: $color-white;

$basecolor-error: $color-red;
$basecolor-error-hover: darken($color-red, 20%);
$basecolor-warning: $color-red;
$basecolor-valid: $color-uiblue;
$basecolor-shadow: 1px solid rgba(0, 0, 0, 0.05);
$basecolor-dark-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.05);
$basecolor-shadow-color: rgba(0, 0, 0, 0.05);

$lightgray: #eeeeee;

// ===================================================================
// Foreground colors(font-colors)
// ===================================================================
$fgcolor-normal: $color-black;
$fgcolor-disabled: $color-darkgray;
$fgcolor-empty: $color-darkgray;
$fgcolor-disabled-fill: $color-lightgray;
$fgcolor-darkbg: $color-white;
$fgcolor-heading: $color-black;
$fgcolor-supplemental: $color-darkgray;
$fgcolor-text-supplementation: $color-black;
$fgcolor-primary-theme: $color-uiblue;
$fgcolor-secondary-theme: $basecolor-secondary;
$fgcolor-arrow: rgba(46, 93, 140, 0.5);
$fgcolor-button: $color-darkgray;
$fgcolor-button-primary: $color-white;
$fgcolor-button-secondary: #4fd0ea;
$fgcolor-button-disabled: $color-gray;
$fgcolor-button-facebook: $color-white;
$fgcolor-button-apple: $color-white;
$fgcolor-button-login: rgba($color-white, 0.7);

$fgcolor-label-gray: $color-gray;

$fgcolor-link: $color-uiblue;
$fgcolor-link-hover: #4388cc;
$fgcolor-tag: $color-black;
$fgcolor-tag-accent: $color-uiblue;
$fgcolor-primary: #ff955f;
$fgcolor-midpriority: $color-black;
$fgcolor-lowpriority: $color-darkgray;
$fgcolor-warning: $color-red;
$fgcolor-error: $color-red;
$fgcolor-danger: #d11010;
$fgcolor-success: $color-black;
$fgcolor-placeholder: $color-darkgray;

// ===================================================================
// Font Sizes
// ===================================================================
$font-size-larger: 24px;
$font-size-3L: 22px;
$font-size-large: 20px;
$font-size-LL: 18px;
$font-size-L: 17px;
$font-size-basic: 16px;
$font-size-small: 14px;
$font-size-smaller: 12px;
$font-size-XS: 10px;
// ===================================================================
// Background colors
// ===================================================================
$bgcolor-normal: $color-white;
$bgcolor-sp-normal: $color-aliceblue;
$bgcolor-primary: $color-aliceblue;
$bgcolor-secondary: #e6eaef;
$bgcolor-tertiary: #dbe1e8;
$bgcolor-hover: $color-lightblue;

$bgcolor-theme: $color-uiblue;
$bgcolor-dark: #202632;

$bgcolor-header: #1a2732;
$bgcolor-footer: $color-aliceblue;

$bgcolor-button: $color-white;
$bgcolor-button-hover: $color-lightgray;
$bgcolor-button-done: $color-icelandblue;
$bgcolor-button-disabled: $color-darkgray;
$bgcolor-button-primary: $color-uiblue;
$bgcolor-button-primary-hover: $color-darkblue;
$bgcolor-button-secondary: $color-white;
$bgcolor-button-secondary-hover: #c8d7e0;
$bgcolor-button-facebook: #4267b2;
$bgcolor-button-facebook-hover: #4c76cc;
$bgcolor-button-apple: #000000;
$bgcolor-button-apple-hover: #000000;

$bgcolor-button-sns-tw: #d7f0fb;
$bgcolor-button-sns-fb: #d6deea;
$bgcolor-button-sns-line: #d6f1d8;

$bgcolor-overlay: rgba(32, 38, 50, 0.8);

$bgcolor-side-nav: #173352;
$bgcolor-side-nav-hover: #617489;
$bgcolor-side-nav-active: #0e1217;

$bgcolor-table-th: #f8f8f9;
$bgcolor-tablerow-hover: $bgcolor-table-th;

$bgcolor-notice: rgba($color-uiblue, 0.8);
$bgcolor-notice-error: rgba($color-red, 0.8);

$bgcolor-input-require: #f6fafd;
$bgcolor-tag: #fbd7d7;
$bgcolor-input-checkbox: #f9f9f9;
$bgcolor-form-disabled: #f9f9f9;

$bgcolor-article-category: $color-lightgray;

$bgcolor-danger: #ffe5e5;

// ===================================================================
// Border colors
// ===================================================================
$bdcolor-normal: $color-gainsboro;
$bdcolor-normal-dark: $color-darkgray;
$bdcolor-strong: $color-bluegray;

$bdcolor-button: $color-bluegray;
$bdcolor-button-sns: #666666;
$bdcolor-button-sns-tw: #59adeb;
$bdcolor-button-sns-fb: #3b5998;
$bdcolor-button-sns-line: #59b43d;
$bdcolor-button-secondary: $fgcolor-button-secondary;
$bdcolor-hover: #c6d8ed;
$bdcolor-focus: $color-uiblue;
$bdcolor-primary-theme: $color-uiblue;
$bdcolor-reverse: $color-white;
$bdcolor-caution: $color-bluegray;
$bdcolor-tag-accent: $color-uiblue;
$bd-panel: $basecolor-shadow;

// ===================================================================
// Border radiuses
// ===================================================================
$bdradius-normal: 100px;
$bdradius-form: 2px;
$bdradius-circle: 100%;
$bdradius-reset: 0;
$bdradius-event-tag: 4px;

// ===================================================================
// Shadow
// ===================================================================
$boxshadow-normal: 0 2px 5px rgba($color-black, 0.5);
$boxshadow-soft-color: rgba(0, 0, 0, 0.1);
$boxshadow-soft: 0 2px 4px $boxshadow-soft-color;
$boxshadow-panel: $basecolor-dark-shadow;
$textshadow-normal: 2px 2px 3px $color-white, -2px -2px 3px $color-white;
$textshadow-darkbg: 0 2px 4px rgba(0, 0, 0, 0.3);

// ===================================================================
// Easing
// ===================================================================
$duration-normal: 150ms;
$duration-appearance: 300ms;
$duration-scroll: 450ms;
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);

// ===================================================================
// LAYOUT
// ===================================================================
$header-height--pc: 60px;
$header-height--sp: 45px;

