// Overlay
//
// オーバーレイです。
//
// .overlay   - オーバーレイです（デフォルト非表示）
// .overlay.is-active - オーバーレイ表示状態
//
// Markup:
// <div class=".overlay"></div>
//

.overlay {
  @include overlay;
}

@include mq-sp(660px) {
  .overlay-sp {
    @include overlay;
  }
}
