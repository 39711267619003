// sass-lint:disable nesting-depth
.certification {
  @include mq-sp {
    margin-top: 10px;
    padding: 3px 6px;
  }

  &__description {
    padding: 16px;
    background-color: $color-lightgray;
    color: $color-black;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;

    @include mq-sp {
      padding: 8px;
    }

    &__list {
      margin: 16px 0;
      padding-left: 20px;
      list-style-type: disc;

      @include mq-sp {
        margin: 18px 0;
      }

      &__listitem {
        margin-bottom: 5px;
      }
    }
  }

  &__caution {
    width: 100%;
    padding: 16px;
    margin-bottom: 24px;
    background-color: $color-lightgray;
    box-sizing: border-box;
    font-size: $font-size-basic;
    line-height: 24px;

    &-- {
      &error {
        background: $color-pink-2;
        padding-left: 14px;
        border-left: 3px solid $fgcolor-error;
        color: $fgcolor-error;

        &__text-link {
          color: $fgcolor-error;
          text-decoration: underline;

          &:hover {
            color: $fgcolor-error;
            text-decoration: none;
          }
        }
      }
    }
  }

  &__email {
    margin-top: 24px;

    &__title {
      font-size: 16px;
    }

    &__input-text {
      width: 100%;
      margin-top: 9px;
    }
  }

  &__send-email {
    margin-top: 24px;
    text-align: center;

    .btn,
    .btn-secondary,
    .btn-big {
      width: 130px;
      @include mq-sp {
        width: 239px;
      }
    }
  }
}
