.article-author {
  border: 1px solid $color-gray;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  gap: 16px;
}

.article-author-avatar {
  flex-shrink: 0;

  &__img-wrapper {
    display: flex;
    width: 64px;
    height: 64px;
    border: 1px solid $color-gray;
    border-radius: 50%;
    background-color: $bgcolor-normal;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
  }

  &__img {
    height: 64px;
    object-fit: contain;
  }
}

.article-author-profile {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__name {
    font-weight: bold;
  }

  &__description {
    font-size: $font-size-small;
    white-space: pre-wrap;
  }
}
