.l-company {
  &__inner {
    @include mq-sp {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &__section {
    margin-bottom: 56px;
    @include mq-sp {
      margin-bottom: 12px;
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &__section:last-of-type {
    @include mq-sp {
      margin-bottom: 0;
    }
  }

  &__title {
    @include mq-sp {
      margin-bottom: 8px;
    }
  }

  &__title--derivation {
    @include mq-sp {
      margin-bottom: 16px;
    }
  }

  .l-grid__container {
    padding-bottom: 80px;
    @include mq-sp {
      padding-bottom: 0;
    }
  }
}

.company-follow {
  .l-company__title {
    @include mq-sp {
      margin-top: 5px;
    }
  }
}
