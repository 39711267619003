$unit-index: 8px;

.register-select-type {
  color: $fgcolor-darkbg;
  padding: 40px 24px 0 24px;
  @include mq-pc {
    padding: 0;
  }

  &--long {
    @include mq-pc {
      padding: 48px 0;
    }
  }

  &:before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    display: block;
    width: 100vw;
    height: 100vh;
    background: url(/assets/img/register/bg-select-type.jpg) no-repeat 0 0 $color-blue-8;
    background-size: cover;
    z-index: 0;
  }

  &__ {
    &inner {
      z-index: 1;
      position: relative;
      @include mq-pc {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        min-height: 100vh;
        &--fix-ie {
          height: 100px; //for IE 11
        }
      }
    }

    &fade {
      opacity: 0;
      transform: translate3d(0, 30px, 0);

      &--show {
        transition: 0.5s ease-out;
        opacity: 1;
        transform: none;
      }
    }

    &content {
      @include mq-pc {
        width: 480px;
        border: 1px solid $bdcolor-reverse;
        border-radius: 8px;
        padding: 48px;
        box-sizing: border-box;
      }

      &--center {
        text-align: center;
      }
    }

    &title {
      font-size: $font-size-basic;
      display: flex;
      align-items: center;
      font-weight: bold;
      min-height: 24px;
      margin-bottom: 16px;
      @include mq-pc {
        font-size: $font-size-LL;
        margin-bottom: 16px;
      }

      &::before,
      &::after {
        content: "";
        flex: 1;
        display: block;
        height: 1px;
        background: $bgcolor-normal;
        min-width: 16px;
      }

      &::before {
        margin-right: 16px;
      }

      &::after {
        margin-left: 16px;
      }
    }

    &text {
      text-align: center;
      margin-bottom: 24px;
      font-weight: bold;
      line-height: 24px;
      font-size: $font-size-basic;
      @include mq-pc {
        font-size: $font-size-LL;
        margin-bottom: 32px;
      }
    }

    &btn {
      text-align: center;
      display: block;
      box-sizing: border-box;
      height: 96px;
      line-height: 94px;
      font-size: $font-size-basic;
      font-weight: 300;
      border-radius: 0;
      border: 1px solid $bdcolor-reverse;
      color: $fgcolor-darkbg;
      transition: 0.1s ease-out;
      margin-bottom: 24px;

      &:hover {
        text-decoration: none;
        background: $bgcolor-normal;
        color: $fgcolor-link-hover;
        transition: 0.1s ease-out;
      }

      @include mq-pc {
        font-size: $font-size-LL;
      }

      &-- {
        &common {
          border-radius: 28px;
          display: inline-block;
          border-width: 2px;
          padding: 0 24px;
          font-size: 16px;
          appearance: none;
          background: none;
          height: 45px;
          line-height: 43px;
          min-width: 168px;
        }

        &register {
          @include mq-pc {
            margin-bottom: 32px;
          }
        }

        &next {
          @include mq-pc {
            margin-bottom: 0;
          }
        }

        &wrapper {
          text-align: center;
        }
      }
    }

    &logo {
      width: 158px;
      height: 44px;
      display: block;
      margin: 0 auto 24px auto;
      line-height: 48px;
      @include mq-pc {
        margin-bottom: 32px;
      }
    }
  }

  &-mail__button {
    @extend .btn-secondary;
    font-weight: bold;
    width: 295px;
    max-width: 100%;

    &:hover {
      text-decoration: none;
    }
  }

  .select2-container {
    color: $fgcolor-normal;
  }
}

.btn-wrapper {
  margin-bottom: 16px;
}

.register-select-login-link {
  color: $fgcolor-darkbg;
  background-color: transparent;
  font-weight: bold;
  border: none;
  transition: all 300ms linear;

  &:hover {
    color: $fgcolor-link-hover;
    transition: all 300ms linear;
  }

  @include mq-sp {
    text-decoration-line: underline;
    font-weight: 500;
  }
}

.select-univ-subtitle {
  font-weight: bold;
  font-size: $font-size-small;
  line-height: $unit-index * 3;
  margin: 0;
  @include mq-pc {
    font-size: $font-size-basic;
    line-height: $unit-index * 4;
  }
}

.select-univ-text {
  font-size: $font-size-small;
  line-height: $unit-index * 3;
  margin: 0 0 $unit-index * 2 0;
  @include mq-pc {
    font-size: $font-size-small;
    line-height: $unit-index * 2;
    margin: 0 0 $unit-index 0;
  }

  &__link {
    display: inline-block;
    color: $fgcolor-darkbg;
    text-decoration: underline;
  }

  &.is-center {
    text-align: center;
    margin: 0;
  }

  &__description {
    @include mq-pc {
      margin: 0 0 $unit-index * 4 0;
    }

    @include mq-sp {
      margin: 0 0 $unit-index * 3 0;
    }
  }

  &__registration {
    margin: 0 0 $unit-index 0;
  }

  &__btn {
    @include mq-pc {
      margin: 0 0 $unit-index * 4 0;
    }
  }
}

.select-univ-text-strong {
  @extend .select-univ-text;
  font-weight: bold;
}

.select-univ-title {
  font-size: $font-size-basic;
  line-height: $unit-index * 3;
  font-weight: bold;
  margin: 0 0 $unit-index 0;

  &__with-account {
    @include mq-pc {
      line-height: $unit-index * 3;
      margin: 0;
    }
  }

  &__without-account {
    line-height: $unit-index * 3;
    margin: 0 0 $unit-index 0;
  }
}

.select-univ-group {
  &__heading {
    font-size: $font-size-LL;
    font-weight: bold;
    padding-left: 8px;
    background-color: $shadow-30;
    height: 26px;
    line-height: 26px;
    @include mq-sp {
      margin: 0 -24px;
      font-size: $font-size-basic;
      padding-left: 24px;
    }
  }
}

.select-univ-separator {
  border-bottom: 1px solid $shadow-30;
  margin-bottom: 48px;
  @include mq-sp {
    margin: 16px 0 16px 0;
  }
}

.select-univ-list {
  &__ {
    &link {
      height: $unit-index * 7;
      display: flex;
      align-items: center;

      &:hover {
        text-decoration: none;
      }

      @include mq-pc {
        padding: 0;
        height: $unit-index * 14;
        box-sizing: border-box;
        &:hover {
          opacity: 0.5;
        }
      }
    }

    &icon {
      display: block;
      flex-basis: 28px;
      height: 32px;
      background-size: 28px 32px;
      margin: 0 $unit-index 0 0;
      @include mq-pc {
        flex-basis: 55px;
        height: 64px;
        background-size: 55px 64px;
        font-size: $font-size-LL;
        margin: 0 $unit-index * 3 0 0;
      }

      &--global {
        background-image: url("/assets/img/logo-univ-shield/logo-global-u.png");
      }
    }

    &title {
      font-size: $font-size-basic;
      color: $fgcolor-darkbg;
      font-weight: bold;
      flex: 1;
      @include mq-pc {
        font-size: $font-size-LL;
      }
    }
  }
}

.select-univ-header__text {
  margin: 0 0 $unit-index * 2 0;
}

.select-univ-inquiry {
  margin-bottom: 24px;

  &-text {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
  }
}

.confirm-univ-title {
  margin-bottom: $unit-index * 3;
  background: $bgcolor-normal;
  padding: $unit-index * 8 0;
  text-align: center;
  border-radius: 5px;
  @include mq-pc {
    margin: 0 0 $unit-index * 5 0;
    padding: $unit-index * 9 0;
  }
}

.confirm-univ-logo {
  height: $unit-index * 6;
  width: auto;
  line-height: 0;
  font-size: 0;
  @include mq-pc {
    height: $unit-index * 9;
  }
}

.note-wrapper {
  margin-bottom: 48px;
  @include mq-sp {
    margin-bottom: 24px;
  }
}

.confirm-univ-note {
  font-size: $font-size-small;
  line-height: $unit-index * 3;
  color: $fgcolor-darkbg;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  @include mq-pc {
    font-size: $font-size-basic;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.confirm-univ-btn-back {
  text-decoration: underline;
  color: $fgcolor-darkbg;
  background-color: transparent;
  font-weight: bold;
  border: none;
  transition: all 300ms linear;

  &:hover {
    color: $fgcolor-link-hover;
    transition: all 300ms linear;
  }
}

.btn-apple--confirm-univ {
  margin: 0 auto $unit-index;
}

.register-select-type-caution-text {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 16px;
}

.register-select-type-caution-label {
  color: $fgcolor-darkbg;
  font-weight: bold;
  min-height: 32px;
  line-height: 32px;
  display: block;

  + .error-message {
    color: $fgcolor-darkbg;
    background: $bgcolor-notice-error;
    padding: 8px;
    margin-bottom: 8px;
    font-weight: bold;
    display: inline-block;
    font-size: $font-size-small;
    border-radius: 6px;
    line-height: 1;

    &:empty {
      display: none;
    }
  }
}

.invitation-univ-pulldown {
  margin-bottom: 24px;
}

.register-inquiry,
.login-form {
  margin-top: 24px;

  &__title {
    font-size: 14px;
  }

  &__link {
    color: $color-white;

    @include mq-sp {
      font-weight: 500;
      text-decoration-line: underline;
    }

    @include mq-pc {
      font-weight: 700;
    }
  }
}

.register-inquiry {
  margin-bottom: 24px;
}

.login-form {
  margin-bottom: 32px;
}


