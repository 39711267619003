/*記事リスト。全てこちらのデザインに統一されたタイミングで_article-listに統一し、このファイルは消す予定*/

.post-list {
  border-top: 1px solid $bdcolor-normal;
  border-bottom: 1px solid $bdcolor-normal;
  margin: 0 -15px;
  @include mq-pc {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 960px;
    margin: 0 auto;
    border: none;
    &::after {
      content: "";
      display: block;
      flex-basis: 270px;
    }
  }
  @include mq-middle {
    justify-content: space-around;
  }

  &__ {
    &item {
      border-bottom: 1px solid $bdcolor-normal;
      box-sizing: border-box;

      &:last-child {
        border: none;
      }

      @include mq-pc {
        flex-basis: 270px;
        margin: 0 0 64px 0;
        border: none;
      }
    }

    &link {
      display: flex;
      padding: 16px;
      text-decoration: none;
      color: $fgcolor-normal;
      transition: 0.05s linear;

      &:hover {
        text-decoration: none;
        color: $fgcolor-normal;
        opacity: 0.8;
      }

      @include mq-pc {
        display: block;
        padding: 0;
        &:hover {
          .post-list__thumbnail {
            animation: post-list-bounce 1.7s ease-out infinite;
          }
        }
      }
    }

    &thumbnail {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 64px;
      flex-basis: 64px;
      display: block;
      margin: 0 16px 0 0;
      background-image: url("/assets/img/company-article-no-main-sp.jpg");
      @include mq-pc {
        background-image: url("/assets/img/company-article-no-main.jpg");
        height: 140px;
        width: 100%;
        margin: 0 0 24px 0;
        display: block;
      }

      &--company {
        background-image: url("/assets/img/company-no-logo.svg");
        border-radius: 32px;
        margin: 0 16px 0 0;
        box-sizing: border-box;
        border: 1px solid $bdcolor-normal;
        @include mq-pc {
          display: none;
        }
      }

      &--cover {
        @include mq-sp {
          display: none;
        }
      }
    }

    &text {
      flex: 1;

      &--company {
        @include mq-sp {
          display: flex;
          flex-direction: column-reverse;
        }
      }
    }

    &title {
      font-size: 15px;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 3px;
      overflow: hidden;
      max-height: 48px;
      @include mq-pc {
        font-size: $font-size-LL;
        min-height: 40px;
        line-height: 24px;
        height: 48px;
        margin-bottom: 8px;
      }
    }

    &description {
      display: none;
      @include mq-pc {
        display: block;
        font-size: $font-size-small;
        line-height: 24px;
        height: 72px;
        margin-bottom: 8px;
        overflow: hidden;
      }
    }

    &date {
      font-size: $font-size-XS;
      display: block;
      line-height: 16px;
      margin-top: 0;
      @include mq-pc {
        font-size: $font-size-smaller;
      }

      &--company {
        @include mq-pc {
          margin-bottom: 16px;
        }
      }
    }
  }

  &-- {
    &under {
      margin-bottom: 32px;
    }
  }
}

.post-list-company {
  @include mq-pc {
    display: flex;
    align-items: center;
  }

  &__ {
    &logo {
      display: block;
      box-sizing: border-box;
      border: 1px solid $bdcolor-normal;
      margin: 0;
      background-position: center;
      @include mq-pc {
        height: 32px;
        background-size: 32px 32px;
        flex-basis: 32px;
        border-radius: 16px;
        margin-right: 8px;
      }
      @include mq-sp {
        display: none;
      }
    }

    &name {
      flex: 1;
      font-size: $font-size-smaller;
      line-height: 24px;
      font-weight: bold;
    }
  }
}

@keyframes post-list-bounce {
  0%,
  10%,
  20%,
  30%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  5% {
    transform: translate3d(0, -12px, 0);
  }
  15% {
    transform: translate3d(0, -6px, 0);
  }
  25% {
    transform: translate3d(0, -2px, 0);
  }
}
