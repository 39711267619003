.l-card-company,
.l-card-company--slider {
  margin-top: 40px;

  .card-company__item + .card-company__item {
    margin-top: 50px;
  }

  .card-company__item:not(:nth-child(3n-2)) {
    margin-left: 75px;
  }

  .card-company__item:nth-of-type(-n + 3) {
    @include mq-pc {
      margin-top: 0;
    }
  }

  &__info {
    margin-top: 30px;

    dd {
      margin-top: 16px;
    }

    .card-company__person + .card-company__person {
      margin-left: 10px;
    }
  }

  &__button {
    margin-top: 16px;
  }
}

.company-follow {
  .l-card-company {
    @include mq-sp {
      margin-top: -12px;
    }
  }
}

.l-card-company {
  @include mq-sp {
    margin-top: 10px;
  }

  .card-company__item + .card-company__item {
    @include mq-sp {
      margin-top: 0;
    }
  }

  .card-company__item:not(:nth-child(3n-2)) {
    @include mq-sp {
      margin-left: 0;
    }
  }

  &__info {
    @include mq-sp {
      margin-top: 0;
      margin-left: 8px;
    }

    dd {
      @include mq-sp {
        margin-top: 8px;
      }
    }
  }

  &__button {
    @include mq-sp {
      margin-top: 0;
    }
  }
}

// slider
.l-card-company--slider {
  @include mq-sp {
    margin-top: 8px;
  }

  .card-company--slider__item + .card-company--slider__item {
    margin-left: 25px;
    @include mq-sp {
      margin-top: 0;
      margin-left: 8px;
    }
  }

  &__info {
    margin-top: 30px;
    @include mq-sp {
      margin-top: 16px;
    }

    dd {
      margin-top: 16px;
      @include mq-sp {
        margin-top: 8px;
      }
    }
  }

  .card-company--slider__person + .card-company--slider__person {
    @include mq-pc {
      margin-left: 5px;
    }
  }

  &__button {
    @include mq-sp {
      margin-top: 16px;
    }
  }
}

.l-card-company--slider + .l-company__title {
  margin-top: 60px;
}

//OB一覧の検索結果
.l-card-company--result {
  margin-top: 15px;
}
