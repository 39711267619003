.profile-edit-form-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  line-height: 1;

  @include mq-sp {
    line-height: 1.5;
  }

  > li {
    margin-top: 5px;
    margin-right: 10px;
    margin-bottom: 5px;

    &:before {
      content: "#";
    }

    @include mq-sp {
      margin-top: 2.5px;
      margin-right: 5px;
      margin-bottom: 2.5px;
    }
  }
}

.profile-edit-form-tag-add-btn {
  @extend %text-action-style;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none;
  width: 64px;

  @include mq-sp {
    margin-top: 2.5px;
    margin-bottom: 2.5px;
  }
}

.profile-edit-form-table {
  &-title {
    vertical-align: top;
    font-weight: bold;
    padding: 10px 0;
    text-align: right;
    line-height: 1;

    @include mq-sp {
      padding: 0;
      line-height: 1.5;
    }
  }

  &-content {
    padding-bottom: 10px;

    @include mq-sp {
      padding-bottom: 0;
    }
  }
}

.profile-edit-form-skill-table {
  .form-control {
    & + .form-control .form-table-content {
      padding-top: 5px;
    }
  }
}

.profile-edit-form {
  &-tag-contents {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 480px;
    flex-wrap: wrap;
    line-height: 1;

    @include mq-sp {
      line-height: 1.5;
    }

    .profile-edit-form-tag-empty-text {
      margin-top: 5px;

      & + .profile-edit-form-tag-add-btn {
        margin-top: 10px;
        margin-left: 10px;
      }
    }
  }

  &-tag-empty-text {
    color: $fgcolor-lowpriority;

    &.has-list {
      display: none;
    }

    @include mq-sp {
      margin-top: 5px;
    }
  }

  &-link {
    margin-top: 10px;
    text-align: center;
    font-size: $font-size-smaller;
  }

  .profile-edit-form-btn {
    margin-top: 20px;
  }
}

.profile-edit-form-text-area {
  width: 480px;
  height: 150px;
  resize: vertical;

  @include mq-sp {
    margin-top: 5px;
    width: 100%;
  }

  &.has-error {
    border: 1px solid $color-red;
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
  }

  &-error {
    visibility: hidden;

    &.has-error {
      visibility: visible;
      color: $color-red;
    }

    @include mq-sp {
      width: 50%;
    }
  }

  &-count {
    text-align: right;
    font-size: 15px;
    color: $color-darkgray;

    &.has-error {
      color: $color-red;
    }

    @include mq-sp {
      width: 50%;
    }
  }
}

.profile-edit-sample-text {
  box-sizing: border-box;
  height: 180px;
  width: 480px;
  margin-top: 10px;
  padding: 10px 20px;
  border: 1px solid $color-gray;
  background-color: $color-lightgray;
  font-size: $font-size-smaller;
  overflow-y: scroll;

  @include mq-sp {
    width: 100%;
  }
}
