.form-table {
  & + .profile-edit-title {
    margin-top: 40px;
  }
}

.profile-edit-title {
  width: 100%;
  margin-bottom: 40px;
  color: $basecolor-primary;
  border-bottom: 1px solid $basecolor-primary;
}
