$event-line-height: 1.6;

// company-detail.scss で @extend .event-inner されているので、うっかり削除しないように注意
.event-inner {
  @include clearfix;

  .card {
    position: relative;
    line-height: $event-line-height;

    .event-list-title {
      margin-top: 10px;
      max-height: $font-size-larger * $event-line-height * 2;
      overflow: hidden;

      @include mq-sp {
        margin-top: 0;
        font-size: $font-size-L;
        font-weight: bold;
        max-height: $font-size-L * $event-line-height * 2;
      }
    }

    .event-list-explain {
      margin-top: 5px;
      max-height: $font-size-basic * $event-line-height * 2;
      overflow: hidden;

      @include mq-sp {
        display: none;
      }
    }

    .event-list-info {
      @include clearfix;
      margin-top: 5px;
      padding-top: 5px;
      position: relative;

      .event-list-info-capacity {
        position: absolute;
        top: 10px;
        right: 0;
        padding-top: 4px;
        padding-left: 15px;

        @include mq-sp {
          top: 80%;
        }
      }
    }

    .event-company {
      @include mq-sp {
        margin-top: 10px;
      }
    }

    .event-list-status-tag {
      position: relative;
      top: -5px;
      margin-right: 5px;
    }
  }
}

.event-page-wrapper {
  .l-grid__container {
    .submenu-horizontal {
      left: -15px;
      width: calc(100% + 30px);

      @include mq-pc {
        display: none;
      }
    }

    @include mq-pc {
      width: 100%;
    }
  }
}

.card-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.search input.focus[type="text"] {
  border: 1px solid $bdcolor-focus;
}
