.breadcrumb {
  display: flex;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.2;

  &.report-detail_no-session {
    @include mq-pc {
      max-width: 720px;
    }
    // tablet
    @media screen and (min-width: 641px) and (max-width: 660px) {
      max-width: inherit;
      display: inherit;
    }
  }

  &__ {
    &item {
      display: flex;
      align-items: center;
      overflow: hidden;
      flex-shrink: 0;

      & + li {
        &:before {
          content: '>';
          margin: 0 8px;
          color: $color-darkgray;
          position: relative;
          bottom: 1px;
        }
      }

      &:last-child {
        flex-shrink: 1;
      }
    }

    &label {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
