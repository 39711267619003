.l-login-body {
  background-color: $bgcolor-primary;

  .login-main {
    padding-top: 50px;

    @include mq-sp {
      padding-top: 20px;
    }
  }

  .l-main {
    @include mq-sp {
      margin-top: 0;
    }
  }
}
