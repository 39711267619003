// Buttons
//
// Styleguide 3.2.0

// 普通のボタン
//
// .btn - 普通のボタンです。ホバーで色が変わります。
// .btn-primary - プライマリボタンです。
// .btn-secondary - セカンダリボタンです。
// .btn-acknowledged - 対応済みにしている時用のボタンです。
//
// Markup:
// <button class="{$modifiers}">普通のボタン</button>
// <button class="{$modifiers} btn-big">大きいボタン</button>
// <a href="#" class="{$modifiers} btn-big">aタグでもボタン</a>
// <button class="{$modifiers} btn-small">小さいボタン</button>
//
// Styleguide 3.2.1
.btn {
  @include btn;
  @include btn-states;
}

.btn-primary {
  @include btn;
  @include btn-states;
  color: $fgcolor-button-primary;
  background-color: $basecolor-primary;
  border: none;
  line-height: 45px;

  &:hover {
    color: $fgcolor-button-primary;
    background-color: $basecolor-primary-hover;
    text-decoration: none;
  }

  &[disabled] {
    color: $fgcolor-button-disabled;
    background-color: $bgcolor-button-disabled;
    border: 0;
    cursor: default;
  }

  &.is-active {
    border: none;
    background-color: $basecolor-primary-opacity;
    color: $basecolor-primary;
  }
}

.btn-secondary {
  @include btn;
  @include btn-states;
  color: $basecolor-primary;
  background-color: $bgcolor-button-secondary;
  border: 1px solid $basecolor-primary;
  line-height: 43px;

  .fa-angle-left {
    font-size: 26px;
    position: relative;
    top: 3px;
    margin-right: 2px;
  }

  @include mq-pc {
    &:hover {
      color: $basecolor-primary;
      background-color: $bgcolor-button-secondary-hover;
    }
  }

  &[disabled] {
    color: $fgcolor-button-disabled;
    background-color: $bgcolor-button-disabled;
    border: 0;
    cursor: default;
  }

  &.is-active {
    border: none;
    background-color: $basecolor-primary;
    color: $fgcolor-button-primary;
  }

  &--mini-padding {
    padding-left: 15px;
    padding-right: 15px;
  }

  &--message {
    height: 34px;
    line-height: 33px;
    width: 280px;
    font-weight: bold;
    margin: 8px auto 0 auto;
    display: block;
    font-size: $font-size-small;
    transition: none;

    &:hover {
      text-decoration: none;
      background: $bgcolor-theme;
      transition: none;
      color: $fgcolor-darkbg;
    }
  }
}

.btn-secondary--vertical-S {
  @extend .btn-secondary;
  @extend .btn-big;
  height: 42px;
  font-size: $font-size-small;

  .fa-search {
    font-size: $font-size-L;
  }
}

.btn-acknowledged {
  @include btn;
  color: $fgcolor-button-disabled;
  background-color: $bgcolor-button-disabled;
  border: 0;
  cursor: default;
  @include mq-pc {
    &:hover {
      color: $fgcolor-button-disabled;
      background-color: $bgcolor-button-disabled;
    }
  }
}

.btn-primary-theme {
  @include btn;
  color: $fgcolor-primary-theme;
  border: 1px solid $fgcolor-primary-theme;
  background-color: $bgcolor-normal;

  @include mq-pc {
    &:hover {
      color: $fgcolor-button-secondary;
      background-color: $bgcolor-button-secondary-hover;
    }
  }
}

// SNS Buttons
//
// SNS連携用ボタンです。
//
// .btn-facebook - Facebookでログインするボタンです。
//
// Markup:
// <button class="{$modifiers}">Facebookでログイン</button>
// <a href="#" class="{$modifiers}">aタグでもFacebookでログイン</a>
//
// Styleguide 3.2.2

%sns-btn {
  @include btn;
  position: relative;
  line-height: 19px;
  font-weight: bold;
  width: 295px;

  &:before {
    content: "";
    display: inline-block;
    position: relative;
  }
}

.btn-apple {
  @extend %sns-btn;
  color: $fgcolor-button-apple;
  background-color: $bgcolor-button-apple;
  border: 1px solid $bgcolor-button-apple;
  transition: none;

  &:before {
    background: url(/assets/img/btn-bg-apple-midium.svg) no-repeat;
    width: 31px;
    height: 43px;
    vertical-align: middle;
  }

  @include mq-pc {
    &:hover {
      color: $color-white;
      background-color: $bgcolor-button-apple-hover;
    }
  }
}

.btn-facebook {
  @extend %sns-btn;
  color: $fgcolor-button-facebook;
  background-color: $bgcolor-button-facebook;
  border: 1px solid $bgcolor-button-facebook;
  padding-top: 11px;

  &:before {
    background: url(/assets/img/btn-bg-facebook-circle.svg) no-repeat;
    background-size: 100%;
    margin-right: 6px;
    width: 16px;
    height: 16px;
    top: 2px;
  }

  @include mq-pc {
    &:hover {
      background-color: $bgcolor-button-facebook-hover;
      color: $fgcolor-button-facebook;
      border: 1px solid $bgcolor-button-facebook-hover;
    }
  }
}

// もっと見るButton
//
// もっと見るボタンです。
//
// .btn-more - もっと見るボタンです。
//
// Markup:
// <div class="more-text-area">
//   <div class="more-text-area-content">
//     <p>
//       長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章
//       長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章
//       長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章
//       長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章長い文章
//     </p>
//     <div class="more-text-area-content-overlay"></div>
//   </div>
//   <div class="{$modifiers}">
//     <a href="#">もっとみる</a>
//   </div>
// </div>
//
// Styleguide 3.2.10
.more-text-area {
  .more-text-area-content {
    overflow: hidden;
    position: relative;
    // C側で「もっと見る」を一旦消しているので、height指定しない
    // height: 144px;
    line-height: 1.8;
    transition-duration: 300ms;

    .more-text-area-content-overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient($shadow-3, $bgcolor-normal);
    }
  }

  .btn-more {
    position: relative;
    top: -5px;
    text-align: center;
  }
}

// キーワードボタン
//
// キーワード追加ボタンです
//
// .btn-keyword - キーワード選択用のボタンです
//
// Markup:
// <div class="keyword-area">
//   <label class="{$modifiers}">
//     <input type="checkbox" /><span>キーワード</span>
//   </label>
//   <label class="{$modifiers}">
//     <input type="checkbox" /><span>キーワード</span>
//   </label>
// </div>
//
// Styleguide 3.2.3

.btn-keyword {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 20px;
  padding: 0;

  input {
    display: none;

    & + span {
      display: inline-block;
      padding: 0 15px;
      height: 45px;
      line-height: 45px;
      border: 1px solid $bdcolor-button;
      border-radius: 5px;
      color: $bdcolor-button;
      cursor: pointer;
      font-size: $font-size-basic;
      font-weight: 300; // FIXME webフォント適用後に変える
      transition: background-color 300ms linear;
      user-select: none;

      &:before {
        content: "";
        display: inline-block;
        position: relative;
        top: 4px;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        background: url(/assets/img/btn-keyword-plus.png) no-repeat;
        background-size: 100%;

        @include mq-sp {
          top: 4px;
          width: 16px;
          height: 17px;
          margin-right: 4px;
        }
      }

      @include mq-pc {
        &:hover {
          background-color: $bgcolor-button-hover;
          transition: background-color 300ms linear;
        }
      }
    }

    &:checked + span {
      border: 1px solid $basecolor-primary;
      color: $fgcolor-darkbg;
      background-color: $basecolor-primary;

      &:before {
        content: "";
        display: inline-block;
        position: relative;
        top: 6px;
        width: 22px;
        height: 22px;
        margin-right: 10px;
        background: url(/assets/icon/check-icon-white.png) no-repeat;
        background-size: 100%;

        @include mq-sp {
          margin-right: 5px;
          top: 4px;
          width: 15px;
          height: 17px;
        }
      }

      @include mq-pc {
        &:hover {
          background-color: $basecolor-primary-hover;
          transition: background-color 300ms linear;
        }
      }
    }
  }

  @include mq-sp {
    margin-right: 8px;
    margin-bottom: 8px;

    input {
      & + span {
        height: 36px;
        padding: 0 5px;
        line-height: 37px;
        background-color: $bgcolor-normal;
        font-size: 13px;
      }
    }
  }
}

// 項目追加ボタン
//
// 項目追加ボタンです
//
// .btn-add - 項目追加用のボタンです
//
// Markup:
// <button class="{$modifiers}">追加</button>
//
// Styleguide 3.2.4

.btn-add {
  padding: 0;
  background: none;
  border: none;
  color: $basecolor-primary;
  outline: none;
  transition: color 300ms linear;

  @include mq-pc {
    &:hover {
      color: $basecolor-primary-hover;
      text-decoration: underline;
      transition: color 300ms linear;
    }
  }

  &:before {
    content: "＋";
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 5px;
    font-size: 26px;
    font-weight: bold;
  }

  &--convert {
    background: $color-lightgray;
    text-align: center;
    border-radius: 3px;
    width: 100%;
    height: 32px;
    line-height: 32px;
    color: $color-uiblue;
    transition: none;
    margin-bottom: 20px;
    font-size: $font-size-small;
    font-weight: bold;

    &:hover {
      text-decoration: none;
      opacity: 0.7;
      color: $color-uiblue;
      transition: none;
    }

    &:before {
      font-size: $font-size-small;
      font-weight: bold;
      position: static;
    }
  }
}

//重要なボタン
.button--primary {
  @include button;
  @include btn-states;
  line-height: 38px;
  color: $color-white;
  background-color: $color-pink;
  border: 2px solid $color-pink;
  font-weight: bold;
  font-size: $font-size-basic;
  transition: 0.1s linear;
  @include mq-pc {
    &:hover {
      color: $color-pink;
      background-color: $color-white;
      transition: 0.1s linear;
    }
  }

  &.is-loading {
    border-color: $color-darkgray;
  }

  &.is-apply-stopped,
  &.is-warning {
    background-color: $color-bluegray;
    border-color: $color-bluegray;
    color: $color-gray;
  }

  &.is-done {
    background-color: $color-pink;
    border: 2px solid $color-pink;
    color: $color-white;
    opacity: 0.5;
  }
}

//アクセントボタン
.button--secondary {
  @include button;
  @include btn-states;
  color: $basecolor-primary;
  background-color: $bgcolor-button-secondary;
  border: 1px solid $basecolor-primary;
  line-height: 45px;
  @include mq-pc {
    &:hover {
      color: $basecolor-primary;
      background-color: $bgcolor-button-secondary-hover;
    }
  }
}

//汎用的なボタン
.button--normal {
  @include button;
  line-height: 43px;
  color: $fgcolor-button;
  background-color: $bgcolor-button;
  border: 1px solid $bdcolor-button;
  font-size: $font-size-basic;
  transition: 0.1s linear;

  i {
    font-size: 20px;
    vertical-align: -1px;
  }

  &:hover {
    @include mq-pc {
      transition: 0.1s linear;
      color: $fgcolor-lowpriority;
      background-color: $lightgray;
      text-decoration: none;
    }
  }

  &.is-active {
    border: none;
    background-color: $basecolor-primary-opacity;
    color: $basecolor-primary;
    cursor: default;
  }
}

h1 + .button--socials {
  margin-top: 10px;
}

.button--socials {
  > div,
  > iframe {
    display: inline-block;
    vertical-align: bottom;
  }
}

.btn-appstore {
  width: 130px;
  height: 38px;
  line-height: 1;
}
