@import "assessment_variable";

.assessment-ranking-step-card {
  background-color: $assessment-bg-blue;
  border-radius: 10px;
  box-shadow: 0 4px 20px $shadow-19;
  padding: 16px;
  position: relative;

  &__ {
    &title {
      color: $fgcolor-darkbg;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
    }
  }
}

.assessment-ranking-step-card-contents {
  display: flex;
  margin-bottom: 16px;

  div:first-child {
    margin-right: 14px;
  }
}

.assessment-ranking-step-card-list-title {
  background-color: $bgcolor-normal;
  border-radius: 9px;
  color: $color-uiblue;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 8px;
  padding: 4px 0;
  text-align: center;
  width: 84px;
}

.assessment-ranking-step-card-list {
  padding-top: 4px;

  &__ {
    &item {
      color: $fgcolor-darkbg;
      font-size: 14px;
      line-height: 1;
      padding-left: 28px;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 14px;
      }

      &:before {
        background: url(/assets/img/assessment/no-check.png) no-repeat center;
        background-size: 100%;
        content: "";
        height: 20px;
        left: 0;
        position: absolute;
        top: -4px;
        width: 20px;
      }

      &--complete {
        &:before {
          background: url(/assets/img/assessment/check.png) no-repeat center;
          background-size: 100%;
        }
      }
    }
  }
}

.assessment-ranking-step-card-img {
  height: 134px;

  &__item {
    width: 142px;
    height: 134px;
  }
}

%card-btn {
  background-color: $bgcolor-normal;
  border: 1px solid $bdcolor-reverse;
  border-radius: 20px;
  color: $color-uiblue;
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  height: 38px;
  margin: 0 auto;
  padding: 12px 0;
  text-align: center;
  width: 202px;
  // sass-lint:disable no-vendor-prefixes
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }
}

.assessment-ranking-step-card-btn {
  margin-bottom: 12px;
  width: 100%;

  &__item {
    @extend %card-btn;
  }
}

.assessment-ranking-step-card-note {
  width: 100%;

  &__text {
    color: $fgcolor-darkbg;
    font-size: 12px;
    line-height: 1;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.assessment-ranking-step-card-overlay {
  background-color: $shadow-20;
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__ {
    &title {
      color: $fgcolor-darkbg;
      font-size: 20px;
      font-weight: bold;
      line-height: 1;
    }

    &description {
      color: $fgcolor-darkbg;
      font-size: 14px;
      line-height: 1.5;
      margin-top: 16px;
    }

    &btn {
      @extend %card-btn;
      margin-top: 24px;
    }
  }
}
