.event-list {
  float: right;
  width: 700px;

  .event-list-mv-wrapper {
    height: round(400 / 960 * (700px - 30px));
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .event-list-mv {
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  .evnet-list-mv-current-status:before {
    z-index: 1;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $shadow-26;
  }

  .event-list-info {
    border-top: 1px solid $bdcolor-normal;

    .event-list-info-participation {
      .event-list-info-participation-date {
        display: inline-block;
        margin-left: 10px;
      }

      .event-list-info-participation-date + .event-list-info-participation-date {
        margin-left: 0;
      }
    }

    .event-list-info-venue {
      max-width: 500px;
    }

    .event-list-info-location {
      color: $color-darkgray;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .event-list-info-participation-location {
        margin-left: 18px;
      }

      .icon-location-pin {
        display: none;
      }
    }

    .event-list-info-capacity {
      color: $fgcolor-supplemental;

      .event-list-info-capacity-label {
        display: inline;
      }

      .event-list-info-capacity-number {
        color: $fgcolor-normal;
        font-size: 30px;
      }
    }
  }

  .event-list-empty {
    width: 100%;
    height: 600px;
    display: table;
    border: $basecolor-shadow;

    span {
      display: table-cell;
      background-color: $bgcolor-normal;
      vertical-align: middle;
      text-align: center;
      font-size: 16px;
    }
  }
}

.event-exit-tag {
  padding: 5px 25px;
  border: 1px solid $basecolor-warning;
  color: $basecolor-warning;
  font-size: 12px;
}

@include mq-sp(660px) {
  .breadcrumbs {
    display: none;
  }

  .event-inner .event-list {
    float: none;
    width: auto;

    .event-list-mv-wrapper {
      background-size: cover;
      margin: -15px -15px 0;
      height: calc((100vw - 30px) * 400 / 960);

      + * {
        margin-top: 5px;
      }

      .event-list-mv {
        position: static;
        transform: none;
        max-width: 100%;
        height: auto;
        left: auto;
        top: auto;
        min-width: 0;
        min-height: 0;
      }
    }

    .event-list-info {
      display: flex;
      justify-content: space-between;

      &::after {
        display: none;
      }

      .event-list-info-venue {
        max-width: calc(80% - 10px);

        @include mq-sp {
          width: 75%;
        }
      }

      .event-list-info-participation {
        .event-list-info-label {
          display: none;
        }

        .event-list-info-participation-date {
          margin-left: 0;
          font-size: 14px;
          line-height: 1.2;

          + * {
            margin-top: 5px;
          }
        }
      }

      .event-list-info-location {
        flex: auto;
        display: flex;

        .icon-location-pin {
          display: inline;
          vertical-align: middle;
          color: $basecolor-appendix;
          position: relative;
          top: 3px;
        }

        .event-list-info-label {
          flex-grow: 0;
          flex-shrink: 0;
          display: none;
        }

        .event-list-info-participation-location {
          font-size: 12px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-left: 4px;
        }
      }

      .event-list-info-capacity {
        position: static;
        align-self: flex-end;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-align: right;
        padding: 0;

        .event-list-info-capacity-label {
          display: none;
        }

        @include mq-sp {
          align-self: flex-start;
        }

        .event-list-info-capacity-number {
          font-size: $font-size-LL;
        }
      }
    }
  }
}

.event-schedule-list {
  border-bottom: 1px solid $bdcolor-normal;
  margin-bottom: 16px;

  @include mq-sp {
    margin-bottom: 8px;
  }

  &__item {
    list-style-type: none;
  }
}

.event-schedule-remaining {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;

  &__ {
    &item {
      color: $color-darkgray;
      font-size: 16px;

      @include mq-sp {
        font-size: 12px;
      }
    }

    &count {
      font-weight: bold;
    }
  }
}

.event-detail-link-text {
  text-align: center;
  color: $color-uiblue;
  font-weight: bold;
}
