.profile-edit-intern-list li {
  padding-bottom: 40px;

  & + li {
    padding-top: 40px;
    border-top: 1px solid $bdcolor-normal;

    @include mq-sp {
      padding-top: 0;
    }
  }
}
