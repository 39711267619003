// sass-lint:disable no-vendor-prefixes
.event-attend {
  padding: 8px 8px 0 8px;

  &-card {
    background-color: $color-green-4;
    color: $fgcolor-button-primary;
    max-width: 400px;
    height: 60vw;
    max-height: 250px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 12px 16px 16px 16px;
    border-radius: 20px;
    box-shadow: 0 8px 40px 0 $card-shadow;
    transition: top 500ms ease-in-out;
    position: relative;
    top: 0;

    &.is-hide {
      display: block;
      position: relative;
      top: -300px;
    }

    &--caution {
      @extend .event-attend-card;
      background-color: $color-brown-3;
    }

    &--qrcode {
      background: $color-temp-primary;
      height: auto;
      max-height: none;
    }

    &__ {
      &title {
        font-size: $font-size-LL;
        line-height: 24px;
        font-weight: bold;
        margin-top: 16px;
      }

      &subtitle {
        font-size: $font-size-small;
        line-height: $font-size-small;
        margin-top: 8px;
      }

      &attention {
        text-align: center;
        font-size: $font-size-small;
        margin-top: 16px;
      }

      &qrcode {
        width: 100%;
        height: auto;
        margin-top: 16px;
        border: 32px solid $bgcolor-normal;
        box-sizing: border-box;
      }
    }

    &-header {
      border-bottom: 1px solid $bgcolor-normal;
      height: 38px;
      position: relative;

      &--caution {
        @extend .event-attend-card-header;
        padding-bottom: 1px;
        height: auto;
        font-size: 18px;
        font-weight: bold;
      }

      &__ {
        &logo {
          height: 26px;
          position: absolute;
          left: 2px;
          top: 4px;
        }

        &name {
          font-size: 16px;
          position: absolute;
          right: 2px;
          bottom: 8px;
        }

        &no {
          font-size: 18px;
          position: absolute;
          right: 2px;
          bottom: 3px;
        }
      }
    }

    &-body {
      margin-top: 12px;
      font-size: 20px;
      font-weight: bold;
      height: calc(100% - 56px);
      overflow-y: auto;
      // sass-lint:disable  no-misspelled-properties
      -webkit-overflow-scrolling: touch;
      word-wrap: break-word;

      &--caution {
        @extend .event-attend-card-body;
        font-size: 16px;
        height: calc(100% - 46px);
        font-weight: normal;
      }
    }
  }

  &-guide {
    text-align: center;
    transition: opacity 700ms ease-out;
    opacity: 1;

    &.is-hide {
      display: block;
      opacity: 0;
    }

    &__ {
      &mark {
        margin-top: 24px;
        height: 40px;
      }

      &description {
        margin-top: 8px;
        font-size: 16px;
        font-weight: bold;
      }

      &strong {
        color: $color-red-4;
      }

      &mock {
        margin-top: 12px;
        width: 114px;
        position: relative;
        left: 8px;
      }
    }
  }
}

.event-qrcode {
  overflow: visible;
}

.qrcode-wrapper {
  padding: 8px;
  height: 100%;
}
