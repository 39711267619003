.login-logo {
  padding-top: 30px;
  text-align: center;

  @include mq-sp {
    padding-top: 20px;
  }

  a {
    transition: opacity 300ms linear;

    &:hover {
      opacity: $opacity-normal;
      transition: opacity 300ms linear;
    }
  }

  img {
    width: 193px;
  }
}

.login-area {
  width: 480px;
  margin: 25px auto 15px;
  padding: 40px;
  background-color: $bgcolor-normal;

  @include mq-sp {
    width: auto;
    padding: 30px 10px;
    box-sizing: border-box;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .login-sns {
    margin-bottom: 18px;
    text-align: center;
  }

  .login-facebook {
    display: inline-block;
    margin-top: 16px;
    line-height: 1.125;
  }

  .login-note {
    margin-top: 35px;
    text-align: center;
    font-size: 14px;
    color: $color-darkgray;

    @include mq-sp {
      font-size: 12px;
    }
  }

  .login-remember-me {
    display: block;
    margin-top: 11px;
    cursor: pointer;
    font-size: $font-size-small;
  }

  .btn-secondary {
    margin-top: 40px;
  }
}

.login-area-border {
  position: relative;
  margin: 25px 0;
  text-align: center;

  @include mq-sp {
    margin: 15px 0;
  }

  &:before {
    content: "OR";
    z-index: 5;
    position: relative;
    padding: 0 15px;
    background-color: $bgcolor-normal;
    color: $bdcolor-normal;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 1px;
    background-color: $bdcolor-normal;
  }
}

.login-signup-link {
  text-align: center;
  padding-bottom: 50px;

  @include mq-sp {
    padding-bottom: 20px;
  }
}

.btn-apple.login-btn-apple {
  @include mq-sp {
    width: 100%;
  }
}

.login {
  &__title {
    margin-top: 20px;
    text-align: center;
    font-size: $font-size-large;
    line-height: 1;
    font-weight: normal;
    color: $color-black;
    @include mq-pc {
      margin-top: 25px;
      font-size: $font-size-larger;
    }

    &--admin {
      color: $fgcolor-normal;
      margin-top: 0;
      margin-bottom: 16px;
    }
  }

  &__under-content {
    padding: 10px;
    padding-bottom: 30px;
    text-align: center;
  }

  &__button--supplemental {
    display: inline-block;
    padding: 7px 20px;
    text-align: center;
    font-size: $font-size-basic;
    line-height: 1;
    color: $fgcolor-link;
    background-color: $bgcolor-normal;
    border: 1px solid $bdcolor-reverse;
    border-radius: 100px;
  }
}

a.login__button--supplemental:hover {
  text-decoration: none;
  opacity: 0.7;
}

.leaked-check-modal {
  background-color: $shadow-6;
  &__ {
    &title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 24px;
      text-align: center;
    }
    &middle {
      padding: 24px 24px;
      border-radius: 10px;
      width: 382px;

      @include mq-pc {
        max-width: 430px;
      }
      @include mq-sp {
        max-width: calc(100% - 32px);
        margin: 0 auto;
      }
    }
    &message {
      margin-bottom: 24px;
    }

    &action {
      margin: 0 auto;
    }

    &do-nothing-btn {
      @extend .button--normal;
      height: 44px;
      width: 145px;
      margin-right: 10px;
      padding: 10px;
      font-size: 16px;
      line-height: 16px;
      @include mq-sp {
        width: 110px;
      }
    }

    &change-btn {
      @include button;
      @include btn-states;
      color: $color-white;
      background-color: $color-uiblue;
      border: 2px solid $color-uiblue;
      height: 44px;
      width: 145px;
      padding: 10px;
      font-size: 16px;
      line-height: 16px;
      @include mq-sp {
        width: 110px;
      }
    }

    &action-inner {
      display: flex;
      width: fit-content;
      margin: 0 auto;
    }
  }
}
