.u-sp-view {
  display: none;
  @include mq-sp($lp-breakpoint-sp) {
    display: block;
  }
}
.u-pc-view {
  display: block;
  @include mq-sp($lp-breakpoint-sp) {
    display: none;
  }
}
