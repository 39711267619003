// Notice
//
// 通知
//
// .notice - 通知
// .notice.notice-error - エラー通知
//
// Markup:
// <div class="{$modifiers}">ここに通知文</div>
//
// Styleguide 3.70

.notice {
  position: fixed;
  z-index: 100;
  height: 0;
  width: 100%;
  background-color: $bgcolor-notice;
  color: $fgcolor-darkbg;
  text-align: center;
  transition-duration: 500ms;
  overflow: hidden;

  &--text {
    position: absolute;
    bottom: 0;
    height: 44px;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0;
  }

  &.notice-on {
    height: 44px;
  }

  &.notice-error {
    background-color: $bgcolor-notice-error;
  }
}

@include mq-sp {
  .notice {
    // アプリと同じデザインにする
    top: 45px;
    font-size: $font-size-smaller;

    &--text {
      height: 24px;
      padding: 3px 0;
    }

    &.is-app {
      top: 0;
    }

    &.notice-on {
      height: 24px;
    }

    &--register {
      top: 60px;
    }
  }
}
