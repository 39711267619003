.side-menu {
  color: $color-black-3;

  .side-menu-title {
    font-weight: bold;
    font-size: 20px;
  }

  .side-menu-item {
    font-size: 15px;
    margin-left: 0;

    &:hover {
      background-color: $color-black-10;
    }

    &.is-active {
      background-color: $color-black-10;
    }
  }

  .side-menu-item a {
    display: block;
    padding-left: 20px;

    &:hover {
      color: $color-black-3;
    }
  }
}

.side-menu.side-menu-job {
  .side-menu-title {
    color: $color-green-3;
  }

  .side-menu-item {
    &.is-active {
      background-color: $color-green-3;
    }
  }
}

.side-menu.side-menu-message {
  .side-menu-title {
    color: $color-blue-6;
  }

  .side-menu-item {
    &.is-active {
      background-color: $color-blue-6;
    }
  }
}

.side-menu.side-menu-contact {
  .side-menu-title {
    color: $color-brown-2;
  }

  .side-menu-item {
    &.is-active {
      background-color: $color-brown-2;
    }
  }
}
