$height-free-comment-sample-button: 36px;

.profile-edit-free-comment-form-area {
  .profile-edit-free-comment-form-area-inner {
    width: 600px;
    margin: auto;

    @include mq-sp {
      width: 100%;
    }
  }

  textarea {
    width: 600px;
    height: 250px;
    box-sizing: border-box;
    resize: vertical;

    @include mq-sp {
      width: 100%;
    }
  }

  .profile-edit-free-comment-sample {
    margin-top: 40px;
    background-color: $color-lightgray;
    padding: 10px 20px;

    @include mq-sp {
      margin-top: 10px;
    }

    p {
      width: 100%;
      overflow: hidden;
      transition: max-height 300ms linear;

      span {
        display: block;
        padding: 10px 20px;
        background-color: $bgcolor-primary;
        border: 1px solid $bdcolor-normal;
      }
    }
  }

  .profile-edit-form-area-explain {
    margin-bottom: 10px;
  }

  .btn-open + p {
    max-height: 0;
  }
}
