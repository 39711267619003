$graph-background: #eeeeee;

.graph {
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background-color: $graph-background;
  font-size: 0;

  span {
    display: inline-block;
    height: 10px;
    border-radius: 10px;
  }

  &--occupation {
    @extend .graph;

    span {
      background-image: linear-gradient(90deg, $color-blue-9 0, $color-green-5 500px);
    }
  }

  &--industry {
    @extend .graph;

    span {
      background-image: linear-gradient(90deg, $color-blue-9 0, $color-pink-5 500px);
    }
  }
}

.list-group--arrow__text + .graph--occupation,
.list-group--arrow__text + .graph--industry {
  margin-top: 10px;
}
