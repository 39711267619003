// Pager
//
// ページャー
//
// .pager - ページャー
//
// Markup:
// <div class="pager">
//   <a href="#" class="pager-previous">&lt;&nbsp;前へ</a>
//   <a href="#">1</a>
//   <span>2</span>
//   <a href="#">3</a>
//   <a href="#">4</a>
//   <a href="#">5</a>
//   <span class="pager-gap">…</span>
//   <a href="#">49</a>
//   <a href="#">50</a>
//   <a href="#" class="pager-next">次へ&nbsp;&gt;</a>
// </div>
//
// Styleguide 3.46

.pager {
  > span:first-of-type {
    @include mq-sp {
      margin-left: 0;
    }
  }

  a,
  span {
    display: inline;
    margin-left: 24px;
    padding: 0;

    @include mq-sp {
      margin-left: 15px;
    }
  }

  .pager-previous {
    margin-right: 16px;
    margin-left: 0;
  }

  .pager-next {
    margin-left: 40px;
  }

  .pager-gap {
    color: $fgcolor-disabled;
  }
}

.pager-box {
  text-align: center;
}

* + .pager-box {
  margin-top: 40px;
}

.pager-back--default {
  background-color: $bgcolor-primary;
  padding: 20px 15px;

  @include mq-sp {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
  }

  p {
    @include mq-pc {
      width: 960px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.pager-simple {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  &__ {
    &link {
      color: $fgcolor-primary-theme;
      display: block;

      &--disabled {
        color: $fgcolor-disabled;
      }
    }

    &num {
      display: block;
      margin: 0 24px;
    }
  }

  .pager-simple__arrow {
    font-size: 32px;
  }
}

.ob-card-list + .cursor-box {
  margin-top: 9px;
  @include mq-sp {
    margin-top: 24px;
  }
}

.cursor-box {
  display: flex;
  justify-content: space-between;
}
