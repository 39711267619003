.convert-wrapper {
  background: $color-white;
  padding: 16px;
  @include mq-pc {
    max-width: 520px;
    border-radius: 20px;
    margin: 48px auto;
    box-shadow: 0 12px 20px 0 $boxshadow-soft-color;
    padding: 32px 84px;
    box-sizing: border-box;
  }
}

.convert-title {
  text-align: center;
  font-weight: bold;
  font-size: $font-size-LL;
  margin-bottom: 24px;
}

.convert-form-block {
  margin-bottom: 24px;

  &__ {
    &title {
      font-weight: bold;
      font-size: $font-size-basic;
      margin-bottom: 8px;

      + * {
        margin: auto;
      }
    }

    &text {
      font-size: 14px;
      margin-bottom: 12px;
    }

    &required {
      color: $color-red;
      font-size: $font-size-smaller;
      margin-left: 4px;
    }
  }
}

.convert-btn-wrapper {
  margin-bottom: 16px;
  text-align: center;
}

.convert-btn {
  display: inline-block;
  height: 44px;
  background: $color-pink;
  box-sizing: border-box;
  line-height: 44px;
  color: $color-white;
  text-align: center;
  font-weight: bold;
  border-radius: 22px;
  padding: 0 18px;
  border: 1px solid $color-pink;
  transition: 0.1s linear;

  &:hover {
    text-decoration: none;
    background: $color-white;
    color: $color-pink;
    transition: 0.1s linear;
  }
}

.convert-form-input-list {
  &__ {
    &item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }

    &input {
      flex: 1;
    }

    &btn-del {
      font-size: $font-size-small;
      margin-left: 8px;
      font-weight: bold;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

input[type="text"].convert-form-input {
  &--error {
    background: $color-pink-2;
    border-color: $color-pink;
  }
}

.convert-select-box {
  width: 100%;
  display: block;
}
