$font-size-segment-control: 13px;
.segment-control {
  display: table;
  table-layout: fixed;
  width: 100%;
  background-color: $bgcolor-normal;
  border: 1px solid $basecolor-primary;
  border-radius: 4px;

  li {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    a {
      display: block;
      padding-top: 6.5px;
      padding-bottom: 6.5px;
      font-size: $font-size-segment-control;
      color: $color-darkgray;
      line-height: $font-size-segment-control;
      cursor: pointer;
    }

    a.is-active {
      background-color: $basecolor-primary;
      color: $bgcolor-normal;
    }
  }

  li + li {
    border-left: 1px solid $basecolor-primary;
  }
}

.l-grid__container {
  .segment-control {
    margin-top: 20px;

    @include mq-pc {
      margin-top: 30px;
    }
  }

  .text-smaller + .segment-control {
    margin-top: 15px;
  }
}

//iOS8対応
html[data-os="iOS 8"] {
  .segment-control {
    display: table;
    width: 100%;
    text-align: center;

    li {
      display: table-cell;
      vertical-align: middle;
      width: 50%;
    }
  }
}
