.ob-intro {
  &__inner {
    width: auto;
    height: 869px;
    background: url(/assets/img/bg-obs-blur.jpg) no-repeat top center;
    background-size: cover;
    @include mq-sp {
      width: auto;
      height: 100vh;
      background: url(/assets/img/bg-obs-blur-sp.jpg) no-repeat top center;
      background-size: cover;
    }
  }

  &__description {
    box-sizing: border-box;
    position: absolute;
    margin-top: -80px;
    top: 50%;
    left: 0;
    width: 100%;
    padding: 30px;
    background-color: $bgcolor-theme;
    opacity: 0.85;
    text-align: center;
    color: $fgcolor-darkbg;
  }

  &__text {
    font-size: $font-size-large;
    margin-bottom: 24px;
    @include mq-sp {
      text-align: left;
      font-size: $font-size-basic;
      line-height: 24px;
      font-weight: bold;
    }

    br {
      @include mq-sp {
        display: none;
      }
    }
  }

  &__link {
    display: inline-block;
    margin-bottom: 8px;
    font-size: $font-size-large;
    font-weight: bold;
    color: inherit;
    padding: 8px 32px;
    border: 3px solid $bdcolor-reverse;
    border-radius: 100px;
    @include mq-sp {
      box-sizing: border-box;
      font-size: $font-size-basic;
      width: 260px;
    }

    &:hover {
      color: $fgcolor-primary-theme;
      background-color: $bgcolor-normal;
      text-decoration: none;
    }
  }

  .l-grid__container {
    padding-bottom: 0;
  }

  .l-fixed__header {
    background-color: $bgcolor-primary;
    padding-top: 32px;
    padding-bottom: 60px;
    @include mq-sp {
      padding-top: 16px;
    }
  }
}
