.tab {
  display: flex;
  background-color: $color-white;
  // l-grid__container に padding-top がかかっていて不要な余白が発生するが影響範囲がわからないので一旦ここで吸収します
  margin-top: -2px;

  @include mq-pc {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid $color-gray;
    border-radius: 4px;
    background-color: $bgcolor-form-disabled;

    > li {
      display: inline-block;
      width: 50%;
      box-sizing: border-box;

      & + li {
        border-left: 1px solid $color-gray;
      }

      &:first-child,
      &:last-child {
        border-radius: 4px 0 0 4px;

        a {
          border-radius: 4px 0 0 4px;
        }
      }

      &.tab--sp {
        display: none;
      }
    }
  }

  li {
    vertical-align: middle;
    text-align: center;

    @include mq-sp {
      display: inline-block;
      min-width: 85px;
      padding: 0 5px;
      white-space: nowrap;
    }

    a {
      display: block;
      padding: 12px 0;
      font-size: 14px;
      color: $color-darkgray;
      text-decoration: none;
      position: relative;

      @include mq-pc {
        font-size: 16px;
        font-weight: 700;
      }

      &.is-active {
        color: $basecolor-primary;

        @include mq-sp {
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            display: block;
            width: 100%;
            height: 2px;
            background-color: $basecolor-primary;
          }
        }

        @include mq-pc {
          font-weight: 700;
          color: $color-uiblue;
          background-color: $color-white;
        }
      }
    }
  }

  //iOS8対応
  @include mq-sp {
    width: 100%;
    display: block;
    position: absolute;
    overflow: auto;
    white-space: nowrap;
  }
}

.tab__count {
  font-size: 11px;
}

.tab__contents {
  padding-top: 1px;
}

.segment-control + .tab__contents {
  margin-top: 0;
}

.company-tab {
  @include mq-sp {
    display: flex;
    padding-bottom: 1px;
    margin-left: -15px;
    margin-right: -15px;
    background-color: $bgcolor-normal;
  }

  &__item {
    display: inline-block;
    @include mq-sp {
      height: 40px;
      flex-basis: 50%;
      align-items: center;
      text-align: center;
      text-decoration: none;
    }

    &__link {
      padding: 9px 13px;
      font-size: 15px;
      color: $fgcolor-primary-theme;
      font-weight: bold;

      &:hover {
        text-decoration: none;
      }

      @include mq-sp {
        display: block;
        font-size: $font-size-basic;
        font-weight: normal;
        color: $fgcolor-supplemental;
        border-bottom: 1px solid $bdcolor-normal;
      }
    }

    &__link.is-active {
      position: relative;
      background-color: $bgcolor-theme;
      color: $fgcolor-darkbg;
      border-radius: 4px;

      @include mq-sp {
        font-weight: normal;
        background-color: $bgcolor-normal;
        color: $fgcolor-primary-theme;
        text-decoration: none;
      }

      &::after {
        @include mq-sp {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          display: block;
          width: 100%;
          height: 2px;
          border-bottom: 3px solid $bdcolor-primary-theme;
          border-radius: 0;
        }
      }
    }
  }
}
