.register-open-campus {
  box-sizing: border-box;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
  width: 960px;
  background-color: $bgcolor-normal;
  @include mq-sp {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    background-color: $bgcolor-sp-normal;
  }

  &__title {
    text-align: center;
    font-size: $font-size-large;
    font-weight: bold;
  }

  &__text {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    font-size: $font-size-small;
    line-height: 1.8;
    @include mq-sp {
      margin-top: 25px;
      width: 100%;
    }
  }

  &__action {
    margin-top: 40px;
    text-align: center;
    @include mq-sp {
      margin-top: 20px;
    }
  }

  &__button {
    @extend .btn-big;
    @extend .btn-secondary;

    &:hover {
      text-decoration: none;
    }
  }

  &__notice {
    text-align: center;
    font-size: $font-size-smaller;
    font-weight: bold;
    color: $fgcolor-error;
  }

  .is-require::after {
    display: inline-block;
    content: "*";
    margin-left: 2px;
    vertical-align: top;
    color: $fgcolor-warning;
  }

  .form {
    margin-top: 40px;
    @include mq-sp {
      margin-top: 20px;
    }
  }

  .form-table-title {
    @include mq-pc {
      padding-top: 20px;
    }
  }
}
