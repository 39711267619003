@import "../module/top/top-variables";

.app-cta {
  position: relative;
  width: 260px;

  > h3 {
    text-align: center;
    font-size: 20px;
  }

  > p {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: 24px;
  }

  @include mq-sp($company-logo-section-breakpoint) {
    > h3 {
      font-size: 16px;
      margin-top: 12px;
    }

    > p {
      font-size: 14px;
      margin-top: 16px;
    }
  }
}

.app-cta-links {
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include mq-sp($company-logo-section-breakpoint) {
    flex-direction: row;
    margin-top: 8px;
  }
}

.app-cta-links-left {
  width: 125px;
  height: auto;

  @include mq-sp($company-logo-section-breakpoint) {
    width: 100%;
    height: auto;
    justify-content: center;
    display: flex;
  }
}

.app-cta-links-app-banner {
  display: block;
  font-size: 0;

  > img {
    width: 100%;
  }

  @include mq-sp($company-logo-section-breakpoint) {
    > img {
      width: 137px;
    }
  }
}

.app-cta-web {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  width: 118px;
  height: 42px;
  line-height: 40px;
  border: 1px solid $color-uiblue;
  border-radius: 23px;
  display: block;
  margin-top: 8px;

  @include mq-sp($company-logo-section-breakpoint) {
    margin-top: 0;
    margin-left: 8px;
  }
}


.app-cta-links-divider {
  width: 1px;
  height: 100px;
  background-color: $color-gray;
  margin-left: 21px;

  @include mq-sp($company-logo-section-breakpoint) {
    display: none;
  }
}

.app-cta-image {
  &-qr {
    margin-left: 11px;
    width: 92px;
    height: auto;

    @include mq-sp($company-logo-section-breakpoint) {
      display: none;
    }
  }
}
