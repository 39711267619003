.event-detail-top-title {
  margin-top: 15px;

  @include mq-sp {
    display: block;
    margin-top: 5px;
    margin-bottom: 24px;
    font-size: $font-size-LL;
    line-height: 30px;
    font-weight: bold;
  }
}

.event-detail-top-external {
  color: $color-darkgray;
  font-size: 16px;
  margin-left: 16px;
  @include mq-sp {
    font-size: 12px;
    margin-left: 8px;
  }
}

.event-detail-mv-wrapper {
  width: 960px;
  height: 400px;
  margin-top: 30px;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  text-align: center;
  font-size: 0;
  position: relative;

  &--all-stop {
    &:before {
      z-index: 1;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $shadow-26;
    }
  }

  @include mq-sp {
    width: auto;
    height: auto;
    margin-top: 15px;
    border-width: 1px 0;
    padding-top: percentage(400/960);

    + * {
      margin-top: 10px;
    }
  }

  .event-detail-top-mv {
    width: 100%;
  }

  .event-detail-status {
    height: 46px;
    padding: 0 15px;
    font-size: 24px;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq-sp {
      height: 26px;
      padding: 0 7px;
      font-size: 16px;
      border-radius: 4px;
    }

    &__ {
      &stop {
        color: $color-red;
        background-color: $color-white;
        border: 1px solid $color-red;
      }
    }
  }
}
