.career-values-edit {
  overflow-x: hidden;
  background-color: $color-lightgray;
  color: $color-black;

  &__wrapper {
    margin: -30px auto 35px; // l-grid__containerにpaddingが付与されているので、これを打ち消すためにマイナスマージンをつけた
    padding: 16px;
    position: relative;
    background-color: $color-white;
    width: 368px;
    min-height: 100vh;

    @include mq-sp {
      width: 100%;
      background-color: $color-lightgray;
      margin: 0 0 35px;
      padding: 15px 1px 16px; // l-grid__containerにpaddingが付与されているので、いい感じになるように無理やり調整した;;
    }
  }

  &__about {
    margin-bottom: 24px;
    padding: 16px;
    box-shadow: 0 4px 12px $career-values-card-shadow;
    border-radius: 8px;
    background-color: $color-white;

    &-title {
      margin-bottom: 11px;
      font-size: 18px;
      line-height: 1;
      font-weight: bold;
    }

    &-description {
      margin-bottom: 11px;
      font-size: 13px;
      line-height: 1.5;
    }

    &-image {
      display: block;
      margin: 0 auto;
      width: 313px;
      height: 96px;
    }
  }

  &__description {
    margin-bottom: 16px;
    &-main {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 1.5;
      font-weight: bold;
    }
    &-note {
      font-size: 14px;
      line-height: 1.5;
    }
  }

  &__card + .career-values-edit__card {
    margin-top: 16px;
  }

  &__card {
    padding: 16px;
    box-shadow: 0 4px 12px $career-values-card-shadow;
    border-radius: 8px;
    background-color: $color-white;

    &-question {
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 1.5;
      font-weight: bold;
    }
    &-options {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      label {
        $radio-circle-size: 40px;
        $radio-hole-size: 15px;
        position: relative;
        padding: 0;
        width: $radio-circle-size;
        height: $radio-circle-size;
        cursor: pointer;

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          width: $radio-circle-size;
          height: $radio-circle-size;
          background-color: $color-gray;
          border-radius: 50%;

          // アニメーション用の要素
          &:before {
            content: "";
            height: 1px;
            width: 1px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0 solid $color-uiblue;
            border-radius: 50%;
            transition-timing-function: ease-in-out;
            transition-duration: 200ms;
            opacity: 0;
            z-index: 20;
          }

          &:after {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            z-index: 20;
            margin-left: -($radio-hole-size / 2);
            margin-top: -($radio-hole-size / 2);
            content: "";
            border-radius: 50%;
            background: $color-white;
            width: $radio-hole-size;
            height: $radio-hole-size;
          }
        }

        &:hover input ~ .checkmark {
          background-color: darken($color-lightgray, 2%);
        }

        input:checked ~ .checkmark {
          &:before {
            border: ($radio-circle-size - 1px) / 2 solid $color-uiblue;
            border-radius: 50%;
            background-color: $color-uiblue;
            transition-timing-function: ease-in-out;
            transition-duration: 200ms;
            opacity: 1;
          }
        }
      }

      input[type="radio"] {
        opacity: 0;
        width: 0;
        height: 0;
      }
    }
    &-options-label {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 16px;

      span {
        white-space: pre-wrap;
        max-width: 160px;
      }

      @include mq-sp {
        font-size: 12px;
        line-height: 1.5;

        span {
          max-width: 132px;
        }
      }
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 12px 0;
    z-index: 30;
    width: 100%;
    height: 95px;
    box-sizing: border-box;
    background-color: $color-white;
    border-top: 1px solid $color-gray;
    min-width: 960px;

    button {
      display: block;
      width: 198px;
      height: 45px;
      margin-bottom: 8px;
      font-weight: bold;
    }

    p {
      color: $color-darkgray;
      font-size: 12px;
      line-height: 1.5;
    }

    @include mq-sp {
      width: 100vw;
      min-width: auto;
    }
  }
}
