.l-card-body {
  background-color: $bgcolor-primary;
  min-height: 100%;
}

.l-card-main {
  width: 960px;
  margin-top: $header-height--pc;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding-top: 1px;
  line-height: 1.5;

  // アプリheaderがネイティブのときにheader以下をつめるために使用
  &.is-app {
    margin: 0;
  }

  @include mq-sp {
    width: 100%;
    box-sizing: border-box;
    padding-top: 1px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
