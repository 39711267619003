// sass-lint:disable no-color-literals
$profile-edit-modal-shadow: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.2) 0%,
  rgba(255, 255, 255, 0.7) 45.74%,
  $color-white 100%
);
$bgcolor-profile-edit-modal-overlay: rgba(32, 38, 50, 0.8);

.bg-profile-edit-modal {
  background-color: $bgcolor-profile-edit-modal-overlay;
}

.profile-edit-modal {
  @include mq-sp {
    box-sizing: border-box;
    display: block;
    overflow: auto;
    padding: 24px 16px;
  }

  &__inner {
    transform: none;
    transition-timing-function: unset;
    transition: none;
    transform-origin: unset;
    max-width: 640px;

    @include mq-sp {
      margin: 0;
      overflow-y: scroll;
      @include hidden-scroll-bar;
    }

    &.profile-edit-modal__inner--long {
      position: relative;
      max-height: 640px;
      overflow: hidden;

      @include mq-sp {
        max-height: 600px;
      }
      @include mq-sp-small {
        max-height: 508px;
      }
    }
  }

  &__content {
    padding: 0 1rem;

    &.profile-edit-modal__content--long {
      max-height: 600px;
      overflow-y: scroll;
      @include hidden-scroll-bar;

      @include mq-sp-small {
        max-height: 508px;
      }
    }

    &-description {
      text-align: center;
      font-size: $font-size-smaller;

      @include mq-sp {
        text-align: left;
      }
    }

    &-tags {
      margin: 16px 0 24px;
      @include hidden-scroll-bar;

      @include mq-sp {
        margin: 16px 0 0;
      }

      &.profile-edit-modal__content-tags--long {
        padding-bottom: 140px;

        @include mq-sp {
          padding-bottom: 160px;
        }
      }
    }
  }

  &__footer {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $color-gray;

    &.profile-edit-modal__footer--long {
      background-color: $color-white;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: -65px;
        display: block;
        width: 100%;
        height: 64px;
        background: $profile-edit-modal-shadow;

        @include mq-sp {
          top: -33px;
          height: 32px;
        }
      }
    }
  }

  &__btn {
    width: 295px;

    @include mq-sp {
      width: 100%;
      margin: 0 24px;
      box-sizing: border-box;
    }
  }
}
