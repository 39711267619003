.l-body-top-boss {
  background-color: $bgcolor-primary;

  .l-container {
    @include mq-sp {
      padding-top: 0;
    }
  }

  .top-sections {
    section {
      &:nth-child(2n) {
        background-color: $bgcolor-normal;
      }
    }
  }
}

.l-body-top {
  .l-container {
    @include mq-sp {
      padding-top: 0;
    }
  }
}

.l-top-section {
  padding: 50px 0;

  @include mq-sp {
    padding: 40px 0;
  }

  &:first-child {
    padding: 80px 0;

    @include mq-sp {
      padding: 60px 0;
    }
  }

  .top-title {
    margin-bottom: 30px;
    text-align: center;
  }

  .l-top-section-inner {
    box-sizing: border-box;

    @include mq-sp {
      padding: 0 10px;
    }
  }
}

.top-btn-inner {
  margin-top: 50px;

  @include mq-sp {
    margin-top: 30px;
  }

  .top-btn-inner-mail {
    margin-top: 10px;

    @include mq-sp {
      + .note {
        margin-top: 20px;
        line-height: 20px;
      }
    }
  }
}

.top-mainvisual {
  position: relative;
  padding-top: 1px;

  .top-mainvisual-login {
    a {
      margin-top: 20px;

      @include mq-sp {
        margin-top: 5px;
      }
    }
  }

  .top-mainvisual-inner {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: -8px auto 0;
    transform: translateY(-50%);
    text-align: center;
    overflow: hidden;

    .top-mainvisual-copy {
      position: relative;
      left: 20px;

      @include mq-sp {
        left: 10px;
      }
    }

    .top-mainvisual-logo-inner {
      margin-top: 60px;

      @include mq-sp {
        margin-top: 30px;
      }

      .top-mainvisual-logo {
        margin: 10px auto 0;
      }
    }
  }
}

.top-university-supplementation + .top-university-center {
  margin-top: 30px;
}

.top-university-supplementation {
  margin-top: 10px;
  text-align: center;
  font-size: $font-size-small;
  line-height: 1.7;
  color: $basecolor-appendix;
}

.top-to-ob {
  margin-top: 10px;
  display: block;
}

.top-mainvisual-signup {
  display: inline-block;
}

//iOS8対応
html[data-os="iOS 8"] {
  .l-body-top-boss {
    .top-mainvisual-inner {
      position: static;
      transform: none;
      margin-top: 150px;
    }
  }

  .l-body-top {
    .top-mainvisual-login {
      margin-top: 5px;
    }

    .top-mainvisual-inner {
      position: static;
      transform: none;
      margin-top: 5px;
    }
  }
}
