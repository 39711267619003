.top-section-title {
  font-weight: normal;
  @include mq-sp {
    font-size: 20px;
  }
}

.top-university {
  @include mq-sp {
    margin-left: 15px;
    margin-right: 15px;
  }

  .top-university-inner-title {
    text-align: center;
    margin-bottom: 50px;

    .top-university-inner-immunity {
      margin-top: 10px;
    }
  }

  .top-university-inner {
    width: 960px;
    margin: 0 auto;

    @include mq-sp {
      width: 100%;
    }

    .top-university-name {
      display: block;
      font-size: 24px;
      text-align: center;
      margin-top: 20px;

      @include mq-sp {
        font-size: $font-size-smaller;
        margin-top: 7px;
      }
    }
  }

  ul {
    margin-left: auto;
    margin-right: auto;
    font-size: 0;

    @include mq-sp {
      display: flex;
      flex-basis: auto;
      flex: 1 1 auto;
      flex-wrap: wrap;
    }

    &.top-university-center {
      max-width: 480px;
      text-align: center;
      font-size: 18px;
      line-height: 34px;

      li {
        margin: 0 10px;

        @include mq-sp {
          font-size: 18px;
          line-height: 40px;
        }
      }
    }
  }

  li {
    display: inline-block;
    color: $basecolor-normal;
  }

  .card {
    width: 310px;
    height: auto;
    padding: 40px 30px;

    @include mq-sp {
      width: 49%;
      padding: 6% 5%;
    }

    &:not(:nth-child(3n + 1)) {
      margin-left: 15px;

      @include mq-sp {
        margin-left: 0;
      }
    }

    &:nth-child(n + 4) {
      margin-top: 20px;

      @include mq-sp {
        margin-top: 0;
      }
    }

    &:not(:nth-child(2n + 1)) {
      @include mq-sp {
        margin-left: 2%;
      }
    }

    &:nth-child(n + 3) {
      @include mq-sp {
        margin-top: 2%;
      }
    }

    img {
      width: 250px;
      height: auto;

      @include mq-sp {
        width: 100%;
        height: auto;
      }
    }
  }
}
