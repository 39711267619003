// sass-lint:disable nesting-depth
// List SubMenu
//
// 一覧画面のサイドメニュー レスポンシブ対応モジュールです
//
// .sub-menu - サイドメニューです(KnockoutのVMは/javascript/event/event_list.jsを参考にするなり、ドキュメント引くなりして作ってください)
//
// Markup:
// <aside class="submenu-horizontal">
//    <p class="submenu-title">
//      <span class="submenu-current">カレントカテゴリ</span>
//      <span class="submenu-filter" data-bind="click: toggleMenu(このへんはKnockoutでよしなに)">カテゴリー</span>
//    </p>
//    <ul data-bind="css: {'is-active': isMenuOpen(このへんはKnockoutでよしなに)}">
//      <li><a href="..." class="{カレントカテゴリ ? 'current' : '' なかんじで}">すべて</a></li>
//      <li><a href="..." class="{カレントカテゴリ ? 'current' : '' なかんじで}">カテゴリ1</a></li>
//      <li><a href="..." class="{カレントカテゴリ ? 'current' : '' なかんじで}">カテゴリ2</a></li>
//      <li><a href="..." class="{カレントカテゴリ ? 'current' : '' なかんじで}">カテゴリ3</a></li>
//      <li><a href="..." class="{カレントカテゴリ ? 'current' : '' なかんじで}">カテゴリ4</a></li>
//    </ul>
//  </aside>
//
// <div class="overlay-sp" data-bind="css: {'is-active': isMenuOpen}, click: toggleMenu"></div>
//
// Styleguide 3.61

.submenu-vertical {
  width: 230px;
  background-color: $bgcolor-normal;
  font-size: $font-size-small;

  .submenu-title {
    display: none;
    padding: 5px 0;
    border-bottom: 1px solid $bdcolor-normal;

    .submenu-current {
      display: none;
    }

    .submenu-filter {
      color: $fgcolor-supplemental;
    }
  }

  .slider__categories {
    padding: 0;
  }

  ul {
    padding: 0;
  }

  li a {
    display: block;
    padding: 10px 20px;
    color: $fgcolor-normal;

    &:hover {
      background-color: $basecolor-primary;
      color: $bgcolor-normal;
      text-decoration: none;
    }

    &.current {
      @extend :hover;
    }
  }

  @include mq-sp {
    display: none;
  }
}

.submenu-horizontal {
  position: relative;
  float: none;
  font-size: $font-size-small;

  .slider__categories-frame {
    height: 40px;

    .slider__categories {
      height: 40px;
      display: table;
      position: absolute;
      padding: 0;
      background-color: $fgcolor-darkbg;
      white-space: nowrap;

      > li {
        display: table-cell;
        position: relative;
        min-width: 125px;
        vertical-align: middle;
        text-align: center;

        @include mq-sp {
          min-width: 90px;
        }

        a {
          display: inline-block;
          color: $basecolor-appendix;
          width: calc(100% - 20px);
          height: 100%;
          padding: 0 10px;
          text-decoration: none;
          line-height: 40px;

          &:hover {
            background-color: inherit;
          }

          &.is-active {
            color: $basecolor-primary;

            &:after {
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              display: block;
              width: 100%;
              height: 2px;
              background-color: $basecolor-primary;
            }
          }
        }
      }
    }
  }
}

.submenu-search-title {
  margin-top: 30px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1em;
}
