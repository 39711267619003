.asessment-popup {
  border-radius: 12px;
  width: 446px;
  @include mq-sp {
    width: 100%;
    padding: 16px;
    margin-left: 12px;
    margin-right: 12px;
  }
  padding: 0;
  z-index: 1002;
  @include mq-sp {
    transform: translate(-50%, -50%);
    top: 116px;
    left: 50%;
    right: auto;
    height: 230px;
    width: 90%;
  }

  &__ {
    &wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      @include mq-sp {
        width: 288px;
        height: 100%;
        margin: 0 auto;
        @media only screen and (max-width: 320px) {
          overflow-y: scroll;
          width: 100%;
        }
      }
    }

    &main-image {
      width: 692px;
      height: 418px;
      position: absolute;
      top: -150px;
      left: -30px;
      display: block;
      @include mq-sp {
        display: none;
      }

      &--small {
        @include mq-pc {
          display: none;
        }
        @include mq-sp {
          display: block;
          width: 312px;
          height: 217px;
          position: absolute;
          left: -10px;
          top: -68px;
        }
      }
    }

    &btn--read {
      line-height: 45px;
    }

    &btn-area {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding-top: 281px;
      width: 310px;
      @include mq-sp {
        width: 260px;
        padding-top: 160px;
      }

      .btn {
        flex-basis: 145px;
        font-size: 16px;
        @include mq-sp {
          flex-basis: 125px;
          font-size: 14px;
        }
      }
    }
  }
}

.popup-assessment {
  display: block;
  position: fixed;
  z-index: 5700;
  width: 446px;
  max-height: 100%;
  max-width: 800px;
  top: 50%;
  padding: 32px;
  @include mq-sp {
    max-width: 90%;
    padding: 32px 16px 32px 16px;
    overflow-y: auto;
  }
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $bgcolor-normal;
  border-radius: 16px;
  box-sizing: border-box;
  text-align: center;

  &.popup-shadow {
    box-shadow: 0 11px 21px 3px $shadow-17;
  }

  &.popup-border {
    border: solid 1px $basecolor-primary;
  }

  &.popup-general {
    border: solid 1px $bdcolor-normal-dark;
  }

  &.popup-overflow {
    overflow-y: auto;
  }

  .popup-content-inner {
    width: 518px;
    margin: 0 auto;

    .popup-label-input {
      text-align: left;
      display: block;
      font-weight: bold;
    }

    * + .popup-label-input {
      margin-top: 10px;
    }

    input[type="text"],
    textarea {
      width: 100%;
    }
  }

  .popup-title {
    font-size: 24px;
  }

  .popup-overlay {
    position: fixed;
    z-index: 110;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $shadow-18;
  }

  p + .popup-btn-area {
    margin-top: 10px;
  }

  .popup-btn-area {
    .btn,
    .btn-negative {
      width: 140px;
      box-sizing: border-box;
    }
  }

  .modal {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5001;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: left;
    font-size: $font-size-basic;
    visibility: hidden;

    &--show {
      visibility: visible;
      transform: none;
    }

    &__ {
      &inner {
        margin: 0 24px;
        width: 100%;
        box-sizing: border-box;
        background: $bgcolor-normal;
        border-radius: 6px;
        opacity: 0;
        transform: translate3d(0, 50px, 0);
        transform-origin: right top;
        transition: 0.24s ease-in-out;
        max-width: 488px;
        max-height: 90%;
        overflow-y: auto;

        &--middle {
          max-width: 560px;
        }

        &--show {
          transform: none;
          opacity: 1;
          transition-timing-function: ease-out;
        }
      }

      &content {
        margin: 24px;
      }

      &header {
        border-bottom: 1px solid $bdcolor-normal;
        text-align: center;
        height: 44px;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 16px;
      }

      &title {
        line-height: 44px;
        height: 44px;
        font-size: $font-size-basic;
      }

      &btn-cancel {
        position: absolute;
        left: 0;
        top: 0;
        height: 44px;
        width: 44px;
        background: url(/assets/icon/btn-modal-close.svg) no-repeat center;
        box-sizing: border-box;
        border: none;
        outline: none;
        font-size: 0;
        // sass-lint:disable no-vendor-prefixes
        -webkit-tap-highlight-color: $shadow-9;

        &:hover {
          @include mq-pc {
            text-decoration: underline;
          }
        }
      }

      &animation-item {
        &--execute {
          transform: none;
          opacity: 1;
        }
      }

      &text {
        padding: 0 16px 16px 16px;

        &--center {
          text-align: center;
        }
      }

      &separate-button {
        display: flex;
        justify-content: center;
        border-top: 1px solid $bdcolor-normal;
      }

      &separate-button__item {
        flex: 1;

        & + & {
          border-left: 1px solid $bdcolor-normal;
        }
      }

      &separate-button__link {
        display: block;
        height: 44px;
        line-height: 44px;
        text-align: center;
        color: $fgcolor-primary-theme;

        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
      }

      &separate-button__link--important {
        @extend .modal__separate-button__link;
        font-weight: bold;
        transition: none;

        &:hover {
          transition: none;
        }
      }
    }
  }
}
