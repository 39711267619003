// sass-lint:disable no-important
.ob-card-list {
  margin-top: 32px;
  font-size: 0; //inline-blockの時の隙間を削除

  .card:not(:nth-child(3n-2)) {
    margin-left: 15px;
  }

  .ob-card {
    @extend .card;
    padding: 16px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 15px;
    vertical-align: top;

    .ob-card-official-label {
      position: absolute;
      top: 0;
      left: 0;
      background: url("/assets/img/ob-card/badge-official.svg") no-repeat;
      width: 45px;
      height: 45px;
      z-index: 10;
    }

    .ob-card-favorite-button {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 10;
      background-color: transparent;
      border: none;
      font-size: 24px;
      color: #9299a0;

      &.is-active {
        color: rgb(0, 71, 143);
      }
    }

    .ob-card-official-public-other-university-label {
      @extend .ob-card-official-label;
      background: url("/assets/img/ob-card/badge-official-other.svg") no-repeat;
    }

    .ob-card-prospective-employee-label {
      @extend .ob-card-official-label;
      background: url("/assets/img/ob-card/badge-naitei.svg") no-repeat;
    }

    .ob-card-profile-area {
      position: relative;

      .ob-card-profile-img {
        position: relative;
        width: 64px;
        height: 64px;

        &-contents {
          @include round-bd-image(64px);
          float: left;
          border-radius: 100%;
          position: relative;
        }

        &-group-icon {
          @include round-bd-image(24px);
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          position: absolute;
          bottom: 0;
          right: 0;
          background-color: $color-white;
        }
      }

      .ob-card-profile-main-info {
        display: flex;
        align-items: center;
        @include clearfix;
      }

      .ob-card-profile {
        float: left;
        width: calc(100% - 85px);
        margin-left: 8px;

        p {
          display: inline-block;
        }

        .ob-card-college-name {
          @extend .note;
          font-size: 12px;
        }

        .ob-card-restriction {
          font-size: 14px;
          margin-bottom: 3px;
          &__limited {
            color: #d11010;
          }
          &__applied {
            color: #00478f;
          }
        }

        .ob-card-company-name {
          max-width: 100%;
          @extend .text-over-flow;
          font-size: 18px;
        }

        .ob-card-name-area {
          display: flex;
          align-items: baseline;
        }

        .ob-card-name {
          @extend .text-over-flow;
          max-width: calc(100% - 100px);
          font-size: 18px;
          margin-right: 8px;

          &:empty {
            margin-right: 0;
          }
        }

        .ob-card-career-year {
          font-size: 14px;
          margin-right: 8px;
        }

        .ob-card-gender {
          display: inline;
          font-size: 14px;
          margin-right: 8px;

          i {
            padding-left: 5px;
            color: $fgcolor-primary-theme;
          }
        }

        .ob-card-name:empty + .ob-card-gender {
          i {
            padding-left: 0;
          }
        }

        .ob-card-comment {
          display: flex;
          padding: 4px 8px;
          background-color: $color-green-6;
          border-radius: 10.5px;

          &__ {
            &icon {
              width: 14px;
              height: 10px;
              margin-right: 5px;
              background-image: url(/assets/icon/comment.svg);
            }

            &count {
              font-size: $font-size-smaller;
              font-weight: 600;
              line-height: $font-size-smaller;
              color: $color-green-2;
            }
          }
        }
      }
    }

    .ob-card-company-area {
      margin-top: 8px;
      font-size: 12px;
      color: $fgcolor-supplemental;
      line-height: 1.8;

      p {
        @extend .text-over-flow;
      }
    }

    .ob-card-company-information {
      margin-top: 8px;
      font-size: 14px;

      .ob-card-department {
        margin-left: 1.5px;
      }
    }

    .ob-card-company-info-horizontal {
      margin-top: 4px;
      display: table;
      table-layout: fixed;
      width: 100%;

      > div {
        display: table-cell;
      }

      > p + p {
        padding-left: 10px;
      }
    }

    .ob-card-department {
      i {
        padding-right: 7px;
        color: $fgcolor-primary-theme;
        font-size: 17px;
        vertical-align: middle;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        @extend .text-over-flow;
        max-width: calc(100% - 25px);
      }
    }

    .ob-card-occupation {
      i {
        padding-right: 5px;
        color: $fgcolor-primary-theme;
        font-size: 17px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        @extend .text-over-flow;
        max-width: calc(100% - 25px);
      }
    }

    .ob-card-prefecture {
      padding-left: 10px;

      i {
        padding-right: 5px;
        color: $fgcolor-primary-theme;
        font-size: 17px;
        vertical-align: middle;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        @extend .text-over-flow;
        max-width: calc(100% - 25px);
      }
    }

    .ob-card-univ-profile {
      border-top: 1px solid $bdcolor-normal;
      padding-top: 8px;
      margin-top: 8px;
      box-sizing: border-box;
      font-size: 14px;
    }

    .ob-card-univ-profile-list {
      > li + li {
        margin-top: 4px;
      }
    }

    .ob-univ-text {
      @extend .text-over-flow;
      width: 275px;
    }

    .ob-univ-tag-text {
      display: inline-block;
      overflow: hidden;
      width: 238px;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;

      &.ob-univ-tag-text-department {
        width: auto;
        max-width: 190px;
      }
    }

    .ob-univ-tag {
      display: inline-block;
      border: 1px solid $basecolor-primary;
      border-radius: 2px;
      font-size: 10px;
      padding: 2px;
      color: $basecolor-primary;
      margin-right: 8px;
    }

    .ob-card-univ-profile-dept {
      @include clearfix;

      > p {
        float: left;
        @extend .text-over-flow;
        max-width: 230px;
      }

      > .ob-card-univ-graduate {
        margin-left: 8px;
        line-height: 20px;
      }
    }

    .ob-card-memo {
      @extend .note-light;
      margin-top: 15px;
      width: 280px;
      height: 48px;
      overflow: hidden;
    }
  }
}

.ob-top-title {
  margin-top: 30px;
  @include mq-sp {
    display: none;
  }
}

.sp-ob-card-profile-img-contents,
.sp-ob-card-profile {
  display: none;
}

@media only screen and (max-width: 660px) {
  .sp-show-tbl {
    display: table !important;
  }
  .sp-show-tc {
    display: table-cell !important;
  }
  .ob-card-list .ob-card:not(:nth-child(3n-2)) {
    margin-left: 0;
  }
  .ob-top-title + .ob-card-list {
    margin-top: 0;
    padding-top: 0;
  }
  .sp-ob-card-profile-img-contents {
    display: table-cell;
    white-space: nowrap;
    width: 55px;
    vertical-align: top;
  }
  .sp-ob-card-profile {
    display: table;
    width: 100%;
    table-layout: fixed;
    padding-left: 8px;

    > .ob-card-company-name {
      display: table-cell;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 20px;
      font-weight: normal;
    }
  }
  .ob-card-company-information {
    padding-left: 8px;
  }
}

$card-visited: #f7f7f7;
.ob-card.is-done {
  background-color: $card-visited;
}

form > .ob-search--accordion {
  margin-top: 32px;
}

.ob-search--accordion {
  position: relative;
  border-radius: 3px;
  padding: 15px 20px;
  background-color: $color-icelandblue;

  &__input-freeword {
    width: 100%;
  }

  &__input-freeword + i {
    position: absolute;
    top: 25px;
    right: 30px;
    font-size: $font-size-large;
    color: $fgcolor-primary-theme;
    background-color: $bgcolor-normal;
  }

  &__trigger {
    display: inline-block;
    margin-top: 10px;
    font-size: $font-size-small;

    > i {
      margin-left: 5px;
      font-size: $font-size-larger;
      vertical-align: sub;

      &.is-active::before {
        content: "\f106";
      }
    }
  }

  &__condition {
    margin-top: 10px;
    font-size: $font-size-small;

    > dt,
    > dd {
      display: inline-block;
      font-weight: bold;
    }

    > dt:first-of-type {
      margin-left: 10px;
    }

    > dt {
      margin-left: 20px;

      &::after {
        content: "：";
      }
    }

    > dd + dd::before {
      content: "、";
    }
  }

  &__content {
    position: absolute;
    z-index: 100;
    box-sizing: border-box;
    width: 960px;
    padding: 20px;
    border: 1px solid $bdcolor-normal;
    background-color: $bgcolor-normal;
    box-shadow: $boxshadow-normal;
    display: none;

    &.is-active {
      display: block;
    }

    > h2 {
      font-size: $font-size-small;
      font-weight: normal;
    }

    > ul > li {
      display: inline-block;
      margin-left: 10px;
    }

    .search-panel + h2 {
      margin-top: 30px;
    }
  }
}

form + .ob-search__text {
  margin-top: 30px;
  line-height: 1;
}

.ob-search {
  &__text {
    margin-top: 15px;
    @include mq-sp {
      margin-top: 17px;
      display: inline-block;
    }

    > span {
      font-size: $font-size-LL;
      font-weight: bold;
    }

    i {
      display: none;
      @include mq-sp {
        margin-left: 5px;
        margin-right: 5px;
        display: inline-block;
        font-size: $font-size-large;
        color: $fgcolor-supplemental;
      }
    }
  }

  &__condition {
    margin-top: 10px;

    &-key:first-of-type {
      margin-left: 0;
    }

    &-key {
      margin-left: 10px;
      font-weight: bold;

      &::after {
        content: "：";
      }
    }

    > &-value + &-value::before {
      content: "、";
    }

    @include mq-sp {
      margin-top: 18px;
    }
  }
}

.ob-visit-apply-filtering {
  margin-top: 16px;
  // ob-card-listのmargin-topを変更するのは影響が大きいのでマイナスマージンで対応する
  margin-bottom: -4px;

  label {
    cursor: pointer;
    padding: 6px 14px;
    line-height: 1.5;
  }
}

.ob-empty-state-area {
  .search-result-empty {
    white-space: pre;
  }
}

.tab__contents > .card-company {
  margin-top: 30px;
}

.card-company--result + .ob-search__text {
  @include mq-pc {
    margin-top: 25px;
  }
}

.popup-overlay--prevent-tap {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
}

.ob-popup-visit-guide {
  border-radius: 12px;
  width: 590px;
  height: 360px;
  padding: 0;
  z-index: 1002;
  @include mq-sp {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    right: auto;
    height: 230px;
    width: 90%;
  }

  &__ {
    &wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      @include mq-sp {
        width: 288px;
        height: 100%;
        margin: 0 auto;
      }
    }

    &main-image {
      width: 692px;
      height: 418px;
      position: absolute;
      top: -150px;
      left: -30px;
      display: block;
      @include mq-sp {
        display: none;
      }

      &--small {
        @include mq-pc {
          display: none;
        }
        @include mq-sp {
          display: block;
          width: 312px;
          height: 217px;
          position: absolute;
          left: -10px;
          top: -68px;
        }
      }
    }

    &btn--read {
      line-height: 45px;
    }

    &btn-area {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding-top: 281px;
      width: 310px;
      @include mq-sp {
        width: 260px;
        padding-top: 160px;
      }

      .btn {
        flex-basis: 145px;
        font-size: 16px;
        @include mq-sp {
          flex-basis: 125px;
          font-size: 14px;
        }
      }
    }
  }
}

.ob-guideline-modal {
  background-color: $shadow-6;
  @include mq-sp {
    box-sizing: border-box;
    display: block;
    overflow: auto;
    padding: 24px 16px;
  }

  &__ {
    &middle {
      padding: 32px 24px;
      border-radius: 10px;
      max-height: calc(100dvh - 48px);
      display: flex;
      flex-direction: column;

      @include mq-pc {
        max-width: 430px;
      }
      @include mq-sp {
        margin: 0 auto;
      }
    }

    &title {
      padding-bottom: 16px;
    }

    &title-text {
      color: $color-pink;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      text-align: center;
    }

    &scroll-area {
      overflow-y: scroll;
      padding-bottom: 8px;

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &description {
      margin-bottom: 16px;
    }

    &text {
      font-size: 14px;
      line-height: 21px;
    }

    &ng {
      margin-bottom: 4px;
    }

    &ng-item {
      margin-bottom: 4px;
    }

    &ng-title {
      font-size: 14px;
      font-weight: bold;
      line-height: 22.4px;
    }

    &updated-label {
      position: relative;
      top: -1px;
      margin-left: 8px;
      font-weight: normal;
    }

    &ng-description {
      font-size: 14px;
      line-height: 21px;
    }

    &supplemental {
      margin-bottom: 20px;
    }

    &supplemental-text {
      font-size: 12px;
      line-height: 18px;
    }

    &action {
      text-align: center;
      position: relative;

      @include mq-sp {
        padding-top: 12px;
      }
    }

    &blur-block {
      display: block;
      position: absolute;
      top: -36px;
      height: 36px;
      width: 100%;
      background-image: linear-gradient(to top, $color-white 15%, transparent 100%);
      pointer-events: none;
    }

    &btn {
      @extend .button--secondary;
      height: 44px;
      width: auto;
      min-width: 100px;
      padding: 10px 20px;
      font-size: 16px;
      line-height: 16px;
    }
  }
}
