
.mentor {
  &-body {
    background-color: $color-lightgray;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mq-sp {
      .l-grid__container {
        padding-top: unset;
        padding-left: unset;
        padding-right: unset;
      }
    }

    .l-grid__container {
      padding-bottom: unset;
    }
  }

  &-layout {
    max-width: 446px;
    background-color: $color-white;
    margin-top: -30px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 0;

    @include mq-sp {
      &::before {
        content: '';
        position: absolute;
        top: 112px;
        left: 0;
        width: 100%;
        height: 100dvh;
        background-color: #F2F7F9;
        border-radius: 100px 100px 0 0;
        z-index: -1;
      }
    }
  }

  &-highlight {
    background-color: #ffff66;
  }

  &-section {
    padding: 0 50px;

    &:first-child {
      padding-top: 40px;
    }

    & + section {
      margin-top: 80px;

      @include mq-sp {
        margin-top: 0;
        padding-top: 80px;
      }
    }

    h2 {
      font-size: 32px;
      color: $color-midnightblue;
      line-height: 1.4;
      font-weight: bold;
      text-align: center;
    }

    p {
      font-size: 18px;
      line-height: 1.6;
      font-weight: bold;
      color: #333638;
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  // 一つ目のセクション
  &-eye-catch {
    &-section {
      padding-top: 40px;

      img + h2,
      h2 + p {
        margin-top: 32px;
      }

      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
    }

    &-img {
      margin: 0 auto;
    }
  }

  &-description-section,
  &-schedule-section {
    p {
      color: $color-midnightblue;
    }

    > * + * {
      margin-top: 32px;
    }

    ul {
      list-style-type: disc;

      li {
        font-weight: bold;
        font-size: 18px;
        line-height: 1.6;

        & + li {
          margin-top: 16px;
        }
      }
    }

    @include mq-sp {
      background-color: #F2F7F9;
    }
  }

  &-notes-section {
    padding-bottom: 40px;

    > div {
      background-color: #005C91;
      padding: 32px 24px;
      border-radius: 8px;
    }

    .mentor-card {
      background-color: $color-white;
      border-radius: 8px;
      padding: 16px;

      > img {
        height: 88px;
        object-fit: contain;
      }

      > h2 {
        font-size: 18px;
      }

      & + ul {
        margin-top: 32px;
      }
    }

    ul {
      list-style-type: disc;
      list-style-position: inside;
      color: $color-white;

      li {
        font-size: 14px;
        font-weight: normal;
      }
    }

    @include mq-sp {
      background-color: #F2F7F9;
    }
  }
}
