// sass-lint:disable no-important no-vendor-prefixes
.assessment-naitei {
  @include mq-pc {
    background-color: $color-lightgray;
  }
}

.assessment-naitei-container {
  background-color: $bgcolor-normal;
  padding-top: 24px;
  width: 100%;
  @include mq-pc {
    box-sizing: border-box;
    margin: -30px auto -60px;
    min-height: 100vh;
    width: 446px;
  }
}

.assessment-naitei-description {
  margin-bottom: 24px;
  @include mq-pc {
    padding: 0 16px;
  }

  &__ {
    &title {
      font-size: 24px;
      margin-bottom: 32px;
    }

    &text {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    &caution {
      color: $color-darkgray;
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.assessment-naitei-form {
  &__ {
    &title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 16px;
      @include mq-pc {
        padding-left: 16px;
      }
    }

    &input-wrapper {
      margin-bottom: 24px;
      width: 100vw;
      @include mq-sp {
        margin-left: -15px;
      }
      @include mq-pc {
        width: 100%;
      }
    }

    &btn {
      display: block;
      font-size: 14px;
      font-weight: bold;
      height: 48px;
      line-height: 16px;
      margin: 0 auto;
      width: 149px;
    }
  }
}

// デフォルトの設定に詳細度で勝つためにここで指定
input[type="text"].assessment-naitei-form__input-area {
  border: none;
  border-top: 0.5px solid $color-bluegray;
  border-bottom: 0.5px solid $color-bluegray;
  font-size: 14px;
  padding: 14px 16px;
  width: 100%;

  &:focus {
    border-top: 0.5px solid $color-bluegray;
    border-bottom: 0.5px solid $color-bluegray;
  }

  &::placeholder {
    color: $color-gray;
  }

  &:-ms-input-placeholder {
    color: $color-gray;
  }

  &::-ms-input-placeholder {
    color: $color-gray;
  }
}

button[disabled].assessment-naitei-form__btn--disabled {
  background-color: $color-bluegray;
  color: $fgcolor-darkbg;
}

// jQueryのauto-completeのスタイルを上書きしている
.assessment-naitei > ul.ui-autocomplete {
  box-sizing: border-box;
  border-radius: 0 0 16px 16px;
  padding: 0;
}

.assessment-naitei > ul.ui-autocomplete > li {
  color: $color-black-4;
  font-weight: bold;
  font-size: 12px;
  overflow: hidden;
  padding: 16px 18px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.ui-state-focus {
    margin: 0 !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid $color-gray !important;
    border-left: 0 !important;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-gray;
  }

  &:last-child {
    border-radius: 0 0 16px 16px;
  }
}
