// Heading
//
// The heading for each sections.
//
// .heading - headingタグを示すモジュールです
//
// Markup:
// <h1 class="{$modifires}">h1のスタイル</h1>
// <h2 class="{$modifires}">h2のスタイル</h2>
// <h3 class="{$modifires}">h3のスタイル</h3>
// <h4 class="{$modifires}">h4のスタイル</h4>
//
// Styleguide 3.1

.heading {
  color: $fgcolor-normal;

  &--bordered {
    border-bottom: 1px solid $fgcolor-primary-theme;
    font-size: $font-size-basic * 1.5;
    font-weight: normal;
    color: $fgcolor-primary-theme;
    line-height: 1.4;
    padding-bottom: 5px;

    + * {
      margin-top: 20px;
    }

    * + & {
      margin-top: 20px;
    }

    @include mq-sp {
      font-size: 18px;
    }
  }

  &--primary {
    font-size: 16px;
    font-weight: bold;
    color: $fgcolor-primary-theme;
    line-height: 1.5;
  }
}

h3.heading {
  font-size: 18px;
  line-height: 1.8;
  font-weight: bold;
}

h4.heading {
  font-size: $font-size-basic;
  line-height: 1.8;
  font-weight: bold;
}

h5.heading {
  font-size: $font-size-basic;
  line-height: 1.8;
  font-weight: bold;
  color: $color-black;
}
