// sass-lint:disable no-important
.app-banner-pc {
  display: block;
  padding-top: 1px;
  background-color: $color-uiblue;
  max-width: 960px;
  margin: 0 auto 40px auto;
  padding: 32px 32px 0 32px;
  box-sizing: border-box;
  border-radius: 20px;

  &__inner {
    @include clearfix();
    width: 770px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  &__image {
    float: left;
    margin-right: 55px !important;
    width: 310px !important;
    vertical-align: bottom;
  }

  &__right {
    padding-top: 1px;
    width: 770px;
  }

  &__app-store-image {
    margin-top: 20px;
    margin-left: inherit !important;
    margin-right: 4px;
    height: 40px;
  }

  &__title {
    margin-top: 50px;
    font-size: 32px;
    font-weight: bold;
    color: $color-white;
    line-height: 1.6;
  }

  @include mq-sp {
    display: none;
  }
}

.l-login-body {
  .app-banner-pc {
    display: none;
  }
}
