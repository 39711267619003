.l-company-detail {
  .l-grid__container {
    @include mq-pc {
      width: 100%;
    }
  }

  &__header {
    margin-top: -30px;
    background-color: $bgcolor-normal;
    position: relative;

    @include mq-sp {
      margin: 0 -15px;
    }
  }

  .visit-limit-exceed-notification {
    background-color: $bgcolor-danger;
    color: $fgcolor-danger;

    @include mq-sp {
      margin: 0 -15px;
    }

    &--content {
      width: 960px;
      margin: 0 auto;
      padding: 8px 16px 8px 16px;
      display: flex;
      gap: 16px;
      align-items: center;
      box-sizing: border-box;

      @include mq-sp {
        width: 100%;
      }
    }
  }

  &__body {
    width: 960px;
    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 180px;

    @include mq-sp {
      width: 100%;
      padding-top: 16px;
    }
  }

  .company-detail-footer__text {
    text-align: center;
    font-size: $font-size-small;
  }

  .company-detail-footer__text + .panel-footer__inner {
    margin-top: 15px;
  }

  .article-list {
    @include mq-sp {
      margin-top: 0;
    }
  }

  .company-detail-ob-visit-apply-filtering {
    margin-top: -9px;
    margin-bottom: 16px;

    label {
      cursor: pointer;
      padding: 6px 14px;
      line-height: 1.5;
    }
  }
}
