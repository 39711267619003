.register-certification {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bgcolor-primary;

  @include mq-sp {
    justify-content: flex-start;
    align-items: flex-start;
  }

  // アプリheaderがネイティブのときにheader以下をつめるために使用
  &.is-app {
    padding-top: 50px;
  }

  &__container {
    width: 860px;
    background-color: $bgcolor-normal;
    padding: 50px;
    @include mq-sp {
      background-color: $bgcolor-primary;
      padding: 40px;
    }
  }

  &__title {
    height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: $color-black;
    @include mq-sp {
      height: 20px;
      font-size: 20px;
    }
  }

  &__image {
    margin: 24px auto 24px auto;
    @include mq-sp {
      margin: 60px auto 24px auto;
    }

    &-- {
      &not-certificated {
        &__image {
          width: 148px;
          height: 98px;
          background: url("/assets/img/certificate.svg") no-repeat 0 0;
          background-size: contain;
          font-size: 0;
          @include mq-sp {
            width: 210px;
            height: 137px;
          }
        }
      }
    }
  }

  &__text {
    text-align: center;
    font-size: 16px;
    color: $color-black;
    @include mq-sp {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__btn-certificate-wrapper {
    margin-top: 32px;
    text-align: center;
  }

  &__btn-certificate {
    @extend .button--secondary;
    width: 149px;
  }

  &__link-skip-wrapper {
    margin-top: 16px;
    text-align: center;
  }

  &__link-skip {
    height: 14px;
    color: $color-uiblue;
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.03px;

    &:hover {
      text-decoration: none;
    }
  }
}
