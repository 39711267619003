.visit-complete-modal {
  &__ {
    &inner {
      position: relative;
      margin: 24px;
      padding: 24px 16px;
      width: 100%;
      background: $bgcolor-normal;
      border-radius: 12px;
      transform: translate3d(0, 100vh, 0) rotate(15deg);
      transform-origin: right top;
      transition: 0.3s ease-in;
      max-height: 90%;
      display: flex;
      flex-direction: column;
      gap: 24px;

      @include mq-pc {
        max-width: 480px;
        padding: 24px;
      }

      &--show {
        transform: none;
        transition-timing-function: ease-out;
      }
    }

    &scroll-area {
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &avatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: block;
      margin: 0 auto 16px auto;
      background-size: cover;
      background-position: 50%;

      @include mq-sp {
        width: 64px;
        height: 64px;
      }
    }

    &title {
      font-weight: bold;
      font-size: $font-size-LL;
      text-align: center;
      line-height: 24px;
      margin-bottom: 24px;
    }

    &text {
      width: 100%;
      margin-bottom: 16px;
      padding: 8px 12px;
      box-sizing: border-box;
      border: 1px solid $bdcolor-normal;
      font-size: $font-size-small;
      line-height: 1.7;
      display: block;
      border-radius: 4px;
      height: 146px;
    }

    &caption {
      color: $color-pink;
      font-size: 13px;
      margin: 0 24px 24px;
      text-align: center;
      line-height: 21px;
    }

    &btn {
      font-size: $font-size-basic;
      line-height: 150%;
      display: block;
      transition: 0.1s linear;
      pointer-events: auto;
      font-weight: bold;
      width: auto;
      min-width: 140px;
      height: 44px;

      &--disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }

    &label {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      padding: 0;
      margin-bottom: 24px;
      font-size: 15px;
      -webkit-tap-highlight-color: $shadow-9;

      &:hover {
        cursor: pointer;
      }
    }

    &check {
      appearance: none;
      opacity: 0;
    }

    &check-text {
      font-weight: bold;

      @include mq-sp {
        font-size: 14px;
      }
    }

    &btn-recommend {
      width: 66px;
      height: 34px;
      border: none;
      background: none;
      padding: 0;
      position: absolute;
      left: 0;
      top: 0;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &--footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}

.visit-complete-modal-confirmation {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $color-gray;
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > img {
    display: block;
    width: 100%;
  }

  &__title {
    font-size: $font-size-LL;
    font-weight: bold;
    text-align: center;
  }

  &__description {
    font-size: $font-size-basic;
    font-weight: bold;
  }

  ul {
    width: 100%;
    margin-top: 4px;
    list-style: disc;
    padding-left: 20px;

    li {
      font-size: $font-size-small;
    }
  }

  &__important-check-list {
    color: $fgcolor-danger
  }
}
