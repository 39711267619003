.company-list {
  @include clearfix();
  height: 330px;
  overflow: hidden;
  @include mq-sp {
    padding: 0;
    height: auto;
  }

  &__item {
    position: relative;
    float: left;
    width: 300px;
    height: 140px;
    @include mq-sp {
      box-sizing: border-box;
      float: none;
      width: 100%;
      height: auto;
      padding: 10px 12px;
      border-top: 1px solid $bdcolor-normal;
    }
  }

  &__item__inner {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;

    &:hover {
      @include mq-pc {
        background-color: $bgcolor-normal;
        opacity: 0.6;
      }
    }
  }

  &__figure {
    margin: 0;
    flex-basis: 80px;
    @include mq-sp {
      flex-basis: 50px;
    }
  }

  &__logo {
    background-color: $bgcolor-normal;
    @include round-bd-image(70px);
    @include mq-sp {
      @include round-bd-image(50px);
    }
  }

  &__caption {
    font-size: 0;
  }

  &__info {
    @include mq-pc {
      flex-basis: 210px;
    }
    @include mq-sp {
      margin-left: 8px;
      flex-basis: calc(100% - 140px);
    }
  }

  &__name {
    padding-top: 4px;
    font-size: $font-size-small;
    font-weight: bold;
    @include text-over-flow-multiline($font-size-small, 1.4);
    @include mq-sp {
      display: block;
      padding-top: 0;
      font-size: $font-size-small;
      overflow: hidden;
      white-space: normal;
      text-overflow: clip;
      line-height: 1.3;
      $height: $font-size-small * 1.3 * 2;
      height: auto;
      max-height: $height;
    }
  }

  &__industry {
    font-size: $font-size-smaller;
    color: $fgcolor-supplemental;
    @extend .text-empty;
    @include mq-sp {
      font-size: $font-size-smaller;
    }
  }

  &__under {
    margin-left: 58px;
    @include mq-pc {
      margin-left: 80px;
    }
  }

  &__person {
    display: inline-block;
  }

  &__person--obog {
    font-size: $font-size-smaller;
    font-weight: bold;
    color: $fgcolor-primary-theme;
    @include mq-sp {
      font-size: $font-size-smaller;
    }
  }

  &__person--obog-count {
    font-size: $font-size-smaller;
    font-weight: bold;
    color: $fgcolor-primary-theme;
    @extend .text-empty;
    @include mq-sp {
      font-size: $font-size-smaller;
    }
  }

  &__person--follower {
    font-size: $font-size-smaller;
    color: $fgcolor-supplemental;
    @include mq-sp {
      font-size: $font-size-smaller;
    }
  }

  &__person--follower-count {
    font-size: $font-size-smaller;
    color: $fgcolor-supplemental;
    @extend .text-empty;
  }

  &__button {
    @extend .button--secondary;
    position: absolute;
    right: 2px;
    display: block;
    width: 220px;
    height: 40px;
    line-height: 35px;
    z-index: 1;
    border-radius: 4px;
    font-weight: bold;
    @include mq-sp {
      position: absolute;
      top: 15px;
      right: 12px;
      z-index: 2;
      padding-left: 0;
      padding-right: 0;
      width: 70px;
      height: 25px;
      line-height: 20px;
      font-size: $font-size-smaller;
    }
  }

  .button--secondary.is-done::before {
    display: none;
  }
}

.company-list__person + .company-list__person {
  margin-left: 5px;
}
