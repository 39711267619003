.withdrawal-convert {
  @include mq-pc {
    width: 576px;
  }
  background-color: $color-lightgray;
  border-radius: 8px;
  margin: 15px auto 40px;
  padding: 24px 16px 24px;
  box-sizing: border-box;

  &__ {
    &title {
      @include mq-pc {
        font-size: $font-size-large;
        text-align: center;
      }
      font-size: $font-size-basic;
      font-weight: bold;
      margin-bottom: 12px;
      line-height: 24px;
    }

    &msg {
      font-size: $font-size-small;
      line-height: 24px;
      margin-bottom: 12px;
    }

    // FIXME 最新のガイドラインのbuttonのスタイルを共通化して、該当する全てのbuttonに適用する。以下の改善チケットで対応予定。
    // https://jira.bizreach.co.jp/browse/ZERO-6532
    &btn {
      display: block;
      margin: 0 auto;
      width: 125px;
      height: 44px;
      line-height: 44px;
      font-size: $font-size-basic;
      font-weight: bold;
      color: $color-uiblue;
      background-color: $color-white;
      border: 1px solid $color-uiblue;
      border-radius: 24px;
      text-align: center;

      &:hover {
        text-decoration: none;
        color: $color-blue-2;
        border: 1px solid $color-blue-2;
        transition: 0.1s linear;
      }
    }
  }
}

.withdrawal-list {
  li {
    &:before {
      content: "・";
    }
  }

  li + li {
    margin-top: 15px;
  }
}

.withdrawal-list + h2 {
  margin-top: 60px;
}

.withdrawal-text {
  line-height: 1.6;
}

.withdrawal-table {
  width: 100%;
  box-sizing: border-box;

  th {
    width: 199px;
    padding: 20px 20px 0 0;
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    vertical-align: middle;

    @include mq-sp {
      display: block;
    }
  }

  td {
    padding-top: 20px;

    @include mq-sp {
      display: block;
    }
  }

  select.is-require {
    background-color: $bgcolor-input-require;
  }

  select {
    @include mq-sp {
      width: 100%;
    }
  }

  textarea {
    width: 100%;
    box-sizing: border-box;
    height: 135px;
  }

  .is-require-mark {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }

  .naitei-company-header {
    padding: 30px 20px 0 0;
    vertical-align: top;
  }

  &__ {
    &naitei-get {
      &__ {
        &label {
          padding: 0 28px 0 0;
        }
      }
    }

    &naitei-company-list {
      &__ {
        &item {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
        }

        &input {
          flex: 1;
          width: 300px;

          &--error {
            background: $color-pink-2;
            border-color: $color-pink;
          }
        }

        &btn-del {
          font-size: $font-size-small;
          margin-left: 8px;
          font-weight: bold;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    &naitei-company-note {
      font-size: 14px;
      color: $color-darkgray;
    }
  }
}

.withdrawal-notice {
  margin-top: 15px;
  text-align: center;
}

.withdrawal-btn-box {
  margin-top: 10px;
  text-align: center;

  button {
    @include mq-sp {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  button + button {
    margin-left: 10px;

    @include mq-sp {
      margin-top: 10px;
      margin-left: 0;
    }
  }
}

.withdrawal-done {
  width: 561px;
  background-color: $bgcolor-normal;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 32px;
  margin: 50px auto 0;

  @include mq-sp {
    width: calc(100% + 30px);
    margin: 32px -15px;
    border-radius: 0;
    padding: 32px 16px;
  }

  &__ {
    &title {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 16px;
    }

    &description {
      font-size: 16px;
      line-height: 24px;
    }

    &link {
      display: inline-block;
      margin-top: 32px;
    }

    &img {
      width: 498px;
      height: 174px;
      border-radius: 8px;
      box-shadow: 0 12px 20px $boxshadow-soft-color;

      @include mq-sp {
        width: 100%;
        height: 133px;
      }
    }

    &link_off_peak {
      display: inline-block;
      margin-top: 32px;
    }

    &img_off_peak_pc {
      width: 498px;
      height: 174px;
      border-radius: 8px;
      box-shadow: 0 12px 20px $boxshadow-soft-color;
    }

    &img_off_peak_sp {
      width: 100%;
      height: auto;
      border-radius: 8px;
      box-shadow: 0 12px 20px $boxshadow-soft-color;
    }
  }
}
