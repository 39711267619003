// --------------------------------------------------------
// mediaquery
// --------------------------------------------------------
@mixin mq-sp-small($breakpoint: 320px) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin mq-sp-middle {
  @media screen and (min-width: 321px) and (max-width: 640px) {
    @content;
  }
}

@mixin mq-sp($breakpoint: 640px) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin mq-middle {
  @media screen and (min-width: 640px) and (max-width: 959px) {
    @content;
  }
}

@mixin mq-pc($breakpoint: 641px) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

//clearfix
@mixin clearfix() {
  zoom: 1;
  &:after {
    clear: both;
    content: "";
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}

// 丸作成
@mixin circle($value) {
  width: $value;
  height: $value;
  border-radius: $value;
}

// 三角作成
// 上向き三角
@mixin triangle-top($width-half, $height, $color) {
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 $width-half $height $width-half;
  border-color: transparent transparent $color;
}

// 行末の三点リーダー
@mixin text-over-flow($value) {
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: $value;
}

// 複数行でover-flow hidden
@mixin text-over-flow-multiline($font-size: 14px, $line-height: 1.7, $line: 2, $fix-height: true) {
  overflow: hidden;
  $height: $font-size * $line-height * $line;
  @if $fix-height {
    height: $height;
  } @else {
    max-height: $height;
  }
  width: 100%;
}

@mixin text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin text-overflow-flexible {
  @include text-overflow;
  width: 0;
  min-width: 100%;
  box-sizing: border-box;
}

// 横幅が可変のelipsis
@mixin variable-ellipsis() {
  display: table;
  width: 100%;
  &__main {
    display: table;
    width: 100%;
    table-layout: fixed;

    > p {
      display: table-cell;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__sub {
    display: table-cell;
    white-space: nowrap;
  }
}

// Round-Image
//
// @example
// <figure class="size-classes round-image" style="background-image:url('/assets/img/no-image.png')"></figure>
// <figure class="round-bd-image50" style="background-image:url('/assets/img/no-image.png')"></figure>
//mixin
@mixin round-image($size: 40px) {
  width: $size;
  height: $size;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  box-sizing: border-box;
}

//線付き丸画像
@mixin round-bd-image($size: 40px, $display: inline-block, $border: 1px solid $bdcolor-normal) {
  @include round-image($size);
  display: $display;
  border: $border;
}

//画像の比率1:1
@mixin media--square($value) {
  display: block;
  width: $value;
  height: $value;
  background-size: cover;
}

@mixin image--responsive($height: 400px, $width: 960px) {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: auto;
  height: 0;
  padding-top: percentage($height/$width);

  @include mq-sp {
    position: relative;
  }
}

@mixin l-grid-container($padding-top, $padding-bottom) {
  //スマホ表示では全て100%になる。pcでは960px(20行目参照)
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: $padding-bottom;
  @include mq-pc {
    padding-top: $padding-top;
  }
  @include mq-sp {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 1px;
  }
}

$btn-height: 45px;
$btn-font: 14px;
$btn-line-height: 45px;

@mixin btn {
  display: inline-block;
  height: $btn-height;
  line-height: $btn-line-height;
  text-align: center;
  cursor: pointer;
  font-size: $font-size-basic;
  user-select: none;
  border: 1px solid $bdcolor-button;
  border-radius: $bdradius-normal;
  color: $fgcolor-button;
  background-color: $bgcolor-button;
  box-sizing: border-box;
  outline: none;
  transition: background-color 300ms linear;

  @include mq-pc {
    &:hover {
      color: $fgcolor-button;
      background-color: $bgcolor-button-hover;
      text-decoration: none;
      transition: background-color 300ms linear;
    }
  }

  &[disable] {
    color: $fgcolor-button-disabled;
    background-color: $bgcolor-button-disabled;
    border: 0;
    cursor: default;
  }

  &.btn-big {
    width: 295px;

    @include mq-sp {
      width: 100%;
    }
  }

  &.btn-small {
    width: 145px;
  }
}

@mixin btn-states {
  &.is-loading {
    color: $fgcolor-button;
    background-color: $bgcolor-button-hover;

    &::before {
      content: "\f110";
      @extend .fa;
      @extend .fa-pulse;
      @extend .fa-fw;
    }
  }

  &.is-done {
    &::before {
      content: "\f00c";
      @extend .fa;
      @extend .fa-fw;
    }
  }
}

@mixin button {
  position: relative;
  display: inline-block;
  height: $btn-height;
  line-height: $btn-line-height;
  text-align: center;
  cursor: pointer;
  font-size: $font-size-small;
  user-select: none;
  border: 1px solid $bdcolor-button;
  border-radius: $bdradius-normal;
  box-sizing: border-box;
  outline: none;
  transition: background-color 300ms linear;
  text-decoration: none;
  width: 280px;

  @include mq-sp {
    width: 100%;
  }

  @include mq-pc {
    &:hover {
      text-decoration: none;
      transition: background-color 300ms linear;
    }
  }

  &[disable] {
    color: $fgcolor-button-disabled;
    background-color: $bgcolor-button-disabled;
    border: 0;
    cursor: default;
  }

  &.is-done {
    background-color: $bgcolor-button-done;
    border: 1px solid $bgcolor-button-done;
    color: $basecolor-primary;
  }
}

@mixin icon--left($padding-left, $margin-top) {
  position: relative;
  padding-left: $padding-left;
  line-height: 1;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: $margin-top;
  }
}

@mixin icon--right($padding-right, $margin-top) {
  position: relative;
  padding-right: $padding-right;
  line-height: 1;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: $margin-top;
  }
}

@mixin label(
  $background-color: $basecolor-primary,
  $font-color: $basecolor-reverse,
  $border-color: $basecolor-primary
) {
  display: inline-block;
  margin-right: 5px;
  min-width: 45px;
  text-align: center;
  border: 1px solid $border-color;
  border-radius: 2px;
  font-size: 10px;
  line-height: 1;
  background-color: $background-color;
  color: $font-color;
  padding: 2px 4px;
  vertical-align: top;
}

@mixin overlay($z-index: 20) {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index;
  background-color: $bgcolor-overlay;

  &.is-active {
    display: block;
  }
}

@mixin popup-background {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin hovered-button($color, $border-color, $background-color) {
  color: lighten($color, 20%);
  border-color: lighten($border-color, 20%);
  background-color: lighten($background-color, 20%);
  text-decoration: none;
  transition-duration: 0.1s;
}

@mixin hidden-scroll-bar {
  // NOTE スクロールバー削除
  // sass-lint:disable no-vendor-prefixes no-misspelled-properties
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /*Firefox*/

  &::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
  }
}
