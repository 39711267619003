.ios16-safari-issue {
  &-register-password {
    @include mq-pc {
      display: none;
    }

    color: $color-black;
    background-color: $color-white;
    padding: 10px;
    margin: 20px 0;
    border-radius: 5px;

    &--head {
      font-weight: bold;
      font-size: 12px;
    }

    &--p {
      margin-top: 5px;
      font-size: 12px;
    }
  }

  &-login {
    @include mq-pc {
      display: none;
    }

    color: $color-black;
    background-color: $color-lightgray;
    padding: 10px;
    margin-top: -10px;
    margin-bottom: 20px;
    border-radius: 5px;

    &--head {
      font-weight: bold;
      font-size: 12px;
    }

    &--p {
      margin-top: 5px;
      font-size: 12px;
    }
  }

  &-password-reset {
    @include mq-pc {
      display: none;
    }

    color: $color-black;
    background-color: $color-lightgray;
    padding: 10px;
    margin: 10px 0 20px;
    border-radius: 5px;

    &--head {
      font-weight: bold;
      font-size: 12px;
    }

    &--p {
      margin-top: 5px;
      font-size: 12px;
    }
  }
}
