.l-setting {
  background: $bgcolor-normal;

  h1 + h2,
  .setting-list + h2 {
    margin-top: 30px;
  }

  h2.heading.heading-border {
    line-height: 1;
    padding-bottom: 10px;
  }

  ul.tool-tip-detail-list {
    list-style-type: disc;

    li {
      margin-left: 20px;
    }
  }
}
