.visit-survey-modal {
  &-inner {
    position: relative;
    z-index: 10;
    margin: 24px;
    width: 100%;
    background: $bgcolor-normal;
    border-radius: 12px;
    transition: 0.3s ease-in;
    max-height: 90%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }

    @include mq-pc {
      max-width: 480px;
    }
  }

  &-header {
    text-align: center;
    padding: 8px 0;
    border-bottom: 1px solid $color-gray;

    > h1 {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
    }
  }

  &-content {
    margin-top: 16px;
    padding: 0 24px;

    > p {
      font-size: 14px;

      & + p {
        margin-top: 16px;
      }
    }

    &-attention-description {
      color: $fgcolor-danger;
    }
  }

  &-footer {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    padding: 0 24px 16px;
    gap: 16px;

    > button {
      padding: 8px 20px;
      height: auto;
      line-height: 1.5;
    }
  }

  &-submit {
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
  }
}
