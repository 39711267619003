.article-list {
  font-size: 0;

  > li {
    border-radius: 2px;
    position: relative;
    width: 310px;
    padding: 15px;
    box-shadow: 0 3px 3px 0 $basecolor-shadow-color;
    border: $basecolor-shadow;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    background-color: $bgcolor-normal;
    transition: all 0.5s ease;
    word-break: break-all;
    @include mq-sp {
      margin-top: 15px;
      width: 100%;
      padding: 10px 12px;
    }

    &:hover {
      box-shadow: 0 10px 10px 0 $shadow-2;
      transition: all 0.5s ease;
    }
  }

  > li:nth-of-type(-n + 3) {
    margin-top: 0;
    @include mq-sp {
      margin-top: 15px;
    }
  }

  li:not(:nth-child(3n-2)) {
    margin-left: 15px;
    @include mq-sp {
      margin-left: 0;
    }
  }

  li + li {
    margin-top: 15px;
  }

  &__head {
    position: relative;
    @include mq-sp {
      display: inline-block;
      width: 105px;
      vertical-align: top;
    }
  }

  &__image {
    background-image: url("/assets/img/company-article-no-main.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: auto;
    height: 0;
    padding-top: 46.8%;
    margin: -15px;
    @include mq-sp {
      background-image: url("/assets/img/company-article-no-main-sp.jpg");
      width: 115px;
      height: 115px;
      padding-top: 0;
      margin: -10px -10px -11px -12px;
    }
  }

  &__external-link {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    right: -15px;
    padding: 3px;
    width: 25px;
    height: 25px;
    background-color: $bgcolor-overlay;
    color: $fgcolor-label-gray;
    @include mq-sp {
      left: 78px;
      bottom: -10px;
    }

    > i {
      $external-icon-size: 19px;
      font-size: $external-icon-size;
    }
  }

  &__body {
    margin-top: 14px;
    @include mq-sp {
      box-sizing: border-box;
      display: inline-block;
      margin-top: 0;
      width: calc(100% - 105px);
      padding-left: 5px;
    }
  }

  &__tag {
    float: left;
    margin-top: 15px;
    display: inline-block;
    vertical-align: middle;
    padding: 4px 9px;
    font-size: $font-size-smaller;
    line-height: 1;
    border: 1px solid $bdcolor-tag-accent;
    border-radius: 5px;
    background-color: $bgcolor-normal;
    color: $fgcolor-tag-accent;
    @include mq-sp {
      margin-top: 0;
      padding: 4px 6px;
      @include text-over-flow(70%);
    }
  }

  &__time--pc,
  &__time--sp {
    float: right;
    margin-top: 18px;
    display: inline-block;
    font-size: $font-size-smaller;
    color: $fgcolor-supplemental;
  }

  &__time--pc {
    @include mq-sp {
      display: none;
    }
  }

  &__time--sp {
    margin-top: 4px;
    @include mq-pc {
      display: none;
    }
  }

  &__heading {
    margin-top: 6px;
    font-size: $font-size-LL;
    font-weight: 500;
    line-height: 1.4;
    @include text-over-flow-multiline($font-size-LL, 1.4, $line: 2);
    @include mq-sp {
      font-size: $font-size-small;
      height: 40px;
    }
  }

  &__complement {
    margin-top: 8px;
    display: table;
    width: 100%;
    font-size: $font-size-small;
  }

  &__complement--upper {
    @include clearfix();
    height: 37px;
    @include mq-sp {
      height: 20px;
    }
  }

  &__company-image {
    display: table-cell;
    white-space: nowrap;

    > span {
      @include round-bd-image(45px);
      vertical-align: middle;
    }

    @include mq-sp {
      display: none;
    }
  }

  &__company-name {
    display: table;
    table-layout: fixed;
    width: 100%;
    font-size: $font-size-small;

    > span {
      display: table-cell;
      padding-left: 5px;
      padding-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: baseline;
      @include mq-sp {
        padding-left: 0;
      }
    }

    @include mq-sp {
      font-size: $font-size-smaller;
    }
  }

  &__reaction {
    display: table-cell;
    white-space: nowrap;
    vertical-align: middle;
    font-size: $font-size-smaller;
    font-weight: 300;
    color: $fgcolor-supplemental;

    > i {
      margin-right: 2px;
    }
  }
}
