// Default Variables
$material-icon-font-path: "/assets/fonts/material-design-icons/" !default;
$material-icon-font-family: "Material Icons" !default;

@font-face {
  font-family: "#{$material-icon-font-family}";
  font-style: normal;
  font-weight: 400;
  src: url("#{$material-icon-font-path}MaterialIcons-Regular.eot"); /* For IE6-8 */
  src: local("#{$material-icon-font-family}"), local("MaterialIcons-Regular"),
    url("#{$material-icon-font-path}MaterialIcons-Regular.woff2") format("woff2"),
    url("#{$material-icon-font-path}MaterialIcons-Regular.woff") format("woff"),
    url("#{$material-icon-font-path}MaterialIcons-Regular.ttf") format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}
