// sass-lint:disable nesting-depth
.top {
  .header {
    background-color: rgba(255, 255, 255, 0.96);
    color: $color-uiblue;
  }

  .global-nav__link {
    &:hover {
      color: $fgcolor-darkbg;
    }
  }

  .global-nav__link--active {
    border-bottom: 3px solid $bdcolor-normal;
    font-weight: bold;
  }
}

.header {
  position: fixed;
  top: 0;
  z-index: 101;
  width: 100%;
  height: 60px;
  background-color: $color-white;
  border-bottom: 1px solid $bdcolor-normal;
  box-sizing: border-box;

  &__btn {
    box-sizing: border-box;
    padding: 0;
    width: 80px;
    height: 32px;
    background: none;
    border-radius: 16px;
    border: 1px solid $color-white;
    color: $fgcolor-primary-theme;
    font-weight: bold;
    line-height: 30px;
    outline: none;
    text-align: center;
    transition: 0.075s linear;

    @include mq-sp {
      width: 72px;
      border-radius: 22px;
      font-size: $font-size-small;
    }

    &:hover {
      text-decoration: none;
      transition: 0.075s linear;
      @include mq-pc {
        color: $color-white;
        background-color: $color-uiblue;
        border: 1px solid $color-uiblue;
      }
    }

    &--register {
      display: block;
      margin-right: 8px;
      font-weight: bold;
      cursor: pointer;

      &--lp {
        display: none;
      }
    }

    &--login {
      &--lp {
        height: 44px;
        width: 87px;
        border-radius: 22px;
        border: solid 1px $color-uiblue;
        line-height: 42px;
      }
    }
  }

  .header-inner {
    position: relative;
    width: 960px;
    height: 100%;
    margin: 0 auto;

    @include mq-sp {
      width: 100%;
    }

    &--front {
      max-width: 960px;
      width: auto;
    }
  }

  &.is-static {
    position: static;

    .header-inner {
      width: 100%;
      max-width: 960px;
    }

    @include mq-sp {
      .header-logo {
        top: 50%;
        transform: translateY(-50%);
        margin-left: 15px;
      }
    }
  }

  .header-lp-inner {
    position: relative;
    width: 1280px;
    height: 100%;
    margin: 0 auto;

    @include mq-sp {
      width: 100%;
    }

    &--front {
      max-width: 1280px;
      width: auto;

      @include mq-sp {
        max-width: 382px;
        height: 60px;
      }
    }

    &.is-static {
      position: static;

      .header-inner {
        width: 100%;
        max-width: 960px;
      }
    }
  }

  .header-logo {
    float: left;
    position: relative;
    transform: translateY(-50%);

    a {
      display: block;
      color: inherit;

      &:hover {
        opacity: $opacity-normal;
      }
    }

    @include mq-pc {
      top: 50%;
    }

    @include mq-sp {
      display: inline-block;
      float: none;
      transform: none;
    }

    &--front-page {
      position: static;
      float: none;
      transform: none;
      display: block;
      margin-right: 24px;

      @include mq-sp {
        margin-right: 8px;
        margin-left: 0;
      }
    }

    img {
      display: block;
      width: auto;
      height: 30px;
    }

    img.header-logo__img {
      display: block;
      width: 138px;
      height: 36px;
    }
  }

  // smart phone
  .header__button {
    &--right {
      &:hover {
        text-decoration: none;
      }

      @include mq-pc {
        display: none;
      }
      @include mq-sp {
        position: absolute;
        top: 8px;
        font-size: 24px;
        color: $basecolor-primary;
      }
    }
  }
}

.header-lp-actions {
  display: flex;
  @include mq-pc($lp-breakpoint-pc) {
    margin-left: 40px;
    gap: 24px;
  }
  @include mq-sp($lp-breakpoint-sp) {
    gap: 12px;
  }
}

.header-login {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

.header__front-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 16px;
  @include mq-pc {
    margin: 0;
    padding: 0 24px;
  }
  @include mq-middle {
    margin: 0 16px;
  }
}

.global-nav {
  &__ {
    &list {
      display: flex;
      align-items: center;
    }

    &link {
      display: block;
      color: $fgcolor-primary-theme;
      font-weight: bold;
      text-decoration: none;
      height: 69px;
      line-height: 69px;
      box-sizing: border-box;
      padding: 0 12px;

      &:hover {
        text-decoration: none;
        border-bottom: 3px solid $color-black-14;
        color: $fgcolor-primary-theme;
        transition: 0.05s border-color linear;
      }

      @include mq-sp {
        font-size: $font-size-smaller;
        height: 43px;
        line-height: 46px;
        padding: 0 8px;
      }

      &-- {
        &active {
          border-bottom: 3px solid $bdcolor-primary-theme;
          font-weight: bold;
        }
      }
    }
  }

  &--not-logged-in {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }
}

.top .global-lp-nav,
.staticpage-companies-body .global-lp-nav {
  &__ {
    &link {
      @include mq-sp(800px) {
        display: none;
      }

      &:hover {
        color: $color-uiblue;
      }
    }
  }
}

.header-lp--index {
  @include mq-sp {
    height: 60px;
  }
}
