.form-control {
  .profile-edit-form-input-text {
    width: 300px;

    @include mq-sp {
      width: 100%;
    }

    &.profile-edit-form-input-name {
      width: 230px;

      @include mq-sp {
        width: 100%;
      }

      &__basic {
        @include mq-sp {
          width: 49%;
        }
      }

      & + .profile-edit-form-input-name {
        margin-left: 5px;

        @include mq-sp {
          margin-left: 0;
        }
      }
    }

    &.profile-edit-form-input-toeic {
      width: 100px;
    }
  }
}

.profile-edit-form-selectbox {
  width: 300px;

  @include mq-sp {
    width: 100%;
  }

  &.profile-edit-form-selectbox-year {
    width: 98px;
  }

  &.profile-edit-form-selectbox-month {
    width: 80px;
  }

  &.profile-edit-form-selectbox-date {
    width: 80px;
  }
}

.profile-edit-form-table .form-control.form-control-margin .form-table-content {
  @include mq-sp {
    margin-top: 8px;
  }
}

.profile-form-table-title {
  @include mq-sp {
    color: $basecolor-sp-primary;
    text-align: left;
  }
}

.profile-form-table-title-long {
  @include mq-pc {
    padding-top: 8px;
    vertical-align: top;
  }
}
