.search-condition-header {
  + .ob-card-list {
    margin-top: 20px;
  }
}

.ob-search-condition-header {
  margin-top: 50px;
  @include mq-sp {
    margin-top: 20px;
  }
}
