.preferred-list {

  &__category-item {
    border: 1px solid $color-bluegray;
    border-radius: 16px;
    background-color: $bgcolor-normal;

    @include mq-pc {
      // スマホブラウザによっては要素をタップするとhoverしていることになるので、PCのみ指定
      transition: background-color .3s linear;
      &:hover {
        background-color: #f3f5f7;
      }
    }

    @include mq-sp {
      margin: 0 16px;
      transition: 0.1s ease-out
    }

    & + & {
      margin-top: 8px;
      @include mq-sp {
        margin-top: 16px;
      }
    }

    &--selected {
      border: 1px solid $color-uiblue;
    }

    &--expanded {
      @include mq-pc {
        &:hover {
          background-color: $bgcolor-normal;
          transition: none;
        }
      }

      @include mq-sp {
        border-width: 1px 0 1px 0;
        border-radius: 0;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  &__category-header {
    display: flex;
    flex-direction: row;

    &-select-all {
      padding: 16px 0 16px 16px;

      &-label {
        bottom: 9px;
        padding-right: 0;

        @include mq-sp {
          bottom: auto;

          &--without-preview {
            bottom: 9px;
          }
        }
      }
    }
  }

  &__category-overview {
    padding: 16px 8px 16px 0;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    cursor: default;

    @include mq-sp {
      flex-direction: column;
      justify-content: left;
    }
  }

  &__category-name {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 700;
    flex-basis: 264px;

    @include mq-sp {
      flex-basis: auto;
    }
  }

  &__category-select-all {
    &-text {
      color: $color-uiblue;
      font-weight: 700;
      padding-top: 8px;
      padding-right: 8px;
      padding-bottom: 8px;
    }
  }

  &__items-preview {
    font-size: 12px;
    line-height: 28px;
    flex: 1;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include mq-sp {
      text-align: left;
      line-height: 1.5;
    }
  }

  &__category-expander {
    padding-right: 16px;
    width: 24px;
    height: 60px;

    &:before {
      position: relative;
      display: block;
      content: "";
      left: 5px;
      top: 20px;
      width: 13px;
      height: 13px;
      box-sizing: border-box;
      border: solid $color-uiblue;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);

      @include mq-sp {
        top: 30px;
      }
    }

    @include mq-sp {
      &--without-preview {
        &:before {
          top: 20px;
        }
      }
    }

    &--expanded {
      &:before {
        top: 28px;
        transform: rotate(225deg);
      }
    }
  }

  &__items-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 16px;

    @include mq-sp {
      gap: 8px;
      margin-top: 0;
      margin-bottom: 16px;
    }
  }

  &__item-content {
    @extend .btn-keyword;
    margin: 0 !important;
  }

  &__expandable-view {
    padding: 0 16px 16px 16px;
  }
}

