.edit-receive-notification {
  &-wrapper {
    background-color: $color-lightgray;

    @include mq-sp {
      padding: 18px 0 0;
    }
  }

  &-content {
    background-color: $color-white;
    padding: 40px;

    @include mq-sp {
      padding: 15px;
    }

    &--option {
      & + & {
        margin-top: 15px;
      }
      display: flex;
      align-items: baseline;

      label {
        padding: 0;
        font-size: 16px;
        line-height: 1.5;
      }

      input[type="radio"] {
        margin: 0 4px 0 0;
      }

      p {
        margin-top: 4px;
        color: $color-darkgray;
        font-size: 12px;
        line-height: 16px;
      }
    }

    &--button {
      margin-top: 40px;
      text-align: center;

      .btn,
      .btn-secondary,
      .btn-big {
        @include mq-sp {
          width: 100%;
        }
      }
    }
  }

  &-note {
    margin-top: 25px;
    color: $color-darkgray;
    font-size: 12px;
    line-height: 18px;
  }
}
