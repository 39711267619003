.scout {
  @include clearfix;

  .submenu-horizontal {
    left: -15px;
    width: calc(100% + 30px);
    background-color: $bgcolor-normal;

    @include mq-pc {
      display: none;
    }
  }

  .scout-nav-list {
    background-color: $bgcolor-normal;
  }

  .scout-main {
    width: 700px;
    box-sizing: border-box;
    float: left;
    margin-left: 10px;
    @include mq-sp {
      width: auto;
      float: none;
      margin: 0;
      padding-top: 15px;
    }
  }

  .scout-trim-img-box {
    display: inline-block;
    vertical-align: -15px;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: url("/assets/img/blank-img.png") no-repeat center center;
    background-size: cover;
    @extend .bd-img-circle;
  }

  .scout-list {
    @include mq-sp {
      margin-top: 5px;
    }

    li {
      position: relative;
      background-color: $bgcolor-normal;
      box-shadow: 0 1px 3px 1px $boxshadow-soft-color;

      a {
        display: block;
        padding: 36px 30px;
        color: $fgcolor-normal;

        &:hover {
          text-decoration: none;
        }

        .scout-tag-matching {
          position: absolute;
          top: 10px;
          left: 10px;
          padding: 2px 10px 2px 25px;
          background-color: $color-black-13;
          border-radius: 2px;
          color: $basecolor-secondary;
          font-size: 10px;

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: -4px;
            bottom: 0;
            left: 11px;
            width: 4px;
            height: 8px;
            border: solid $basecolor-secondary;
            border-width: 0 2px 2px 0;
            margin: auto;
            transform: rotate(45deg);
          }
        }

        .scout-tag-caution {
          font-size: 10px;
          padding: 2px 10px;
          position: absolute;
          background-color: $bgcolor-button-disabled;
          border-radius: 2px;
          color: $fgcolor-label-gray;
          top: 10px;
          left: 10px;
        }

        .scout-tag-caution + .scout-tag-matching {
          left: 100px;
        }

        h2 {
          font-size: 16px;
          font-weight: normal;
          display: inline-block;
          @include mq-sp {
            font-size: 12px;
          }
        }

        time {
          position: absolute;
          top: 36px;
          right: 30px;
          font-size: 14px;
          @include mq-sp {
            font-size: 12px;
          }
        }
      }

      a.is-unread {
        h2,
        time,
        .scout-list-text {
          font-weight: bold;
        }
      }

      &.icon-meal-scout {
        position: relative;

        a {
          &:before {
            position: absolute;
            top: -2px;
            left: 5px;
            display: inline-block;
            content: "";
            width: 27px;
            height: 31px;
            background: url("/assets/icon/icon-meal-scout-ribbon.png") no-repeat top left;
            background-size: 27px 31px;
          }

          .scout-tag-matching {
            left: 41px;
          }

          .scout-tag-caution {
            left: 41px;
          }

          .scout-tag-caution + .scout-tag-matching {
            left: 131px;
          }
        }
      }

      + li {
        margin-top: 20px;
        @include mq-sp {
          margin-top: 10px;
        }
      }
    }
  }

  .scout-list-text {
    margin-top: 20px;
    width: 100%;
    @extend .text-over-flow;
    @include mq-sp {
      font-size: 12px;
    }
  }
}
