$lp-breakpoint1: 960px;

.top-global-footer {
  box-sizing: border-box;
  border-top: 1px solid $bdcolor-normal;
  padding: 24px 16px 16px 16px;
  background: $bgcolor-normal;

  @include mq-pc {
    display: flex;
    height: 136px;
    align-items: center;
  }
  @include mq-sp($lp-breakpoint1) {
    display: block;
  }

  &__ {
    &wrapper {
      @include mq-pc {
        display: flex;
        width: 960px;
        margin: 0 auto;
      }
      @include mq-sp($lp-breakpoint1) {
        display: block;
        width: 100%;
      }
    }

    &global-link-nav {
      display: flex;

      @include mq-sp($lp-breakpoint1) {
        flex-direction: column;
      }
    }

    &logo-link {
      width: 171px;
      display: block;
      margin: auto 40px auto 0;
      @include mq-sp($lp-breakpoint1) {
        display: block;
        margin: 0 auto 16px;
      }
    }

    &logo {
      width: 171px;
      display: block;
      line-height: 0;
      font-size: 0;
      @include mq-sp($lp-breakpoint1) {
        width: 115px;
        margin: 0 auto;
      }
    }

    &global-link {
      display: flex;
      color: $color-uiblue;
      font-weight: bold;
      text-decoration: none;
      box-sizing: border-box;
      margin: 0;
      align-items: center;

      @include mq-sp($lp-breakpoint1) {
        justify-content: center;
        margin-bottom: 24px;
      }

      a:hover {
        text-decoration: none;
      }
    }

    &global-link-article {
      margin-right: 24px;
    }

    &link-wrapper {
      @include mq-pc {
        flex: 1;
        justify-content: flex-end;
      }
      @include mq-sp($lp-breakpoint1) {
        display: block;
        flex: 0;
      }
    }

    &nav {
      @include mq-pc {
        display: flex;
        justify-content: flex-end;
      }
      @include mq-sp($lp-breakpoint1) {
        display: block;
      }
    }
  }
}

.top-footer-nav {
  font-size: $font-size-basic;
  margin: 0 auto 16px auto;
  min-height: 24px;
  line-height: 32px;

  @include mq-pc {
    margin: 0 0 8px 0;
    width: auto;
    justify-content: flex-end;
    display: flex;
    text-align: left;
  }
  @include mq-sp($lp-breakpoint1) {
    display: block;
    text-align: center;
    font-size: $font-size-small;
  }

  &__ {
    &item {
      display: inline-block;
      margin-left: 8px;
      @include mq-pc {
        margin-left: 24px;
      }

      &:first-child {
        margin-left: 0;
        justify-content: center;
      }

      &--with-icon {
        display: flex;

        &:before {
          content: "";
          display: inline-block;
        }
      }
    }

    &link {
      color: $color-black;
      display: block;
      transition: 0.075s linear;

      &:hover {
        text-decoration: none;
        transition: 0.075s linear;
        color: $color-bluegray;
      }
    }
  }

  &-- {
    &social {
      margin-bottom: 32px;
      display: flex;
      justify-content: center;
      line-height: 1;
      @include mq-pc {
        margin-bottom: 0;
        justify-content: flex-end;
      }
      @include mq-sp($lp-breakpoint1) {
        display: block;
        margin-bottom: 32px;
      }
    }
  }
}

.global-footer {
  box-sizing: border-box;
  border-top: 1px solid $bdcolor-normal;
  padding: 24px 16px 16px 16px;
  background: $bgcolor-normal;
  @include mq-pc {
    display: flex;
    height: 136px;
    align-items: center;
  }

  &__ {
    &logo-link {
      @include mq-pc {
        width: 171px;
        flex-basis: 171px;
        height: 48px;
        display: block;
      }
    }

    &logo {
      width: 115px;
      margin: 0 auto 8px auto;
      display: block;
      line-height: 0;
      font-size: 0;
      @include mq-pc {
        display: none;
      }

      &--large {
        @include mq-pc {
          display: block;
          width: 171px;
          margin: 0;
        }
        @include mq-sp {
          display: none;
        }
      }
    }

    &tag-line {
      display: block;
      color: $color-black;
      font-size: $font-size-smaller;
      font-weight: 600;
      margin-top: 10px;
      @include mq-sp {
        text-align: center;
        margin-bottom: 24px;
      }
    }

    &wrapper {
      @include mq-pc {
        display: flex;
        width: 960px;
        margin: 0 auto;
      }
    }

    &link-wrapper {
      @include mq-pc {
        flex: 1;
        justify-content: flex-end;
      }
    }

    &nav {
      @include mq-pc {
        display: flex;
        justify-content: flex-end;
      }
    }

    &copyright {
      width: 85px;
      height: 12px;
    }
  }
}

.footer-nav {
  font-size: $font-size-small;
  margin: 0 auto 16px auto;
  min-height: 24px;
  text-align: center;
  line-height: 32px;
  @include mq-pc {
    margin: 0 0 8px 0;
    font-size: $font-size-basic;
    width: auto;
    justify-content: flex-end;
    display: flex;
    text-align: left;
  }

  &__ {
    &link {
      color: $color-black;
      display: block;
      transition: 0.075s linear;

      &:hover {
        text-decoration: none;
        transition: 0.075s linear;
        color: $color-bluegray;
      }
    }

    &item {
      display: inline-block;
      margin-left: 16px;
      @include mq-pc {
        margin-left: 24px;
      }

      &:first-child {
        margin-left: 0;
      }

      &--with-icon {
        display: flex;

        &:before {
          content: "";
          display: inline-block;
        }
      }
    }
  }

  &-- {
    &social {
      margin-bottom: 32px;
      display: flex;
      justify-content: center;
      line-height: 1;
      @include mq-pc {
        margin-bottom: 0;
        justify-content: flex-end;
      }
    }
  }
}

@mixin clearfix() {
  zoom: 1;
  &:after {
    clear: both;
    content: "";
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}

.l-body-top-boss,
.l-body-top,
.l-login-body {
  .footer {
    @include mq-sp {
      width: 100%;
      min-width: inherit;
    }
  }
}

.footer {
  width: 100%;
  min-width: 960px;
  background-color: $bgcolor-footer;
  border-top: 1px solid $color-black-12;

  @include mq-sp {
    width: 100%;
    min-width: inherit;
  }

  .footer-inner {
    width: 960px;
    margin: 0 auto;
    @include clearfix;

    @include mq-sp {
      width: 90%;
    }
  }

  .footer-top {
    @include clearfix;
    padding: 20px 0;

    @include mq-sp {
      padding: 30px 0;
    }
  }

  .footer-logo {
    float: left;

    @include mq-sp {
      float: none;
    }

    img {
      display: block;
      width: 165px;
      height: auto;
      transition: opacity 300ms linear;

      @include mq-sp {
        width: 180px;
        margin: auto;
      }

      &:hover {
        opacity: $opacity-normal;
        transition: opacity 300ms linear;
      }
    }

    &__tag-line {
      display: block;
      color: $fgcolor-primary-theme;
      font-size: $font-size-smaller;
      font-weight: 600;
      margin-top: 10px;
      @include mq-sp {
        text-align: center;
      }
    }
  }

  .footer-menu {
    float: right;
    line-height: 80px;

    @include mq-sp {
      float: none;
      margin-top: 20px;
      line-height: 44px;
    }

    li {
      float: left;
      margin-left: 20px;

      @include mq-sp {
        margin-left: 0;
        float: none;
        text-align: center;
      }

      a {
        display: block;

        &:hover {
          text-decoration: none;
          color: $fgcolor-lowpriority;
        }
      }
    }
  }

  .footer-bottom {
    @include clearfix;
    margin-bottom: 20px;
    border-top: 1px solid $bdcolor-normal;
  }

  .footer-sns {
    float: left;
    font-size: 0; // inline-blockの隙間を埋めるため

    @include mq-sp {
      float: none;
      text-align: center;
    }

    li {
      display: inline-block;

      a {
        display: block;
        width: 40px;
        height: 40px;
        border: 1px solid $bdcolor-button-sns;
        border-radius: $bdradius-circle;
        box-sizing: border-box;
      }

      & + li {
        margin-left: 20px;
      }
    }

    .footer-sns-tw a {
      background-image: url("../resources/static/assets/img/sns-tw-off.png");
      background-position: center;
      background-size: 21px auto;
      background-repeat: no-repeat;

      &:hover {
        background-image: url("../resources/static/assets/img/sns-tw-on.png");
        background-color: $bgcolor-button-sns-tw;
        border: 1px solid $bdcolor-button-sns-tw;
      }
    }

    .footer-sns-fb a {
      background-image: url("../resources/static/assets/img/sns-fb-off.png");
      background-position: center;
      background-size: 21px auto;
      background-repeat: no-repeat;

      &:hover {
        background-image: url("../resources/static/assets/img/sns-fb-on.png");
        background-color: $bgcolor-button-sns-fb;
        border: 1px solid $bdcolor-button-sns-fb;
      }
    }

    .footer-sns-line a {
      background-image: url("../resources/static/assets/img/sns-line-off.png");
      background-position: center;
      background-size: 21px auto;
      background-repeat: no-repeat;

      &:hover {
        background-image: url("../resources/static/assets/img/sns-line-on.png");
        background-color: $bgcolor-button-sns-line;
        border: 1px solid $bdcolor-button-sns-line;
      }
    }
  }

  .footer-contact {
    float: right;
    text-align: right;

    @include mq-sp {
      float: none;
      margin-top: 20px;
      text-align: center;
    }

    .footer-contact-address {
      margin-top: 3px;
      font-size: 12px;
    }
  }

  .footer-copyright {
    line-height: 35px;
    color: $fgcolor-lowpriority;

    small {
      width: 960px;
      margin: auto;
      text-align: right;
      display: block;
      font-size: $font-size-basic;
      @include mq-sp {
        width: 90%;
        text-align: center;
        padding-bottom: 30px;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
